import { FormikSelect, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';
import { OptionsPropsEditor } from '../propseditor/OptionsPropsEditor';

const SelectField: React.FC<JsonikFieldProps> = props => (
  <FormikSelect
    {...props}
    options={props.props.options}
    getOptionLabel={(option: any) => option?.label || option}
    getOptionValue={(option: any) => option?.value || option}
  />
);

export const SelectFieldMap: JsonikFieldMap = {
  type: 'select',
  component: SelectField,
  friendlyName: 'Seleção',
  description: 'Aceita apenas valores pré-definidos em uma lista',
  yupType: Yup.mixed,
  getDefaultProps: () => ({
    options: ['OPÇÃO 1', 'OPÇÃO 2']
  }),
  editor: OptionsPropsEditor
};
