import CruzamentoPage from '../pages/desif/CruzamentoPage';
import { Roles } from '../utils/Roles';

const desifRoutes = [
  {
    path: '/desif/cruzamento',
    component: CruzamentoPage,
    title: 'Cruzamento de Informações',
    role: Roles.fis_desif_cruzamento_informacoes.name
  }
];

export default desifRoutes;
