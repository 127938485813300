import { DateUtils, Yup } from '@elotech/components';

import { Cnae } from '../../../types/Cnae';

const mensagemFiltrosObrigatorios: string =
  'Informe pelo menos um cnae, regime fiscal ou tipo de divergência.';

export const validationSchema = Yup.object().shape({
  dataInicial: Yup.date()
    .required()
    .max(DateUtils.getTodayDate())
    .label('Data inicial'),
  dataFinal: Yup.date()
    .required()
    .label('Data Final')
    .max(DateUtils.getTodayDate())
    .test(
      'dataFinalMaiorQueInicial',
      'A data final não pode ser menor do que o inicial',
      function(value: Date) {
        const { dataInicial } = this.parent;
        return dataInicial <= value;
      }
    ),
  regimeFiscal: Yup.number().test(
    'regimeFiscalObrigatorio',
    mensagemFiltrosObrigatorios,
    function(value) {
      const { cnae, tipo } = this.parent;
      return value || (cnae && cnae.id) || tipo;
    }
  ),
  cnae: Yup.object().test(
    'cnaeObrigatorio',
    mensagemFiltrosObrigatorios,
    function(value: Cnae) {
      const { idRegimeFiscal, tipo } = this.parent;
      return idRegimeFiscal || (value && value.id) || tipo;
    }
  )
});
