import { BasicInput, Yup } from '@elotech/components';
import { FieldProps } from 'formik';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';
import {
  reactQuillFormats,
  reactQuillModules
} from './ConfigurationReactQuill';

const HtmlField: React.FC<JsonikFieldProps> = props => {
  const reactQuillRef = useRef<ReactQuill>(null);
  return (
    <BasicInput
      {...props}
      render={({ field, form }: FieldProps) => (
        <ReactQuill
          theme={'snow'}
          ref={reactQuillRef}
          modules={reactQuillModules}
          formats={reactQuillFormats}
          value={field.value || ''}
          onChange={(any, Delta, Sources, editor) => {
            form.setFieldValue(field.name, editor.getHTML());
          }}
        />
      )}
    />
  );
};

export const HtmlFieldMap: JsonikFieldMap = {
  type: 'html',
  component: HtmlField,
  friendlyName: 'HTML',
  description: 'Editor de texto longo com formatação',
  yupType: Yup.string
};
