import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDate,
  Loading,
  Row
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import GeracaoProcessoAlvaraService from '../../../service/GeracaoProcessoAlvaraService';
import { GeracaoProcessoAlvara } from '../../../types/GeracaoProcesso';

type Props = {};

export const GeracaoProcessoAlvaraDataPage: React.FC<Props> = () => {
  const [processo, setProcesso] = useState<GeracaoProcessoAlvara>({
    id: 0
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setLoading(true);
    GeracaoProcessoAlvaraService.findById(id)
      .then(response => {
        setProcesso(response.data);
      })
      .catch(error =>
        Alert.error({ title: 'Ocorreu um erro ao buscar o Processo' }, error)
      )
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loading} />
        <DisplayDataGrid column={true}>
          <Row>
            <DisplayDataItem md={2} title="Processo">
              {processo.id}
            </DisplayDataItem>
            <DisplayDataItem md={2} title="Cadastro Geral">
              {processo.cadastroGeral?.id}
            </DisplayDataItem>
            <DisplayDataItem md={4} title="Razão Social">
              {processo.razaoSocial}
            </DisplayDataItem>
          </Row>
          <Row>
            <DisplayDataItem md={4} title="Data Cadastro">
              <FormattedDate value={processo.dataCadastro} />
            </DisplayDataItem>
            <DisplayDataItem md={4} title="Situação Mobiliário">
              {processo.situacaoMobiliario?.descricao}
            </DisplayDataItem>
            <DisplayDataItem md={4} title="Situação">
              {processo.situacao}
            </DisplayDataItem>
          </Row>
          <Row>
            <DisplayDataItem md={4} title="Justificativa">
              {processo.justificativa}
            </DisplayDataItem>
          </Row>
        </DisplayDataGrid>
      </Container>
    </>
  );
};
