import { EnumValue } from '../../../iss-admin/src/types';

export type TipoRelatorioGerencialOrdemServico = EnumValue;

export const tipoRelatorioGerencialOrdemServicoCodigo = {
  DOCUMENTOS_AUTUACAO: 'DA',
  ORDEM_SERVICO: 'OS'
};

export const tipoRelatorioGerencialOrdemServico: TipoRelatorioGerencialOrdemServico[] = [
  {
    codigo: tipoRelatorioGerencialOrdemServicoCodigo.DOCUMENTOS_AUTUACAO,
    descricao: 'Documentos de Autuação'
  },
  {
    codigo: tipoRelatorioGerencialOrdemServicoCodigo.ORDEM_SERVICO,
    descricao: 'Ordem de Serviço'
  }
];

export const tipoRelatorioGerencialOrdemServicoSelect = tipoRelatorioGerencialOrdemServico.map(
  tipo => ({
    key: tipo.codigo,
    value: tipo.codigo,
    label: tipo.descricao
  })
);
