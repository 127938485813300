import axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';

export const loadParametros = (modulo: string, parametro: string) => {
  return axios.get(`${CONTEXT_PATH}/parametro-geral/`, {
    params: { modulo, parametro }
  });
};

export default { loadParametros };
