import { Button, Modal, Panel } from '@elotech/components';
import React from 'react';

import { BuildInfo } from '../types/BuildInfo';

type Props = {
  onClose(): void;
  buildInfo: BuildInfo;
  onLinkNovidades(): void;
};

const AboutModal: React.FC<Props> = ({
  onClose,
  buildInfo,
  onLinkNovidades
}) => {
  return (
    <Modal onClose={onClose} maxWidth="40%">
      <Panel title="Sobre o ISS-Fiscalização">
        <p className="center mb-xs" id="teste">
          Versão: {buildInfo.build ? buildInfo.build.version : '0.0.0'}
        </p>

        <Button
          className="btn center"
          onClick={onLinkNovidades}
          type="submit"
          data-test-id="linkTeste"
        >
          Novidades da Versão
        </Button>
      </Panel>
    </Modal>
  );
};

export default AboutModal;
