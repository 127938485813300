import { ActionButton } from '@elotech/components';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { JsonikFieldMap, JsonikFieldProps } from '../common/types';
import {
  BlockPanel,
  ButtonPanel,
  Clear,
  Holder,
  HolderInfoPanel,
  StyledButtonDiv
} from './styledcomponents';

type JsonikFieldEditorProps = {
  field: JsonikFieldProps;
  map: JsonikFieldMap;
  onRemove(field: JsonikFieldProps): void;
  onEdit(field: JsonikFieldProps): void;
};

const FieldWrapper: React.FC<JsonikFieldEditorProps> = ({
  field,
  map,
  onRemove,
  onEdit
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Col md={field.size}>
      <Holder
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Row>
          <map.component {...field} size={12} disabled={true} />
          <Clear />
          <BlockPanel hover={hover}>
            <StyledButtonDiv>
              <ActionButton icon="pencil-alt" onClick={() => onEdit(field)} />
              <ActionButton icon="times" onClick={() => onRemove(field)} />
            </StyledButtonDiv>
            <HolderInfoPanel>
              <div>{field.name}</div>
              <div>{map.description}</div>
            </HolderInfoPanel>
          </BlockPanel>
        </Row>
      </Holder>
    </Col>
  );
};

export { FieldWrapper, ButtonPanel };
