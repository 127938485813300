import {
  Chip,
  FormattedCurrency,
  Panel,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import { CruzamentoBalanceteDmsDTO } from '../../types/Desif';

type Props = {
  values?: CruzamentoBalanceteDmsDTO[];
};

const CruzamentoBalanceteDmsListPage: React.FC<Props> = ({ values }) => {
  const highlightError = (item: CruzamentoBalanceteDmsDTO) =>
    item.diferencaCredito !== 0 ||
    item.diferencaDebito !== 0 ||
    ((item.valorCredito > 0 || item.valorDebito > 0) &&
      item.dmsLancado === 'N');
  return (
    <>
      <SectionTitle>Informações</SectionTitle>
      <Panel isTable className="mt-xs">
        <Table<CruzamentoBalanceteDmsDTO>
          values={values || []}
          keyExtractor={item => `${item.ano}-${item.mes}-${item.conta}`}
          highlightError={item => highlightError(item)}
          reduced={false}
        >
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Cosif"
            value={item => item.contaCosif}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Conta"
            value={item => item.conta}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Descrição"
            value={item => item.descricao}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Valor Crédito"
            align="right"
            value={item => <FormattedCurrency value={item.valorCredito} />}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Valor Débito"
            align="right"
            value={item => <FormattedCurrency value={item.valorDebito} />}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Diferença Crédito"
            align="right"
            value={item => <FormattedCurrency value={item.diferencaCredito} />}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Diferença Débito"
            align="right"
            value={item => <FormattedCurrency value={item.diferencaDebito} />}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Valor Crédito (DMS)"
            align="right"
            value={item => <FormattedCurrency value={item.vlrCreditoLancado} />}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="Valor Débito (DMS)"
            align="right"
            value={item => <FormattedCurrency value={item.vlrDebitoLancado} />}
          />
          <Table.Column<CruzamentoBalanceteDmsDTO>
            header="DMS"
            align="center"
            value={item =>
              item.dmsLancado === 'S' ? (
                <Chip value={'Sim'} color="neutral" outlined={false} />
              ) : (
                <Chip
                  value={'Não'}
                  color="negative"
                  outlined={highlightError(item) ? false : true}
                />
              )
            }
          />
        </Table>
      </Panel>
    </>
  );
};

export default CruzamentoBalanceteDmsListPage;
