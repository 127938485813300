import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { UsuarioLocalInterno } from '../types/UsuarioLocalInterno';

export const findAllByUsuarioId = (
  id: number
): AxiosPromise<UsuarioLocalInterno[]> =>
  axios.get(`${CONTEXT_PATH}/usuario-local-interno/${id}`);

export default { findAllByUsuarioId };
