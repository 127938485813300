import { Alert, Button } from '@elotech/components';
import { History } from 'history';
import React from 'react';
import styled from 'styled-components';

import {
  JsonikFieldMap,
  JsonikForm
} from '../../../common/components/JsonikForm';
import AtoProcessoFormularioValoresService from '../../../service/AtoProcessoFormularioValoresService';
import { AtoProcesso } from '../../../types/AtoProcesso';
import { AtoProcessoFormulario } from '../../../types/AtoProcessoFormulario';
import { Processo } from '../../../types/Processo';
import ApuracaoIssInfracaoComponent from './formularios-estaticos/Apuracao/ApuracaoIssInfracaoComponent';
import EnquadramentoLegalComponent from './formularios-estaticos/EnquadramentoLegal/EnquadramentoLegalComponent';
import { FundamentacaoLegalComponent } from './formularios-estaticos/fundamentacaoLegal/FundamentacaoLegalComponent';
import { MapaServicoComponent } from './formularios-estaticos/MapaServico/MapaServicoComponent';
import { UploadComponent } from './formularios-estaticos/upload/UploadComponent';

type Props = {
  formulario: AtoProcessoFormulario;
  index: number;
  processo: Processo;
  ato: AtoProcesso;
  customFields: JsonikFieldMap[];
  history: Pick<History, 'push' | 'replace'>;
  readonly: boolean;
};

export const FormularioRenderer: React.FC<Props> = ({
  formulario,
  index,
  processo,
  ato,
  customFields,
  history,
  readonly
}) => {
  const valoresToJsonikData = (valores: AtoProcessoFormularioValor[]) =>
    valores.reduce((p, c) => {
      p[c.chave] = c.valor;
      return p;
    }, {} as AtoProcessoFormularioValor);

  const jsonikDataToValores = (values: object): AtoProcessoFormularioValor[] =>
    Object.keys(values).map(key => ({
      chave: key,
      valor: values[key]
    }));

  const saveValores = async (
    formulario: AtoProcessoFormulario,
    values: object
  ) => {
    try {
      await AtoProcessoFormularioValoresService.saveValores(
        formulario.id,
        jsonikDataToValores(values)
      );

      Alert.info({ title: 'Dados salvos com sucesso!' });
    } catch (e) {
      Alert.error({ title: 'Ocorreu um erro ao salvar os dados' }, e);
    }
  };

  const render = () => {
    switch (formulario.tipo) {
      case 'DINAMICO':
        return (
          <JsonikForm
            data-testid={`jsonik-${index}`}
            key={`jsonikform-${index}`}
            recipe={formulario.json}
            customFields={customFields}
            onSubmit={values => {
              saveValores(formulario, values);
            }}
            data={valoresToJsonikData(formulario.valores)}
            render={props => (
              <div className="mt-sm">
                <Button
                  className="inline"
                  onClick={() => props.submitForm()}
                  disabled={!props.dirty}
                >
                  Salvar
                </Button>
                <Button
                  className="inline"
                  onClick={() => props.resetForm()}
                  disabled={!props.dirty}
                >
                  Cancelar
                </Button>
              </div>
            )}
          />
        );

      case 'ENQUADRAMENTO_LEGAL':
        return (
          <EnquadramentoLegalComponent
            atoProcesso={ato}
            readonly={readonly}
            history={history}
          ></EnquadramentoLegalComponent>
        );

      case 'UPLOAD':
        return <UploadComponent formulario={formulario} readonly={readonly} />;

      case 'APURACAO':
        return <ApuracaoIssInfracaoComponent idAto={ato.id} readonly={false} />;

      case 'MAPA_SERVICO':
        return (
          <MapaServicoComponent
            processo={processo}
            atoProcesso={ato}
            history={history}
            readonly={readonly}
          />
        );

      case 'FUNDAMENTACAO_LEGAL':
        return <FundamentacaoLegalComponent atoProcesso={ato} />;

      default:
        return <span>Formulário não implementado</span>;
    }
  };

  return (
    <FormWrapper
      disabled={
        readonly &&
        formulario.tipo !== 'UPLOAD' &&
        formulario.tipo !== 'APURACAO' &&
        formulario.tipo !== 'ENQUADRAMENTO_LEGAL'
      }
      data-testid="form-wrapper"
    >
      {render()}
    </FormWrapper>
  );
};

const FormWrapper = styled.fieldset<{ disabled: boolean }>`
  border: none !important;
  padding: none !important;
  margin: none !important;
  ${props =>
    props.disabled &&
    `
    disabled: true;
    pointer-events: none;
  `}
`;
