import { ConfiguracaoEmailDataPage } from '../pages/configuracao/email/ConfiguracaoEmailDataPage';
import { Roles } from '../utils/Roles';

const emailRoutes = [
  {
    path: '/configuracao/email',
    component: ConfiguracaoEmailDataPage,
    title: 'Configuração de e-mail',
    role: Roles.tipo_processo_read.name
  }
];

export default emailRoutes;
