import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  Field,
  FormattedDate,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import { BatchStatusChip } from 'iss-common/components';
import { JobStatus } from 'iss-common/enums';
import React from 'react';

import DivergenciaService from '../../service/DivergenciaService';
import { Divergencia } from '../../types/Divergencia';

type Props = {
  history: Pick<History, 'push'>;
};

const fields: Field[] = [
  {
    label: 'Número',
    name: 'id',
    type: 'NUMBER'
  },
  {
    label: 'Usuário',
    name: 'usuario.nome',
    type: 'STRING'
  },
  {
    label: 'Situação',
    name: 'batchJobExecution.status',
    type: 'ENUM',
    options: Object.entries(JobStatus)
      .filter(
        ([codigo]) =>
          codigo === 'COMPLETED' ||
          codigo === 'STARTED' ||
          codigo === 'FAILED' ||
          codigo === 'STOPPED'
      )
      .map(([codigo, descricao]) => ({
        name: codigo,
        descricao
      }))
  }
];

export const DivergenciaConsultaListPage: React.FC<Props> = ({ history }) => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<Divergencia>({
    search: DivergenciaService.load,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar a divergência.' }, error);
    }
  });

  const onStop = (item: Divergencia) => {
    DivergenciaService.stopConsultaDivergencia(item.id)
      .then(() => {
        history.push(`/divergencia`);
        Alert.success({
          title: 'Tarefa marcada com sucesso para ser interrompida.'
        });
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao parar o processo.'
          },
          error
        );
      });
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <button onClick={() => doSearch()} title="Atualizar">
          <i className="fa fa-sync fa-lg" />
        </button>
      }
    >
      <Panel isTable>
        <SearchFilter fields={fields} search={doSearch} />
        <Table
          loading={loading}
          values={values}
          keyExtractor={(item: Divergencia) => `${item.id}`}
        >
          <Table.Column<Divergencia>
            header="Número"
            value={item => item.id}
          ></Table.Column>
          <Table.Column<Divergencia>
            header="Início"
            value={item => <FormattedDate value={item.inicio} />}
          ></Table.Column>
          <Table.Column<Divergencia>
            header="Fim"
            value={item => <FormattedDate value={item.fim} />}
          ></Table.Column>
          <Table.Column<Divergencia>
            header="Usuário"
            name="usuario"
            value={item => item.usuario?.nome}
          />
          <Table.Column<Divergencia>
            header="Status"
            value={item => <BatchStatusChip jobStatus={item.status} />}
          ></Table.Column>
          <Table.Column<Divergencia>
            header=""
            name="actions-buttons"
            value={item => (
              <ActionsGroup>
                {(item.status! === 'COMPLETED' ||
                  item.status! === 'STOPPED') && (
                  <ActionButton
                    key="view-button"
                    icon="eye"
                    label="Visualizar"
                    path={`/divergencia/${item.id}/visualizar`}
                  />
                )}
                {item.status! === 'STARTED' && (
                  <ActionButton
                    key="parar"
                    icon="stop-circle"
                    label="Parar"
                    onClick={() => onStop(item)}
                  />
                )}
              </ActionsGroup>
            )}
          ></Table.Column>
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <div className="btn-save">
        <FAB
          icon="plus"
          iconColor="white"
          title="Novo"
          path="/divergencia/pesquisar"
        />
      </div>
    </Container>
  );
};
