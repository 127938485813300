import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  FormikInputInteger,
  FormikSelect,
  Loading,
  RadioButton,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { openPdf } from 'iss-common/utils/FileUtils';
import React, { useState } from 'react';

import {
  situacaoAto,
  situacaoAtoCodigo,
  statusProcesso,
  statusProcessoCodigo,
  tipoRelatorioGerencialOrdemServicoCodigo,
  tipoRelatorioGerencialOrdemServicoSelect
} from '../../../enum';
import CadastroGeralService from '../../../service/CadastroGeralService';
import PessoaService from '../../../service/PessoaService';
import RelatorioGerencialOrdemServicoService from '../../../service/RelatorioGerencialOrdemServicoService';
import { CadastroGeral } from '../../../types/CadastroGeral';
import { EnumValue } from '../../../types/EnumValue';
import { FiltroRelatorioGerencialOrdemServicoDTO } from '../../../types/FiltroRelatorioGerencialOrdemServicoDTO';
import { Pessoa } from '../../../types/Pessoa';
import { Usuario } from '../../../types/Usuario';

const filtroRelatorioGerencialOrdemServicoInitial: FiltroRelatorioGerencialOrdemServicoDTO = {
  tipoRelatorio: tipoRelatorioGerencialOrdemServicoCodigo.DOCUMENTOS_AUTUACAO,
  situacaoProcesso: statusProcessoCodigo.TODOS,
  situacaoAto: situacaoAtoCodigo.TODOS
};

const validationSchema = Yup.object().shape(
  {
    dataInicio: Yup.date()
      .label('Data Início')
      .when('dataFim', (value: string, schema: Yup.DateSchema) =>
        value ? schema.required() : schema
      )
      .test(
        'dataInicioMaiorQueFinal',
        'A data de início não pode ser maior do que a data de fim',
        function(value: Date) {
          const { dataFim } = this.parent;
          if (!value) {
            return true;
          }

          return value <= dataFim;
        }
      ),
    dataFim: Yup.date()
      .label('Data Fim')
      .when('dataInicio', (value: Date, schema: Yup.DateSchema) =>
        value ? schema.required() : schema
      )
      .test(
        'dataFimMaiorQueInicial',
        'A data de fim não pode ser menor do que a data de início',
        function(value: Date) {
          const { dataInicio } = this.parent;
          if (!value) {
            return true;
          }
          return dataInicio <= value;
        }
      ),
    processoInicial: Yup.number()
      .min(0)
      .label('Processo Inicial')
      .when('processoFinal', (value: number, schema: Yup.NumberSchema) =>
        value ? schema.required() : schema
      )
      .test(
        'processoInicialMaiorQueFinal',
        'O processo inicial não pode ser maior do que o processo final',
        function(value: number) {
          const { processoFinal } = this.parent;
          if (!value) {
            return true;
          }
          return +value <= processoFinal;
        }
      ),
    processoFinal: Yup.number()
      .min(0)
      .label('Processo Final')
      .when('processoInicial', (value: number, schema: Yup.NumberSchema) =>
        value ? schema.required() : schema
      )
      .test(
        'processoFinalMenorQueInicial',
        'O processo final não pode ser menor do que o processo inicial',
        function(value: number) {
          const { processoInicial } = this.parent;
          if (!value) {
            return true;
          }
          return +value >= processoInicial;
        }
      ),
    atoInicial: Yup.number()
      .min(0)
      .label('Ato Inicial')
      .when('atoFinal', (value: number, schema: Yup.NumberSchema) =>
        value ? schema.required() : schema
      )
      .test(
        'atoInicialMaiorQueFinal',
        'O ato inicial não pode ser maior do que o ato final',
        function(value: number) {
          const { atoFinal } = this.parent;
          if (!value) {
            return true;
          }
          return +value <= atoFinal;
        }
      ),
    atoFinal: Yup.number()
      .min(0)
      .label('Ato Final')
      .when('atoInicial', (value: number, schema: Yup.NumberSchema) =>
        value ? schema.required() : schema
      )
      .test(
        'atoFinalMenorQueInicial',
        'O ato final não pode ser menor do que o ato inicial',
        function(value: number) {
          const { atoInicial } = this.parent;
          if (!value) {
            return true;
          }
          return +value >= atoInicial;
        }
      ),
    tipoRelatorio: Yup.string()
      .label('Tipo Relatório')
      .required()
  },
  [
    ['dataFim', 'dataInicio'],
    ['processoInicial', 'processoFinal'],
    ['atoInicial', 'atoFinal']
  ]
);

export const RelatorioGerencialOrdemServicoPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onFilter = async (values: FiltroRelatorioGerencialOrdemServicoDTO) => {
    setLoading(true);
    await RelatorioGerencialOrdemServicoService.generateReport(values)
      .then((response: any) => {
        openPdf(response.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Col md={12}>
        <Row>
          <Formik<FiltroRelatorioGerencialOrdemServicoDTO>
            enableReinitialize
            initialValues={filtroRelatorioGerencialOrdemServicoInitial}
            validationSchema={validationSchema}
            onSubmit={onFilter}
            render={(
              formProps: FormikProps<FiltroRelatorioGerencialOrdemServicoDTO>
            ) => (
              <>
                <Row>
                  <FormikInputDate
                    label="Data Início"
                    name="dataInicio"
                    size={2}
                  />
                  <FormikInputDate name="dataFim" label="Data Fim" size={2} />
                </Row>
                <Row>
                  <BasicInput
                    data-test-id="tipoRelatorio"
                    size={12}
                    name="tipoRelatorio"
                    label="Tipo Relatório"
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        options={tipoRelatorioGerencialOrdemServicoSelect}
                        selectedValue={field.value}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <FormikInputInteger
                    label="Processo Inicial"
                    name="processoInicial"
                    size={2}
                  />
                  <FormikInputInteger
                    label="Processo Final"
                    name="processoFinal"
                    size={2}
                  />
                  <FormikInputInteger
                    label="Ato Inicial"
                    name="atoInicial"
                    size={2}
                  />
                  <FormikInputInteger
                    label="Ato Final"
                    name="atoFinal"
                    size={2}
                  />
                </Row>
                <Row>
                  <FormikAutocomplete<CadastroGeral>
                    name="contribuinte"
                    label="Contribuinte"
                    onSearch={CadastroGeralService.autoCompleteReuExterno}
                    getOptionLabel={value =>
                      value.pessoa
                        ? `${value.pessoa?.cnpjCpf || ''} - 
                    ${value.pessoa?.nome || ''} - 
                    ${value.tipoCadastro || ''} - 
                    ${value.cadastroGeral || ''}`
                        : ''
                    }
                    getOptionValue={value => value.id}
                    size={8}
                  />
                </Row>
                <Row>
                  <FormikAutocomplete<Usuario>
                    name="fiscal"
                    label="Fiscal"
                    onSearch={PessoaService.search}
                    getOptionLabel={(value: Pessoa) =>
                      `${value?.cnpjCpf} - ${value.nome}`
                    }
                    getOptionValue={(value: Pessoa) => value.id}
                    size={8}
                  />
                </Row>
                <Row>
                  <FormikSelect<EnumValue>
                    label="Situação do Processo"
                    name="situacaoProcesso"
                    size={4}
                    options={statusProcesso}
                    getOptionLabel={option => option.descricao}
                    getOptionValue={option => option.codigo}
                    fast={false}
                  />
                  <FormikSelect<EnumValue>
                    label="Situação do Ato"
                    name="situacaoAto"
                    size={4}
                    options={situacaoAto}
                    getOptionLabel={option => option.descricao}
                    getOptionValue={option => option.codigo}
                    fast={false}
                  />
                </Row>

                <div className="form-group">
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={formProps.submitForm}
                    type="submit"
                  >
                    <i className="fa fa-print" />
                    Imprimir
                  </Button>
                </div>
              </>
            )}
          />
        </Row>
      </Col>
    </Container>
  );
};
