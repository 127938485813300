import { JsonikFieldMap } from '../../common/types';
import { AutocompleteFieldMap } from './AutocompleteField';
import { BooleanFieldMap } from './BooleanField';
import { DateFieldMap } from './DateField';
import { HtmlFieldMap } from './HtmlField';
import { MemoFieldMap } from './MemoField';
import { NumberFieldMap } from './NumberField';
import { SelectFieldMap } from './SelectField';
import { SetFieldMap } from './SetField';
import { TextFieldMap } from './TextField';

export const baseFieldMap: JsonikFieldMap[] = [
  BooleanFieldMap,
  DateFieldMap,
  MemoFieldMap,
  NumberFieldMap,
  SelectFieldMap,
  SetFieldMap,
  TextFieldMap,
  AutocompleteFieldMap,
  HtmlFieldMap
];
