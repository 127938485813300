import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDate,
  Loading,
  Row
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { FormattedTime } from 'react-intl';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';

import AtoEmailsEnviadosService from '../../../service/AtoEmailsEnviadosService';
import { AtoEmailEnviado } from '../../../types/AtoEmailEnviado';

const AtoEmailsEnviadosViewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<AtoEmailEnviado>();

  useEffect(() => {
    setIsLoading(true);
    AtoEmailsEnviadosService.findById(id)
      .then(response => {
        setEmail(response.data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Erro ao buscar as informações do e-mail.' },
          error
        );
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  return (
    <>
      <Container breadcrumb>
        <Loading loading={isLoading}></Loading>
        <DisplayDataGrid column={true}>
          <Row>
            <DisplayDataItem title="E-mail Destinatário">
              {email?.emailDestinatario}
            </DisplayDataItem>
            <DisplayDataItem title="Data de envio">
              <FormattedDate value={email?.dataEnvio} />
            </DisplayDataItem>
            <DisplayDataItem title="Hora de envio">
              <FormattedTime value={email?.horaEnvio} />
            </DisplayDataItem>
            <DisplayDataItem title="Usuário de Envio">
              {email?.usuarioEnvio}
            </DisplayDataItem>
          </Row>
          <Row>
            <DisplayDataItem title="Conteúdo">
              <ReactQuill
                value={email?.conteudoEmail || ' '}
                readOnly={true}
                theme="bubble"
              />
            </DisplayDataItem>
          </Row>
        </DisplayDataGrid>
      </Container>
    </>
  );
};

export default AtoEmailsEnviadosViewPage;
