import { Field, ListPage } from '@elotech/components';
import { History } from 'history';
import React from 'react';

import TipoAtoService from '../../../service/TipoAtoService';
import { TipoAto } from '../../../types/TipoAto';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  { type: 'STRING', label: 'Descrição', name: 'descricao' },
  { type: 'NUMBER', label: 'Código', name: 'id' }
];

export const TipoAtoListPage: React.FC<Props> = ({ history }) => {
  const onDelete = (entity: TipoAto) => TipoAtoService.deleteById(entity.id);
  //const onEdit = (entity: TipoAto) => undefined;
  //const onNew = () => undefined;

  return (
    <ListPage<TipoAto>
      title="Tipo de Ato"
      icon={'university'}
      baseUrl="/configuracao/tipo-ato"
      getId={entity => entity.id}
      history={history}
      searchFields={searchFields}
      onSearch={TipoAtoService.load}
      onDelete={onDelete}
      columns={[
        ['Código', (item: TipoAto) => item.id],
        ['Descrição', (item: TipoAto) => item.descricao]
      ]}
      hideButtons={['view']}
    />
  );
};
