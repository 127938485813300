import {
  FilterUtils,
  PagedResponse,
  RoleUsuarioDTO
} from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { Usuario } from '../types/Usuario';

const crudService = CrudService<Usuario>('/usuarioSeletor', undefined);

const usuarioAutoComplete = (
  searchParams: string
): AxiosPromise<PagedResponse<Usuario>> =>
  axios.get(`${CONTEXT_PATH}/usuarioSeletor/usuarioAutoComplete`, {
    params: {
      search: FilterUtils.buildNameFilter('id', 'usuario.nome')(searchParams),
      fields: 'nome'
    }
  });

const autoComplete = (
  searchParams: string
): AxiosPromise<PagedResponse<Usuario>> =>
  axios.get(`${CONTEXT_PATH}/usuario/auto-complete`, {
    params: {
      search: FilterUtils.buildNameFilter('id', 'nome')(searchParams),
      fields: 'nome,id'
    }
  });

const findUsuarioByCpf = (usuarioCpf: string) =>
  axios.get(`${CONTEXT_PATH}/usuario/usuario/${usuarioCpf}`);

const permissoesByUsuario = (): AxiosPromise<RoleUsuarioDTO> =>
  axios.get(`${CONTEXT_PATH}/usuarioSeletor/permissoes`);

const buscarNomeFiscalResponsavel = (
  fiscal: string | undefined
): AxiosPromise<any> =>
  axios.get(`${CONTEXT_PATH}/usuario/buscar-nome-fiscal/${fiscal}`);

export default {
  permissoesByUsuario,
  usuarioAutoComplete,
  ...crudService,
  findUsuarioByCpf,
  buscarNomeFiscalResponsavel,
  autoComplete
};
