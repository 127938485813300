import {
  Card,
  DisplayDataGrid,
  DisplayDataItem,
  SectionTitle
} from '@elotech/components';
import { History } from 'history';
import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { ApuracaoIssNota } from '../../../../types/ApuracaoIssNota';
import Constantes from '../../../../utils/Constantes';
import { Value } from '../ApuracaoAnual/Value';

type Props = {
  notas: ApuracaoIssNota[] | undefined;
  deleteById: any;
  history: Pick<History, 'push'>;
  apuracao: ApuracaoIss | undefined;
};

const ColorOverride = styled.div<{}>`
  & .dd-content *,
  .dd-title,
  .td-content * {
    color: black;
  }
`;
export const ValoresMensal: React.FC<Props> = ({
  notas,
  deleteById,
  history,
  apuracao
}) => {
  return (
    <>
      <SectionTitle>Notas Fiscais</SectionTitle>
      {notas &&
        notas.map((nota, index) => (
          <Card key={index}>
            <div
              className="btn-dropdown"
              onClick={e => e.currentTarget.classList.add('open')}
              onMouseLeave={e => e.currentTarget.classList.remove('open')}
            >
              <button className="hint clean fa-ellipsis-v right"></button>
              <ul className="btn-dropdown-list right-all">
                <li
                  onClick={e =>
                    history.push(
                      `/apuracao-nota-fiscal/${apuracao?.id}/${nota.id}`
                    )
                  }
                >
                  Editar
                </li>
                <li
                  onClick={async e => {
                    await deleteById(index, nota, notas);
                  }}
                >
                  Excluir
                </li>
              </ul>
            </div>
            <DisplayDataGrid border={false} column={true}>
              <ColorOverride>
                <DisplayDataItem md={2} title="Número">
                  {nota.numeroDocumento}
                </DisplayDataItem>
              </ColorOverride>
              <ColorOverride>
                <DisplayDataItem md={2} title="Data emissão">
                  <FormattedDate value={nota.dataEmissao} timeZone={'UTC'} />
                </DisplayDataItem>
              </ColorOverride>
              <ColorOverride>
                <DisplayDataItem md={2} title="Situação">
                  {
                    Constantes.listaSituacoes.find(
                      x => x.id === nota.situacaoNota.toString()
                    )?.descricao
                  }
                </DisplayDataItem>
              </ColorOverride>
              <ColorOverride>
                <DisplayDataItem md={2} title="Tipo">
                  {nota.tipoDocumento.descricao}
                </DisplayDataItem>
              </ColorOverride>
              <ColorOverride>
                <DisplayDataItem md={2} title="Local prestação">
                  {nota.localPrestacaoCidade?.descricao}
                </DisplayDataItem>
              </ColorOverride>
              <ColorOverride>
                <DisplayDataItem md={2} title="Natureza Operação">
                  {nota.naturezaOperacao.descricao}
                </DisplayDataItem>
              </ColorOverride>
              <Value title="Total" value={nota.valorTotalNota} />
              <Value title="Deduções" value={nota.valorDeducoes} />
              <Value title="Valor serviços" value={nota.valorServico} />
              <Value title="Base de calculos" value={nota.baseCalculo} />
              <Value title="Aliquota" value={nota.aliquotaIss} />
              <Value title="Valor ISS" value={nota.valorIss} />
              <ColorOverride>
                <DisplayDataItem md={2} title="ISS retido">
                  {nota.issRetido ? 'Sim' : 'Não'}
                </DisplayDataItem>
              </ColorOverride>
              <Value title="Valor ISS retido" value={nota.valorIssRetido} />
            </DisplayDataGrid>
          </Card>
        ))}
    </>
  );
};
