import {
  BasicInput,
  DataPage,
  FormikAutocomplete,
  FormikCheckBox,
  FormikEffect,
  FormikInputDate,
  FormikInputNumber,
  FormikSelect,
  Row,
  SectionTitle
} from '@elotech/components';
import { FormikProps } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { match } from 'react-router-dom';

import ApuracaoIssNotaService from '../../../../service/ApuracaoIssNotaService';
import ApuracaoIssService from '../../../../service/ApuracaoIssService';
import CidadeService from '../../../../service/CidadeService';
import NaturezaOperacaoService from '../../../../service/NaturezaOperacaoService';
import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { ApuracaoIssNota } from '../../../../types/ApuracaoIssNota';
import { NaturezaOperacao } from '../../../../types/NaturezaOperacao';
import { TipoDocumentoFiscalEntity } from '../../../../types/TipoDocumentoFiscalEntity';
import Constantes from '../../../../utils/Constantes';
import { ApuracaoNotaFiscalDetail } from './ApuracaoNotaFiscalDetail';
import { Cidade, Estado, Situacao } from './Types';
import { validationSchema } from './ValidatonSchema';

type Props = {
  match: match<{ id: string; idApuracao: string }>;
  history: Pick<History, 'replace'>;
};

const CANCELADO = '1';
export const ApuracaoNotaFiscalPage: React.FC<Props> = ({ match, history }) => {
  const [apuracao, setApuracao] = useState<ApuracaoIss>();

  useEffect(() => {
    ApuracaoIssService.findById(match.params.idApuracao).then(response => {
      setApuracao(response.data);
    });
  }, [match.params.idApuracao]);

  const save = (apuracao: ApuracaoIss | undefined, value: ApuracaoIssNota) => {
    if (apuracao) {
      value.apuracao = apuracao;
    }
    if (value.issRetido === undefined) {
      value.issRetido = false;
    }
    return ApuracaoIssNotaService.save(value);
  };
  const onChangeFormik = (
    formikProps: FormikProps<ApuracaoIssNota>,
    oldFormikProps: FormikProps<ApuracaoIssNota>
  ) => {
    if (
      formikProps.values.valorTotalNota !== oldFormikProps.values.valorTotalNota
    ) {
      formikProps.setFieldValue(
        'baseCalculo',
        formikProps.values.valorTotalNota
      );
      formikProps.setFieldValue(
        'valorServico',
        formikProps.values.valorTotalNota
      );
    }
    if (
      formikProps.values.baseCalculo !== oldFormikProps.values.baseCalculo ||
      formikProps.values.aliquotaIss !== oldFormikProps.values.aliquotaIss
    ) {
      let calculo =
        (formikProps.values.baseCalculo * formikProps.values.aliquotaIss) / 100;
      formikProps.setFieldValue('valorIss', calculo);
    }
  };
  return apuracao ? (
    <DataPage
      title={match.params.id ? 'Editar nota' : 'Nova nota'}
      name="nota-fiscal"
      icon="file-alt"
      match={match}
      validationSchema={validationSchema(
        new Date(apuracao.anoCompetencia, apuracao.mesCompetencia - 1, 1),
        new Date(apuracao.anoCompetencia, apuracao.mesCompetencia, 0)
      )}
      load={ApuracaoIssNotaService.findById}
      history={history}
      redirectUrl={`/processo-ato-apuracao/${apuracao?.idProcessoAto}/${apuracao?.anoCompetencia}/${apuracao?.mesCompetencia}`}
      onSave={value => save(apuracao, value)}
      render={props => {
        return (
          apuracao && (
            <>
              <ApuracaoNotaFiscalDetail
                nota={props.values}
                apuracao={apuracao}
              />
              <SectionTitle>Documento fiscal</SectionTitle>
              <FormikEffect
                onChange={(
                  formikProps: FormikProps<ApuracaoIssNota>,
                  oldFormikProps: FormikProps<ApuracaoIssNota>
                ) => onChangeFormik(formikProps, oldFormikProps)}
              />
              <Row>
                <FormikSelect<Situacao>
                  label="Situação documento"
                  name="situacaoNota"
                  options={Constantes.listaSituacoes}
                  getOptionLabel={value => value.descricao}
                  getOptionValue={value => value.id}
                  onSelect={value => {
                    if (value && value.id === '1') {
                      props.setFieldValue('valorDeducoes', 0);
                      props.setFieldValue('valorIss', 0);
                      props.setFieldValue('valorIssRetido', 0);
                      props.setFieldValue('valorIssZero', 0);
                      props.setFieldValue('valorServico', 0);
                      props.setFieldValue('valorTotalNota', 0);
                      props.setFieldValue('baseCalculo', 0);
                      props.setFieldValue('aliquotaIss', 0);
                    }
                  }}
                />
                <BasicInput name="numeroDocumento" label="Numero Documento" />
                <FormikInputDate label="Data Emissão" name="dataEmissao" />
              </Row>
              <Row>
                <FormikAutocomplete<TipoDocumentoFiscalEntity>
                  name="tipoDocumento"
                  label="Tipo Documento"
                  onSearch={ApuracaoIssNotaService.loadAutoComplete}
                  getOptionLabel={(tipo: TipoDocumentoFiscalEntity) =>
                    `${tipo.id} - ${tipo.descricao}`
                  }
                  getOptionValue={(tipo: TipoDocumentoFiscalEntity) => tipo.id}
                  size={4}
                />
                <FormikSelect<Estado>
                  label="Local de prestação do serviço"
                  name="localPrestacaoUF"
                  options={Constantes.optionsEstados}
                  onSelect={_ =>
                    props.setFieldValue('localPrestacaoCidade', null)
                  }
                  getOptionLabel={value => value.label}
                  getOptionValue={value => value.key}
                />
                <FormikAutocomplete<Cidade>
                  name="localPrestacaoCidade"
                  label="Cidade"
                  fast={false}
                  onSearch={value =>
                    CidadeService.autoComplete(
                      value,
                      props.values.localPrestacaoUF
                    )
                  }
                  getOptionLabel={(tipo: Cidade) =>
                    `${tipo.id} - ${tipo.descricao}`
                  }
                  getOptionValue={(tipo: Cidade) => tipo.id}
                  size={4}
                />
              </Row>
              <Row>
                <FormikAutocomplete<NaturezaOperacao>
                  name="naturezaOperacao"
                  label="Natureza operação"
                  onSearch={NaturezaOperacaoService.loadAutoComplete}
                  getOptionLabel={(natureza: NaturezaOperacao) =>
                    `${natureza.id} - ${natureza.descricao}`
                  }
                  getOptionValue={(natureza: NaturezaOperacao) => natureza.id}
                  size={6}
                />
                <FormikCheckBox
                  noLabel={false}
                  size={3}
                  name="issRetido"
                  label="ISS Retido"
                  id="issRetido"
                />
              </Row>
              <Row>
                <FormikInputNumber
                  name="valorTotalNota"
                  label="Valor total"
                  fast={false}
                  disabled={props.values.situacaoNota?.toString() === CANCELADO}
                />
                <FormikInputNumber
                  name="valorServico"
                  label="Valor serviço"
                  fast={false}
                  disabled={props.values.situacaoNota?.toString() === CANCELADO}
                />
                <FormikInputNumber
                  name="valorDeducoes"
                  fast={false}
                  label="Valor dedução"
                  disabled={props.values.situacaoNota?.toString() === CANCELADO}
                />
              </Row>
              <Row>
                <FormikInputNumber
                  name="baseCalculo"
                  label="Base de cálculo"
                  fast={false}
                  disabled={props.values.situacaoNota?.toString() === CANCELADO}
                />
                <FormikInputNumber
                  name="aliquotaIss"
                  label="Aliquota"
                  fast={false}
                  disabled={props.values.situacaoNota?.toString() === CANCELADO}
                />
                <FormikInputNumber
                  name="valorIss"
                  label="Valor ISS"
                  readOnly
                  fast={false}
                  disabled={props.values.situacaoNota?.toString() === CANCELADO}
                />
                <FormikInputNumber
                  name="valorIssRetido"
                  label="Valor ISS retido"
                  fast={false}
                  disabled={
                    props.values.situacaoNota?.toString() === CANCELADO ||
                    !props.values.issRetido
                  }
                />
              </Row>
            </>
          )
        );
      }}
    />
  ) : (
    <div></div>
  );
};
