import {
  Alert,
  FormattedCurrency,
  FormattedDate,
  Panel,
  SectionTitle,
  Table
} from '@elotech/components';
import React, { useEffect, useState } from 'react';

import DivergenciaService from '../../../service/DivergenciaService';
import { NotaFiscal } from '../../../types/NotaFiscal';

type Props = { id: string };

const NotaFiscalTable: React.FC<Props> = ({ id }) => {
  const [notaFiscal, setNotaFiscal] = useState<NotaFiscal[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    DivergenciaService.findNotaFiscalDivergencia(parseInt(id))
      .then(response => {
        setNotaFiscal(response.data.content);
      })
      .catch(error => {
        Alert.error({ title: `Erro ao carregar os dados` }, error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      <SectionTitle>Notas Fiscais</SectionTitle>
      <Panel isTable>
        <Table<NotaFiscal>
          values={notaFiscal ?? []}
          keyExtractor={item => item.id}
          loading={loading}
        >
          <Table.Column<NotaFiscal>
            header="Documento"
            value={item => item.numeroNotaFiscal}
          />
          <Table.Column<NotaFiscal>
            header="Tipo documento"
            value={item => item.tipoDocumento}
          />
          <Table.Column<NotaFiscal>
            header="Data emissão"
            value={item => <FormattedDate value={item.dataEmissao} />}
          />
          <Table.Column<NotaFiscal>
            header="Situação"
            value={item => item.descricaoSituacao}
          />
          <Table.Column<NotaFiscal>
            header="Valor NFSe"
            value={item => <FormattedCurrency value={item.valorTotalNota} />}
          />
        </Table>
      </Panel>
    </>
  );
};

export default NotaFiscalTable;
