import { Col, FormikCheckBox, Row, SectionTitle } from '@elotech/components';
import React from 'react';

import {
  AtoConfiguracao,
  atoConfiguracoes
} from '../../../enum/AtoConfiguracoes';
import { TipoAto } from '../../../types/TipoAto';

type Props = {
  tipoAto: TipoAto;
};

const atos = [...atoConfiguracoes];
const getGrupos = () =>
  atos
    .sort((c1, c2) => c2.grupo.localeCompare(c1.grupo))
    .reduce((p: string[], c: AtoConfiguracao) => {
      if (c.grupo !== p[p.length - 1]) p.push(c.grupo);
      return p;
    }, []);

const getConfig = (grupo: string) =>
  atoConfiguracoes
    .filter(config => config.grupo === grupo)
    .sort((c1, c2) => c1.descricao.localeCompare(c2.descricao));
export const Configuracao: React.FC<Props> = ({ tipoAto }) => {
  return (
    <>
      {getGrupos().map(grupo => (
        <Row style={{ marginBottom: '1em' }} key={grupo}>
          <Col md={12}>
            <SectionTitle marginTop="0">{grupo}</SectionTitle>
          </Col>

          {getConfig(grupo).map((config: any) => (
            <FormikCheckBox
              key={`checkbox-${config.codigo}`}
              id={`checkbox-${config.codigo}`}
              name={`configAux.${config.codigo}`}
              label={config.descricao}
              size={4}
            />
          ))}
        </Row>
      ))}
    </>
  );
};
