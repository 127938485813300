import { RelatorioGerencialFiscalizacaoPage } from '../pages/relatorios/gerencial-fiscalizacao/RelatorioGerencialFiscalizacaoPage';
import { RelatorioGerencialOrdemServicoPage } from '../pages/relatorios/gerencial-ordem-servico/RelatorioGerencialOrdemServicoPage';
import { RelatorioDocumentosIntimadosPage } from '../pages/relatorios/RelatorioDocumentosIntimadosPage';
import { RelatorioDocumentosSolicitadosEntregues } from '../pages/relatorios/RelatorioDocumentosSolicitadosEntregues';
import { RelatorioProcedimentosFiscaisPeriodoPage } from '../pages/relatorios/RelatorioProcedimentosFiscaisPeriodoPage';
import { Roles } from '../utils/Roles';

const relatorioRoutes = [
  {
    path: '/relatorios/documentos-intimados',
    component: RelatorioDocumentosIntimadosPage,
    title: 'Documentos Intimados',
    role: Roles.fis_relatorio_documentos_intimados.name,
    icon: 'file-alt'
  },
  {
    path: '/relatorios/gerencial-fiscalizacao',
    component: RelatorioGerencialFiscalizacaoPage,
    title: 'Gerencial da Fiscalização',
    role: Roles.fis_relatorio_gerencial_fiscalizacao.name,
    icon: 'cogs'
  },
  {
    path: '/relatorios/procedimentos-fiscais-periodo',
    component: RelatorioProcedimentosFiscaisPeriodoPage,
    title: 'Procedimentos Fiscais por Período',
    role: Roles.fis_relatorio_procedimentos_fiscais_periodo.name,
    icon: 'file-signature'
  },
  {
    path: '/relatorios/documentos-solicitados-entregues',
    component: RelatorioDocumentosSolicitadosEntregues,
    title: 'Documentos Solicitados/Entregues',
    role: Roles.fis_relatorio_documentos_solicitados_entregues.name,
    icon: 'file-signature'
  },
  {
    path: '/relatorios/gerencial-ordem-servico',
    component: RelatorioGerencialOrdemServicoPage,
    title: 'Gerencial Ordem de Serviço',
    role: Roles.fis_relatorio_gerencial_ordem_servico.name,
    icon: 'file-contract'
  }
];

export default relatorioRoutes;
