import { BreadCrumbRoute } from '@elotech/components';

import { DivergenciaConsultaListPage } from '../pages/divergencia/DivergenciaConsultaListPage';
import DivergenciaFormPage from '../pages/divergencia/DivergenciaFormPage';
import DivergenciaNotaFiscalListPage from '../pages/divergencia/itens-divergencia/DivergenciaNotaFiscalListPage';
import DivergenciaVisualizacaoPage from '../pages/divergencia/itens-divergencia/DivergenciaVisualizacaoPage';
import { Roles } from '../utils/Roles';

const divergenciasRoutes: BreadCrumbRoute[] = [
  {
    path: '/divergencia',
    component: DivergenciaConsultaListPage,
    title: 'Divergência',
    role: Roles.divergencia_read.name,
    icon: 'cash-register'
  },
  {
    path: '/divergencia/pesquisar',
    component: DivergenciaFormPage,
    title: 'Divergência',
    role: Roles.divergencia_update.name,
    icon: 'cash-register'
  },
  {
    title: 'Visualizar',
    path: `/divergencia/:id/visualizar`,
    parent: '/divergencia',
    component: DivergenciaVisualizacaoPage,
    role: Roles.divergencia_read.name,
    icon: 'cash-register'
  },
  {
    title: 'Notas Fiscais',
    path: `/divergencia/:id/visualizar/:id/notas`,
    parent: '/divergencia/:id/visualizar',
    parentURLBuilder: currentRoute =>
      `${currentRoute.matchedURL!.match(/\/divergencia\/\d+/)}/visualizar`,
    component: DivergenciaNotaFiscalListPage,
    role: Roles.divergencia_read.name,
    icon: 'cash-register'
  }
];

export default divergenciasRoutes;
