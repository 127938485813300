import { EnumValue } from '../types/EnumValue';

export const situacoesGeracaoProcesso: EnumValue[] = [
  { codigo: 'NOVA', descricao: 'Nova' },
  { codigo: 'EM_ABERTO', descricao: 'Em Aberto' },
  { codigo: 'EM_ANDAMENTO', descricao: 'Em Andamento' },
  { codigo: 'ENCERRADA', descricao: 'Encerrada' }
];

export const getDescricaoGeracao = (situacao: string) => {
  const found = situacoesGeracaoProcesso.find(item => item.codigo === situacao);
  return found ? found.descricao : '';
};
