import { ArtigoDataPage } from '../pages/configuracao/artigo/ArtigoDataPage';
import { ArtigoListPage } from '../pages/configuracao/artigo/ArtigoListPage';
import { Roles } from '../utils/Roles';

const artigoRoutes = [
  {
    path: '/configuracao/cadastro-artigo',
    component: ArtigoListPage,
    title: 'Cadastro de Artigo',
    role: Roles.artigo_read.name
  },
  {
    path: '/configuracao/cadastro-artigo/:id',
    component: ArtigoDataPage,
    title: 'Consulta de Artigo',
    role: Roles.artigo_update.name
  }
];

export default artigoRoutes;
