import { Button } from '@elotech/components';
import React from 'react';

type Props = {
  onClickNovoAto: any;
  onClickNovoReu: any;
  disableNovoAto?: boolean;
};

export const AtosNotFound: React.FC<Props> = ({
  onClickNovoAto,
  onClickNovoReu,
  disableNovoAto
}) => {
  return (
    <div className="not-found">
      <span className="fa fa-frown"></span>
      <h2> Nenhum ato foi encontrado</h2>
      {disableNovoAto ? (
        <>
          <small>
            {' '}
            É necessário vincular o Réu para adicionar um novo ato!{' '}
          </small>
          <Button onClick={onClickNovoReu} className="center mt-xs">
            VINCULAR RÉU
          </Button>
        </>
      ) : (
        <>
          <small>
            {' '}
            Clique em novo ato para adicionar um ato nesse processo.{' '}
          </small>
          <Button
            onClick={onClickNovoAto}
            className="center mt-xs"
            disabled={disableNovoAto}
          >
            NOVO ATO
          </Button>
        </>
      )}
    </div>
  );
};
