import {
  Alert,
  DataPage,
  FAB,
  FabSpeedDial,
  SectionTitle
} from '@elotech/components';
import { History } from 'history';
import React, { useState } from 'react';
import { match } from 'react-router-dom';

import ApuracaoIssNotaService from '../../../../service/ApuracaoIssNotaService';
import ApuracaoIssService from '../../../../service/ApuracaoIssService';
import AtoProcessoService from '../../../../service/AtoProcessoService';
import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { ApuracaoIssNota } from '../../../../types/ApuracaoIssNota';
import { Processo } from '../../../../types/Processo';
import { ProcessoDetail } from '../ApuracaoAnual/ProcessoDetail';
import { ValoresMensal } from './ValoresMensal';

type Props = {
  match: match<{ id: string; ano: string; mes: string }>;
  history: Pick<History, 'push'>;
};
export const ApuracaoMensalDataPage: React.FC<Props> = ({ match, history }) => {
  const load = async (id: number) => {
    const ato = await AtoProcessoService.findById(id);
    setProcesso(ato.data.processo);
    const apuracoes = await ApuracaoIssService.loadByAtoAno(id, ano);
    setApuracao(apuracoes.data.find(f => f.mesCompetencia === parseInt(mes)));
    const dados = await ApuracaoIssNotaService.findAllNotasByIdAtoAnoMes(
      id,
      ano,
      mes
    );
    setNotas(dados.data);
    return dados;
  };
  const deleteById = async (
    index: number,
    nota: any,
    notas: ApuracaoIssNota[]
  ) => {
    let retorno = await Alert.question({
      title: 'Deseja realmente excluir o registro ?'
    });
    if (retorno.value) {
      let deletado = await ApuracaoIssNotaService.deleteById(nota.id);
      if (deletado.status === 200) {
        Alert.success({
          title: 'Excluido com sucesso.'
        });
        setNotas(undefined);
        notas.splice(index, 1);
        setNotas(notas);
      } else {
        Alert.error({
          title: 'Não foi possível excluir o registro.',
          ...deletado.data
        });
        setNotas(notas);
      }
    }
    return true;
  };
  const [apuracao, setApuracao] = useState<ApuracaoIss>();
  const [processo, setProcesso] = useState<Processo>();
  const [notas, setNotas] = useState<ApuracaoIssNota[]>();
  const ano = match.params.ano;
  const mes = match.params.mes;
  return (
    <DataPage
      match={match}
      name="apuracaoMensal"
      title={`Apuração Mensal - ${ano}/${mes}`}
      icon="university"
      load={load}
      render={_ => (
        <>
          {processo && (
            <>
              <SectionTitle marginTop={'0'}>Dados do Processo</SectionTitle>
              <ProcessoDetail processo={processo} />
              <ValoresMensal
                notas={notas}
                apuracao={apuracao}
                deleteById={deleteById}
                history={history}
              />
              <FabSpeedDial icon="ellipsis-v" title="Ações">
                <FAB
                  data-testid="btn-incluir-doc-fiscal"
                  icon="plus"
                  onClick={() =>
                    history.push(`/apuracao-nota-fiscal/${apuracao?.id}/`)
                  }
                  title="Incluir DOC. Fiscal"
                />
                <FAB
                  data-testid="btn-outras-receitas"
                  icon="money-check-alt"
                  onClick={() =>
                    history.push(
                      `/apuracao-iss-outra-receita/apuracao/${apuracao?.id}`
                    )
                  }
                  title="Outras receitas"
                />
              </FabSpeedDial>
            </>
          )}
        </>
      )}
    />
  );
};
