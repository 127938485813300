import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { TipoAto } from '../types/TipoAto';

const crudService = CrudService<TipoAto>({
  default: '/tipo-ato',
  list: '/tipo-ato/vo'
});

const tipoAtoAutoComplete = (
  searchParams: string
): AxiosPromise<PagedResponse<TipoAto>> =>
  axios.get(`${CONTEXT_PATH}/tipo-ato/auto-complete`, {
    params: {
      search: FilterUtils.buildNameFilter('id', 'descricao')(searchParams),
      fields: 'descricao,id'
    }
  });

const downloadRelatorio = (id: any) =>
  axios.get(`${CONTEXT_PATH}/tipo-ato/downloadRelatorio/${id}`, {
    responseType: 'blob'
  });

const parameterHandleRelatorio = () =>
  axios.get(`${CONTEXT_PATH}/tipo-ato/pdf-parameterHandler`);

export default {
  ...crudService,
  tipoAtoAutoComplete,
  downloadRelatorio,
  parameterHandleRelatorio
};
