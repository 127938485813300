import {
  ActionButton,
  ActionsGroup,
  Alert,
  Button,
  Col,
  FormattedCurrency,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Spinner,
  Table
} from '@elotech/components';
import download from 'downloadjs';
import { History } from 'history';
import React, { RefObject, useEffect, useRef, useState } from 'react';

import ApuracaoIssService from '../../../../../service/ApuracaoIssService';
import MapaServicoService from '../../../../../service/MapaServicoService';
import { AtoProcesso } from '../../../../../types/AtoProcesso';
import { Processo } from '../../../../../types/Processo';
import { ResumoApuracao } from '../../../../../types/ResumoApuracao';
import { ResumoApuracaoAno } from '../../../../../types/ResumoApuracaoAno';
import { ValoresCorrigidosComponent } from './ValoresCorrigidosComponent';
import { ValoresSemCorrecaoComponent } from './ValoresSemCorrecaoComponent';

type Props = {
  processo: Processo;
  atoProcesso: AtoProcesso;
  history: Pick<History, 'push'>;
  readonly: boolean;
};

export const MapaServicoComponent: React.FC<Props> = ({
  processo,
  atoProcesso,
  history,
  readonly
}) => {
  const openApuracaoAnual = (ano: number) =>
    history.push(`/processo-ato-apuracao/${atoProcesso.id}/${ano}`);

  const load = (processo: Processo, atoProcesso: AtoProcesso) => {
    setInitialLoading(true);

    return MapaServicoService.abrirApuracao({
      dataEmissaoInicial: processo.inicioFiscalizacao,
      dataEmissaoFinal: processo.fimFiscalizacao,
      idAto: atoProcesso.id
    })
      .then(response => {
        setResumoApuracao(response.data);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  };

  useEffect(() => {
    setInitialLoading(true);

    load(processo, atoProcesso)
      .catch(error => {
        setInitialLoading(false);
        Alert.error({ title: 'Erro ao abrir a apuração' }, error);
      })
      .finally(() => setInitialLoading(false));
  }, [processo, atoProcesso]);

  const [resumoApuracao, setResumoApuracao] = useState<ResumoApuracao>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);

  const validateArquivo = (value: string) => {
    return value !== null && value !== undefined && value !== '';
  };

  const onImprimir = async () => {
    setLoading(true);
    ApuracaoIssService.exportarXLS(atoProcesso.id)
      .then(async () => {
        Alert.success({
          title: 'Iniciada com sucesso a exportação do Mapa de Serviços.',
          text: 'Uma notificação será enviada ao fim do processo.'
        });
      })
      .catch(err => {
        Alert.error(
          { title: 'Erro ao exportar a planilha do Mapa de Serviços.' },
          err
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onExportar = async () => {
    if (!validateArquivo(atoProcesso?.idArquivo ?? '')) {
      return onImprimir();
    }

    await Alert.question({
      title:
        'Já existe um arquivo de mapa de serviço impresso. Deseja reimprimir?'
    }).then((answer: any) => {
      if (answer.value) {
        downloadMapaServico();
        return;
      }

      return onImprimir();
    });
  };

  const downloadMapaServico = () => {
    setLoading(true);
    ApuracaoIssService.getUrlMapaServico(atoProcesso!.idArquivo ?? '')
      .then(response => {
        download(response.data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Erro ao realizar o download do Mapa de Serviços.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const btnUpload = useRef<HTMLInputElement>(null);
  const onImportar = () => {
    btnUpload.current?.click();
  };

  const getBase64 = (file: File) => {
    if (file.size === 0) return;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        //@ts-ignore
        let encoded = reader!.result!.replace(/^data:(.*;base64,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  const uploadXls = async ({ currentTarget }: any) => {
    setLoading(true);

    const file = await getBase64(currentTarget.files[0]);
    ApuracaoIssService.importarXLS(atoProcesso.id, { arquivo: file })
      .then(async () => {
        Alert.success({
          title: 'Iniciada com sucesso a importação do Mapa de Serviços.',
          text: 'Uma notificação será enviada ao fim do processo.'
        });
      })
      .catch(err => {
        Alert.error(
          { title: 'Erro ao importar a planilha do Mapa de Serviços.' },
          err
        );
      })
      .finally(() => {
        currentTarget.value = '';
        setLoading(false);
      });
  };

  const calcularApuracao = async () => {
    setLoading(true);
    setInitialLoading(true);
    try {
      setResumoApuracao(
        (await MapaServicoService.calcularApuracao(atoProcesso.id)).data
      );

      return await load(processo, atoProcesso);
    } catch (error) {
      Alert.error({ title: 'Erro ao calcular a apuração' }, error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  return (
    <>
      {initialLoading && (
        <p>
          <Spinner /> Carregando o mapa de serviço...
        </p>
      )}
      <Loading loading={loading} />
      {resumoApuracao?.apuracoesAno && (
        <>
          <SectionTitle>Apuração do Período</SectionTitle>
          <Panel isTable style={{ marginTop: '10px' }}>
            <Table<ResumoApuracaoAno>
              values={resumoApuracao.apuracoesAno}
              keyExtractor={value => value.ano}
              footer={
                <Footer
                  disabled={readonly}
                  onImportar={onImportar}
                  onExportar={onExportar}
                  calcularApuracao={calcularApuracao}
                />
              }
            >
              <Table.Column<ResumoApuracaoAno>
                header={'Regime fiscal'}
                value={value => value.regimeFiscal}
              />
              <Table.Column<ResumoApuracaoAno>
                header={'Ano'}
                value={value => value.ano}
              />
              <Table.Column<ResumoApuracaoAno>
                header={'Receita bruta'}
                value={value => (
                  <FormattedCurrency value={value.receitaBruta} />
                )}
              />
              <Table.Column<ResumoApuracaoAno>
                header={'Outras receitas'}
                value={value => (
                  <FormattedCurrency value={value.outrasReceitas} />
                )}
              />
              <Table.Column<ResumoApuracaoAno>
                header={'Receita serviços'}
                value={value => (
                  <FormattedCurrency value={value.receitaServicos} />
                )}
              />
              <Table.Column<ResumoApuracaoAno>
                header={'Receita tributável'}
                value={value => (
                  <FormattedCurrency value={value.receitaTributavel} />
                )}
              />
              <Table.Column<ResumoApuracaoAno>
                header=""
                value={value => (
                  <>
                    <ActionsGroup>
                      <ActionButton
                        onClick={uploadXls => openApuracaoAnual(value.ano)}
                        icon="eye"
                      />
                    </ActionsGroup>
                  </>
                )}
              />
            </Table>
          </Panel>

          <ValoresSemCorrecaoComponent
            value={resumoApuracao?.resumoSemCorrecao}
          />
          <ValoresCorrigidosComponent value={resumoApuracao?.resumoCorrigido} />
          <UploadHiddenComponent
            referencia={btnUpload}
            onChangeArquivo={uploadXls}
          />
        </>
      )}
    </>
  );
};

type FooterProps = {
  disabled: boolean;
  onExportar: () => void;
  onImportar: () => void;
  calcularApuracao: () => void;
};

const Footer: React.FC<FooterProps> = ({
  disabled,
  onExportar,
  onImportar,
  calcularApuracao
}) => (
  <tfoot>
    <tr>
      <td colSpan={7}>
        <Button
          iconPosition="left"
          className="inline"
          onClick={onImportar}
          disabled={disabled}
        >
          <i className={'fa fa-dollar-sign'}></i>Importar mapa
        </Button>
        <Button
          iconPosition="left"
          className="inline"
          onClick={onExportar}
          disabled={disabled}
        >
          <i className={'fa fa-dollar-sign'}></i>Exportar mapa
        </Button>
        <Button
          iconPosition="left"
          className="inline"
          onClick={calcularApuracao}
          disabled={disabled}
        >
          <i className={'fa fa-dollar-sign'}></i>Calcular Apuração
        </Button>
      </td>
    </tr>
  </tfoot>
);

type UploadProps = {
  onChangeArquivo: any;
  referencia: RefObject<HTMLInputElement>;
};

const UploadHiddenComponent: React.FC<UploadProps> = ({
  onChangeArquivo,
  referencia
}) => {
  return (
    <Row>
      <Col md={12} style={{ display: 'none' }}>
        <div className="form-group">
          <div className="file-uploader">
            <input
              ref={referencia}
              id="upload-file-input"
              type="file"
              accept=".xls,.xlsx"
              multiple={false}
              className={`file-uploader-input`}
              data-title="importacao-excel"
              onChange={onChangeArquivo}
            />

            <label
              htmlFor="upload-file-input"
              className="input"
              data-title="importacao-excel"
            />
            <label htmlFor="upload-file-input" className="file-uploader-icon" />
          </div>
        </div>
      </Col>
    </Row>
  );
};
