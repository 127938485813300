const optionsEstados = [
  { key: 'AC', label: 'Acre' },
  { key: 'AL', label: 'Alagoas' },
  { key: 'AP', label: 'Amapá' },
  { key: 'AM', label: 'Amazonas' },
  { key: 'BA', label: 'Bahia' },
  { key: 'CE', label: 'Ceará' },
  { key: 'DF', label: 'Distrito Federal' },
  { key: 'ES', label: 'Espirito Santo' },
  { key: 'GO', label: 'Goiás' },
  { key: 'MA', label: 'Maranhão' },
  { key: 'MT', label: 'Mato Grosso' },
  { key: 'MS', label: 'Mato Grosso do Sul' },
  { key: 'MG', label: 'Minas Gerais' },
  { key: 'PA', label: 'Pará' },
  { key: 'PB', label: 'Paraiba' },
  { key: 'PR', label: 'Paraná' },
  { key: 'PE', label: 'Pernambuco' },
  { key: 'PI', label: 'Piauí' },
  { key: 'RJ', label: 'Rio de Janeiro' },
  { key: 'RN', label: 'Rio Grande do Norte' },
  { key: 'RS', label: 'Rio Grande do Sul' },
  { key: 'RO', label: 'Rondônia' },
  { key: 'RR', label: 'Roraima' },
  { key: 'SC', label: 'Santa Catarina' },
  { key: 'SP', label: 'São Paulo' },
  { key: 'SE', label: 'Sergipe' },
  { key: 'TO', label: 'Tocantins' }
];

const listaSituacoes = [
  { id: '0', descricao: 'Emitida' },
  { id: '1', descricao: 'Cancelada' },
  { id: '2', descricao: 'Extraviada' },
  { id: '3', descricao: 'Substituida' }
];

const acaoTermoCircunstanciado = 'IS002';
const acaoResposta = 'GR005';
const acaoCancelarAto = 'GR006';
const acaoEncaminhado = 'GR003';
const acaoDefesaFiscal = 'IS003';
const acaoNotificacao = 'IS005';
const acaoNotificacaoReincidencia = 'IS009';

export default {
  optionsEstados,
  listaSituacoes,
  acaoTermoCircunstanciado,
  acaoResposta,
  acaoCancelarAto,
  acaoEncaminhado,
  acaoDefesaFiscal,
  acaoNotificacao,
  acaoNotificacaoReincidencia
};
