import { FormikCheckBox } from '@elotech/components';
import React from 'react';

type Props = {};

const ConfiguracoesGeral: React.FC<Props> = () => {
  return (
    <>
      <FormikCheckBox
        label="Exige cadastro imobiliário"
        name="exigeCadastroImobiliario"
        size={3}
        noLabel={false}
      />
      <FormikCheckBox
        label="Vistoria em lote"
        name="vistoriaEmLote"
        size={2}
        noLabel={false}
      />
      <FormikCheckBox
        label="Bloqueia criação de atos em Processos em Transferência"
        name="bloqueiaAtosProcessosTransferencia"
        size={3}
        noLabel={false}
      />
      <FormikCheckBox
        label="Réu não obrigatório"
        name="reuNaoObrigatorio"
        size={3}
        noLabel={false}
      />
    </>
  );
};

export { ConfiguracoesGeral };
