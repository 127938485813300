import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { ApuracaoIss } from '../types/ApuracaoIss';

const crudService = CrudService<ApuracaoIss>('/apuracao-iss');

const loadByAto = (idAto: number): AxiosPromise<ApuracaoIss[]> =>
  axios.get(`${CONTEXT_PATH}/apuracao-iss/por-ato/${idAto}`);

const loadByAtoAno = (
  idAto: number,
  ano: string
): AxiosPromise<ApuracaoIss[]> =>
  axios.get(`${CONTEXT_PATH}/apuracao-iss/por-ato-ano/${idAto}/${ano}`);

const alteraImpostoDeclaradoAberto = (
  apuracaoIss: ApuracaoIss
): AxiosPromise<ApuracaoIss[]> =>
  axios.post(
    `${CONTEXT_PATH}/apuracao-iss/altera-imposto-declarado-aberto`,
    apuracaoIss
  );

const alteraImpostoRecolhido = (
  apuracaoIss: ApuracaoIss
): AxiosPromise<ApuracaoIss[]> =>
  axios.post(
    `${CONTEXT_PATH}/apuracao-iss/altera-imposto-recolhido`,
    apuracaoIss
  );

const exportarXLS = (idAto: number) =>
  axios.get(`${CONTEXT_PATH}/apuracao-iss/exportar-xls/${idAto}`);

const importarXLS = (idAto: number, file: any) =>
  axios.post(`${CONTEXT_PATH}/apuracao-iss/importar-xls/${idAto}`, file);

const getProcesso = (idApuracao: string) =>
  axios.get(`${CONTEXT_PATH}/apuracao-iss/${idApuracao}/processo`);

export const getUrlMapaServico = (idArquivo: string): AxiosPromise =>
  axios.get(`${CONTEXT_PATH}/apuracao-iss/${idArquivo}/download`);

export default {
  loadByAto,
  loadByAtoAno,
  alteraImpostoDeclaradoAberto,
  alteraImpostoRecolhido,
  exportarXLS,
  importarXLS,
  getProcesso,
  getUrlMapaServico,
  ...crudService
};
