export type TipoRelatorioGerencial = {
  codigo: string;
  descricao: string;
};

export const tipoRelatorioGerencialCodigo = {
  CONTRIBUINTES_FISCALIZADOS: 'CF',
  CONTRIBUINTES_EM_FISCALIZACAO: 'CEF',
  QUANTITATIVO_POR_FISCAL: 'QPF',
  CONTRIBUINTES_NAO_FISCALIZADOS: 'CNF'
};

export const tipoRelatorioGerencial: TipoRelatorioGerencial[] = [
  { codigo: 'CF', descricao: 'Contribuintes Fiscalizados' },
  { codigo: 'CEF', descricao: 'Contribuintes Em Fiscalização' },
  { codigo: 'QPF', descricao: 'Quantitativo Por Fiscal' },
  { codigo: 'CNF', descricao: 'Contribuintes Não Fiscalizados' }
];

export const tipoRelatorioGerencialSelect = tipoRelatorioGerencial.map(
  tipo => ({
    key: tipo.codigo,
    value: tipo.codigo,
    label: tipo.descricao
  })
);
