import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { SolicitacaoBaixaRedeSim } from '../types/SolicitacaoBaixaRedeSim';

export const findSolicitacoes = (
  pagination?: any
): AxiosPromise<PagedResponse<SolicitacaoBaixaRedeSim>> =>
  axios.get(`${CONTEXT_PATH}/solicitacao-baixa-redesim/solicitacoes`, {
    params: {
      ...pagination
    }
  });

export const findTotalSolicitacoes = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/solicitacao-baixa-redesim/solicitacoes/total`);

export const ignorar = (solicitacoes: SolicitacaoBaixaRedeSim[]) =>
  axios.post(`${CONTEXT_PATH}/solicitacao-baixa-redesim/ignorar`, solicitacoes);

export default { findSolicitacoes, findTotalSolicitacoes, ignorar };
