export type TipoCadastro = {
  codigo: string;
  descricao: string;
};

export const tipoCadastroCodigo = {
  TODOS: '0',
  IMOBILIARIO: '1',
  MOBILIARIO: '2',
  AVULSO: '9'
};

export const tipoCadastro: TipoCadastro[] = [
  { codigo: '0', descricao: 'Todos' },
  { codigo: '1', descricao: 'Imobiliário' },
  { codigo: '2', descricao: 'Mobiliário' },
  { codigo: '9', descricao: 'Avulso' }
];

export const tipoCadastroSelect = tipoCadastro.map(tipo => ({
  key: tipo.codigo,
  value: tipo.codigo,
  label: tipo.descricao
}));
