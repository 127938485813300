import React, { useState } from 'react';

import { JsonikFieldDef, JsonikFieldMap, JsonikRecipe } from '../common/types';
import { mixBaseFieldMap } from '../form/utils';

type JsonikFormEditorContextProps = {
  recipe: JsonikRecipe;
  setRecipe(recine: JsonikRecipe): void;
  fieldMap?: JsonikFieldMap[];
  setFieldMap(fieldMap: JsonikFieldMap[]): void;
  presetFields?: JsonikFieldDef[];
  setPresetFields(presetFields: JsonikFieldDef[]): void;
  editingField?: string;
  setEditingField(name?: string): void;
  insertingOrEditing: boolean;
  setInsertingOrEditing(value: boolean): void;
  testing: boolean;
  setTesting(value: boolean): void;
};

const Context = React.createContext<JsonikFormEditorContextProps>({
  recipe: '',
  setRecipe: (recine: string) => {},
  fieldMap: [],
  setFieldMap: (fieldMap: JsonikFieldMap[]) => {},
  presetFields: [],
  setPresetFields: (presetFields: JsonikFieldDef[]) => {},
  editingField: undefined,
  setEditingField: (name: string) => {},
  insertingOrEditing: false,
  setInsertingOrEditing: (value: boolean) => {},
  testing: false,
  setTesting: (value: boolean) => {}
});

type Props = {
  recipe: JsonikRecipe;
  fieldMap?: JsonikFieldMap[];
  presetFields?: JsonikFieldDef[];
};
const ContextProvider: React.FC<Props> = ({ children, ...props }) => {
  const [recipe, setRecipe] = useState<JsonikRecipe>(props.recipe);
  const [fieldMap, setFieldMap] = useState<JsonikFieldMap[] | undefined>(
    mixBaseFieldMap(props.fieldMap)
  );
  const [presetFields, setPresetFields] = useState<
    JsonikFieldDef[] | undefined
  >(props.presetFields);
  const [editingField, setEditingField] = useState<string>();
  const [insertingOrEditing, setInsertingOrEditing] = useState<boolean>(false);
  const [testing, setTesting] = useState<boolean>(false);
  return (
    <Context.Provider
      value={{
        recipe,
        setRecipe,
        fieldMap,
        setFieldMap,
        presetFields,
        setPresetFields,
        editingField,
        setEditingField,
        insertingOrEditing,
        setInsertingOrEditing,
        testing,
        setTesting
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
