import { NotFoundPage, ProtectedRoute } from '@elotech/components';
import { RouteWithRedirect } from 'iss-common/components';
import { NotificacaoPage } from 'iss-common/components';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import GerenciadorTarefasListPage from './pages/gerenciador-tarefas/GerenciadorTarefasListPage';
import Home from './pages/home/Home';
import { MyAccountPage } from './pages/user/MyAccountPage';
import artigoRoutes from './routes/routesArtigo';
import artigoGuiaRecolhimentoRoutes from './routes/routesArtigoGuiaRecolhimento';
import configuracoesRoutes from './routes/routesConfiguracoes';
import desifRoutes from './routes/routesDesif';
import divergenciasRoutes from './routes/routesDivergencia';
import emailRoutes from './routes/routesEmail';
import geracaoProcessosRoutes from './routes/routesGeracaoProcesso';
import processosRoutes from './routes/routesProcesso';
import relatorioRoutes from './routes/routesRelatorio';
import tipoAtoRoutes from './routes/routesTipoAto';
import tipoLocalInternoRoutes from './routes/routesTipoLocalInterno';
import tipoProcessoRoutes from './routes/routesTipoProcesso';
import { Roles } from './utils/Roles';

const rootRoutes = [
  {
    path: '/minha-conta',
    component: MyAccountPage,
    title: 'Minha Conta'
  },
  {
    title: 'Gerenciador de Tarefas',
    icon: 'cogs',
    path: '/tarefas',
    component: GerenciadorTarefasListPage,
    role: Roles.fis_gerenciador_tarefas_read.name
  },
  {
    title: 'Notificações',
    icon: 'envelope',
    path: '/notificacoes',
    component: NotificacaoPage
  }
];

const routeComponents = routes =>
  routes.map(({ path, component, role, exact = true }, key) =>
    role ? (
      <ProtectedRoute
        role={role}
        exact={exact}
        path={path}
        component={component}
        key={key}
      />
    ) : (
      <RouteWithRedirect
        exact={exact}
        path={path}
        component={component}
        key={key}
      />
    )
  );

const Routes = () => {
  return (
    <Switch>
      <RouteWithRedirect path="/" component={Home} exact />
      {routeComponents(artigoRoutes)}
      {routeComponents(artigoGuiaRecolhimentoRoutes)}
      {routeComponents(divergenciasRoutes)}
      {routeComponents(emailRoutes)}
      {routeComponents(geracaoProcessosRoutes)}
      {routeComponents(processosRoutes)}
      {routeComponents(tipoAtoRoutes)}
      {routeComponents(configuracoesRoutes)}
      {routeComponents(tipoLocalInternoRoutes)}
      {routeComponents(tipoProcessoRoutes)}
      {routeComponents(relatorioRoutes)}
      {routeComponents(rootRoutes)}
      {routeComponents(desifRoutes)}

      <Route component={NotFoundPage} />
    </Switch>
  );
};

export const rotasBreadcrumb = [
  ...artigoGuiaRecolhimentoRoutes,
  ...artigoRoutes,
  ...divergenciasRoutes,
  ...emailRoutes,
  ...geracaoProcessosRoutes,
  ...processosRoutes,
  ...tipoAtoRoutes,
  ...tipoLocalInternoRoutes,
  ...tipoProcessoRoutes,
  ...relatorioRoutes,
  ...configuracoesRoutes
];

export default Routes;
