import { ReactComponent as Logo } from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { Header, KeycloakService } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AboutModal from '../pages/AboutModal';
import BuildInfoService from '../service/BuildInfoService';
import JobButton from './JobButton';

class AppHeader extends React.Component {
  state = {
    showQuickView: false,
    buildInfo: {}
  };

  onLogout = () => {
    KeycloakService.logout();
  };

  onMyAccount = () => {
    this.props.history.push('/minha-conta');
  };

  onClose = () => {
    this.setState({ showQuickView: false });
  };

  onAbout = () => {
    BuildInfoService.loadInfos().then(response => {
      this.setState({
        buildInfo: response.data,
        showQuickView: true
      });
    });
  };

  onLinkNovidades = () => {
    window.open(
      'https://atendimentoelotech.zendesk.com/hc/pt-br/sections/360004484533-ARW-ISSQN-Admin',
      '_blank'
    );
    this.setState({ showQuickView: false });
  };

  onClickNovidadesVersao = () => {
    window.open(
      'https://elotech.movidesk.com/kb/pt-br/category/arr-oxy-iss',
      '_blank'
    );
  };

  render() {
    const { showMenu } = this.props;

    const { showQuickView, buildInfo } = this.state;

    return (
      <React.Fragment>
        {showQuickView && (
          <AboutModal
            onClose={this.onClose}
            buildInfo={buildInfo}
            onLinkNovidades={this.onLinkNovidades}
          />
        )}
        <Header
          title={this.props.entidade.nome}
          brasao={this.props.entidade.brasao}
          showToggleButton={showMenu}
          logoModulo={Logo}
        >
          <Header.Features>
            <JobButton />
            <Header.Features.AccessibilityButton />
            <Header.Features.NotificationButton />
            <Header.Features.AjustesButton />
            <Header.Features.ModulosButton />
          </Header.Features>
          <Header.FeaturesContent>
            <Header.FeaturesContent.AccessibilityContent />
            <Header.FeaturesContent.EloNotificationContent />
            <Header.FeaturesContent.AjustesContent
              showNovidadesVersao={true}
              onClickNovidadesVersao={this.onClickNovidadesVersao}
            />
            <Header.FeaturesContent.ModulosContent />
          </Header.FeaturesContent>
        </Header>
      </React.Fragment>
    );
  }
}

AppHeader.propTypes = {
  userExists: PropTypes.bool,
  currentUser: PropTypes.object
};

const mapStateToProps = state => ({
  entidade: state.entidade.current,
  state: state
});

const connectedComponent = connect(mapStateToProps)(AppHeader);

export default withRouter(connectedComponent);
