import { AtoEmailsEnviadosListPage } from '../pages/configuracao/ato-emails-enviados/AtoEmailsEnviadosListPage';
import AtoEmailsEnviadosViewPage from '../pages/configuracao/ato-emails-enviados/AtoEmailsEnviadosViewPage';
import { Roles } from '../utils/Roles';

const configuracoesRoutes = [
  {
    path: '/configuracao/ato-emails-enviados',
    component: AtoEmailsEnviadosListPage,
    title: 'E-mails enviados',
    icon: 'envelope-open',
    role: Roles.ato_emails_enviados_read.name
  },
  {
    path: '/configuracao/ato-emails-enviados/:id',
    component: AtoEmailsEnviadosViewPage,
    title: 'Email',
    icon: 'envelope-open',
    role: Roles.ato_emails_enviados_read.name,
    parent: '/configuracao/ato-emails-enviados'
  }
];

export default configuracoesRoutes;
