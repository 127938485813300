import {
  Alert,
  Button,
  Col,
  Container,
  FormikInputDate,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik } from 'formik';
import { openPdf } from 'iss-common/utils/FileUtils';
import React, { useState } from 'react';

import RelatorioProcedimentosFiscaisPeriodoService from '../../service/RelatorioProcedimentosFiscaisPeriodoService';

const validationSchema = Yup.object().shape({
  periodoInicial: Yup.date()
    .label('Período Inicial')
    .required(),
  periodoFinal: Yup.date()
    .label('Período Final')
    .required()
    .test(
      'periodoFinalMaiorQueInicial',
      'O período final não pode ser menor que o período inicial',
      function(periodoFinal: Date) {
        const { periodoInicial } = this.parent;
        return periodoInicial <= periodoFinal;
      }
    )
});

export const RelatorioProcedimentosFiscaisPeriodoPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onFilter = async (values: any) => {
    setLoading(true);
    await RelatorioProcedimentosFiscaisPeriodoService.generateReport(values)
      .then((response: any) => {
        openPdf(response.data);
      })
      .catch((error: any) => {
        Alert.info({
          title: 'Nenhum registro encontrado para gerar o relatório.'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Col md={12}>
        <Row>
          <Formik
            enableReinitialize
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={onFilter}
            render={formProps => (
              <>
                <Row>
                  <FormikInputDate
                    label="Período Inicial"
                    name="periodoInicial"
                    size={3}
                  />
                  <FormikInputDate
                    label="Período Final"
                    name="periodoFinal"
                    size={3}
                  />
                </Row>

                <div className="form-group">
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={formProps.submitForm}
                    type="submit"
                  >
                    <i className="fa fa-print" />
                    Imprimir
                  </Button>
                </div>
              </>
            )}
          />
        </Row>
      </Col>
    </Container>
  );
};
