import { DataPage, SectionTitle } from '@elotech/components';
import { History } from 'history';
import React, { useState } from 'react';
import { match } from 'react-router-dom';

import ApuracaoIssService from '../../../../service/ApuracaoIssService';
import AtoProcessoService from '../../../../service/AtoProcessoService';
import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { Processo } from '../../../../types/Processo';
import { HistoricoServico } from './HistoricoServico';
import { ProcessoDetail } from './ProcessoDetail';
import { Valores } from './Valores';

type Props = {
  match: match<{ id: string; ano: string }>;
  history: Pick<History, 'push'>;
};

export const ApuracaoAnualDataPage: React.FC<Props> = ({ match, history }) => {
  const ano = Number.parseInt(match.params.ano);
  const id = Number.parseInt(match.params.id);
  const [processo, setProcesso] = useState<Processo>();

  const load = async (id: number) => {
    const ato = await AtoProcessoService.findById(id);
    setProcesso(ato.data.processo);
    return ApuracaoIssService.loadByAto(id);
  };

  const getApuracaoCorrente = (apuracoes: ApuracaoIss[]) =>
    apuracoes.filter(h => h.anoCompetencia === ano);

  const onChangeImpostoRecolhido = (apuracaoIss: ApuracaoIss) =>
    ApuracaoIssService.alteraImpostoRecolhido(apuracaoIss);

  const onChangeImpostoDeclaradoAberto = (apuracaoIss: ApuracaoIss) =>
    ApuracaoIssService.alteraImpostoDeclaradoAberto(apuracaoIss);
  const onClickInVisualizar = (mes: any) => {
    history.push(`/processo-ato-apuracao/${id}/${ano}/${mes}`);
  };
  return (
    <DataPage<ApuracaoIss[]>
      initialValues={[]}
      name="processo-ato-apuracao"
      title={`Apuração Anual - ${ano}`}
      icon="university"
      match={match}
      load={load}
      render={({ values }) => (
        <>
          {processo && (
            <>
              <SectionTitle marginTop={'0'}>Dados do Processo</SectionTitle>
              <ProcessoDetail processo={processo} />
              <HistoricoServico apuracoes={values} />
              <Valores
                apuracoes={getApuracaoCorrente(values)}
                goToMensal={onClickInVisualizar}
                onChangeImpostoRecolhido={onChangeImpostoRecolhido}
                onChangeImpostoDeclaradoAberto={onChangeImpostoDeclaradoAberto}
              />
            </>
          )}
        </>
      )}
    />
  );
};
