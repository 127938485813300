import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  Loading,
  Row
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import DivergenciaService from '../../../service/DivergenciaService';
import { DivergenciaItem } from '../../../types/DivergenciaItem';
import NotaFiscalTable from './NotaFiscalTable';

type Props = {};

const DivergenciaNotaFiscalListPage: React.FC<Props> = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState<DivergenciaItem>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true);
    DivergenciaService.findItemById(+id)
      .then(response => {
        setItem(response.data);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar o item da divergência' }, error);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  return (
    <Container breadcrumb>
      <Loading loading={isLoading} />
      <DisplayDataGrid>
        <Row>
          <DisplayDataItem md={2} title="Código">
            {item?.id}
          </DisplayDataItem>
          <DisplayDataItem md={2} title="Mês">
            {item?.mes}
          </DisplayDataItem>
          <DisplayDataItem md={2} title="Ano">
            {item?.ano}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem md={6} title="Tipo">
            {item ? item.descricaoTipo : ''}
          </DisplayDataItem>
          <DisplayDataItem md={6} title="Empresa">
            {item ? item.cadastroGeral.pessoa?.nome : ''}
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>
      <NotaFiscalTable id={id}></NotaFiscalTable>
      <div className="btn-save">
        <FAB icon="arrow-left" title="Voltar" onClick={history.goBack} />
      </div>
    </Container>
  );
};

export default DivergenciaNotaFiscalListPage;
