import { Field, ListPage } from '@elotech/components';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React from 'react';

import TipoProcessoService from '../../../service/TipoProcessoService';
import { TipoProcesso } from '../../../types/TipoProcesso';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  { type: 'NUMBER', label: 'Código', name: 'id' },
  { type: 'STRING', label: 'Descrição', name: 'descricao' }
];

export const TipoProcessoListPage: React.FC<Props> = ({ history }) => {
  const onDelete = async (entity: TipoProcesso) => {
    return TipoProcessoService.deleteById(entity.id).catch(err => {
      Alert.error({ title: 'Erro ao excluir registro' }, err);
    });
  };

  return (
    <ListPage<TipoProcesso>
      title="Tipo de Processo"
      icon={'university'}
      baseUrl="/configuracao/tipo-processo"
      getId={entity => entity.id}
      history={history}
      searchFields={searchFields}
      onSearch={TipoProcessoService.loadVo}
      onDelete={onDelete}
      columns={[
        ['Código', (item: TipoProcesso) => item.id],
        ['Descrição', (item: TipoProcesso) => item.descricao]
      ]}
      hideButtons={['view']}
    />
  );
};
