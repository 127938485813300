import {
  Button,
  Col,
  FormikAutocomplete,
  Loading,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React from 'react';

import UsuarioService from '../../../service/UsuarioService';
import { Usuario } from '../../../types/Usuario';
import { UsuarioLocalInterno } from '../../../types/UsuarioLocalInterno';

type Props = {
  onSubmit: (valor: UsuarioLocalInterno) => void;
  initialValue?: UsuarioLocalInterno;
};

const SCHEMA_VALIDATION = Yup.object().shape({
  usuarioLocalInterno: Yup.string()
    .required()
    .label('Nome do fiscal')
});

const convertUsuario = (values: any) => {
  const usuarioLocalInterno: UsuarioLocalInterno = {
    usuario: values.usuarioLocalInterno
  };
  return usuarioLocalInterno;
};

const AutocompleteFiscalResposavel: React.FC<Props> = props => {
  const loading: boolean = false;

  return (
    <>
      <Loading loading={loading} />
      <Formik<any>
        enableReinitialize={true}
        initialValues={props.initialValue || { ativo: true }}
        onSubmit={(values, actions) => {
          props.onSubmit(convertUsuario(values));
          actions.resetForm();
        }}
        validationSchema={SCHEMA_VALIDATION}
        render={(formProps: FormikProps<UsuarioLocalInterno>) => {
          return (
            <>
              <Row style={{ marginBottom: '10px' }}>
                <FormikAutocomplete<Usuario>
                  name="usuarioLocalInterno"
                  label="Nome do Fiscal"
                  onSearch={UsuarioService.usuarioAutoComplete}
                  getOptionLabel={value => `${value.nome}`}
                  getOptionValue={value => value.id}
                  size={10}
                />
                <Col className="form-group" md={2}>
                  <label className="label" />
                  <Button onClick={formProps.submitForm}>Adicionar</Button>
                </Col>
              </Row>
            </>
          );
        }}
      ></Formik>
    </>
  );
};

export default AutocompleteFiscalResposavel;
