import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { TemplateEmailCampo } from '../types/TemplateEmailCampo';

const loadTemplateCampoEmail = (): AxiosPromise<TemplateEmailCampo[]> =>
  axios.get(`${CONTEXT_PATH}/template-campo-email`);

export default {
  loadTemplateCampoEmail
};
