import { Loading, Panel } from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

import { ApuracaoIss } from '../../../../types/ApuracaoIss';

const meses = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
];

type Props = {
  apuracoes: ApuracaoIss[];
};

export const HistoricoServico: React.FC<Props> = ({ apuracoes }) => {
  const [anos, setAnos] = useState<number[]>([]);

  useEffect(() => {
    setAnos(
      Array.from(new Set(apuracoes.map(a => a.anoCompetencia))).sort(
        (a1, a2) => a1 - a2
      )
    );
  }, [apuracoes]);

  const getReceitaServico = (mes: number, ano: number) => {
    const apuracao = apuracoes.filter(
      apuracao =>
        apuracao.anoCompetencia === ano && apuracao.mesCompetencia === mes
    );

    return !!apuracao.length ? apuracao[0].receitaServicos : 0;
  };

  const getLabels = () => ['Mês', ...anos.map(ano => ano.toString())];

  const getData = () =>
    meses.map((mes, iMes) => [
      mes,
      ...anos.map(ano => getReceitaServico(iMes + 1, ano))
    ]);

  return (
    <>
      <Panel expansible={true} title="Comparações">
        <Chart
          width={'100%'}
          height={200}
          chartType="ColumnChart"
          loader={<Loading loading={true} />}
          data={[getLabels(), ...getData()]}
          options={{
            vAxis: { minValue: 0 },
            // For the legend to fit, we make the chart area smaller
            chartArea: { width: '80%', height: '70%' },
            legend: { position: 'top', maxLines: 3 }
          }}
          // For tests
          rootProps={{ 'data-test-id': 'area-chart' }}
        />
      </Panel>
    </>
  );
};
