import {
  ActionButton,
  ActionsGroup,
  Alert,
  BasicInput,
  Button,
  Checkbox,
  Col,
  FormikAutocomplete,
  FormikAutocompleteMultiple,
  FormikCheckBox,
  FormikInputInteger,
  Loading,
  Panel,
  RadioButton,
  Row,
  SearchPagination,
  SearchPanel,
  SectionTitle,
  Table,
  TableChild,
  Yup,
  usePagedQuery
} from '@elotech/components';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import { parImparCodigo, parImparSelect } from '../../enum';
import CadastroGeralService from '../../service/CadastroGeralService';
import LogradouroService from '../../service/LogradouroService';
import SituacaoMobiliarioService from '../../service/SituacaoMobiliarioService';
import { CadastroGeralProcessoDTO } from '../../types/CadastroGeralProcessoDTO';
import { Cnae } from '../../types/Cnae';
import { FiltroProcessoCadastroDTO } from '../../types/FiltroProcessoCadastroDTO';
import { SituacaoMobiliario } from '../../types/GeracaoProcesso';
import { Logradouro } from '../../types/Logradouro';

const filtroProcessoCadastroDefault: FiltroProcessoCadastroDTO = {
  parImpar: parImparCodigo.AMBOS,
  predialInicial: '',
  predialFinal: ''
};

type ShowInnerComponent = 'cnaes';

type Props = {
  cadastrosProcesso: CadastroGeralProcessoDTO[];
  setCadastrosProcesso(cadastrosProcesso: CadastroGeralProcessoDTO[]): void;
};

const validationSchema = Yup.object().shape({
  logradouro: Yup.object()
    .label('Endereço Rua')
    .required(),
  predialInicial: Yup.number()
    .label('Predial Inicial')
    .min(1)
    .test(
      'prediais',
      'O número predial inicial deve ser menor ou igual ao inicial',
      function(this: Yup.TestContext, value: any) {
        if (value === undefined || value === '') {
          return true;
        }

        return this.parent.predialFinal >= value;
      }
    ),
  predialFinal: Yup.number()
    .label('Predial Final')
    .min(1)
    .test(
      'prediais',
      'O número predial final deve ser maior ou igual ao inicial',
      function(this: Yup.TestContext, value: any) {
        if (value === undefined || value === '') {
          return true;
        }

        return this.parent.predialInicial <= value;
      }
    ),
  situacoesMobiliario: Yup.array()
    .label('Situação Mobiliário')
    .required()
});

const ProcessoCadastro: React.FC<Props> = ({
  cadastrosProcesso,
  setCadastrosProcesso
}) => {
  const [filtroProcessoCadastro, setFiltroProcessoCadastro] = useState<
    FiltroProcessoCadastroDTO
  >(filtroProcessoCadastroDefault);
  const [showInnerComponent, setShowInnerComponent] = useState<{
    [key: string]: ShowInnerComponent | undefined;
  }>({});

  const {
    values,
    pagination,
    doSearch,
    doPagedSearch,
    loading
  } = usePagedQuery<CadastroGeralProcessoDTO, FiltroProcessoCadastroDTO>({
    search: CadastroGeralService.searchEmpresasProcesso,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar os cadastros' }, error);
    }
  });

  setCadastrosProcesso(values);

  const onHandleChangeCheckAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    cadastros: CadastroGeralProcessoDTO[]
  ) => {
    const { checked } = e.target;
    setCadastrosProcesso(
      cadastros.map(cadastro => {
        cadastro.selecionado = checked;
        return cadastro;
      })
    );
  };

  const onHandleChangeCheckBoxCadastros = (
    cadastroSelecionado: CadastroGeralProcessoDTO
  ) => {
    setCadastrosProcesso(
      values?.map(cadastro => {
        if (cadastro.id === cadastroSelecionado.id) {
          cadastro.selecionado = !cadastro.selecionado;
        }
        return cadastro;
      })
    );
  };

  const limpaFormulario = (
    formProps: FormikProps<FiltroProcessoCadastroDTO>
  ): void => {
    formProps.resetForm({ ...filtroProcessoCadastro });
    setFiltroProcessoCadastro({ ...filtroProcessoCadastro });
  };

  const setInnerComponent = (
    item: CadastroGeralProcessoDTO,
    innerComponent: ShowInnerComponent
  ) => {
    const actualInnerComponent = showInnerComponent![`${item.id}`];

    setShowInnerComponent(showInnerComponent => {
      return {
        ...showInnerComponent,
        [`${item.id}`]:
          innerComponent !== actualInnerComponent ? innerComponent : undefined
      };
    });
  };

  const onViewCnaes = async (item: CadastroGeralProcessoDTO) => {
    await setInnerComponent(item, 'cnaes');
  };

  const getInnerComponent = (item: CadastroGeralProcessoDTO) => {
    return showInnerComponent![`${item.id}`];
  };

  const renderInnerComponent = (item: CadastroGeralProcessoDTO) => {
    const innerComponent = getInnerComponent(item);

    if (!innerComponent) {
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <TableChild>
          <Panel isTable>
            <Table
              loading={loading}
              data-test-id="table-cnaes"
              values={item.cnaes || []}
              keyExtractor={(item: Cnae) => `${item.id}`}
            >
              <Table.Column<any> header="Código" value={item => item.codigo} />
              <Table.Column<any>
                header="Descrição"
                value={item => item.descricao}
              />
            </Table>
          </Panel>
        </TableChild>
      </React.Fragment>
    );
  };

  return (
    <Panel isTable>
      <Loading loading={loading}></Loading>
      <SearchPanel>
        <Formik
          enableReinitialize
          onSubmit={value => doSearch(value, { page: 0, size: 10 })}
          validationSchema={validationSchema}
          initialValues={filtroProcessoCadastro}
          render={formProps => (
            <>
              <Form>
                <SectionTitle>Filtros</SectionTitle>
                <Row>
                  <FormikAutocomplete<Logradouro>
                    data-testid="autocomplete-logradouro"
                    name="logradouro"
                    label="Endereço Rua"
                    onSearch={search => LogradouroService.autoComplete(search)}
                    getOptionLabel={value =>
                      `${value.id!} - ${value.tipoLogradouroDescricao} ${
                        value.descricao
                      }`
                    }
                    getOptionValue={(logradouro: Logradouro) => logradouro.id}
                    size={5}
                  />
                  <FormikInputInteger
                    size={2}
                    label="Predial Inicial"
                    name="predialInicial"
                    fast={false}
                  />
                  <FormikInputInteger
                    size={2}
                    label="Predial Final"
                    name="predialFinal"
                    fast={false}
                  />
                  <BasicInput
                    data-test-id="parImpar"
                    size={3}
                    name="parImpar"
                    label="Par ou Impar"
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        options={parImparSelect}
                        selectedValue={field.value}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <FormikAutocompleteMultiple<SituacaoMobiliario>
                    name="situacoesMobiliario"
                    label="Situação do Cadastro"
                    onSearch={SituacaoMobiliarioService.search}
                    getOptionLabel={value => `${value.id} - ${value.descricao}`}
                    getOptionValue={value => value.id}
                    size={9}
                    fast={false}
                  />
                  <FormikCheckBox
                    label="MEI"
                    name="mei"
                    size={1}
                    noLabel={false}
                  />
                  <Col md={1} className={'mt-xs'}>
                    <div className="form-group">
                      <Button type="submit">Buscar cadastros</Button>
                    </div>
                  </Col>
                  <Col md={1} className={'mt-xs'}>
                    <div className="form-group">
                      <Button
                        color="warning"
                        onClick={() => limpaFormulario(formProps)}
                      >
                        Limpar filtros
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        />
      </SearchPanel>
      <Table
        messageEmpty="Busque os cadastros que deseja gerar os processos"
        values={values ?? []}
        fixed
        keyExtractor={item => item.id}
        renderInnerComponent={renderInnerComponent}
      >
        <Table.Column
          headerClassName="column-checkbox no-print"
          className="column-checkbox no-print"
          name="select-all-cadastros"
          header={
            <div className="hidden-xs">
              <Checkbox
                id={'checkAll'}
                data-test-id={`checkAll`}
                checked={
                  cadastrosProcesso.length > 0 &&
                  cadastrosProcesso.every(
                    cadastroProcesso => !!cadastroProcesso.selecionado
                  )
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onHandleChangeCheckAll(event, values)
                }
              />
            </div>
          }
          value={(item: CadastroGeralProcessoDTO, index) => (
            <Checkbox
              id={`check-box-${index}`}
              data-testid={`check-box-${index}`}
              checked={item.selecionado}
              onChange={() => onHandleChangeCheckBoxCadastros(item)}
            />
          )}
        />
        <Table.Column<any>
          headerClassName="left"
          className="left"
          header="Tipo Cadastro/Cadastro Geral"
          name="tipoCadastro-column"
          value={item => `${item.tipoCadastro} / ${item.cadastroGeral}`}
        />
        <Table.Column<any>
          headerClassName="left"
          className="left"
          header="CNPJ/CPF da empresa"
          name="cnpjCpf-column"
          value={item => item.cnpjCpfEmpresa}
        />
        <Table.Column<any>
          headerClassName="left"
          className="left"
          header="Endereço"
          name="endereco-column"
          value={item => item.enderecoCompleto}
        />
        <Table.Column<any>
          headerClassName="left"
          className="left"
          header="Situação Cadastral"
          name="situacaoCadastral-column"
          value={item => item.situacaoMobiliarioDescricao}
        />
        <Table.Column
          header=""
          value={(item: CadastroGeralProcessoDTO) => (
            <ActionsGroup>
              <ActionButton
                data-test-id="view-cnaes"
                key="view-cnaes"
                icon="clipboard-list"
                label="CNAES"
                onClick={() => onViewCnaes(item)}
              />
            </ActionsGroup>
          )}
        />
      </Table>
      {pagination && (
        <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
      )}
    </Panel>
  );
};

export default ProcessoCadastro;
