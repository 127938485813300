import { Modal, Panel, SectionTitle } from '@elotech/components';
import React from 'react';

import { JsonikFormEditor } from '../../../common/components/JsonikForm/editor/JsonikFormEditor';
import { TipoAtoFormulario } from '../../../types/TipoAtoFormulario';

type Props = {
  formulario: TipoAtoFormulario;
  show: boolean;
  onClose: (value: any) => void;
};

export const FormEditor: React.FC<Props> = ({ formulario, show, onClose }) => {
  if (!show) return null;

  return (
    <Modal
      onClose={() => {
        onClose(formulario);
      }}
    >
      <Panel footer={true}>
        <SectionTitle>Editor de Formulário</SectionTitle>
        <JsonikFormEditor recipe={formulario.modelo!} onSubmit={item => {}} />
      </Panel>
    </Modal>
  );
};
