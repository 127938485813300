import {
  Alert,
  Button,
  Col,
  FormikAutocomplete,
  FormikInputDate,
  FormikSelect,
  Loading,
  Row
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import CadastroGeralService from '../../../service/CadastroGeralService';
import CnaeService from '../../../service/CnaeService';
import RegimeFiscalService from '../../../service/RegimeFiscalService';
import TipoDivergenciaService from '../../../service/TipoDivergenciaService';
import { CadastroGeral } from '../../../types/CadastroGeral';
import { Cnae } from '../../../types/Cnae';
import { FiltroDivergencia } from '../../../types/FiltroDivergencia';
import { RegimeFiscal } from '../../../types/RegimeFiscal';
import { TipoDivergencia } from '../../../types/TipoDivergencia';
import { validationSchema } from './validationAvancadaSchema';

const initRegimesFiscais: RegimeFiscal[] = [{ id: 0 }];
const initTiposDivergencia: TipoDivergencia[] = [];

const filtroDivergencia = {} as FiltroDivergencia;

type Props = {
  onPesquisarDivergencia(filtro: FiltroDivergencia): void;
};

export const ConsultaAvancadaDivergencia: React.FC<Props> = ({
  onPesquisarDivergencia
}) => {
  const [loading, setLoading] = useState(false);
  const [regimesFiscais, setRegimesFiscais] = useState(initRegimesFiscais);
  const [tiposDivergencia, setTiposDivergencia] = useState(
    initTiposDivergencia
  );

  const loadRegimeFiscal = () => {
    return RegimeFiscalService.load()
      .then((result: AxiosResponse<RegimeFiscal[]>) => {
        setRegimesFiscais(result.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao carregar os regimes fiscais.` }, error);
      });
  };

  const loadTipoDivergencia = () => {
    return TipoDivergenciaService.load()
      .then((result: AxiosResponse<TipoDivergencia[]>) => {
        setTiposDivergencia(result.data);
      })
      .catch((error: any) => {
        Alert.error(
          { title: `Erro ao carregar os tipos de divergência.` },
          error
        );
      });
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([loadRegimeFiscal(), loadTipoDivergencia()]).then(() =>
      setLoading(false)
    );
  }, []);

  const formatPessoa = (cadastroGeral: CadastroGeral) => {
    if (!cadastroGeral.pessoa) return '';

    const format = (val: any) => (val ? `${val} - ` : val);

    return `${format(cadastroGeral.cadastroGeral)} ${format(
      cadastroGeral.pessoa?.cnpjCpf
    )} ${cadastroGeral.pessoa?.nome}`;
  };

  return (
    <>
      <Loading loading={loading} />
      <Formik<FiltroDivergencia>
        enableReinitialize
        initialValues={filtroDivergencia}
        onSubmit={onPesquisarDivergencia}
        validationSchema={() => validationSchema}
        render={(formProps: FormikProps<FiltroDivergencia>) => (
          <>
            <Row>
              <FormikInputDate
                label="Data Inicial"
                name="dataInicial"
                size={2}
              />
              <FormikInputDate label="Data Final" name="dataFinal" size={2} />
              <FormikSelect<RegimeFiscal>
                name="regimeFiscal"
                label="Regime Fiscal"
                options={regimesFiscais}
                getOptionLabel={option => option.descricao}
                getOptionValue={option => option.id}
                size={4}
                fast={false}
              />
              <FormikAutocomplete<Cnae>
                data-test-id="auto-complete-cnae"
                name="cnae"
                label="Cnae"
                onSearch={CnaeService.load}
                getOptionLabel={value =>
                  value.descricao || value.codigo
                    ? `${value.codigo} - ${value.descricao}`
                    : ''
                }
                getOptionValue={value => value.id}
                size={4}
              />
            </Row>
            <Row>
              <FormikAutocomplete<CadastroGeral>
                data-test-id="auto-complete-cadastro-geral"
                name="cadastroGeral"
                label="Empresa"
                onSearch={CadastroGeralService.autoComplete}
                getOptionLabel={formatPessoa}
                getOptionValue={value => value.id}
                size={4}
              />
              <FormikSelect<TipoDivergencia>
                name="tipo"
                label="Tipo Divergência"
                options={tiposDivergencia || []}
                getOptionLabel={option => option.descricao}
                getOptionValue={option => option.id}
                size={4}
                fast={false}
              />
              <Col md={3}>
                <div className="form-group">
                  <label className="label" />
                  <Button
                    data-test-id="submit-form-verificar"
                    onClick={formProps.submitForm}
                  >
                    Analisar Divergência
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </>
  );
};
