import { EnumValue } from '../types/EnumValue';

export const situacaoMobiliario: EnumValue[] = [
  { codigo: 0, descricao: 'Ativo' },
  { codigo: 1, descricao: 'Inativo' },
  { codigo: 2, descricao: 'Baixado' },
  { codigo: 3, descricao: 'Suspenso' },
  { codigo: 4, descricao: 'Excluido' },
  { codigo: 5, descricao: 'Em Análise' },
  { codigo: 6, descricao: 'Provisório' },
  { codigo: 7, descricao: 'Encerrado' },
  { codigo: 8, descricao: 'Homologação' },
  { codigo: 9, descricao: 'Provisório para Regularização' },
  { codigo: 10, descricao: 'MEI REDESIM CG59' }
];

export const getSituacaoMobiliario = (situacao: number) =>
  situacaoMobiliario.find(
    situacaoMobiliario => situacaoMobiliario.codigo === situacao
  )?.descricao;
