import { JsonikFieldMap } from '../common/types';
import { baseFieldMap } from './BaseFields';

export const mixBaseFieldMap = (fieldMap?: JsonikFieldMap[]) =>
  fieldMap
    ? [
        ...baseFieldMap.filter(
          bmap => !fieldMap.find(fmap => fmap.type === bmap.type)
        ),
        ...fieldMap
      ]
    : baseFieldMap;
