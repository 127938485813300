import { Card, DisplayDataGrid, Loading } from '@elotech/components';
import { AxiosPromise } from 'axios';
import React, { useMemo, useState } from 'react';

import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { Totalizador } from './Totalizador';
import { Value } from './Value';

type Props = {
  apuracoes: ApuracaoIss[];
  onChangeImpostoRecolhido(
    apuracoesIss: ApuracaoIss
  ): AxiosPromise<ApuracaoIss[]>;
  onChangeImpostoDeclaradoAberto(
    apuracoesIss: ApuracaoIss
  ): AxiosPromise<ApuracaoIss[]>;
  goToMensal(mes: any): void;
};

export const Valores: React.FC<Props> = ({
  apuracoes,
  onChangeImpostoRecolhido,
  onChangeImpostoDeclaradoAberto,
  goToMensal
}) => {
  const [apuracoesShow, setApuracoesShow] = useState<ApuracaoIss[]>();
  const [updating, setUpdating] = useState(false);

  const changeImpostoDeclaradoAberto = async (
    apuracao: ApuracaoIss,
    value: number
  ) => {
    setUpdating(true);

    try {
      const retorno = await onChangeImpostoDeclaradoAberto({
        ...apuracao,
        impostoDeclaradoAberto: value
      });
      if (retorno.data) {
        setApuracoesShow(
          retorno.data.sort((a1, a2) => a1.mesCompetencia - a2.mesCompetencia)
        );
      } else {
        setApuracoesShow(
          apuracoesSorted.sort(
            (a1, a2) => a1.mesCompetencia - a2.mesCompetencia
          )
        );
      }
    } finally {
      setUpdating(false);
    }
  };

  const changeImpostoRecolhido = async (
    apuracao: ApuracaoIss,
    value: number
  ) => {
    setUpdating(true);

    try {
      const retorno = await onChangeImpostoRecolhido({
        ...apuracao,
        impostoRecolhido: value
      });
      if (retorno.data) {
        setApuracoesShow(
          retorno.data.sort((a1, a2) => a1.mesCompetencia - a2.mesCompetencia)
        );
      } else {
        setApuracoesShow(
          apuracoesSorted.sort(
            (a1, a2) => a1.mesCompetencia - a2.mesCompetencia
          )
        );
      }
    } finally {
      setUpdating(false);
    }
  };

  const apuracoesSorted = useMemo(() => {
    if (apuracoes.length > 0) {
      setApuracoesShow(
        apuracoes.sort((a1, a2) => a1.mesCompetencia - a2.mesCompetencia)
      );
    }
    return apuracoes.sort((a1, a2) => a1.mesCompetencia - a2.mesCompetencia);
  }, [apuracoes]);

  if (!apuracoesSorted.length) {
    return <span>Não há dados para este período</span>;
  }

  return (
    <>
      <Loading loading={updating} />

      {(apuracoesShow || []).map((apuracao: ApuracaoIss) => (
        <Card key={apuracao.id}>
          <button
            className="btn circle pull-right regular"
            onClick={e => goToMensal(apuracao.mesCompetencia)}
          >
            <em className="fa fa-eye"></em>
            <div className="btn-actions-label"> Visualizar </div>
          </button>
          <DisplayDataGrid border={false} column={true} className="mb-xs">
            <Mes value={apuracao.mesCompetencia} />
            <Value title="Receita Bruta" value={apuracao.receitaBruta} />
            <Value title="Outras receitas" value={apuracao.outrasReceitas} />
            <Value title="Receita serviços" value={apuracao.receitaServicos} />
            <Value
              title="Receita tributável"
              value={apuracao.receitaTributavel}
            />
            <Value title="RBT12" value={apuracao.rbt12} />
            <Value title="RBA" value={apuracao.rba} />
            <Value
              title="Imposto devido"
              value={apuracao.impostoDevido}
              color="red"
            />
            <Value
              title="Imposto recolhido"
              value={apuracao.impostoRecolhido}
              onChange={value => changeImpostoRecolhido(apuracao, value)}
            />
            <Value title="Imposto retido" value={apuracao.impostoRetido} />
            <Value
              title="Imposto declarado aberto"
              value={apuracao.impostoDeclaradoAberto}
              onChange={value => changeImpostoDeclaradoAberto(apuracao, value)}
            />
            <Value title="Saldo" value={apuracao.saldo} color="red" />
            <Value title="Multa" value={apuracao.multa} color="red" />
            <Value title="Juros" value={apuracao.juros} />
            <Value title="Correção" value={apuracao.correcao} />
            <Value title="Saldo total" value={apuracao.saldoTotal} />
            <Value title="Abatimento" value={apuracao.abatimento} />
            <Value
              title="Saldo final"
              value={apuracao.saldoFinal}
              color="red"
            />
          </DisplayDataGrid>
        </Card>
      ))}
      {apuracoesShow && <Totalizador apuracoesShow={apuracoesShow} />}
    </>
  );
};

type MesProps = {
  value: number;
};

const Mes: React.FC<MesProps> = ({ value }) => (
  <Value title="Mês" value={value} color="#0091e6" decimal={false} />
);
