import {
  Alert,
  Button,
  FieldsOfType,
  FormattedCurrency,
  Icon,
  Loading,
  Panel,
  PanelFooter,
  PanelFooterItem,
  Spinner,
  Table
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { connect } from 'react-redux';

import AtoProcessoService from '../../../../../service/AtoProcessoService';
import AutoInfracaoApuracaoIssService from '../../../../../service/AutoInfracaoApuracaoIssService';
import { AutoInfracaoApuracaoIss } from '../../../../../types/AutoInfracaoApuracaoIss';

type Props = {
  idAto: number;
  readonly: boolean;
  usuario?: any;
};

const ApuracaoIssInfracaoComponent: React.FC<Props> = props => {
  const [infracoes, setInfracoes] = useState<AutoInfracaoApuracaoIss[]>();
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setLoading(true);

    AutoInfracaoApuracaoIssService.findAll(props.idAto)
      .then(response => {
        setInfracoes(response.data);
      })
      .catch(error => {
        setLoading(false);
        Alert.error(
          { message: 'Não foi possível carregar a apuração.' },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [props.idAto]);

  const gerarDebito = async () => {
    setWorking(true);

    try {
      await AtoProcessoService.constituirDebitos(
        props.idAto,
        props.usuario.cpfCnpj
      );
      Alert.info({ title: 'Débitos gerados com sucesso' });
    } catch (error) {
      setWorking(false);
      Alert.error({ title: 'Ocorreu um erro ao gerar os débitos' }, error);
    } finally {
      setWorking(false);
    }
  };

  if (loading) return <Spinner />;

  if (!infracoes) return null;

  return (
    <>
      <Loading loading={working} />
      <Panel isTable footer={<Footer infracoes={infracoes} />}>
        <Table
          columnSelector
          keyExtractor={(item, index) => index as number}
          values={infracoes}
        >
          <Table.Column
            header="Mês"
            value={(item: AutoInfracaoApuracaoIss) => (
              <div>{item.mesCompetencia}</div>
            )}
          />
          <Table.Column
            header="Ano"
            value={(item: AutoInfracaoApuracaoIss) => (
              <div>{item.anoCompetencia}</div>
            )}
          />
          <Table.Column
            header="Venct"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedDate value={item.dataVencimento} />
            )}
          />
          <Table.Column
            header="Valor Original"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.saldo)} />
            )}
          />
          <Table.Column
            header="Vlr. Orig. Abat."
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.impostoRecolher)} />
            )}
          />
          <Table.Column
            header="Correção Monetária"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.correcao)} />
            )}
          />
          <Table.Column
            header="Multa"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.multa)} />
            )}
          />
          <Table.Column
            header="Juros"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.juros)} />
            )}
          />
          <Table.Column
            headerClassName="right"
            className="right"
            header="Valor Total"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.saldoTotal)} />
            )}
          />
          <Table.Column
            headerClassName="right"
            className="right"
            header="Abatimento"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.abatimento)} />
            )}
          />
          <Table.Column
            headerClassName="right"
            className="right"
            header="Saldo Atualizado"
            value={(item: AutoInfracaoApuracaoIss) => (
              <FormattedCurrency value={Math.abs(item.saldoFinal)} />
            )}
          />
          <Table.Column
            header="Débito Gerado"
            value={(item: AutoInfracaoApuracaoIss) =>
              item.idDebito !== undefined && (
                <Icon icon="check-circle" positive />
              )
            }
          />
          <Table.Column
            header=""
            value={(item: AutoInfracaoApuracaoIss) => <div></div>}
          />
        </Table>
      </Panel>
      <Button disabled={props.readonly} onClick={gerarDebito}>
        Constituir Débitos
      </Button>
    </>
  );
};

const Footer: React.FC<{
  infracoes: AutoInfracaoApuracaoIss[];
}> = ({ infracoes }) => {
  const getTotal = (
    array: AutoInfracaoApuracaoIss[],
    fieldName: FieldsOfType<AutoInfracaoApuracaoIss, number>
  ): number => array.reduce((total, current) => total + current[fieldName!], 0);

  return (
    <>
      <PanelFooter>
        <PanelFooterItem
          label="Valor Original"
          value={
            <FormattedCurrency value={Math.abs(getTotal(infracoes, 'saldo'))} />
          }
        />
        <PanelFooterItem
          label="Vlr. Orig. Abat."
          value={
            <FormattedCurrency
              value={Math.abs(getTotal(infracoes, 'impostoRecolher'))}
            />
          }
        />
        <PanelFooterItem
          label="Correção Monetária"
          value={
            <FormattedCurrency
              value={Math.abs(getTotal(infracoes, 'correcao'))}
            />
          }
        />
        <PanelFooterItem
          label="Multa"
          value={
            <FormattedCurrency value={Math.abs(getTotal(infracoes, 'multa'))} />
          }
        />
        <PanelFooterItem
          label="Juros"
          value={
            <FormattedCurrency value={Math.abs(getTotal(infracoes, 'juros'))} />
          }
        />
        <PanelFooterItem
          label="Valor Total"
          value={
            <FormattedCurrency
              value={Math.abs(getTotal(infracoes, 'saldoTotal'))}
            />
          }
        />
        <PanelFooterItem
          label="Abatimento"
          value={
            <FormattedCurrency
              value={Math.abs(getTotal(infracoes, 'abatimento'))}
            />
          }
        />
        <PanelFooterItem
          label="Saldo Atualizado"
          value={
            <FormattedCurrency
              value={Math.abs(getTotal(infracoes, 'saldoFinal'))}
            />
          }
        />
      </PanelFooter>
    </>
  );
};

const connectedComponent = connect(
  (state: any) => ({
    usuario: state.user.currentUser
  }),
  null
)(ApuracaoIssInfracaoComponent);

export { connectedComponent as default, ApuracaoIssInfracaoComponent };
