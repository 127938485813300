import { CrudService } from '../common/service/CrudService';
import { AtoEmailEnviado } from '../types/AtoEmailEnviado';

const crudService = CrudService<AtoEmailEnviado>({
  default: '/ato-emails-enviados',
  list: '/ato-emails-enviados/search',
  findById: '/ato-emails-enviados'
});

export default {
  ...crudService
};
