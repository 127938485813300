import { baseFieldMap } from './../form/BaseFields/index';
import { JsonikFieldDef, JsonikFieldMap, JsonikRecipe } from '../common/types';

export const normalizeRecipe = (recipe: JsonikRecipe) => {
  if (typeof recipe === 'string') {
    recipe = JSON.parse(recipe) as JsonikFieldDef[];
  }
  if (Array.isArray(recipe)) {
    for (let field of recipe) {
      field.typeField = baseFieldMap.find(
        typeField => typeField.type === field.type
      );
    }
  }
  return recipe;
};

export const changeRecipe = (
  fieldNameToChange: string,
  fieldtoAdd?: JsonikFieldDef,
  recipe?: JsonikRecipe
) => {
  if (!recipe) return '';
  let newRecipe: JsonikFieldDef[] = normalizeRecipe(recipe);

  const pos = newRecipe.findIndex(item => item.name === fieldNameToChange);

  if (fieldtoAdd && !fieldtoAdd.props) {
    fieldtoAdd.props = {};
  }

  if (pos >= 0) {
    if (!!fieldtoAdd) {
      newRecipe[pos] = fieldtoAdd!;
    } else {
      newRecipe = newRecipe.filter((_, index) => index !== pos);
    }
  } else {
    newRecipe.push(fieldtoAdd!);
  }

  return JSON.stringify(newRecipe);
};

export const setDefaultValues = (
  field: JsonikFieldDef,
  internalRecipe: JsonikRecipe
) => {
  const nextId =
    (normalizeRecipe(internalRecipe) as JsonikFieldDef[])
      .filter(item => item.type === field.type)
      .map(item => item.name.replace(/\D*/, ''))
      .map(str => (!str ? 0 : +str))
      .reduce((p, c) => (c > p ? c : p), 1) + 1;

  const name = `${field.type}${nextId}`;
  return {
    ...field,
    name,
    label: field.label || name
  };
};

export const fieldFromMap = (map: JsonikFieldMap): JsonikFieldDef => ({
  name: '',
  type: map.type,
  label: map.friendlyName,
  description: map.description,
  props: map.getDefaultProps && map.getDefaultProps()
});
