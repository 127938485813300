import {
  Button,
  Col,
  FormikAutocomplete,
  FormikInputDate,
  FormikSelect,
  Row,
  SearchPanel
} from '@elotech/components';
import { Form, Formik, FormikActions } from 'formik';
import React, { useState } from 'react';

import SituacaoMobiliarioService from '../../../service/SituacaoMobiliarioService';
import { FiltroProcessoAlvaraImport } from '../../../types/Alvara';
import { SituacaoMobiliario } from '../../../types/GeracaoProcesso';

type Props = { onSearch: any };

const optionsGrauRisco: string[] = ['A', 'B', 'C'];

export const ProcessoAlvaraImportFiltroPage: React.FC<Props> = ({
  onSearch
}) => {
  const [filtro, setFiltro] = useState<FiltroProcessoAlvaraImport>({});

  const onSubmit = (values: FiltroProcessoAlvaraImport) => {
    setFiltro({});
    onSearch(values);
  };

  const onClear = (
    formikActions: FormikActions<FiltroProcessoAlvaraImport>
  ) => {
    formikActions.resetForm();
  };

  return (
    <>
      <SearchPanel>
        <Formik
          initialValues={filtro}
          onSubmit={values => {
            onSubmit(values);
          }}
          onReset={(_values, formikActions) => {
            onClear(formikActions);
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Row>
                <FormikInputDate
                  name="dataAlvaraInicial"
                  label="Data Alvará Inicial"
                  size={2}
                />
                <FormikInputDate
                  name="dataAlvaraFinal"
                  label="Data Alvará Final"
                  size={2}
                />
                <FormikInputDate
                  name="dataInclusaoInicial"
                  label="Data Inclusão Inicial"
                  size={2}
                />
                <FormikInputDate
                  name="dataInclusaoFinal"
                  label="Data Inclusão Final"
                  size={2}
                />
              </Row>
              <Row>
                <FormikSelect<string>
                  size={2}
                  name="grauRisco"
                  label="Grau de Risco"
                  options={optionsGrauRisco}
                  getOptionLabel={value => value}
                  getOptionValue={value => value}
                />
                <FormikAutocomplete<SituacaoMobiliario>
                  name="situacaoMobiliario"
                  label="Situação Mobiliário"
                  onSearch={SituacaoMobiliarioService.load}
                  getOptionLabel={value => `${value.descricao}`}
                  getOptionValue={value => value.id}
                  size={2}
                />
                <Col md={4} className={'form-group'}>
                  <label className="label" />
                  <Button type="submit" className="inline mt-auto mb-auto">
                    Filtrar
                  </Button>
                  <Button
                    type="reset"
                    color="neutral"
                    className="inline mt-auto mb-auto"
                  >
                    Limpar Filtro
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </SearchPanel>
    </>
  );
};
