import {
  ActionButton,
  ActionsGroup,
  BasicInput,
  Button,
  Col,
  FormikUtils,
  Panel,
  Row,
  Table,
  Yup
} from '@elotech/components';
import { Formik } from 'formik';
import React from 'react';

import { JsonikFieldDef } from '../../common/types';

type Props = {
  field: JsonikFieldDef;
  valueEditing: any;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  saveOptionField: (value: any) => void;
};

const SCHEMA_VALIDATION = Yup.object().shape({
  descricao: Yup.string()
    .required() // eslint-disable-next-line
    .test('Test', 'Opção com valor ${descricao} já inserido', function(valor) {
      const { options, valueEditing } = this.options.context as any;

      if (options.find((opt: any) => opt === valor && valueEditing !== opt)) {
        return false;
      }
      return true;
    })
    .label('Descrição da opção')
});

export const OptionsPropsEditor: React.FC<Props> = ({
  field,
  valueEditing,
  onEdit,
  onDelete,
  saveOptionField
}) => {
  return (
    <>
      <Formik<any>
        initialValues={valueEditing ? { descricao: valueEditing } : {}}
        enableReinitialize={true}
        onSubmit={(value, action) => {
          saveOptionField(value.descricao);
          action.resetForm();
        }}
        validate={FormikUtils.validateWithContext(SCHEMA_VALIDATION, {
          options: field.props.options,
          valueEditing
        })}
        render={({ submitForm }) => (
          <Row>
            <BasicInput
              label="Descrição da Opção"
              id="descricao-da-opcao"
              name="descricao"
              size={9}
            />
            <Col md={3} className="form-group">
              <label className="label" />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={submitForm}>Salvar Opção</Button>
              </div>
            </Col>
          </Row>
        )}
      />
      <Panel isTable style={{ marginTop: '20px' }}>
        <Table<any>
          values={field.props?.options || []}
          keyExtractor={form => form}
        >
          <Table.Column<any>
            header="Descrição"
            value={form => form?.label || form}
          />
          <Table.Column<any>
            header=""
            value={(form, index: any) => (
              <>
                <ActionsGroup>
                  <ActionButton
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => {
                      onEdit(index);
                    }}
                  />
                  <ActionButton
                    icon="trash-alt"
                    label="Remover"
                    onClick={() => onDelete(index)}
                  />
                </ActionsGroup>
              </>
            )}
          />
        </Table>
      </Panel>
    </>
  );
};
