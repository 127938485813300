import {
  FormikCheckBox,
  FormikInputInteger,
  FormikSelect
} from '@elotech/components';
import React from 'react';

type Props = {};

export type TipoDeCadastro = {
  id: number;
  descricao: string;
};
const TIPOS_DE_CADASTRO: TipoDeCadastro[] = [
  { id: 1, descricao: 'CADASTRO IMOBILIÁRIO' },
  { id: 2, descricao: 'CADASTRO MOBILIÁRIO' },
  { id: 3, descricao: 'CADASTRO RURAL' },
  { id: 4, descricao: 'CADASTRO CEMITÉRIO' },
  { id: 9, descricao: 'CADASTRO AVULSO' }
];

const ConfiguracoesHabitese: React.FC<Props> = props => {
  return (
    <>
      <FormikSelect
        label="Tipo de cadastro para geração de valores"
        name="tipoCadastroDebito"
        size={7}
        options={TIPOS_DE_CADASTRO}
        getOptionLabel={option => option.descricao}
        getOptionValue={option => option.id}
        fast={false}
      />
      <FormikInputInteger
        name="prazoDoProcesso"
        label="Prazo do Processo"
        size={5}
      />
      <FormikCheckBox
        label="Exige cadastro imobiliário"
        name="exigeCadastroImobiliario"
        size={3}
        noLabel={false}
      />
      <FormikCheckBox
        label="Permite vincular Profissional"
        name="permiteVincularProfissional"
        size={3}
        noLabel={false}
      />
      <FormikCheckBox
        label="Validar pendência Profissional"
        name="validarPendenciaProfissional"
        size={3}
        noLabel={false}
      />
      <FormikCheckBox
        label="Validar pendência Imóvel"
        name="validarPendenciaImovel"
        size={3}
        noLabel={false}
      />
    </>
  );
};

export { ConfiguracoesHabitese };
