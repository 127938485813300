import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { TipoProcessoAto } from '../types/TipoProcessoAto';

const loadTiposAtos = (tipoProcesso: number): AxiosPromise<TipoProcessoAto[]> =>
  axios.get(`${CONTEXT_PATH}/tipo-processo-ato/tipo-processo/${tipoProcesso}`);

const loadTiposAtosAtivos = (
  tipoProcesso: number
): AxiosPromise<TipoProcessoAto[]> =>
  axios.get(
    `${CONTEXT_PATH}/tipo-processo-ato/tipo-processo/ativos/${tipoProcesso}`
  );

export default { loadTiposAtos, loadTiposAtosAtivos };
