import { FilterUtils } from '@elotech/components';
import axios from 'axios';
import { ServiceUtils } from 'iss-common/utils';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { NaturezaOperacao } from '../types/NaturezaOperacao';

const crudService = CrudService<NaturezaOperacao>('/natureza-operacao');
const filterAutocomplete = (search: any) => {
  if (search) {
    if (isNaN(search)) {
      return FilterUtils.buildDefaultFilter('descricao')(search);
    }

    return FilterUtils.buildDefaultFilter('id')(search);
  }

  return '';
};
export const loadAutoComplete = (searchParams: any, page = 50) =>
  axios.get(
    `${CONTEXT_PATH}/natureza-operacao?${ServiceUtils.buildSearchParams(
      filterAutocomplete(searchParams)
    )}`
  );
export default {
  ...crudService,
  loadAutoComplete
};
