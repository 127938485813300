import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  SectionTitle
} from '@elotech/components';
import React from 'react';

import { ResumoApuracaoCorrigidos } from '../../../../../types/ResumoApuracaoCorrigidos';

type Props = {
  value: ResumoApuracaoCorrigidos;
};

export const ValoresCorrigidosComponent: React.FC<Props> = ({ value }) => {
  return (
    <>
      <SectionTitle>Valores corrigidos</SectionTitle>
      <DisplayDataGrid>
        <DisplayDataItem title="Totais de débitos" md={3}>
          <FormattedCurrency value={value.totaisDebitos} />
        </DisplayDataItem>
        <DisplayDataItem title="Encargos sobre débitos" md={3}>
          <FormattedCurrency value={value.encargosDebitos} />
        </DisplayDataItem>
        <DisplayDataItem title="Total de débitos com encargos" md={3}>
          <FormattedCurrency value={value.totalDebitosEncargos} />
        </DisplayDataItem>
        <DisplayDataItem title="Total de créditos" md={3}>
          <FormattedCurrency value={value.totalCreditos} />
        </DisplayDataItem>

        <DisplayDataItem title="Correção monetária sobre crédito" md={3}>
          <FormattedCurrency value={value.correcaoMonetariaCreditos} />
        </DisplayDataItem>
        <DisplayDataItem title="Total de créditos com correção" md={3}>
          <FormattedCurrency value={value.totalCreditosCorrecao} />
        </DisplayDataItem>
        <DisplayDataItem title="Saldo final" md={3}>
          <FormattedCurrency value={value.saldoFinalApurado} />
        </DisplayDataItem>
      </DisplayDataGrid>
    </>
  );
};
