import axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { ConfiguracaoEmail } from '../types/ConfiguracaoEmail';

const ENDPOINT: string = '/configuracao-email';

const getConfiguracaoEmail = () => axios.get(`${CONTEXT_PATH}${ENDPOINT}`);

const save = (configuracaoEmail: ConfiguracaoEmail) =>
  axios.post(`${CONTEXT_PATH}${ENDPOINT}`, configuracaoEmail);

export default { getConfiguracaoEmail, save };
