import { UserInfo, useAuth } from '@elotech/components';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import React from 'react';

import DivergenciaService from '../service/DivergenciaService';

export type BadgeValue = {
  key: string;
  value?: any;
};

export type ServiceProps = {
  currentUser: UserInfo;
};

export type ServiceFunction = {
  key: string; // Chave usada para associar o valor ao item do menu
  func: (serviceProps: ServiceProps) => AxiosPromise<any>; // service chamado para retornar o valor
  interval: number; // intervalor, em segundos, entre as chamadas ao service
  lastCall?: number; // data da última chamada ao service
};

// Adicionar aqui os services a serem chamados,
// com a chave e o intervalo (em segundos)
var serviceFunctions: ServiceFunction[] = [
  {
    key: 'denunciasNovas',
    func: DivergenciaService.qtdProcessadosNaoVisualizados,
    interval: 60
  }
];

type BadgeValuesContextProps = {
  badges: BadgeValue[];
  getBadgeValue: (key: string) => any;
};

export const BadgeValuesContext = React.createContext<BadgeValuesContextProps>({
  badges: [],
  getBadgeValue: () => undefined
});

export const BadgeValuesConsumer = BadgeValuesContext.Consumer;

export const resetBadgeValues = () =>
  serviceFunctions.forEach(item => (item.lastCall = undefined));

// Usado em testes unitários
export const _setServiceFunctions = (sf: ServiceFunction[]) =>
  (serviceFunctions = sf);

type Props = {
  children: ReactNode;
};

const emptyBadges = () =>
  serviceFunctions.map(sf => ({ key: sf.key, value: undefined }));

export const BadgeValuesProvider: React.FC<Props> = ({ children }) => {
  const [badges, setBadges] = useState<BadgeValue[]>(emptyBadges);
  const [count, setCount] = useState(0);
  const { userInfo } = useAuth();

  const updateBadgesValues = () =>
    callBadgeServices(
      (key: string, value: any) => {
        badges.find(item => item.key === key)!.value = value;
        setBadges(badges);
        setCount(count + 1);
      },
      { currentUser: userInfo }
    );

  const getBadgeValue = (key: string) => {
    const value = (
      (badges && badges.find(badge => badge.key === key)) || {
        value: undefined
      }
    ).value;

    return Number.isInteger(value) ? (value > 0 ? value : undefined) : value;
  };

  useEffect(() => {
    // Verifica, a cada segundo, quais serviços
    // precisam ser chamados novamente.
    const intervalHandler = setInterval(() => updateBadgesValues(), 1000);
    return () => clearInterval(intervalHandler);
  });

  return (
    <>
      <BadgeValuesContext.Provider value={{ badges, getBadgeValue }}>
        {children}
      </BadgeValuesContext.Provider>
    </>
  );
};

type BadgeCallback = (key: string, value: number) => void;

/**
 * Chama os services, respeitando o intervalo mínimo de cada um
 */
const callBadgeServices = (
  callback: BadgeCallback,
  serviceProps: ServiceProps
) =>
  serviceFunctions

    // Seleciona os serviços cujo intervalo expirou
    .filter(
      item =>
        !item.lastCall || Date.now() - item.lastCall >= item.interval * 1000
    )

    // Realiza a chamada ao serviço e passa a resposta ao callback
    .map(item =>
      item
        .func(serviceProps)
        .then((res: AxiosResponse<any>) => callback(item.key, res.data))
        .finally(
          () =>
            // Atualiza a hora da última chamada
            (serviceFunctions.find(
              s => s.key === item.key
            )!.lastCall = Date.now())
        )
    );
