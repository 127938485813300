import {
  Button,
  Col,
  FormikEffect,
  FormikInputNumber,
  FormikSelect,
  Row,
  Yup
} from '@elotech/components';
import { Alert } from '@elotech/components';
import { Formik, FormikActions, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import swal from 'sweetalert2';

import { tipoReceitas } from '../../../../enum/TipoReceitas';
import { ApuracaoIssOutraReceita } from '../../../../types/ApuracaoIssOutraReceita';
import { EnumValue } from '../../../../types/EnumValue';

type Props = {
  values?: ApuracaoIssOutraReceita;
  onSave(values: ApuracaoIssOutraReceita): Promise<void>;
};

type TributavelOption = {
  option: string;
  description: string;
};

const TRIBUTAVEL = 'T';
const NAOTRIBUTAVEL = 'NT';
const NAOTRIBUTAVEL_OUTRASEMPRESAS = 'NTOEG';

const tributavelOptions: TributavelOption[] = [
  {
    option: TRIBUTAVEL,
    description: 'Tributável'
  },
  {
    option: NAOTRIBUTAVEL,
    description: 'Não Tributável'
  },
  {
    option: NAOTRIBUTAVEL_OUTRASEMPRESAS,
    description: 'Receita de outras empresas do grupo'
  }
];

type ApuracaoIssOutraReceitaForm = ApuracaoIssOutraReceita & {
  custom_tributavel?: string;
};

const initialValues: any = {
  valorReceita: 0,
  aliquotaIssQn: 0,
  valorIssQn: 0,
  valorIssRetido: 0,
  tributavel: '',
  tipoOutraReceita: '',
  observacao: '',
  receitaOutraEmpresaDoGrupo: false
};

const validationSchema = Yup.object().shape({
  tipoOutraReceita: Yup.string()
    .label('Tipo de receita')
    .required(),
  custom_tributavel: Yup.string()
    .label('Tributável')
    .required()
    .test(
      'bloqueiaVendadeMercadoriaTributavel',
      'Valor inválido para este tipo de receita',
      function(value: string) {
        return !(
          value &&
          value === TRIBUTAVEL &&
          this.parent.tipoOutraReceita !== 'R'
        );
      }
    ),
  valorReceita: Yup.number()
    .label('Valor da Receita')
    .moreThan(0)
    .required(),
  aliquotaIssQn: Yup.string()
    .label('Alíquota')
    .required(),
  valorIssQn: Yup.string()
    .label('Valor ISS')
    .required(),
  valorIssRetido: Yup.string()
    .label('Valor ISS Retido')
    .required()
});

const toCustom = (
  values: ApuracaoIssOutraReceita
): ApuracaoIssOutraReceitaForm => ({
  ...values,
  dataCadastro: undefined,
  receitaOutraEmpresaDoGrupo: !values.tributavel,
  custom_tributavel: values.tributavel
    ? TRIBUTAVEL
    : values.receitaOutraEmpresaDoGrupo
    ? NAOTRIBUTAVEL_OUTRASEMPRESAS
    : NAOTRIBUTAVEL
});

const fromCustom = (
  values: ApuracaoIssOutraReceitaForm
): ApuracaoIssOutraReceita => {
  values.dataCadastro = undefined;
  values.tributavel = values.custom_tributavel === TRIBUTAVEL;
  values.receitaOutraEmpresaDoGrupo =
    values.custom_tributavel === NAOTRIBUTAVEL_OUTRASEMPRESAS;
  delete values['custom_tributavel'];

  return values;
};

export const ApuracaoOutrasReceitasEditPanel: React.FC<Props> = ({
  values = initialValues,
  onSave
}) => {
  const [disableCamposValores, setDisableCamposValores] = useState<boolean>(
    true
  );
  const [customValues, setCustomValues] = useState<
    ApuracaoIssOutraReceitaForm
  >();

  const focus = () => document.getElementById('tipoOutraReceita')?.focus();

  useEffect(() => {
    setDisableCamposValores(!values.tributavel);
    setCustomValues(toCustom(values));
    focus();
  }, [values]);

  const onChangeFormik = (
    formikProps: FormikProps<ApuracaoIssOutraReceitaForm>,
    oldFormikProps: FormikProps<ApuracaoIssOutraReceitaForm>
  ) => {
    if (
      formikProps.values.valorReceita !== oldFormikProps.values.valorReceita ||
      formikProps.values.aliquotaIssQn !== oldFormikProps.values.aliquotaIssQn
    ) {
      let calculo =
        (formikProps.values.valorReceita * formikProps.values.aliquotaIssQn) /
        100;
      formikProps.setFieldValue('valorIssQn', calculo);
    }

    if (
      oldFormikProps.values.custom_tributavel === TRIBUTAVEL &&
      formikProps.values.custom_tributavel !== TRIBUTAVEL
    ) {
      formikProps.setFieldValue('aliquotaIssQn', 0);
      formikProps.setFieldValue('valorIssQn', 0);
      formikProps.setFieldValue('valorIssRetido', 0);
    }
  };

  const save = async (
    values: ApuracaoIssOutraReceitaForm,
    actions: FormikActions<ApuracaoIssOutraReceitaForm>
  ) => {
    await onSave(fromCustom(values));
    actions.resetForm();
    focus();
  };

  const showObs = (obs: string) =>
    Alert.question({
      title: 'Digite a observação',
      input: 'textarea',
      inputPlaceholder: 'Observação',
      inputValue: obs,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar'
    }).then((res: { dismiss: swal.DismissReason; value: any }) =>
      res.dismiss === swal.DismissReason.cancel ? obs : res.value
    );

  return (
    <>
      <Formik<ApuracaoIssOutraReceitaForm>
        validationSchema={() => validationSchema}
        enableReinitialize={true}
        onSubmit={save}
        initialValues={customValues!}
        render={({
          values,
          submitForm,
          isValid,
          isSubmitting,
          setFieldValue
        }) => (
          <>
            <Row>
              <div className="form-group">
                {
                  <FormikEffect
                    onChange={(
                      formikProps: FormikProps<ApuracaoIssOutraReceitaForm>,
                      oldFormikProps: FormikProps<ApuracaoIssOutraReceitaForm>
                    ) => onChangeFormik(formikProps, oldFormikProps)}
                  />
                }

                <FormikSelect<EnumValue>
                  id="tipoOutraReceita"
                  name="tipoOutraReceita"
                  label="Tipos de receitas"
                  options={tipoReceitas}
                  getOptionLabel={option =>
                    `${option.codigo} - ${option.descricao}`
                  }
                  getOptionValue={option => option.codigo}
                  size={3}
                />

                <FormikSelect<TributavelOption>
                  name="custom_tributavel"
                  label="Tributável?"
                  options={tributavelOptions}
                  size={3}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.option}
                  onSelect={value => {
                    if (value) {
                      setDisableCamposValores(value.option !== TRIBUTAVEL);
                    }
                  }}
                />
              </div>
              <FormikInputNumber
                name="valorReceita"
                label="Valor Receita"
                fast={false}
                size={1}
              />

              <FormikInputNumber
                name="aliquotaIssQn"
                label="Alíquota ISSQN"
                fast={false}
                size={1}
                disabled={disableCamposValores}
              />

              <FormikInputNumber
                name="valorIssQn"
                label="Valor ISSQN"
                fast={false}
                size={1}
                disabled={disableCamposValores}
              />

              <FormikInputNumber
                name="valorIssRetido"
                label="V. ISSQN retido"
                fast={false}
                size={1}
                disabled={disableCamposValores}
              />
              <Col md={1}>
                <FormGroup>
                  <label className="label" />
                  <Button
                    disabled={!isValid && isSubmitting}
                    onClick={async () =>
                      setFieldValue(
                        'observacao',
                        await showObs(values.observacao || '')
                      )
                    }
                    color="neutral"
                  >
                    Observações
                  </Button>
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <label className="label" />
                  <Button
                    disabled={!isValid && isSubmitting}
                    onClick={submitForm}
                  >
                    Salvar
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      />
    </>
  );
};
