import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CopiaNotaVo } from '../types/CopiaNotaVo';
import { ResumoApuracao } from '../types/ResumoApuracao';

const abrirApuracao = (
  copiaNotaVo: CopiaNotaVo
): AxiosPromise<ResumoApuracao> =>
  axios.post(`${CONTEXT_PATH}/apuracao-iss/abrir`, copiaNotaVo);

const calcularApuracao = (idAto: number): AxiosPromise<ResumoApuracao> =>
  axios.get(`${CONTEXT_PATH}/apuracao-iss/calcular-apuracao/${idAto}`, {
    timeout: 0
  });

export default { abrirApuracao, calcularApuracao };
