import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CrudService } from '../common/service/CrudService';
import { GeracaoProcesso } from '../types/GeracaoProcesso';

const findAll = (
  searchParams?: string,
  page?: PageRequest
): AxiosPromise<PagedResponse<GeracaoProcesso>> =>
  axios.get(`/geracao-processo`, {
    params: {
      search: searchParams ? searchParams : undefined,
      ...page
    }
  });

const crudService = CrudService<GeracaoProcesso>('/geracao-processo', {
  sort: 'id,desc'
});

export default { findAll, ...crudService };
