import {
  Alert,
  Checkbox,
  Container,
  FAB,
  FormattedDate,
  Loading,
  Panel,
  SearchPagination,
  Table,
  formatUtils,
  usePagedQuery
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import * as GeracaoProcessoAlvaraService from '../../../service/GeracaoProcessoAlvaraService';
import { FiltroProcessoAlvaraImport } from '../../../types/Alvara';
import { GeracaoProcessoAlvaraDTO } from '../../../types/GeracaoProcessoAlvaraDTO';
import { ProcessoAlvaraImportFiltroPage } from './ProcessoAlvaraImportFiltroPage';

type Props = {};

export const GeracaoProcessoAlvaraImport: React.FC<Props> = () => {
  const history = useHistory();
  const {
    values,
    pagination,
    doSearch,
    doPagedSearch,
    loading
  } = usePagedQuery<GeracaoProcessoAlvaraDTO, FiltroProcessoAlvaraImport>({
    search: GeracaoProcessoAlvaraService.filtrar,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar Processos Alvará.' }, error);
    }
  });

  const [processoAlvaras, setProcessoAlvaras] = useState<
    GeracaoProcessoAlvaraDTO[]
  >(values);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setProcessoAlvaras(values);
  }, [values]);

  const onHandleChangeCheckAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    processos: GeracaoProcessoAlvaraDTO[]
  ) => {
    const { checked } = e.target;
    setProcessoAlvaras(
      processos.map(alvara => {
        alvara.selecionado = checked;
        return alvara;
      })
    );
  };

  const onhandleChangeCheckBoxParcelas = (
    processoSelecionado: GeracaoProcessoAlvaraDTO
  ) => {
    setProcessoAlvaras(
      values?.map(alvara => {
        if (alvara.cadastroGeral === processoSelecionado.cadastroGeral) {
          alvara.selecionado = !alvara.selecionado;
        }
        return alvara;
      })
    );
  };

  const importProcesso = async (values: GeracaoProcessoAlvaraDTO[]) => {
    const result = await Alert.question({
      title: 'Deseja importar os cadastros selecionados?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      target: '#alert'
    });
    if (result.value) {
      setIsLoading(true);
      const alvaras: GeracaoProcessoAlvaraDTO[] = processoAlvaras?.filter(
        (alvara: GeracaoProcessoAlvaraDTO) => {
          return alvara.selecionado;
        }
      );
      GeracaoProcessoAlvaraService.saveAll(alvaras)
        .then(() => {
          history.push('/geracao-processo/');
        })
        .catch(error => {
          Alert.error({ title: 'Ocorreu um ao importar alvará' }, error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const peloMenosUmSelecionado =
    values?.some(alvara => alvara.selecionado) ?? false;

  return (
    <>
      <Container breadcrumb>
        <Panel isTable>
          <Loading loading={loading || isLoading}></Loading>
          <ProcessoAlvaraImportFiltroPage
            onSearch={doSearch}
          ></ProcessoAlvaraImportFiltroPage>
          <Table
            values={values ?? []}
            keyExtractor={item => `${item.cadastroGeral}`}
          >
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header={
                <div className="hidden-xs">
                  <Checkbox
                    checked={
                      processoAlvaras.length > 0 &&
                      processoAlvaras.every(alvara => alvara.selecionado)
                    }
                    id="checkAll"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeCheckAll(event, values)
                    }
                  />
                </div>
              }
              value={(processo: GeracaoProcessoAlvaraDTO, index: number) => (
                <Checkbox
                  id={`${index}`}
                  checked={processo.selecionado}
                  onChange={() => onhandleChangeCheckBoxParcelas(processo)}
                />
              )}
            ></Table.Column>
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header="ID"
              value={item => item.cadastroGeral}
            ></Table.Column>
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header="Razão Social"
              value={item => item.nome}
            ></Table.Column>
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header="CPF/CNPJ"
              value={item => formatUtils.formatCpfCnpj(item.cnpjCpf)}
            ></Table.Column>
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header="Grau de Risco"
              value={item => item.grauRisco}
            ></Table.Column>
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header="Data Alvará"
              value={item => <FormattedDate value={item.dataAlvara} />}
            ></Table.Column>
            <Table.Column<GeracaoProcessoAlvaraDTO>
              header="Situação Mobiliário"
              value={item => item.descricao}
            ></Table.Column>
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={doPagedSearch}
            />
          )}
        </Panel>
        {peloMenosUmSelecionado && (
          <div className="btn-save">
            <FAB
              icon="check"
              iconColor="white"
              title="Importar"
              onClick={() => importProcesso(processoAlvaras)}
            />
          </div>
        )}
      </Container>
    </>
  );
};
