import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';

const PATH_TASK = `${CONTEXT_PATH}/tarefa`;

export const hasRunningExecution = (): AxiosPromise<boolean> =>
  axios.get(`${PATH_TASK}/running-execution`);

export default { hasRunningExecution };
