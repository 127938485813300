import React from 'react';

type Props = {
  idStatus: string;
  status: string;
  allSize?: boolean;
};

export const StatusRender: React.FC<Props> = ({
  idStatus,
  status,
  allSize
}) => {
  let cor = '';
  switch (idStatus) {
    case 'A':
      cor = 'module-color';
      break;
    case 'F':
      cor = 'neutral';
      break;
    case 'C':
      cor = 'negative';
      break;
    case 'T':
      cor = 'warning';
      break;
  }

  return (
    <div className="col-md-12">
      <div className={`chip-container ${allSize && 'center'} ${cor} outlined`}>
        <p className="chip-text">{status}</p>
      </div>
    </div>
  );
};
