import { Button, FormikAutocomplete, RadioButton } from '@elotech/components';
import { AxiosPromise } from 'axios';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CadastroGeral } from '../../types/CadastroGeral';
import { TipoLocalInterno } from '../../types/TipoLocalInterno';

export type TipoLocal = 'INTERNO' | 'EXTERNO';

type TipoLocalProps = {
  namePrefix: string;
  labelPrefix: string;
  size: number;
  tipo: TipoLocal;
  permiteImobiliario?: boolean;
  openModal?(): void;
  onSearchTipoLocal(search: string): AxiosPromise<any>;
  onSearchCadastroGeral(search: string): AxiosPromise<any>;
  onSetTipo(tipo: TipoLocal): void;
};

export const LocalField: React.FC<TipoLocalProps> = ({
  namePrefix,
  labelPrefix,
  size,
  tipo,
  permiteImobiliario,
  onSearchTipoLocal,
  onSearchCadastroGeral,
  onSetTipo,
  openModal
}) => (
  <>
    <div className="form-group">
      <Col md={size}>
        <Row>
          <Col md={12}>
            <label className="label">{labelPrefix}</label>
            <RadioButton
              name={namePrefix}
              label={labelPrefix}
              options={[
                {
                  key: 'EXTERNO',
                  value: 'EXTERNO',
                  label: 'Externo'
                },
                {
                  key: 'INTERNO',
                  value: 'INTERNO',
                  label: 'Interno'
                }
              ]}
              selectedValue={tipo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onSetTipo(e.target.value as TipoLocal)
              }
            />
          </Col>
        </Row>
        <Row>
          {tipo === 'INTERNO' && (
            <FormikAutocomplete<TipoLocalInterno>
              data-test-id={`auto-complete-${namePrefix}-interno`}
              name={`${namePrefix}Interno`}
              label={`${labelPrefix} Interno`}
              onSearch={onSearchTipoLocal}
              getOptionLabel={value => value.descricao || ''}
              size={12}
            />
          )}
          {tipo === 'EXTERNO' && !permiteImobiliario && (
            <FormikAutocomplete<CadastroGeral>
              data-test-id={`auto-complete-${namePrefix}-externo`}
              name={`${namePrefix}Externo`}
              label={`${labelPrefix} Externo`}
              onSearch={onSearchCadastroGeral}
              getOptionLabel={value =>
                value.pessoa
                  ? `${value.pessoa?.cnpjCpf || ''} - 
                ${value.pessoa?.nome || ''} - 
                ${value.tipoCadastro || ''} - 
                ${value.cadastroGeral || ''}`
                  : ''
              }
              size={12}
            />
          )}
          {tipo === 'EXTERNO' && permiteImobiliario && (
            <>
              <FormikAutocomplete<CadastroGeral>
                data-test-id={`auto-complete-${namePrefix}-externo`}
                name={`${namePrefix}Externo`}
                label={`${labelPrefix} Externo`}
                onSearch={onSearchCadastroGeral}
                getOptionLabel={value =>
                  value.pessoa
                    ? `${value.pessoa?.cnpjCpf || ''} - 
                     ${value.pessoa?.nome || ''} - 
                     ${value.tipoCadastro || ''} - 
                     ${value.cadastroGeral || ''}`
                    : ''
                }
                size={9}
              />
              <div className="form-group col-3 col-md-3 col-lg-3">
                <label className="label"></label>
                <Button onClick={openModal}>Cadastro Imobiliario</Button>
              </div>
            </>
          )}
        </Row>
      </Col>
    </div>
  </>
);
