import {
  ActionButton,
  ActionsGroup,
  DragDropFiles,
  Loading,
  Panel,
  Row,
  Table
} from '@elotech/components';
import download from 'downloadjs';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useState } from 'react';

import AtoProcessoFormularioAnexoService from '../../../../../service/AtoProcessoFormularioAnexoService';
import { AtoProcessoFormulario } from '../../../../../types/AtoProcessoFormulario';

type FileHolder = {
  id: number;
  nomeArquivo: string;
};

type Props = {
  formulario: AtoProcessoFormulario;
  readonly: boolean;
};

export const UploadComponent: React.FC<Props> = props => {
  const [filesRecuperados, setFilesRecuperados] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const recuperarAnexos = (idFormulario: number) => {
    setLoading(true);
    return AtoProcessoFormularioAnexoService.findAllAnexos(idFormulario)
      .then(response => {
        setFilesRecuperados(response.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    recuperarAnexos(props.formulario.id);
  }, [props.formulario.id]);

  function dataURLtoFile(dataurl: any, filename: any) {
    var bstr = atob(dataurl),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename);
  }

  const addFile = (file: File) => {
    setLoading(true);

    AtoProcessoFormularioAnexoService.saveAnexo(
      props.formulario.id,
      file.name,
      file
    )
      .then(() => {
        Alert.success({ title: 'Arquivo enviado com sucesso.' });
        recuperarAnexos(props.formulario.id);
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível enviar o arquivo.' }, error);
      })
      .finally(() => setLoading(false));
  };

  const removeFile = async (fileHolder: FileHolder) => {
    const confirm = await Alert.question({
      title: 'Deseja realmente remover este arquivo?'
    }).then((result: any) => result.value);

    if (!confirm) return;

    setLoading(true);

    AtoProcessoFormularioAnexoService.deleteAnexo(fileHolder.id)
      .then(() => {
        Alert.success({ title: 'Arquivo deletado com sucesso.' });
        recuperarAnexos(props.formulario.id);
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível deletar o arquivo.' }, error);
      })
      .finally(() => setLoading(false));
  };

  const downloadFile = (fileHolder: FileHolder) => {
    setLoading(true);

    AtoProcessoFormularioAnexoService.downloadAnexo(fileHolder.id)
      .then(response => {
        download(
          dataURLtoFile(response.data, fileHolder.nomeArquivo),
          fileHolder.nomeArquivo
        );
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível fazer download do arquivo.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading loading={loading} />
      <Row>
        {props.readonly || (
          <DragDropFiles
            files={[]}
            onAddFile={file => addFile(file as File)}
            onRemoveFile={() => {}}
            acceptedFiles={props.formulario.mimeType}
            multiple={false}
          />
        )}
        <Panel
          isTable
          style={{ marginTop: props.readonly ? 'inherited' : '10px' }}
        >
          <Table<FileHolder>
            values={filesRecuperados || []}
            keyExtractor={fileHolder => fileHolder.nomeArquivo}
          >
            <Table.Column<FileHolder>
              header="Nome do arquivo"
              value={fileHolder => fileHolder.nomeArquivo}
            />
            <Table.Column<FileHolder>
              header=""
              value={fileHolder => (
                <>
                  <ActionsGroup>
                    <ActionButton
                      icon="download"
                      label="Download"
                      onClick={() => {
                        try {
                          downloadFile(fileHolder);
                        } catch (error) {
                          Alert.error(
                            {
                              title:
                                'Não foi possível fazer download do arquivo'
                            },
                            error
                          );
                        }
                      }}
                    />
                    {!props.readonly && (
                      <ActionButton
                        icon="trash-alt"
                        label="Remover"
                        onClick={() => removeFile(fileHolder)}
                      />
                    )}
                  </ActionsGroup>
                </>
              )}
            />
          </Table>
        </Panel>
      </Row>
    </>
  );
};
