import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { FiltroRelatorioGerencialOrdemServicoDTO } from '../types/FiltroRelatorioGerencialOrdemServicoDTO';

const generateReport = (filtro: FiltroRelatorioGerencialOrdemServicoDTO) =>
  Axios.post(`${CONTEXT_PATH}/relatorio-gerencial-ordem-servico/`, filtro, {
    responseType: 'blob'
  });

export default {
  generateReport
};
