import { BasicInput, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';

const TextField: React.FC<JsonikFieldProps> = props => (
  <BasicInput {...props} />
);

export const TextFieldMap: JsonikFieldMap = {
  type: 'text',
  component: TextField,
  friendlyName: 'Texto',
  description: 'Caixa de texto simples',
  yupType: Yup.string,
  getDefaultProps: () => undefined
};
