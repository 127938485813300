import { FormikTextArea, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';
import { RowsPropsEditor } from '../propseditor/RowsPropsEditor';

const MemoField: React.FC<JsonikFieldProps> = props => (
  <FormikTextArea {...props} rows={props.props && props.props.rows} />
);

export const MemoFieldMap: JsonikFieldMap = {
  type: 'memo',
  component: MemoField,
  friendlyName: 'Texto Longo',
  description: 'Caixa para texto longo',
  yupType: Yup.string,
  getDefaultProps: () => undefined,
  editor: RowsPropsEditor
};
