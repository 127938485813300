import { JsonikFieldMap } from '../JsonikForm';
import { AutocompleteFieldMap } from './AutocompleteField';
import { SelectFieldMap } from './SelectField';
import { SetFieldMap } from './SetField';

export const customFields: JsonikFieldMap[] = [
  SelectFieldMap,
  SetFieldMap,
  AutocompleteFieldMap
];
