export type ParImpar = {
  codigo: string;
  descricao: string;
};

export const parImparCodigo = {
  PAR: 'P',
  IMPAR: 'I',
  AMBOS: 'A'
};

export const parImpar: ParImpar[] = [
  { codigo: 'P', descricao: 'Par' },
  { codigo: 'I', descricao: 'Impar' },
  { codigo: 'A', descricao: 'Ambos' }
];

export const parImparSelect = parImpar.map(tipo => ({
  key: tipo.codigo,
  value: tipo.codigo,
  label: tipo.descricao
}));
