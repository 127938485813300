import {
  DataPage,
  FormikAutocomplete,
  FormikCheckBox,
  FormikTextArea,
  Panel,
  Yup,
  useShowNotification
} from '@elotech/components';
import { Modulos } from 'iss-common/enums';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';

import CoordenadoriaService from '../../../service/CoordenadoriaService';
import DiretoriaService from '../../../service/DiretoriaService';
import GerenciaService from '../../../service/GerenciaService';
import Parametros from '../../../service/Parametros';
import TipoLocalInternoService from '../../../service/TipoLocalInternoService';
import UsuarioLocalInternoService from '../../../service/UsuarioLocalInternoService';
import { Coordenadoria } from '../../../types/Coordenadoria';
import { Diretoria } from '../../../types/Diretoria';
import { Gerencia } from '../../../types/Gerencia';
import { TipoLocalInterno } from '../../../types/TipoLocalInterno';
import { UsuarioLocalInterno } from '../../../types/UsuarioLocalInterno';
import AutocompleteFiscalResposavel from './AutocompleteFiscalResposavel';
import TableFiscalResposavel from './TableFiscalResponsavel';

const initialValues = { usuariosLocalInterno: [], ativo: true };

const validationSchema = Yup.object().shape({
  descricao: Yup.string()
    .required()
    .label('Descrição')
});

type Params = {
  id: string;
};

type Props = {};

const TipoLocalInternoFormPage: React.FC<Props> = props => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const showNotification = useShowNotification();

  const [indiceEdicao, setIndiceEdicao] = useState<number>();
  const [vincularUsuarioEmVariosLocais, setUsuarioVariosLocais] = useState<
    boolean
  >(false);

  const saveAndRedirect: any = async (tipoLocalInterno: TipoLocalInterno) => {
    await TipoLocalInternoService.save(tipoLocalInterno).then(() => {
      showNotification({
        level: 'success',
        message: 'Registro salvo com sucesso.'
      });

      history.replace('/configuracao/tipo-local-interno');
    });
  };

  const load = (id: string) => {
    Parametros.loadParametros(
      Modulos.ISS,
      'VINCULAR_USUARIO_VARIOS_LOCAIS_INTERNOS'
    ).then(response => {
      setUsuarioVariosLocais(response?.data === 'S');
    });

    return TipoLocalInternoService.findById(id);
  };

  const onSubmitFiscalResponsavel = async (
    values: TipoLocalInterno,
    item: UsuarioLocalInterno,
    setFieldValue: any
  ) => {
    if (
      values.usuariosLocalInterno?.find(
        value => value.usuario.id === item.usuario.id
      )
    ) {
      Alert.warning({ title: 'Usuário já existe neste local interno.' });
      return;
    }

    if (!vincularUsuarioEmVariosLocais) {
      const { data } = await UsuarioLocalInternoService.findAllByUsuarioId(
        item.usuario.id
      );
      if (data?.length > 0) {
        Alert.warning({
          title: 'Usuário já incluído em outro local interno.'
        });
        return;
      }
    }

    if (indiceEdicao) {
      values.usuariosLocalInterno[indiceEdicao] = item;
      setFieldValue('usuariosLocalInterno', values.usuariosLocalInterno);
    } else {
      setFieldValue('usuariosLocalInterno', [
        ...values.usuariosLocalInterno,
        item
      ]);
    }
    setIndiceEdicao(undefined);
  };

  const onDelete = (index: number, values: any, setFieldValue: any) =>
    Alert.question({
      title: `Deseje excluir este fiscal?`
    }).then((result: any) => {
      if (result.value) {
        const fiscais = [
          ...values.usuariosLocalInterno.slice(0, index),
          ...values.usuariosLocalInterno.slice(index + 1)
        ];
        setFieldValue('usuariosLocalInterno', fiscais);
      }
    });

  return (
    <>
      <DataPage<TipoLocalInterno>
        name="tipo-local-interno"
        title="Local Interno"
        icon="users-cog"
        redirectUrl="configuracao/tipo-local-interno"
        history={history}
        match={{ params: { id: id } } as any}
        load={load}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSave={saveAndRedirect}
        render={({ values, setFieldValue }) => (
          <>
            <Row>
              <FormikTextArea
                name="descricao"
                label="Descrição"
                rows={1}
                size={10}
              />
              <FormikCheckBox
                name="ativo"
                label="Ativo"
                size={2}
                noLabel={false}
              />
            </Row>
            <Row>
              <FormikAutocomplete<Coordenadoria>
                name="coordenadoria"
                label="Coordenadoria"
                onSearch={search => {
                  return CoordenadoriaService.load(
                    `ativo==true and descricao==*${search}*`
                  );
                }}
                size={4}
                getOptionLabel={value => `${value.descricao}`}
                newValueLabel={'Incluir como nova coordenadoria'}
                onCreateValue={texto =>
                  CoordenadoriaService.save({
                    ativo: true,
                    descricao: texto
                  } as Coordenadoria)
                }
              />
              <FormikAutocomplete<Gerencia>
                name="gerencia"
                label="Gerência"
                onSearch={search => {
                  return GerenciaService.load(
                    `ativo==true and descricao==*${search}*`
                  );
                }}
                size={4}
                getOptionLabel={value => `${value.descricao}`}
                newValueLabel={'Incluir como nova gerência'}
                onCreateValue={texto =>
                  GerenciaService.save({
                    ativo: true,
                    descricao: texto
                  } as Gerencia)
                }
              />
              <FormikAutocomplete<Diretoria>
                name="diretoria"
                label="Diretoria"
                onSearch={search => {
                  return DiretoriaService.load(
                    `ativo==true and descricao==*${search}*`
                  );
                }}
                size={4}
                getOptionLabel={value => `${value.descricao}`}
                newValueLabel={'Incluir como nova diretoria'}
                onCreateValue={texto =>
                  DiretoriaService.save({
                    ativo: true,
                    descricao: texto
                  } as Diretoria)
                }
              />
            </Row>
            <Row>
              <Panel
                title="Fiscais responsáveis"
                className="mt-xs"
                expansible={false}
              >
                <AutocompleteFiscalResposavel
                  initialValue={
                    indiceEdicao === undefined
                      ? undefined
                      : values.usuariosLocalInterno[indiceEdicao]
                  }
                  onSubmit={async (item: UsuarioLocalInterno) =>
                    await onSubmitFiscalResponsavel(values, item, setFieldValue)
                  }
                />
                <TableFiscalResposavel
                  onDelete={index => onDelete(index, values, setFieldValue)}
                  fiscais={values.usuariosLocalInterno}
                />
              </Panel>
            </Row>
          </>
        )}
      />
    </>
  );
};

export default TipoLocalInternoFormPage;
