import { FilterUtils } from '@elotech/components';
import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { TipoLocalInterno } from '../types/TipoLocalInterno';

const defaultSort = { sort: 'descricao' };

const crudService = CrudService<TipoLocalInterno>(
  '/tipo-local-interno',
  defaultSort,
  (search: string) =>
    'ativo==true and ' + FilterUtils.buildFilter(['descricao'])(search)
);

const findAll = (searchParams: string, page?: any) =>
  Axios.get(CONTEXT_PATH + `/tipo-local-interno`, {
    params: {
      search: searchParams
        ? `ativo == true and ${searchParams}`
        : 'ativo==true',
      sort: 'id',
      ...page
    }
  });

export default { ...crudService, findAll };
