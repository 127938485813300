import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDate,
  Row
} from '@elotech/components';
import React, { useEffect, useState } from 'react';

import UsuarioService from '../../../service/UsuarioService';
import { Processo } from '../../../types/Processo';
import { getGoogleMapsUrl } from '../../../utils/utils';

type Props = {
  processo: Processo;
};

export const ProcessoDetail: React.FC<Props> = ({ processo }) => {
  const [nomeFiscalResponsavel, setNomeFiscalResponsavel] = useState<
    string | undefined
  >('');

  useEffect(() => {
    if (processo?.movimentacaoAtual?.fiscalResponAtual) {
      UsuarioService.buscarNomeFiscalResponsavel(
        processo?.movimentacaoAtual?.fiscalResponAtual
      )
        .then(response => {
          setNomeFiscalResponsavel(response.data);
        })
        .catch(() => {
          setNomeFiscalResponsavel(
            processo?.movimentacaoAtual
              ? processo?.movimentacaoAtual?.fiscalResponAtual
              : ''
          );
        });
    } else {
      UsuarioService.buscarNomeFiscalResponsavel(processo?.fiscalResponsavel)
        .then(response => {
          setNomeFiscalResponsavel(response.data);
        })
        .catch(() => {
          setNomeFiscalResponsavel(processo.fiscalResponsavel);
        });
    }
  }, [processo]);

  const temLocalizacao = processo?.localizacao !== undefined;

  return (
    <DisplayDataGrid column={true}>
      <Row>
        <DisplayDataItem md={temLocalizacao ? 4 : 5} title="Processo">
          {processo?.tipoProcesso?.descricao}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Número/Exercicio">
          {processo?.numero}/{processo?.exercicio}
        </DisplayDataItem>
        {temLocalizacao && (
          <DisplayDataItem md={2} title="Localização">
            <a
              data-testid="link-localizacao"
              href={getGoogleMapsUrl(processo.localizacao!)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {processo.localizacao}
            </a>
          </DisplayDataItem>
        )}
        <DisplayDataItem md={2} title="Número Protocolo">
          {processo?.numeroProtocolo}
        </DisplayDataItem>
        <DisplayDataItem md={1} title="Prazo">
          {processo?.prazoDoProcesso}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Status">
          {processo?.descricaoStatus || ''}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={temLocalizacao ? 4 : 5} title="Fiscal Responsável">
          {nomeFiscalResponsavel}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Data Abertura">
          <FormattedDate value={processo?.dataAbertura} />
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Data Encerramento">
          {processo?.dataFechamento !== undefined ? (
            <FormattedDate value={processo?.dataFechamento} />
          ) : (
            ''
          )}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Período de Fiscalização">
          <FormattedDate value={processo?.inicioFiscalizacao} /> até{' '}
          <FormattedDate value={processo?.fimFiscalizacao} />
        </DisplayDataItem>
      </Row>
    </DisplayDataGrid>
  );
};
