import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { Logradouro } from '../types/Logradouro';

const crudService = CrudService<Logradouro>('/logradouros');

const customSort = { sort: 'id,descricao' };

const autoComplete = (
  search: string,
  extraClause?: string
): AxiosPromise<AxiosPromise<Logradouro[]>> =>
  axios.get(`${CONTEXT_PATH}/logradouros/autocomplete`, {
    params: {
      ...customSort,
      search: `${FilterUtils.buildNumberAndStringFilter(
        ['id'],
        ['descricao']
      )(search)} and inativo == false ${
        extraClause ? ' and ' + extraClause : ''
      }`,
      fields: 'id,descricao,tipoLogradouroDescricao,cidade.id'
    }
  });

export default {
  ...crudService,
  autoComplete
};
