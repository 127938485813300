import { FormikCheckBox, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';

const BooleanField: React.FC<JsonikFieldProps> = props => (
  <FormikCheckBox {...props} id={`boolean_field_${props.name}`} />
);

export const BooleanFieldMap: JsonikFieldMap = {
  type: 'boolean',
  component: BooleanField,
  friendlyName: 'Caixa de Verificação',
  description: 'Aceita apenas 2 valores: marcado ou desmarcado',
  yupType: Yup.string,
  getDefaultProps: () => undefined
};
