import {
  ActionButton,
  ActionsGroup,
  ObjectUtils,
  Panel,
  Table
} from '@elotech/components';
import React from 'react';

import { TipoAto } from '../../../types/TipoAto';
import { TipoAtoFormulario } from '../../../types/TipoAtoFormulario';

type Props = {
  tipoAto: TipoAto;
  tipo: string;
  onDelete: (index: any) => void;
  onEdit: (index: any) => void;
};

export const FormularioTable: React.FC<Props> = ({
  tipoAto,
  tipo,
  onDelete,
  onEdit
}) => {
  const getFormularios = () =>
    tipoAto.formularios
      ? tipoAto.formularios.filter(form => form.tipo === tipo)
      : [];

  return (
    <>
      <Panel isTable style={{ marginTop: '20px' }}>
        <Table<TipoAtoFormulario>
          values={getFormularios()}
          keyExtractor={form => {
            return form.descricao!;
          }}
        >
          <Table.Column<TipoAtoFormulario>
            header="Descrição"
            value={form => form.descricao}
          />
          <Table.Column<TipoAtoFormulario>
            header="Ativo"
            value={form => ObjectUtils.booleanToSimNao(form.ativo!)}
          />
          {tipo === 'UPLOAD' ? (
            <Table.Column<TipoAtoFormulario>
              header="Obrigatório"
              value={form => ObjectUtils.booleanToSimNao(form.obrigatorio!)}
            />
          ) : (
            undefined
          )}
          {tipo === 'UPLOAD' ? (
            <Table.Column<TipoAtoFormulario>
              header="Extensão"
              value={form => form.mimeType}
            />
          ) : (
            undefined
          )}
          <Table.Column<TipoAtoFormulario>
            header=""
            value={(form, index: any) => (
              <>
                <ActionsGroup>
                  <ActionButton
                    key="1"
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => {
                      onEdit(index);
                    }}
                  />
                  <ActionButton
                    key="2"
                    icon="trash-alt"
                    label="Remover"
                    onClick={() => onDelete(index)}
                  />
                </ActionsGroup>
              </>
            )}
          />
        </Table>
      </Panel>
    </>
  );
};
