import { EnumValue } from '../types/EnumValue';
import { SituacaoTarefaAssincrona } from '../types/TarefaAssincrona';

export const SituacaoTarefaAssincronaEnum = {
  processando: 'PROCESSANDO',
  erro: 'ERRO',
  finalizado: 'FINALIZADO'
};

export const SituacaoTarefaAssincronaValues: EnumValue[] = [
  {
    codigo: SituacaoTarefaAssincronaEnum.processando,
    descricao: 'Processando'
  },
  {
    codigo: SituacaoTarefaAssincronaEnum.erro,
    descricao: 'Erro'
  },
  {
    codigo: SituacaoTarefaAssincronaEnum.finalizado,
    descricao: 'Finalizado'
  }
];

export const getSituacaoTarefaAssincronaLabel = (
  situacao: SituacaoTarefaAssincrona
): string => {
  const match = SituacaoTarefaAssincronaValues.find(
    value => value.codigo === situacao
  );
  return match ? match.descricao : '';
};
