import axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';

const saveValores = (idFormulario: number, valores: object) =>
  axios.put(
    `${CONTEXT_PATH}/ato-processo-formulario/${idFormulario}/valores/`,
    valores
  );

export default { saveValores };
