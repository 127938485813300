import { Button, Col, ErrorText, Row, SectionTitle } from '@elotech/components';
import { ErrorMessage } from 'formik';
import React, { useState } from 'react';

import { JsonikFieldDef } from '../common/types';
import { JsonikForm } from '..';

const sizeOptions = [
  { value: 1, label: '8%' },
  { value: 2, label: '16%' },
  { value: 3, label: '25%' },
  { value: 4, label: '33%' },
  { value: 5, label: '42%' },
  { value: 6, label: '50%' },
  { value: 7, label: '58%' },
  { value: 8, label: '66%' },
  { value: 9, label: '75%' },
  { value: 10, label: '83%' },
  { value: 11, label: '92%' },
  { value: 12, label: '100%' }
];

const makeData = (field: JsonikFieldDef) => {
  const res = {};
  Object.keys(field).map(key => (res[key] = field[key]));
  return res;
};

type Props = {
  field: JsonikFieldDef;
  onSubmit: (values: any) => void;
  fieldMap: any;
  allFields: any;
};

export const BasicPropsEditor: React.FC<Props> = ({
  field,
  onSubmit,
  fieldMap,
  allFields
}) => {
  const [index, setIndex] = useState<any>(undefined);
  const [valueEditing, setValueEditing] = useState<any>(undefined);
  return (
    <>
      <JsonikForm
        recipe={[
          {
            type: 'text',
            name: 'name',
            label: 'Nome',
            hint: 'Nome interno do campo.',
            size: '4',
            required: true
          },
          {
            type: 'text',
            name: 'label',
            label: 'Descrição Campo',
            hint: 'Descrição visível para o usuário.',
            size: '4',
            required: true
          },
          {
            type: 'autocompletetypefield',
            name: 'typeField',
            label: 'Tipo do campo',
            hint: 'Tipo do campo a ser gerado',
            size: '4',
            required: true
          },
          {
            type: 'select',
            name: 'size',
            label: 'Tamanho',
            hint: 'Tamanho do campo, em relação à largura disponível.',
            size: '4',
            required: true,
            props: { options: sizeOptions }
          },
          {
            type: 'text',
            name: 'hint',
            label: 'Ajuda',
            hint: 'Texto de ajuda exibido ao usuário (como este).',
            size: '8',
            required: false
          },
          {
            type: 'boolean',
            name: 'required',
            label: 'Obrigatório',
            hint: 'Indica que o campo é de preenchimento obrigatório',
            size: '4',
            required: false
          },
          {
            type: 'boolean',
            name: 'break',
            label: 'Quebrar linha',
            hint: 'Força o próximo componente a ser exibido em uma nova linha.',
            size: '4',
            required: false
          }
        ]}
        data={makeData(field)}
        allFields={allFields}
        onSubmit={onSubmit}
        render={(props: any) => {
          const map = fieldMap!.find(
            (map: any) => map.type === props.values.type
          );

          const saveOptionField = (option: any) => {
            if (index !== undefined) {
              props.values.props.options[index] = option;
              props.setFieldValue('props.options', props.values.props.options);
            } else {
              let index = props.values.props.options.findIndex(
                (a: any) => a === option
              );

              if (index !== -1) {
                return;
              }

              props.setFieldValue('props.options', [
                ...props.values.props.options,
                option
              ]);
            }
            setIndex(undefined);
            setValueEditing(undefined);
          };

          const onEdit = (index: number) => {
            setValueEditing(props.values.props.options[index]);
            setIndex(index);
          };

          const onDelete = (index: number) => {
            const options = [
              ...props.values.props.options.slice(0, index),
              ...props.values.props.options.slice(index + 1)
            ];
            props.setFieldValue('props.options', options);
          };

          return (
            <>
              {map?.editor && (
                <Row style={{ marginTop: '15px' }}>
                  <Col md={12}>
                    <SectionTitle marginTop="0" hasButton>
                      Opções
                      <ErrorMessage className="center" name="props">
                        {msg => <ErrorText>{msg}</ErrorText>}
                      </ErrorMessage>
                    </SectionTitle>
                    {map.editor({
                      field: props.values,
                      valueEditing,
                      onDelete,
                      onEdit,
                      saveOptionField
                    })}
                  </Col>
                </Row>
              )}
              <Row style={{ marginTop: '5px' }}>
                <Col md={12} style={{ marginTop: '5px' }}>
                  <Button
                    id="salvar-campo"
                    onClick={() => {
                      props.submitForm();
                    }}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
      />
    </>
  );
};
