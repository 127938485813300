export const ConvertBase64ToFile = (
  base64: string,
  filename: string,
  fileType: string
) => {
  const base64Data = atob(base64);
  const buffer = new ArrayBuffer(base64.length);
  const base64Decode = new Uint8Array(buffer);

  for (let index = 0; index < base64Data.length; index++) {
    base64Decode[index] = base64Data.charCodeAt(index);
  }
  const file = new File([base64Decode], filename, { type: fileType });
  return file;
};

export const isFileSuported = () =>
  window.File && window.FileReader && window.FileList && window.Blob;

export const openPdf = (data: any) => {
  let file = new Blob([data], { type: 'application/pdf' });
  let fileURL = URL.createObjectURL(file);

  window.open(fileURL);
};
