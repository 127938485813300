import { Yup } from '@elotech/components';

export const validationSchema = (inicioMes: Date, fimMes: Date) =>
  Yup.object().shape({
    situacaoNota: Yup.string()
      .label('Situação Nota')
      .required(),
    numeroDocumento: Yup.string()
      .label('Numero Documento')
      .required(),
    dataEmissao: Yup.date()
      .label('Data Emissão')
      .min(
        inicioMes,
        `A data emissão precisa estar entre o mês e ano da apuração ${fimMes.getMonth() +
          1}/${fimMes.getFullYear()}`
      )
      .max(
        fimMes,
        `A data emissão precisa estar entre o mês e ano da apuração ${fimMes.getMonth() +
          1}/${fimMes.getFullYear()}`
      )
      .required(),
    tipoDocumento: Yup.object()
      .label('Tipo documento')
      .required(),
    localPrestacaoUF: Yup.string()
      .label('Estado')
      .required(),
    localPrestacaoCidade: Yup.string()
      .label('Cidade')
      .required(),
    naturezaOperacao: Yup.object()
      .label('Natureza operação')
      .required(),
    valorTotalNota: Yup.number()
      .label('Valor total nota')
      .required(),
    valorServico: Yup.number()
      .label('valor serviço')
      .required(),
    valorDeducoes: Yup.number()
      .label('Valor deduções')
      .required(),
    baseCalculo: Yup.number()
      .label('Base de cálculo')
      .required(),
    aliquotaIss: Yup.number()
      .label('Aliquota')
      .required()
  });
