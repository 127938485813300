import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  Field,
  FormattedDate,
  Loading,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import React from 'react';
import { FormattedTime } from 'react-intl';
import { useHistory } from 'react-router';

import AtoEmailsEnviadosService from '../../../service/AtoEmailsEnviadosService';
import { AtoEmailEnviado } from '../../../types/AtoEmailEnviado';

const searchFields: Field[] = [
  { type: 'DATE', label: 'Data Inicial', name: 'dataEnvio' },
  { type: 'DATE', label: 'Data Final', name: 'dataEnvio' },
  { type: 'STRING', label: 'E-mail Destinatário', name: 'emailDestinatario' },
  { type: 'STRING', label: 'Usuário Responsável', name: 'usuarioEnvio' }
];

export const AtoEmailsEnviadosListPage: React.FC = () => {
  const history = useHistory();
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<AtoEmailEnviado>({
    search: AtoEmailsEnviadosService.load,
    onError: error => {
      Alert.error({ title: 'Erro ao carregar e-mails.' }, error);
    }
  });

  const onView = (item: AtoEmailEnviado) => {
    history.replace(`/configuracao/ato-emails-enviados/${item.id}`);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter fields={searchFields} search={doSearch} />
          <Table<AtoEmailEnviado>
            values={values}
            keyExtractor={item => `${item.id}`}
          >
            <Table.Column<AtoEmailEnviado>
              header="Data - Hora"
              value={item => (
                <div>
                  <FormattedDate value={item.dataEnvio} />
                  {' - '}
                  <FormattedTime value={item.horaEnvio} />
                </div>
              )}
            />

            <Table.Column<AtoEmailEnviado>
              header="E-mail Destinatário"
              value={item => item.emailDestinatario}
            />

            <Table.Column<AtoEmailEnviado>
              header="Usuário Responsável"
              value={item => item.usuarioEnvio}
            />

            <Table.Column<AtoEmailEnviado>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    key="analyse-button"
                    icon="eye"
                    label="Ver e-mail"
                    onClick={() => onView(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={doPagedSearch}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
