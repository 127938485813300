import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { FiltroRelatorioDocumentosSolicitadosEntreguesDTO } from '../types/FiltroRelatorioDocumentosSolicitadosEntreguesDTO';
import { FiltroRelatorioGerencialFiscalizacaoDTO } from '../types/FiltroRelatorioGerencialFiscalizacaoDTO';

const gerarRelDocumentosIntimados = (search: any) =>
  Axios.post(`${CONTEXT_PATH}/relatorios/documentos-intimados/`, search, {
    responseType: 'blob'
  });

const gerarRelatorioGerencialFiscalizacao = (
  filtro: FiltroRelatorioGerencialFiscalizacaoDTO
) =>
  Axios.post(`${CONTEXT_PATH}/relatorios/gerencial-fiscalizacao/`, filtro, {
    responseType: 'blob'
  });

const gerarRelatorioDocumentosSolicitadosEntregues = (
  filtro: FiltroRelatorioDocumentosSolicitadosEntreguesDTO
) =>
  Axios.post(
    `${CONTEXT_PATH}/relatorios/documentos-solicitados-entregues`,
    filtro,
    {
      responseType: 'blob'
    }
  );

export default {
  gerarRelDocumentosIntimados,
  gerarRelatorioGerencialFiscalizacao,
  gerarRelatorioDocumentosSolicitadosEntregues
};
