import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';

const crudService = CrudService('/artigoGuia', {
  sort: 'id'
});

const buscaGuiaVoByArtigo = (id: number, sort: string) => {
  return Axios.get(`${CONTEXT_PATH}/artigoGuia/vo/${id}/?sort=${sort}`);
};

export default {
  ...crudService,
  buscaGuiaVoByArtigo
};
