export type TipoAgrupamentoRelatorioGerencial = {
  codigo: string;
  descricao: string;
};

export const tipoAgrupamentoRelatorioGerencialCodigo = {
  POR_CADASTRO: 'C',
  POR_FISCAL: 'F'
};

export const tipoAgrupamentoRelatorioGerencial: TipoAgrupamentoRelatorioGerencial[] = [
  { codigo: 'C', descricao: 'Por Cadastro' },
  { codigo: 'F', descricao: 'Por Fiscal' }
];

export const tipoAgrupamentoRelatorioGerencialSelect = tipoAgrupamentoRelatorioGerencial.map(
  tipo => ({
    key: tipo.codigo,
    value: tipo.codigo,
    label: tipo.descricao
  })
);
