import '@fortawesome/fontawesome-free/css/all.css';
import 'react-quill/dist/quill.snow.css';
import 'sweetalert2/dist/sweetalert2.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
