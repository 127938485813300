import axios, { AxiosPromise } from 'axios';

import { ReadonlyService } from './ReadonlyService';
import { BasePath, Entity, makeGetPath } from './utils';

export const CrudService = <T extends Entity>(
  basePath: BasePath,
  defaultSort?: object,
  filterAutocomplete?: (search: string) => string
) => {
  const _getPath = makeGetPath(basePath);

  const readonlyService = ReadonlyService<T>(
    _getPath,
    defaultSort,
    filterAutocomplete
  );

  const save = (form: T): AxiosPromise<T> =>
    form.id === undefined || form.id === 0
      ? axios.post(`${_getPath('save')}`, form)
      : axios.put(`${_getPath('save')}/${form.id}`, form);

  const deleteById = (id?: number): AxiosPromise<T> =>
    axios.delete(`${_getPath('deleteById')}/${id}`);

  return {
    ...readonlyService,
    save,
    deleteById
  };
};
