import { Chip } from '@elotech/components';
import React from 'react';

import {
  SituacaoTarefaAssincronaEnum,
  getSituacaoTarefaAssincronaLabel
} from '../../enum';

type Props = { situacao: string };

const getSituacaoColorChip = (
  situacao: string
): 'module-color' | 'negative' | 'positive' => {
  switch (situacao) {
    case SituacaoTarefaAssincronaEnum.finalizado:
      return 'positive';
    case SituacaoTarefaAssincronaEnum.erro:
      return 'negative';
    default:
      return 'module-color';
  }
};

const TarefaAssincronaStatusChip: React.FC<Props> = ({ situacao }) => {
  return (
    <Chip
      className="center"
      value={getSituacaoTarefaAssincronaLabel(situacao)}
      color={getSituacaoColorChip(situacao)}
    />
  );
};

export default TarefaAssincronaStatusChip;
