import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { AtoProcessoFormularioAnexo } from '../types/AtoProcessoFormularioAnexo';

const crudService = CrudService<AtoProcessoFormularioAnexo>(
  '/ato-processo-formulario-anexo'
);

const saveAnexo = (idFormulario: number, chave: String, anexo: File) => {
  const formData = new FormData();
  formData.append('file', anexo[0]);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.put(
    `${CONTEXT_PATH}/ato-processo-formulario-anexo/save/${idFormulario}`,
    formData,
    config
  );
};

const findAllAnexos = (
  idFormulario: number
): AxiosPromise<Array<AtoProcessoFormularioAnexo>> =>
  axios.get(
    `${CONTEXT_PATH}/ato-processo-formulario-anexo/load/${idFormulario}`
  );

const deleteAnexo = (id: number) =>
  axios.delete(`${CONTEXT_PATH}/ato-processo-formulario-anexo/delete/${id}`);

const downloadAnexo = (idAnexo: number) =>
  axios.get(
    `${CONTEXT_PATH}/ato-processo-formulario-anexo/download/${idAnexo}`
  );

export default {
  saveAnexo,
  findAllAnexos,
  crudService,
  deleteAnexo,
  downloadAnexo
};
