import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { StatusRender } from '../../../common/components/StatusRender';
import { AtoProcesso } from '../../../types/AtoProcesso';

type Props = {
  atos: AtoProcesso[];
  onViewClick(id: number, idProcesso: any): void;
};

export const AtosTimeline: React.FC<Props> = ({ atos, onViewClick }) => (
  <div className="timeline-container">
    <ul className="timeline type2">
      {atos.map((ato, index) => (
        <li key={index}>
          <div className="datetime">
            <b>
              <FormattedDate value={ato.dataAbertura}></FormattedDate>{' '}
            </b>
            <small>
              <FormattedTime value={ato.dataAbertura}></FormattedTime>
            </small>
          </div>
          <div className="info">
            <div className="head">
              <StatusRender
                idStatus={ato.idStatus || ''}
                status={ato.descricaoStatus || ''}
              />
            </div>
            <div className="description">{ato.tipoAto?.descricao}</div>
            <div
              className="link"
              onClick={() => onViewClick(ato.id, ato.processo)}
              data-testid="link"
            >
              <i className="fa fa-chevron-right hint clean module-color top-right">
                <div className="hint-content">Ir ao ato</div>
              </i>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);
