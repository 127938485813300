import { EnumValue } from '../types/EnumValue';

export const situacaoProcessoAlvara: EnumValue[] = [
  { codigo: 'NOVA', descricao: 'Nova' },
  { codigo: 'IGNORADA', descricao: 'Ignorada' },
  { codigo: 'PROCESSO_GERADO', descricao: 'Processo Gerado' }
];

export const getDescricaoSituacaoProcesso = (situacao: string) => {
  const found = situacaoProcessoAlvara.find(item => item.codigo === situacao);
  return found ? found.descricao : '';
};
