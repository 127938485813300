import { FormikSelect, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../JsonikForm';
import { StringListOptionsPropsEditor } from '../propseditor/StringListOptionsPropsEditor';

const SelectField: React.FC<JsonikFieldProps> = props => (
  <FormikSelect
    {...props}
    options={props.props.options}
    getOptionLabel={(option: any) => option?.label || option}
    getOptionValue={(option: any) => option?.value || option}
  />
);

export const SelectFieldMap: JsonikFieldMap = {
  type: 'select',
  component: SelectField,
  friendlyName: 'Seleção',
  description: 'Aceita apenas valores pré-definidos em uma lista',
  yupType: Yup.mixed,
  getDefaultProps: () => ({
    options: ['Opção 1', 'Opção 2']
  }),
  editor: StringListOptionsPropsEditor
};
