import {
  Alert,
  Col,
  Container,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  SwitchButtons,
  UrlUtils
} from '@elotech/components';
import {
  Field,
  PageRequest,
  PagedResponse,
  PaginationData
} from '@elotech/components/src/type';
import { AxiosResponse } from 'axios';
import { JobStatus } from 'iss-common/enums';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  JobType,
  SituacaoTarefaAssincronaEnum,
  TipoTarefaAssincrona,
  getSituacaoTarefaAssincronaLabel
} from '../../enum';
import BatchService from '../../service/BatchService';
import TarefaAssincronaService from '../../service/TarefaAssincronaService';
import { BatchInstanceDTO } from '../../types/BatchAtividadesProcessamento';
import { TarefaAssincrona } from '../../types/TarefaAssincrona';
import TarefaAssincronaListPage from './TarefaAssincronaListPage';
import TarefaBatchListPage from './TarefaBatchListPage';

type Props = {};

const FILTER_LOTE = 'LOTE';
const FILTER_TAREFA = 'TAREFA';
const FILTER_TYPE_KEY = 'tipoFiltro';

const filterTypeOptions = [
  {
    key: FILTER_LOTE,
    value: FILTER_LOTE,
    label: 'Lotes'
  },
  {
    key: FILTER_TAREFA,
    value: FILTER_TAREFA,
    label: 'Tarefas'
  }
];

const searchFieldsLote: Field[] = [
  {
    label: 'Situação',
    name: 'status',
    type: 'ENUM',
    options: Object.entries(JobStatus)
      .filter(
        ([codigo]) =>
          codigo === 'COMPLETED' ||
          codigo === 'STARTED' ||
          codigo === 'FAILED' ||
          codigo === 'STOPPED'
      )
      .map(([codigo, descricao]) => ({
        name: codigo,
        descricao
      }))
  },
  {
    label: 'Tarefa',
    name: 'tarefa',
    type: 'ENUM',
    options: Object.entries(JobType).map(([codigo, descricao]) => ({
      name: codigo,
      descricao
    }))
  },
  {
    label: 'Processo',
    name: 'instanceId',
    type: 'NUMBER'
  }
];

const searchFieldsTarefa: Field[] = [
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: [
      {
        name: SituacaoTarefaAssincronaEnum.processando,
        descricao: getSituacaoTarefaAssincronaLabel(
          SituacaoTarefaAssincronaEnum.processando
        )
      },
      {
        name: SituacaoTarefaAssincronaEnum.erro,
        descricao: getSituacaoTarefaAssincronaLabel(
          SituacaoTarefaAssincronaEnum.erro
        )
      },
      {
        name: SituacaoTarefaAssincronaEnum.finalizado,
        descricao: getSituacaoTarefaAssincronaLabel(
          SituacaoTarefaAssincronaEnum.finalizado
        )
      }
    ]
  },
  {
    label: 'Tarefa',
    name: 'tarefa',
    type: 'ENUM',
    options: Object.entries(TipoTarefaAssincrona).map(
      ([codigo, descricao]) => ({
        name: codigo,
        descricao: descricao
      })
    )
  },
  {
    label: 'Processo',
    name: 'id',
    type: 'NUMBER'
  }
];

const GerenciadorTarefasListPage: React.FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();

  const [filterType, setFilterType] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationData | undefined>(
    undefined
  );
  const [tarefasBatch, setTarefasBatch] = useState<BatchInstanceDTO[]>([]);
  const [tarefasAssincronas, setTarefasAssincronas] = useState<
    TarefaAssincrona[]
  >([]);
  const [searchParams, setSearchParams] = useState<string>('');

  const isFilterTypeTarefa = (): boolean => filterType === FILTER_TAREFA;

  const getSearchFields = (): Field[] =>
    isFilterTypeTarefa() ? searchFieldsTarefa : searchFieldsLote;

  useEffect(() => {
    if (!!location.search) {
      setFilterType(
        UrlUtils.getValueFromUrlSearchParams(location, FILTER_TYPE_KEY)!
      );
    } else {
      setFilterType(FILTER_LOTE);
      history.push({
        pathname: location.pathname,
        search: `${FILTER_TYPE_KEY}=${FILTER_LOTE}`
      });
    }
  }, [location, history]);

  const onChangeFilterType = (filter: string) => {
    setFilterType(filter);
    history.push({
      pathname: location.pathname,
      search: filter ? `${FILTER_TYPE_KEY}=${filter}` : ''
    });
  };

  const onPaginationSearch = (page: PageRequest) => onSearch(undefined, page);

  const onSearch = (
    searchValues: string = searchParams,
    page?: PageRequest
  ) => {
    setSearchParams(searchValues);
    if (filterType !== undefined) {
      return isFilterTypeTarefa()
        ? onSearchTarefas(searchValues, page)
        : onSearchLote(searchValues, page);
    }
  };

  const onSearchLote = (searchValues: string, page?: PageRequest) => {
    setLoading(true);

    BatchService.getTarefas(searchValues, page)
      .then((response: AxiosResponse<PagedResponse<BatchInstanceDTO>>) => {
        const {
          content,
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        } = response.data;

        setTarefasBatch(content);
        setPagination({
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        });
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao pesquisar os lotes' }, error);
        setTarefasBatch([]);
        setPagination(undefined);
      })
      .finally(() => setLoading(false));
  };

  const onSearchTarefas = (searchValues: string, page?: PageRequest) => {
    setLoading(true);

    TarefaAssincronaService.searchTarefas(searchValues, page)
      .then((response: AxiosResponse<PagedResponse<TarefaAssincrona>>) => {
        const {
          content,
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        } = response.data;
        setTarefasAssincronas(content);
        setPagination({
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        });
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao pesquisar as tarefas' }, error);
        setTarefasAssincronas([]);
        setPagination(undefined);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <button
          onClick={() =>
            onSearch(searchParams, {
              size: pagination!.size,
              page: pagination!.number
            })
          }
          title="Atualizar"
        >
          <i className="fa fa-sync fa-lg" />
        </button>
      }
    >
      <Loading loading={loading} />
      <Panel isTable>
        <SearchFilter
          key={filterType}
          renderLeftComponent={() => (
            <Col sm={2}>
              <div className="form-group">
                <SwitchButtons
                  name="tipoFiltro"
                  value={filterType}
                  options={filterTypeOptions}
                  onChange={onChangeFilterType}
                />
              </div>
            </Col>
          )}
          fieldSelectSize={2}
          valueSelectSize={6}
          fields={getSearchFields()}
          search={onSearch}
        />

        {isFilterTypeTarefa() ? (
          <TarefaAssincronaListPage
            tarefas={tarefasAssincronas}
            loading={loading}
          />
        ) : (
          <TarefaBatchListPage tarefas={tarefasBatch} loading={loading} />
        )}

        {pagination && (
          <SearchPagination
            page={pagination}
            searchWithPage={onPaginationSearch}
          />
        )}
      </Panel>
    </Container>
  );
};

export default GerenciadorTarefasListPage;
