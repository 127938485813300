import {
  ActionButton,
  ActionsGroup,
  ErrorText,
  Panel,
  Table
} from '@elotech/components';
import { ErrorMessage } from 'formik';
import React from 'react';

import { TipoProcessoAto } from '../../../types/TipoProcessoAto';

type Props = {
  tiposProcessoAto: TipoProcessoAto[];
  onDelete: (index: number) => void;
  onEdit?: (index: number) => void;
};

export const TipoAtoProcesso: React.FC<Props> = ({
  tiposProcessoAto,
  onDelete,
  onEdit
}) => (
  <>
    <Panel isTable={true} insidePanel={true}>
      <Table
        values={tiposProcessoAto}
        keyExtractor={item => `${item.tipoAto.id}`}
      >
        <Table.Column<TipoProcessoAto>
          header="Código"
          value={item => item.tipoAto.id}
        />
        <Table.Column<TipoProcessoAto>
          header="Ato"
          value={item => item.tipoAto.descricao}
        />
        <Table.Column<TipoProcessoAto>
          header=""
          value={(value, index: any) => (
            <ActionsGroup>
              <ActionButton
                key="edit-button"
                icon="pencil-alt"
                label="Editar"
                onClick={() => onEdit?.(index)}
              />
              <ActionButton
                key="delete-button"
                icon="trash"
                label="Excluir"
                onClick={() => onDelete(index)}
              />
            </ActionsGroup>
          )}
        />
      </Table>
    </Panel>
    <ErrorMessage name="tiposProcessoAto">
      {msg => <ErrorText>{msg}</ErrorText>}
    </ErrorMessage>
  </>
);
