import {
  ActionButton,
  ActionsGroup,
  Alert,
  Panel,
  SearchPagination,
  Table,
  TableChild,
  usePagedQuery
} from '@elotech/components';
import React, { useCallback, useEffect } from 'react';

import DivergenciaService from '../../../service/DivergenciaService';
import { DivergenciaItem } from '../../../types/DivergenciaItem';

type Props = {
  id: number;
  cadastrogeralid: number;
  onViewNota: (item: DivergenciaItem) => void;
};

const DivergenciaItemEmpresaTable: React.FC<Props> = ({
  id,
  cadastrogeralid,
  onViewNota
}) => {
  const find = useCallback(
    (search, page, sort) =>
      DivergenciaService.findItensDivergencia(
        id,
        cadastrogeralid,
        search,
        page
      ),
    [id, cadastrogeralid]
  );

  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<DivergenciaItem>({
    search: find,
    onError: useCallback(error => {
      Alert.error(
        { title: 'Erro ao buscar os itens das divergências.' },
        error
      );
    }, [])
  });

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  return (
    <>
      <TableChild>
        <Panel isTable>
          <Table values={values ?? []} loading={loading}>
            <Table.Column<DivergenciaItem>
              header="Código"
              value={item => item.id}
            ></Table.Column>
            <Table.Column<DivergenciaItem>
              header="Ano"
              value={item => item.ano}
            ></Table.Column>
            <Table.Column<DivergenciaItem>
              header="Mês"
              value={item => item.mes}
            ></Table.Column>
            <Table.Column<DivergenciaItem>
              header="Tipo"
              value={item => item.descricaoTipo}
            ></Table.Column>
            <Table.Column<DivergenciaItem>
              header=""
              name="actions-buttons"
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    key="view-button"
                    icon="eye"
                    label="Notas"
                    onClick={() => onViewNota(item)}
                  />
                </ActionsGroup>
              )}
            ></Table.Column>
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={doPagedSearch}
            />
          )}
        </Panel>
      </TableChild>
    </>
  );
};

export default DivergenciaItemEmpresaTable;
