import { FormikCheckBox } from '@elotech/components';
import React from 'react';

type Props = {};

const ConfiguracoesEndereco: React.FC<Props> = props => {
  return (
    <>
      <FormikCheckBox
        label="Exige Localização"
        name="exigeEndereco"
        size={3}
        noLabel={false}
      />
    </>
  );
};

export { ConfiguracoesEndereco };
