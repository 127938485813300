import React from 'react';

import { ContextProvider } from './Context';
import { FieldEditor } from './FieldEditor';
import { FormRenderer } from './FormRenderer';
import { FormTester } from './FormTester';
import { JsonikFieldDef, JsonikFieldMap, JsonikRecipe } from '..';

type Props = {
  recipe: JsonikRecipe;
  fieldMap?: JsonikFieldMap[];
  presetFields?: JsonikFieldDef[];
  onSubmit(recipe: any): void;
};

export const JsonikFormEditor: React.FC<Props> = ({
  recipe = [],
  fieldMap,
  presetFields,
  onSubmit
}) => (
  <ContextProvider
    recipe={recipe}
    fieldMap={fieldMap}
    presetFields={presetFields}
  >
    <FormRenderer onDelete={onSubmit} />
    <FormTester />

    <FieldEditor onSubmit={onSubmit} />
  </ContextProvider>
);
