import {
  Alert,
  BasicInput,
  Button,
  Col,
  FormikAutocomplete,
  FormikInputInteger,
  FormikInputNumber,
  FormikSelect,
  Loading,
  Row,
  Spinner
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AtoProcessoService from '../../../../../service/AtoProcessoService';
import ArtigoService from '../../../../../service/AutoInfracaoService';
import AutoInfracaoService from '../../../../../service/AutoInfracaoService';
import { ArtigoFormValue } from '../../../../../types/ArtigoFormValue';
import { ArtigoVo } from '../../../../../types/ArtigoVo';
import { AtoProcesso } from '../../../../../types/AtoProcesso';
import { AutoInfracao } from '../../../../../types/AutoInfracao';
import { EnquadramentoLegalListComponent } from './EnquadramentoLegalListComponent';

type Props = {
  atoProcesso: AtoProcesso;
  autoInfracao?: AutoInfracao;
  readonly: boolean;
  usuario?: any;
  history: Pick<History, 'push'>;
};

const EnquadramentoLegalComponent: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [showArtigos, setShowArtigos] = useState<boolean>(false);
  const [artigoFormValue, setArtigoFormValue] = useState<ArtigoFormValue>(
    {} as ArtigoFormValue
  );
  const [
    atosDoProcessoParaVinculacaoAutoInfracao,
    setAtosDoProcessoParaVinculacaoAutoInfracao
  ] = useState<AtoProcesso[]>([]);

  const addAutoInfracaoArtigo = (
    values: ArtigoFormValue,
    idAtoProcesso: number
  ) => {
    setLoading(true);

    ArtigoService.addAutoInfracaoArtigo({ ...values, idAtoProcesso })
      .then(response => {
        setArtigoFormValue(response.data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível adicionar o artigo ao Auto de Infração.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const deletarArtigoAutoInfracao = (id: number) => {
    setLoading(true);

    AutoInfracaoService.deletarByIdAndLoad(id, props.atoProcesso.id)
      .then(response => {
        setArtigoFormValue(response.data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível adicionar o artigo ao Auto de Infração.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const atualizarFatorMultiplicadorArtigoAutoInfracao = (
    id: number,
    fatorMultiplicador: number
  ) => {
    setLoading(true);

    AutoInfracaoService.atualizarFatorMultiplicadorArtigoAutoInfracao(
      id,
      props.atoProcesso.id,
      fatorMultiplicador
    )
      .then(response => {
        setArtigoFormValue(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refresh = (atoProcesso: AtoProcesso) => {
    ArtigoService.buscarArtigosAutoInfracao(atoProcesso.id)
      .then(response => {
        setArtigoFormValue(response.data);
        setShowArtigos(true);
      })
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao carregar os artigos' }, error);
      });
  };

  useEffect(() => {
    refresh(props.atoProcesso);
    loadAtosDoProcessoParaVinculacaoAutoInfracao(props.atoProcesso);
  }, [props.atoProcesso]);

  const gerarDebito = async () => {
    setLoading(true);

    try {
      await AtoProcessoService.constituirDebitos(
        props.atoProcesso.id,
        props.usuario.cpfCnpj
      );

      refresh(props.atoProcesso);

      Alert.info({ title: 'Débitos gerados com sucesso' });
    } catch (error) {
      setLoading(false);
      Alert.error({ title: 'Ocorreu um erro ao gerar os débitos' }, error);
    } finally {
      setLoading(false);
    }
  };

  const loadAtosDoProcessoParaVinculacaoAutoInfracao = (
    processoSelecionado: AtoProcesso
  ) => {
    AtoProcessoService.findAtosDoProcessoParaVinculacao(
      processoSelecionado.idProcesso
    )
      .then((result: AxiosResponse<AtoProcesso[]>) =>
        setAtosDoProcessoParaVinculacaoAutoInfracao(result.data)
      )
      .catch((error: any) => {
        Alert.error(
          { title: `Erro ao carregar os atos para vinculação do Processo.` },
          error
        );
      });
  };

  return showArtigos ? (
    <>
      <Loading loading={loading} />
      <Formik<ArtigoFormValue>
        enableReinitialize={true}
        initialValues={artigoFormValue}
        onSubmit={values => {
          addAutoInfracaoArtigo(values, props.atoProcesso.id);
        }}
        render={(formProps: FormikProps<any>) => {
          return (
            <>
              {props.readonly || (
                <Row style={{ marginBottom: '10px' }}>
                  <FormikAutocomplete<ArtigoVo>
                    name="artigoVo"
                    label="Fundamentação Legal"
                    onSearch={ArtigoService.findAllArtigos}
                    getOptionLabel={value => `${value.titulo}`}
                    getOptionValue={value => value.id}
                    onItemSelected={(formProps, value) => {
                      formProps.setFieldValue('penalidade', value?.penalidade);
                      formProps.setFieldValue(
                        'tipoPenalidade',
                        value?.tipoPenalidade
                      );
                      formProps.setFieldValue(
                        'fatorMultiplicador',
                        value?.fatorMultiplicador
                      );
                      formProps.setFieldValue(
                        'indice',
                        value?.tipoPenalidade === 'V'
                          ? '%'
                          : value?.indiceCorrecao?.descricao
                      );
                    }}
                    size={6}
                  />
                  <FormikInputNumber
                    name="penalidade"
                    label="Penalidade"
                    size={2}
                    readOnly={true}
                  />

                  <BasicInput
                    label="Índice Correção"
                    name="indice"
                    readOnly={true}
                    size={2}
                  />
                  <FormikInputInteger
                    name="fatorMultiplicador"
                    label="Multiplicador"
                    size={1}
                  />
                  {formProps.values.tipoPenalidade === 'V' &&
                  !props.atoProcesso.atoOrigem ? (
                    <FormikSelect<AtoProcesso>
                      name="atoVinculado"
                      options={atosDoProcessoParaVinculacaoAutoInfracao}
                      label="Ato Vinculado"
                      getOptionLabel={option =>
                        `${option.tipoAto?.descricao} - Nr. ${option.numero}`
                      }
                      getOptionValue={option => option.id}
                      fast={false}
                      size={4}
                    />
                  ) : (
                    <div></div>
                  )}
                  <Col md={1}>
                    <label className="label" />
                    <Button onClick={formProps.submitForm}>Adicionar</Button>
                  </Col>
                </Row>
              )}
              <EnquadramentoLegalListComponent
                value={formProps.values}
                deletarArtigoAutoInfracao={deletarArtigoAutoInfracao}
                atualizarFatorMultiplicador={
                  atualizarFatorMultiplicadorArtigoAutoInfracao
                }
                readonly={props.readonly}
                onGerarDebitos={gerarDebito}
                history={props.history}
              />
            </>
          );
        }}
      ></Formik>
    </>
  ) : (
    <Spinner />
  );
};

const connectedComponent = connect(
  (state: any) => ({
    usuario: state.user.currentUser
  }),
  null
)(EnquadramentoLegalComponent);

export { connectedComponent as default, EnquadramentoLegalComponent };
