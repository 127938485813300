import {
  ActionButton,
  ActionsGroup,
  Alert,
  Checkbox,
  Container,
  FAB,
  FabSpeedDial,
  FormattedCurrency,
  FormattedDateTime,
  FormattedDecimal,
  Loading,
  PageRequest,
  PaginationData,
  Panel,
  SearchPagination,
  SectionTitle,
  Table
} from '@elotech/components';
import React, { useCallback, useEffect, useState } from 'react';
import { match } from 'react-router';

import { getDescricaoTipoOutrasReceitas } from '../../../../enum/TipoReceitas';
import ApuracaoIssService from '../../../../service/ApuracaoIssService';
import ApuracaoOutrasReceitasService from '../../../../service/ApuracaoOutrasReceitasService';
import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { ApuracaoIssOutraReceita } from '../../../../types/ApuracaoIssOutraReceita';
import { Processo } from '../../../../types/Processo';
import { ProcessoDetail } from '../ApuracaoAnual/ProcessoDetail';
import { ApuracaoOutrasReceitasEditPanel } from './ApuracaoOutrasReceitasEditPanel';

type Props = {
  match: match<{ idApuracao: string }>;
};

export const ApuracaoOutrasReceitasListPage: React.FC<Props> = ({ match }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<ApuracaoIssOutraReceita[]>([]);
  const [pagination, setPagination] = useState<PaginationData | undefined>();
  const [editing, setEditing] = useState<ApuracaoIssOutraReceita | undefined>();
  const [apuracaoIss, setApuracaoIss] = useState<ApuracaoIss | undefined>();
  const [processo, setProcesso] = useState<Processo | undefined>();
  const [working, setWorking] = useState<boolean>(true);
  const [savedIdMarker, setSavedIdMarker] = useState<number>();
  const [selection, setSelection] = useState<number[]>([]);

  const load = useCallback(
    async (page?: PageRequest) => {
      setLoading(true);

      try {
        const res = await Promise.all([
          ApuracaoOutrasReceitasService.findAllByIdApuracao(
            match.params.idApuracao,
            page || { page: 0, size: 10 }
          ),
          !processo
            ? ApuracaoIssService.getProcesso(match.params.idApuracao)
            : undefined
        ]);

        const resValues = res[0].data;
        const resProcesso = res[1] ? res[1].data : processo;

        setValues(resValues.content);
        setProcesso(resProcesso);

        setPagination({
          number: resValues.number,
          totalPages: resValues.totalPages,
          first: resValues.first,
          last: resValues.last,
          numberOfElements: resValues.numberOfElements,
          size: resValues.size
        });
      } catch (error) {
        setLoading(false);
        Alert.error({ title: `Erro ao carregar os dados` }, error);
      } finally {
        setLoading(false);
      }
    },
    [match.params.idApuracao, processo]
  );

  useEffect(() => {
    ApuracaoIssService.findById(match.params.idApuracao)
      .then(res => setApuracaoIss(res.data))
      .then(() => load())
      .catch(error => {
        Alert.error({ title: 'Erro ao carregar a apuração' }, error);
      })
      .finally(() => setWorking(false));
  }, [match.params.idApuracao, load]);

  const clearMarkers = () => {
    setSavedIdMarker(undefined);
  };

  const saveEditing = async (values: ApuracaoIssOutraReceita) => {
    clearSelection();

    const inserting = !values.id;
    values.apuracao = apuracaoIss;

    try {
      setWorking(true);
      const saved = await ApuracaoOutrasReceitasService.save(values).then(
        res => res.data
      );

      setEditing(undefined);
      setSavedIdMarker(saved.id);
      setWorking(false);

      return load({
        page: inserting ? 0 : pagination?.number!,
        size: pagination?.size!
      });
    } catch (e) {
      Alert.error({ title: 'Erro ao salvar a apuração' }, e);
    } finally {
      setWorking(false);
    }
  };

  const edit = (values: ApuracaoIssOutraReceita) => {
    clearSelection();
    clearMarkers();

    setEditing(values);
  };

  const isSelected = (item: ApuracaoIssOutraReceita) =>
    selection.indexOf(item.id) !== -1;

  const isAllSelected = () =>
    selection.length + values.length > 0 && selection.length === values.length;

  const handleSelection = (item?: ApuracaoIssOutraReceita) => {
    if (item) {
      if (isSelected(item))
        setSelection(selection.filter(id => id !== item.id));
      else setSelection(selection.concat(item.id));
    } else {
      setSelection(selection.length ? [] : values.map(item => item.id));
    }
  };

  const clearSelection = () => setSelection([]);

  const delMultiple = async (
    title: string,
    text: string | undefined,
    callback: () => Promise<any>
  ) => {
    const resp = await Alert.question({ title, text }).then(
      (answer: any) => answer.value
    );

    if (!resp) {
      return;
    }

    try {
      setWorking(true);
      await callback();
      setEditing(undefined);
      load();
    } catch (e) {
      setWorking(false);
      Alert.error({ title: 'Erro ao excluir os registros' }, e);
    } finally {
      setWorking(false);
    }
  };

  const delAll = () =>
    delMultiple(
      'Deseja realmente excluir todos os registros?',
      'Todos os registros desta competência, incluindo os registros das demais páginas da listagem, serão excluidos.',
      () =>
        ApuracaoOutrasReceitasService.removerPorApuracao(
          match.params.idApuracao,
          []
        )
    );

  const delSelected = () =>
    delMultiple(
      'Deseja realmente excluir registros selecionados?',
      undefined,
      () =>
        ApuracaoOutrasReceitasService.removerPorApuracao(
          match.params.idApuracao,
          selection
        )
    );

  const competenciaStr = () =>
    `${(apuracaoIss?.mesCompetencia! < 10 ? '0' : '') +
      apuracaoIss?.mesCompetencia}/${apuracaoIss?.anoCompetencia}`;

  return (
    <Container
      icon={'money-check-alt'}
      title={`Outras receitas (${competenciaStr()})`}
    >
      <Loading loading={loading || working} />

      {processo && <ProcessoDetail processo={processo} />}

      <ApuracaoOutrasReceitasEditPanel values={editing} onSave={saveEditing} />

      <SectionTitle>Outras Receitas cadastradas</SectionTitle>

      <Panel isTable>
        <Table
          values={values}
          highlightWarning={item =>
            editing !== undefined && editing.id === item.id
          }
          highlightSuccess={item =>
            savedIdMarker !== undefined && savedIdMarker === item.id
          }
        >
          <Table.Column<ApuracaoIssOutraReceita>
            header={
              <Checkbox
                id={`cb-all`}
                checked={isAllSelected()}
                onChange={() => handleSelection()}
              />
            }
            value={item => {
              return (
                <Checkbox
                  id={`cb-${item.id}`}
                  checked={isSelected(item)}
                  onChange={() => handleSelection(item)}
                />
              );
            }}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Tipo de Receita"
            value={item =>
              getDescricaoTipoOutrasReceitas(item.tipoOutraReceita!)
            }
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Tributável"
            value={item => (item.tributavel === true ? 'Sim' : 'Não')}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Valor Receita"
            value={item => <FormattedCurrency value={item.valorReceita} />}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Alíquota ISSQN"
            value={item => (
              <FormattedDecimal
                value={item.aliquotaIssQn}
                minimumFractionDigits={2}
              />
            )}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Valor ISSQN"
            value={item => <FormattedCurrency value={item.valorIssQn} />}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Valor ISSQN Retido"
            value={item => <FormattedCurrency value={item.valorIssRetido} />}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header="Cadastro em"
            value={item => <FormattedDateTime value={item.dataCadastro} />}
          />
          <Table.Column<ApuracaoIssOutraReceita>
            header=""
            value={item => (
              <ActionsGroup>
                <ActionButton
                  key={'edit-button'}
                  icon="pencil-alt"
                  label="Editar"
                  onClick={() => edit(item)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={load} />
        )}
      </Panel>

      <FabSpeedDial icon="ellipsis-v" title="Ações">
        <FAB
          key={'edit-button'}
          icon="trash"
          title="Excluir Seleção"
          disabled={!selection.length}
          onClick={() => delSelected()}
        />
        <FAB
          key={'del-button'}
          icon="trash-alt"
          title="Excluir Tudo"
          onClick={() => delAll()}
        />
      </FabSpeedDial>
    </Container>
  );
};
