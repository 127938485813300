import { FormikInputDate, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';

const DateField: React.FC<JsonikFieldProps> = props => (
  <FormikInputDate {...props} />
);

export const DateFieldMap: JsonikFieldMap = {
  type: 'date',
  component: DateField,
  friendlyName: 'Data',
  description: 'Data com calendário',
  yupType: Yup.date,
  getDefaultProps: () => undefined
};
