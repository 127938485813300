import { EnumValue } from '../types/EnumValue';

export const statusProcessoCodigo = {
  TODOS: 'G',
  ABERTO: 'A',
  CANCELADO: 'C',
  EM_TRANSFERENCIA: 'T',
  FECHADO: 'F'
};

export const statusProcesso: EnumValue[] = [
  {
    codigo: statusProcessoCodigo.TODOS,
    descricao: 'Todos'
  },
  {
    codigo: statusProcessoCodigo.ABERTO,
    descricao: 'Aberto'
  },
  {
    codigo: statusProcessoCodigo.CANCELADO,
    descricao: 'Cancelado'
  },
  {
    codigo: statusProcessoCodigo.EM_TRANSFERENCIA,
    descricao: 'Em transferência'
  },
  {
    codigo: statusProcessoCodigo.FECHADO,
    descricao: 'Fechado'
  }
];
