type CodigoAcao =
  | 'GR001'
  | 'GR002'
  | 'GR003'
  | 'GR004'
  | 'GR005'
  | 'GR006'
  | 'GR007'
  | 'GR008'
  | 'IS001'
  | 'IS002'
  | 'IS003'
  | 'IS004'
  | 'IS005'
  | 'IS006'
  | 'IS007'
  | 'IS008'
  | 'IS009'
  | 'AV001'
  | 'AV002'
  | 'AV003'
  | 'AV004'
  | 'AV005'
  | 'AV006'
  | 'AV007';

export type AtoConfiguracao = {
  codigo: CodigoAcao;
  grupo: string;
  descricao: string;
};

export const atoConfiguracoes: AtoConfiguracao[] = [
  { codigo: 'GR001', grupo: 'Geral', descricao: 'Final' },
  { codigo: 'GR002', grupo: 'Geral', descricao: 'Permite varios réus' },
  { codigo: 'GR003', grupo: 'Geral', descricao: 'Encaminhar' },
  { codigo: 'GR004', grupo: 'Geral', descricao: 'Montar Volume' },
  { codigo: 'GR005', grupo: 'Geral', descricao: 'Resposta' },
  { codigo: 'GR006', grupo: 'Geral', descricao: 'Cancelar Ato' },
  { codigo: 'GR007', grupo: 'Geral', descricao: 'Inicial' },
  {
    codigo: 'GR008',
    grupo: 'Geral',
    descricao: 'Bloqueia atos duplicados para o mesmo cadastro'
  },
  { codigo: 'IS001', grupo: 'ISS', descricao: 'Exige Documentos' },
  { codigo: 'IS002', grupo: 'ISS', descricao: 'Apuração ISS' },
  { codigo: 'IS003', grupo: 'ISS', descricao: 'Defesa Fiscal' },
  { codigo: 'IS004', grupo: 'ISS', descricao: 'TIAF' },
  { codigo: 'IS005', grupo: 'ISS', descricao: 'Notificação' },
  { codigo: 'IS006', grupo: 'ISS', descricao: 'Intimação Fiscal' },
  { codigo: 'IS007', grupo: 'ISS', descricao: 'Auto de Infração' },
  { codigo: 'IS008', grupo: 'ISS', descricao: 'Apuração ISS Construção Civil' },
  {
    codigo: 'IS009',
    grupo: 'ISS',
    descricao: 'Auto de Infração de Reincidência'
  },
  {
    codigo: 'AV001',
    grupo: 'Alvará',
    descricao: 'Parecer Liberado Definitivo'
  },
  {
    codigo: 'AV002',
    grupo: 'Alvará',
    descricao: 'Parecer Liberado Provisório'
  },
  { codigo: 'AV003', grupo: 'Alvará', descricao: 'Parecer Não Liberado' },
  { codigo: 'AV004', grupo: 'Alvará', descricao: 'Parecer Exigência' },
  { codigo: 'AV005', grupo: 'Alvará', descricao: 'Licença Sanitária' },
  {
    codigo: 'AV006',
    grupo: 'Alvará',
    descricao: 'Adiciona Parâmentro da Solicitação nos Relatórios'
  },
  {
    codigo: 'AV007',
    grupo: 'Alvará',
    descricao: 'Permitir Consulta Solicitação'
  }
];
