import {
  BasicInput,
  DataPage,
  FormikAutocomplete,
  FormikCheckBox,
  FormikInputNumber,
  FormikSelect,
  FormikTextArea,
  Row,
  Yup
} from '@elotech/components';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { match } from 'react-router-dom';

import ArtigoService from '../../../service/ArtigoService';
import IndiceCorrecaoService from '../../../service/IndiceCorrecaoService';
import LeiService from '../../../service/LeiService';
import TipoArtigoService from '../../../service/TipoArtigoService';
import { Artigo } from '../../../types/Artigo';
import { IndiceCorrecao } from '../../../types/IndiceCorrecao';
import { Lei } from '../../../types/Lei';
import { TipoArtigo } from '../../../types/TipoArtigo';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const TIPOS_PENALIDADE = [
  { valor: 'VARIAVEL', descricao: 'Variável' },
  { valor: 'FIXA', descricao: 'Fixa' }
];

const TIPOS_DATA_VENCIMENTO = [
  { valor: 'PADRAO', descricao: 'Padrão' },
  {
    valor: 'DIAS_A_PARTIR_CONSTITUIR_DEBITO',
    descricao: 'Dias a partir da constituição do débito'
  }
];

const TIPOS_APLICACAO_PENALIDADE = [
  { valor: 'M2', descricao: 'Metro quadrado' },
  { valor: 'VALOR_VENAL', descricao: 'Valor venal' },
  { valor: 'VALOR_DEBITO', descricao: 'Valor débito' },
  { valor: 'REINCIDENCIA_MULTA', descricao: 'Reincidência sobre multa' }
];

const SCHEMA_VALIDATION = Yup.object().shape({
  titulo: Yup.string()
    .required()
    .min(3, 'O título deve ter no mínimo 3 caracteres')
    .max(100, 'O título deve ter no máximo 100 caracteres')
    .label('Título'),
  tipoArtigo: Yup.object().shape({
    id: Yup.number()
      .required()
      .label('Tipo Artigo')
  }),
  lei: Yup.object()
    .required()
    .label('Lei Complementar'),
  tipoPenalidade: Yup.string()
    .required()
    .label('Tipo Penalidade'),
  tipoDataVencimento: Yup.string()
    .required()
    .label('Tipo Vencimento'),
  minimoDias: Yup.number().min(0),
  descricao: Yup.string()
    .required()
    .label('Descrição')
});
export const ArtigoDataPage: React.FC<Props> = ({ match, history }) => {
  const onLoad = (id: number) => ArtigoService.findById(id);

  const onSave = (entity: Artigo) => ArtigoService.save(entity);
  const [tiposArtigo, setTiposArtigo] = useState<TipoArtigo[]>([]);
  const [indicesCorrecao, setIndicesCorrecao] = useState<IndiceCorrecao[]>([]);

  useEffect(() => {
    TipoArtigoService.load('').then(({ data }) => {
      setTiposArtigo(data.content);
    });
    IndiceCorrecaoService.load('').then(({ data }) => {
      setIndicesCorrecao(data.content);
    });
  }, []);

  return (
    <DataPage<Artigo>
      load={onLoad}
      match={match}
      name="artigo"
      title="Cadastro de Artigo"
      history={history}
      initialValues={{ ativo: true, penalidade: 0, limiteLegal: 0 }}
      validationSchema={SCHEMA_VALIDATION}
      redirectUrl="/configuracao/cadastro-artigo"
      onSave={onSave}
      icon="university"
      render={({ values, setFieldValue }) => (
        <>
          <Row>
            <BasicInput label="Título" name="titulo" maxLength={100} size={7} />
            <FormikCheckBox
              label="Ativo"
              name="ativo"
              size={1}
              noLabel={false}
            />
            <FormikSelect<TipoArtigo>
              name="tipoArtigo.id"
              label="Tipo Artigo"
              options={tiposArtigo}
              getOptionLabel={option => option.descricao}
              getOptionValue={option => option.id}
              size={2}
              fast={false}
            />
            <FormikSelect<IndiceCorrecao>
              name="indiceCorrecao.id"
              label="Índice de correção"
              options={indicesCorrecao}
              getOptionLabel={option => `${option.id} - ${option.descricao}`}
              getOptionValue={option => option.id}
              size={2}
              fast={false}
            />
            <FormikAutocomplete<Lei>
              name="lei"
              label="Lei Complementar"
              onSearch={LeiService.leiAutoComplete}
              getOptionLabel={value => `${value.numero} - ${value.descricao}`}
              getOptionValue={value => value.id}
              size={12}
            />
            <FormikSelect<any>
              name="tipoPenalidade"
              label="Tipo Penalidade"
              options={TIPOS_PENALIDADE}
              getOptionLabel={option => `${option.descricao}`}
              getOptionValue={option => option.valor}
              size={3}
              fast={false}
            />
            <FormikSelect<any>
              name="tipoAplicacaoPenalidade"
              label="Aplicação Penalidade"
              options={TIPOS_APLICACAO_PENALIDADE}
              getOptionLabel={option => `${option.descricao}`}
              getOptionValue={option => option.valor}
              size={3}
              fast={false}
            />
            <FormikInputNumber
              name="penalidade"
              label="Penalidade"
              size={3}
              fast={false}
            />
            <FormikInputNumber
              name="limiteLegal"
              label="Limite Legal"
              size={3}
              fast={false}
            />
            <FormikSelect<any>
              name="tipoDataVencimento"
              label="Tipo Vencimento"
              options={TIPOS_DATA_VENCIMENTO}
              getOptionLabel={option => `${option.descricao}`}
              getOptionValue={option => option.valor}
              size={3}
              onSelect={option => {
                setFieldValue('minimoDias', 0);
                if (
                  !!option &&
                  option.valor === 'DIAS_A_PARTIR_CONSTITUIR_DEBITO'
                ) {
                  setFieldValue('minimoDias', 30);
                }
              }}
              fast={false}
            />
            {values.tipoDataVencimento ===
              'DIAS_A_PARTIR_CONSTITUIR_DEBITO' && (
              <FormikInputNumber
                name="minimoDias"
                label="Mínimo Dias Vencimento"
                size={3}
                decimalScale={0}
                min={0}
                fast={false}
                onChange={value => {
                  if (!value.target.value) {
                    setFieldValue('minimoDias', 0);
                  }
                }}
              />
            )}
            <FormikTextArea
              name="descricao"
              rows={4}
              size={12}
              label="Descrição"
            />
          </Row>
        </>
      )}
    />
  );
};
