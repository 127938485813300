import { Col, SectionTitle, formatUtils } from '@elotech/components';
import React from 'react';
import { Row } from 'react-bootstrap';

import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { ApuracaoIssNota } from '../../../../types/ApuracaoIssNota';

type Props = {
  nota: ApuracaoIssNota;
  apuracao: ApuracaoIss;
};

export const ApuracaoNotaFiscalDetail: React.FC<Props> = ({
  nota,
  apuracao
}) => {
  return (
    <>
      <SectionTitle marginTop="0">Dados do prestador</SectionTitle>
      <Row>
        <div className="form-group">
          <Col md={2} xs={12}>
            <label className="label">CNPJ</label>
            <input
              type="text"
              className="disabled undefined"
              disabled
              value={formatUtils.formatCpfCnpj(apuracao.cnpjCpfPrestador)}
            />
          </Col>
          <Col md={10} xs={12}>
            <label className="label">Nome/Razão social</label>
            <input
              type="text"
              className="disabled undefined"
              disabled
              value={apuracao.razaoSocialPrestador}
            />
          </Col>
        </div>
      </Row>
      <SectionTitle>Dados do tomador</SectionTitle>
      <Row>
        <div className="form-group">
          <Col md={2} xs={12}>
            <label className="label">Inscrição</label>
            <input
              type="text"
              className="disabled undefined"
              disabled
              value={nota.inscMunTomador}
            />
          </Col>
          <Col md={10} xs={12}>
            <label className="label">Nome/Razão social</label>
            <input
              type="text"
              className="disabled undefined"
              disabled
              value={nota.razaoSocialTomador}
            />
          </Col>
        </div>
      </Row>
      <SectionTitle>Dados da Fiscalização</SectionTitle>
      <Row>
        <div className="form-group">
          <Col md={2} xs={12}>
            <label className="label">Data Inicial da Fiscalização</label>
            <input
              type="date"
              className="disabled undefined"
              disabled
              value={apuracao.dataInicioFiscalizacao}
            />
            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
          </Col>
          <Col md={2} xs={12}>
            <label className="label">Data Final da Fiscalização</label>
            <input
              type="date"
              className="disabled undefined"
              disabled
              value={apuracao.dataFinalFiscalizacao}
            />
            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
          </Col>
          <Col md={1} xs={12}>
            <label className="label">Mês</label>
            <input
              type="text"
              className="disabled undefined"
              disabled
              value={apuracao.mesCompetencia}
            />
          </Col>
          <Col md={1} xs={12}>
            <label className="label">Ano</label>
            <input
              type="text"
              className="disabled undefined"
              disabled
              value={apuracao.anoCompetencia}
            />
          </Col>
        </div>
      </Row>
    </>
  );
};
