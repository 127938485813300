import { Modal, Panel } from '@elotech/components';
import React, { useContext } from 'react';

import { BasicPropsEditor } from './BasicPropsEditor';
import { Context } from './Context';
import { changeRecipe, normalizeRecipe } from './utils';
import { JsonikFieldDef, JsonikRecipe } from '..';

type Props = {
  onSubmit(recipe: JsonikRecipe): void;
};

const defaultField: JsonikFieldDef = {
  name: '',
  type: ''
};
export const FieldEditor: React.FC<Props> = ({ onSubmit }) => {
  const {
    recipe,
    setRecipe,
    insertingOrEditing,
    setInsertingOrEditing,
    editingField,
    setEditingField,
    fieldMap
  } = useContext(Context);

  const field = editingField
    ? normalizeRecipe(recipe).find(f => f.name === editingField)!
    : defaultField;

  if (!insertingOrEditing) return null;

  return (
    <Modal
      onClose={() => {
        setInsertingOrEditing(false);
      }}
      maxWidth="800px"
    >
      <Panel title={`Incluir Campo`}>
        <BasicPropsEditor
          field={field}
          allFields={recipe}
          onSubmit={(values: any) => {
            setInsertingOrEditing(false);
            const newRecipe = changeRecipe(field.name, values, recipe);
            setRecipe(newRecipe);
            setEditingField(undefined);
            onSubmit(newRecipe);
          }}
          fieldMap={fieldMap}
        />
      </Panel>
    </Modal>
  );
};
