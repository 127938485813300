import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { AtoProcesso } from '../types/AtoProcesso';
import { AtoVo } from '../types/AtoVo';

const crudService = CrudService<AtoProcesso>('/ato-processo');

const findAllByIdProcesso = (id: any): AxiosPromise<AtoProcesso[]> =>
  axios.get(`${CONTEXT_PATH}/ato-processo/${id}/atos/`);

const deletarAto = (
  id: any,
  motivoExclusao: string,
  usuario: any
): AxiosPromise<any> =>
  axios.post(`${CONTEXT_PATH}/ato-processo/excluir/${id}`, {
    motivoExclusao,
    usuario
  });

const visualizarDocumentos = (id: any) =>
  axios.get(`${CONTEXT_PATH}/ato-processo/${id}/pdf`, { responseType: 'blob' });

const concluirAto = (
  idAtoProcesso: string,
  prazoAtendido: string | undefined,
  incluiNfOriginal: string | undefined,
  enviaEmail: boolean
) =>
  axios.post(`${CONTEXT_PATH}/ato-processo/concluir/`, {
    idAtoProcesso,
    prazoAtendido,
    incluiNfOriginal,
    enviaEmail
  });

const reabrirAto = (
  idAtoProcesso: string,
  observacao: string,
  usuario: string
) =>
  axios.post(`${CONTEXT_PATH}/ato-processo/reabrir-processo-ato/`, {
    idAtoProcesso,
    observacao,
    usuario
  });

const findAtosDoProcessoParaVinculacao = (id: any) =>
  axios.get(
    `${CONTEXT_PATH}/ato-processo/atos-do-processo-para-vinculacao/${id}`
  );

const findAtosAutoDeInfracaoDoProcessoParaVinculacao = (id: any) =>
  axios.get(
    `${CONTEXT_PATH}/ato-processo/atos-do-processo-auto-infracao-para-vinculacao/${id}`
  );

const findAllFundamentacoes = (id: any) =>
  axios.get(`${CONTEXT_PATH}/ato-processo/${id}/fundamentacao-legal`);

const criarAto = (atoVo: AtoVo) =>
  axios.post(`${CONTEXT_PATH}/ato-processo/criar-novo-ato/`, atoVo);

const quantidadeAtosComPrazoEncerrado = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/ato-processo/atos-processos-prazos-encerrados`);

const adicionarFundamentacao = (idAto: number, idArtigo: number) =>
  axios.post(
    `${CONTEXT_PATH}/ato-processo/${idAto}/fundamentacao-legal/adicionar/${idArtigo}`
  );

const removerFundamentacao = (idAto: number, idArtigo: number) =>
  axios.post(
    `${CONTEXT_PATH}/ato-processo/${idAto}/fundamentacao-legal/remover/${idArtigo}`
  );

const existeEncaminhamentoAbertoUsuario = (idProcesso: number) =>
  axios.get(
    `${CONTEXT_PATH}/ato-processo/existe-ecaminhamento-aberto-usuario/${idProcesso}`
  );

const constituirDebitos = (id: number, cpfFiscal: string): AxiosPromise => {
  return axios.post(
    `${CONTEXT_PATH}/ato-processo/${id}/constituir-debitos`,
    null,
    {
      headers: {
        cpfFiscal
      }
    }
  );
};

const existTipoProcessoAtoCadastro = (
  idCadastroReu: number,
  idAto: number
): AxiosPromise => {
  return axios.get(
    `${CONTEXT_PATH}/ato-processo/existe-tipo-processo-ato-cadastro/${idCadastroReu}/${idAto}`
  );
};

export default {
  ...crudService,
  findAllByIdProcesso,
  deletarAto,
  visualizarDocumentos,
  concluirAto,
  reabrirAto,
  findAtosDoProcessoParaVinculacao,
  findAtosAutoDeInfracaoDoProcessoParaVinculacao,
  criarAto,
  quantidadeAtosComPrazoEncerrado,
  findAllFundamentacoes,
  adicionarFundamentacao,
  removerFundamentacao,
  existeEncaminhamentoAbertoUsuario,
  constituirDebitos,
  existTipoProcessoAtoCadastro
};
