import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FAB,
  FormikCheckBox,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Yup,
  useShowNotification
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import EmailService from '../../../service/EmailService';
import EnviarEmailService from '../../../service/EnviarEmailService';
import { ConfiguracaoEmail } from '../../../types/ConfiguracaoEmail';

type Props = {};

export type ProtocoloSmtp = {
  descricao: string;
  protocolo: string;
};

const PROTOCOLOS_SMTP: ProtocoloSmtp[] = [
  { descricao: 'SMTP', protocolo: 'smtp' },
  { descricao: 'SMTPS', protocolo: 'smtps' }
];

const SCHEMA_VALIDATION = Yup.object().shape({
  emailToTest: Yup.string()
    .email('O e-mail está inválido.')
    .label('Email de teste'),
  usuarioSmtp: Yup.string()
    .email('O e-mail está inválido.')
    .required()
    .label('Email do Usuário'),
  portaSmtp: Yup.number()
    .required()
    .label('Porta'),
  protocoloEnvio: Yup.string()
    .required()
    .label('Protocolo'),
  servidorSmtp: Yup.string()
    .required()
    .label('Servidor SMTP')
});

const initialValues: ConfiguracaoEmail = {
  servidorSmtp: '',
  protocoloEnvio: PROTOCOLOS_SMTP[0].descricao,
  requerAutenticacao: false,
  sslSmtp: false,
  usuarioSmtp: '',
  senhaSmtp: '',
  emailToTest: ''
};

export const ConfiguracaoEmailDataPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<ConfiguracaoEmail>(initialValues);
  const showNotification = useShowNotification();

  useEffect(() => {
    setLoading(true);
    EmailService.getConfiguracaoEmail()
      .then((response: AxiosResponse<ConfiguracaoEmail>) => {
        setValues(response.data);
      })
      .catch((error: any) => {
        Alert.error(
          { title: 'Não foi possível carregar a configuração de email' },
          error
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const onSave = (values: ConfiguracaoEmail) => {
    setLoading(true);
    EmailService.save(values)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Configuracão salva com sucesso.'
        });
      })
      .catch((error: any) => {
        Alert.error(
          { title: 'Ocorreu um erro ao salvar as configurações' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onClickTestarEmail = (destinatario: string, remetente: string) => {
    setLoading(true);
    EnviarEmailService.sendEmailTeste({
      emailDestinatario: destinatario,
      emailRemetente: remetente
    })
      .then(response => {
        if (response.status === 200) {
          Alert.success({ title: 'Email enviado com sucesso!' });
        }
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível enviar o email!' }, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container breadcrumb>
      <Panel>
        <Loading loading={loading} />
        <Formik<ConfiguracaoEmail>
          initialValues={values}
          onSubmit={onSave}
          validationSchema={() => SCHEMA_VALIDATION}
          enableReinitialize
          render={(form: FormikProps<ConfiguracaoEmail>) => (
            <>
              <SectionTitle marginTop="0px">Configurações Gerais</SectionTitle>
              <Row>
                <Col>
                  <BasicInput label="Usuário" name="usuarioSmtp" size={2} />
                </Col>
                <Col>
                  <BasicInput
                    label="Senha"
                    name="senhaSmtp"
                    size={2}
                    type="password"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BasicInput
                    label="Servidor SMTP"
                    name="servidorSmtp"
                    size={2}
                  />
                </Col>
                <Col>
                  <FormikInputInteger
                    label="Porta"
                    name="portaSmtp"
                    size={1}
                    fast={false}
                  />
                </Col>
                <Col>
                  <FormikSelect
                    label="Protocolo de envio"
                    name="protocoloEnvio"
                    size={2}
                    options={PROTOCOLOS_SMTP}
                    getOptionLabel={option => option.descricao}
                    getOptionValue={option => option.protocolo}
                    fast={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikCheckBox
                    label="Servidor requer autenticação"
                    name="requerAutenticacao"
                    size={3}
                    noLabel={false}
                  />
                </Col>
                <Col>
                  <FormikCheckBox
                    label="Servidor requer conexão segura (SSL)"
                    name="sslSmtp"
                    size={3}
                    noLabel={false}
                  />
                </Col>
              </Row>
              <SectionTitle>Teste de Envio</SectionTitle>
              <Row>
                <Col>
                  <BasicInput
                    label="Destinatário para Testes"
                    name="emailToTest"
                    size={2}
                    type="email"
                  />
                </Col>
                <Col md={2}>
                  <div className="form-group">
                    <label className="label" />
                    <Button
                      data-test-id="button-email-teste"
                      iconPosition="left"
                      color="module-color"
                      onClick={() =>
                        onClickTestarEmail(
                          form.values.emailToTest,
                          form.values.usuarioSmtp
                        )
                      }
                    >
                      <i className="fa fa-vial" />
                      Testar envio
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="btn-save">
                <FAB
                  data-test-id="buttonSave"
                  icon="check"
                  typeButton="module-color"
                  iconColor="white"
                  title="Salvar alterações"
                  onClick={form.submitForm}
                />
              </div>
            </>
          )}
        />
      </Panel>
    </Container>
  );
};
