import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';

const generateReport = (search: any) =>
  Axios.post(
    `${CONTEXT_PATH}/relatorio-procedimentos-fiscais/por-periodo/`,
    search,
    {
      responseType: 'blob'
    }
  );

export default {
  generateReport
};
