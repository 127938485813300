import { Yup } from '@elotech/components';

import { TipoProcesso } from '../../types/TipoProcesso';
import { TipoLocal } from './LocalField';

const numeroProtocoloValidation = (tipoProcesso?: TipoProcesso) =>
  (validation =>
    tipoProcesso?.exigeNumeroProtocolo ? validation.required() : validation)(
    Yup.string().label('Número do Protocolo')
  );

const anoProtocoloValidation = (tipoProcesso?: TipoProcesso) =>
  (validation =>
    tipoProcesso?.exigeNumeroProtocolo
      ? validation
          .required()
          .test('anoValorValido', 'Ano inválido', function(value) {
            if (value === undefined) {
              return true;
            }
            return Number(value.length) === 4;
          })
      : validation)(Yup.string().label('Ano do Protocolo'));

const localizacaoValidation = (tipoProcesso?: TipoProcesso) =>
  (validation =>
    tipoProcesso && tipoProcesso.exigeEndereco
      ? validation.required()
      : validation)(Yup.string().label('Localização'));

export const tipoLocalAutorValidation = (
  label: string,
  tipoLocalRequired: TipoLocal,
  tipoLocal?: TipoLocal
) =>
  (validation =>
    tipoLocal === tipoLocalRequired ? validation.required() : validation)(
    Yup.object().label(label)
  );

export const tipoLocalReuValidation = (
  label: string,
  tipoLocalRequired: TipoLocal,
  tipoLocal?: TipoLocal,
  tipoProcesso?: TipoProcesso
) =>
  (validation =>
    tipoLocal === tipoLocalRequired &&
    (!tipoProcesso || !tipoProcesso.vistoriaEmLote) &&
    !tipoProcesso?.reuNaoObrigatorio
      ? validation.required()
      : validation)(Yup.object().label(label));

export const dadosGeraisStep = (
  tipoProcesso?: TipoProcesso,
  tipoAutor?: TipoLocal,
  tipoReu?: TipoLocal
) => {
  return {
    inicioFiscalizacao: Yup.date()
      .required()
      .label('Início fiscalização'),
    fimFiscalizacao: Yup.date()
      .required()
      .label('Fim fiscalização')
      .max(
        new Date(),
        `Fim fiscalização deve ser até ${new Date().toLocaleDateString()}`
      )
      .test(
        'dataFinalMaiorQueInicial',
        'A data final não pode ser menor do que a inicial',
        function(value: Date) {
          const { inicioFiscalizacao } = this.parent;
          return inicioFiscalizacao <= value;
        }
      ),
    idTipoProcesso: Yup.number()
      .label('Tipo do Processo')
      .required(),
    idTipoProcessoAto: Yup.number()
      .label('Tipo do Ato')
      .required(),
    autorInterno: tipoLocalAutorValidation(
      'Autor Interno',
      'INTERNO',
      tipoAutor
    ),
    autorExterno: tipoLocalAutorValidation(
      'Autor Externo',
      'EXTERNO',
      tipoAutor
    ),
    reuInterno: tipoLocalReuValidation(
      'Réu Interno',
      'INTERNO',
      tipoReu,
      tipoProcesso
    ),
    reuExterno: tipoLocalReuValidation(
      'Réu Externo',
      'EXTERNO',
      tipoReu,
      tipoProcesso
    ),
    numeroProtocolo: numeroProtocoloValidation(tipoProcesso),
    anoProtocolo: anoProtocoloValidation(tipoProcesso),
    localizacao: localizacaoValidation(tipoProcesso)
  };
};

export const processoValidationSchema = (
  tipoProcesso?: TipoProcesso,
  tipoAutor?: TipoLocal,
  tipoReu?: TipoLocal
) =>
  Yup.object().shape({
    ...dadosGeraisStep(tipoProcesso, tipoAutor, tipoReu)
  });
