import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDate,
  Loading,
  Row
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import DivergenciaService from '../../../service/DivergenciaService';
import { Divergencia } from '../../../types/Divergencia';
import DivergenciaItemTable from './DivergenciaItemTable';

type Props = {};

const DivergenciaVisualizacaoPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [divergencia, setDivergencia] = useState<Divergencia>();

  useEffect(() => {
    setIsLoading(true);
    DivergenciaService.findById(id)
      .then(response => {
        setDivergencia(response.data);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar a divergência.' }, error);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  return (
    <Container breadcrumb>
      <Loading loading={isLoading}></Loading>
      <DisplayDataGrid column={true}>
        <Row>
          <DisplayDataItem title="Número">{divergencia?.id}</DisplayDataItem>
          <DisplayDataItem title="Início">
            <FormattedDate value={divergencia?.inicio} />
          </DisplayDataItem>
          <DisplayDataItem title="Fim">
            <FormattedDate value={divergencia?.fim} />
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>
      <DivergenciaItemTable
        id={id}
        dataInicial={divergencia?.inicio}
        dataFim={divergencia?.fim}
      ></DivergenciaItemTable>
    </Container>
  );
};

export default DivergenciaVisualizacaoPage;
