import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDate,
  Row
} from '@elotech/components';
import React from 'react';
import { Link } from 'react-router-dom';

import { StatusRender } from '../../../common/components/StatusRender';
import { AtoProcesso } from '../../../types/AtoProcesso';

type Props = {
  ato: AtoProcesso;
};

export const AtoDetail: React.FC<Props> = ({ ato }) => (
  <>
    <DisplayDataGrid>
      <Row>
        <DisplayDataItem md={4} title="Ato">
          {ato?.tipoAto?.descricao}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Número/Exercicio">
          {ato?.numero}/{ato?.exercicio}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Origem">
          {ato.atoOrigem ? (
            <Link
              to={`/processo/${ato.idProcesso}/processo-ato/${ato.atoOrigem?.id}`}
            >
              {ato.atoOrigem?.numero}/{ato.atoOrigem?.exercicio}
            </Link>
          ) : null}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Status">
          <StatusRender
            idStatus={ato.idStatus!}
            status={ato.descricaoStatus!}
          />
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={4} title="Criado por">
          {ato?.fiscalResponsavelNome}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Abertura">
          {ato.dataAbertura && <FormattedDate value={ato.dataAbertura} />}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Encerramento">
          {ato.dataFechamento && <FormattedDate value={ato.dataFechamento} />}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Emissão">
          {ato.dataAbertura && <FormattedDate value={ato.dataEmissao} />}
        </DisplayDataItem>
      </Row>
    </DisplayDataGrid>
  </>
);
