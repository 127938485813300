import { Field, ListPage } from '@elotech/components';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React from 'react';

import { StatusRender } from './../../../common/components/StatusRender';
import ArtigoService from '../../../service/ArtigoService';
import { ArtigoVo } from '../../../types/ArtigoVo';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  { type: 'STRING', label: 'Título', name: 'titulo' },
  { type: 'NUMBER', label: 'Nº Lei', name: 'lei.numero' }
];

export const ArtigoListPage: React.FC<Props> = ({ history }) => {
  const onDelete = async (entity: ArtigoVo) => {
    return ArtigoService.deleteById(entity.id).catch(err => {
      Alert.error({ title: 'Erro ao excluir registro' }, err);
    });
  };

  return (
    <ListPage<ArtigoVo>
      title="Cadastro de Artigo"
      icon={'university'}
      baseUrl="/configuracao/cadastro-artigo"
      getId={entity => entity.id}
      history={history}
      searchFields={searchFields}
      onSearch={ArtigoService.loadVo}
      onDelete={onDelete}
      columns={[
        ['Título', (item: ArtigoVo) => item.titulo],
        ['Nº Lei', (item: ArtigoVo) => item.lei],
        ['Tipo Penalidade', (item: ArtigoVo) => item.tipoPenalidadeDesc],
        ['Aplic. Penalidade', (item: ArtigoVo) => item.descricao],
        ['Penalidade', (item: ArtigoVo) => item.penalidade],
        ['Limite Legal', (item: ArtigoVo) => item.limiteLegal],
        [
          'Status',
          (item: ArtigoVo) => (
            <StatusRender
              allSize={true}
              idStatus={item.ativo ? 'A' : 'C'}
              status={item.ativo ? 'Ativo' : 'Inativo'}
            />
          )
        ]
      ]}
      hideButtons={['view']}
    />
  );
};
