import {
  Alert,
  Button,
  Col,
  Container,
  FormikInputInteger,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik } from 'formik';
import { openPdf } from 'iss-common/utils/FileUtils';
import React, { useState } from 'react';

import RelatoriosService from '../../service/RelatoriosService';

const schema = Yup.object().shape({
  numeroProcesso: Yup.string()
    .required()
    .label('Número do Processo'),
  exercicioProcesso: Yup.string()
    .required()
    .test('anoValorValido', 'Ano inválido', function(value) {
      if (value === undefined) {
        return true;
      }
      return Number(value.length) === 4;
    })
    .label('Exercicio do Processo')
});
export const RelatorioDocumentosIntimadosPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatoriosService.gerarRelDocumentosIntimados(values)
      .then((response: any) => {
        openPdf(response.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        initialValues={{}}
        validationSchema={schema}
        onSubmit={onSubmit}
        render={form => (
          <>
            <Row>
              <FormikInputInteger
                label="Número do Processo"
                name="numeroProcesso"
                size={3}
                fast={false}
              />
              <FormikInputInteger
                label="Exercicio do Processo"
                name="exercicioProcesso"
                size={3}
                maxLength={4}
                fast={false}
              />
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};
