import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import {
  CruzamentoBalanceteApuracaoMensalDTO,
  CruzamentoBalanceteDmsDTO
} from '../types/Desif';

const findCruzamentoBalanceteDms = (
  idCadatro: number,
  competencia: string
): AxiosPromise<CruzamentoBalanceteDmsDTO[]> =>
  axios.get(
    `${CONTEXT_PATH}/desif/cruzamento-balancete-dms?idCadastro=${idCadatro}&competencia=${competencia}`
  );

const findCruzamentoBalanceteApuracaoMensal = (
  idCadatro: number,
  competencia: string
): AxiosPromise<CruzamentoBalanceteApuracaoMensalDTO[]> =>
  axios.get(
    `${CONTEXT_PATH}/desif/cruzamento-balancete-apuracao-mensal?idCadastro=${idCadatro}&competencia=${competencia}`
  );

export default {
  findCruzamentoBalanceteDms,
  findCruzamentoBalanceteApuracaoMensal
};
