import {
  Alert,
  Container,
  Hint,
  Loading,
  NotificationActions
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';
import { match } from 'react-router';

import DivergenciaService from '../../service/DivergenciaService';
import { Divergencia } from '../../types/Divergencia';
import { FiltroDivergencia } from '../../types/FiltroDivergencia';
import { ConsultaAvancadaDivergencia } from './tipo-pesquisa/ConsultaAvancadaDivergencia';

type Props = {
  showNotification(notification: Notification): void;
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const initialValues: Divergencia = {
  id: 0,
  itens: [],
  inicio: undefined,
  fim: undefined
};

const URL_HELP =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-fiscalizacao-divergencia';

export const DivergenciaFormPage: React.FC<Props> = ({
  showNotification,
  match,
  history
}) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [divergencia, setDivergencia] = useState(initialValues);

  const load = (id: string) => {
    if (!id) {
      setLoading(false);
      return;
    }

    DivergenciaService.findById(id)
      .then((result: AxiosResponse<Divergencia>) => {
        setDivergencia(result.data);
      })
      .catch((error: any) => {
        setLoading(false);
        Alert.error(
          { title: `Erro ao carregar os dados da divergência` },
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    load(id);
  }, [id]);

  useEffect(() => {
    const update =
      !divergencia ||
      (divergencia.status !== 'CONCLUIDO' && divergencia.status !== 'ERRO');

    if (update) {
      setTimeout(() => load(id), 3000);
    }
  }, [divergencia, id]);

  const onPesquisarDivergencia = (filtro: FiltroDivergencia) => {
    setLoading(true);

    DivergenciaService.searchDivergencia(filtro)
      .then(() => {
        showNotification({
          title: 'Aguarde, pesquisando divergências',
          level: 'info',
          message:
            'Enquanto a busca é feita, você pode trabalhar em outras telas. Avisaremos você quando terminar.'
        });
        history.replace(`/divergencia/`);
      })
      .catch((error: any) => {
        Alert.error(
          { title: 'Não foi possível pesquisar as divergências.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading loading={loading} />
      <Container
        title="Divergências"
        icon="plus"
        breadcrumb
        titleRightComponent={
          <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <ConsultaAvancadaDivergencia
          data-test-id="pesquisa"
          onPesquisarDivergencia={onPesquisarDivergencia}
        />
      </Container>
    </>
  );
};

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(DivergenciaFormPage);

export default ConnectedComponent;
