import { Checkbox, Panel, Row, Table } from '@elotech/components';
import React, { useEffect, useState } from 'react';

import AtoProcessoService from '../../../../../service/AtoProcessoService';
import { ArtigoVo } from '../../../../../types/ArtigoVo';
import { AtoProcesso } from '../../../../../types/AtoProcesso';

type Props = {
  atoProcesso: AtoProcesso;
};

export const FundamentacaoLegalComponent: React.FC<Props> = ({
  atoProcesso
}) => {
  const [artigos, setArtigos] = useState<ArtigoVo[]>([]);
  const [artigosFiltrados, setArtigosFiltrados] = useState<ArtigoVo[]>([]);
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    recuperarFundamentacoes(atoProcesso.id);
  }, [atoProcesso]);

  const recuperarFundamentacoes = (id: number) => {
    setArtigos([]);
    setArtigosFiltrados([]);
    AtoProcessoService.findAllFundamentacoes(id).then(response => {
      setArtigos(response.data);
      setArtigosFiltrados(response.data);
    });
  };

  const filtrarArtigos = (event: any) => {
    const valor = (event.target.value as string).toUpperCase();
    setFiltro(event.target.value);
    const valores = artigos.filter(
      x =>
        x.regulamento.toUpperCase().includes(valor) ||
        x.titulo.toUpperCase().includes(valor) ||
        x.descricao.toUpperCase().includes(valor)
    );
    setArtigosFiltrados([...valores]);
  };

  const handleChangeSelecionado = (item: ArtigoVo) => {
    const novosArtigos = artigosFiltrados;
    const artigoAlterado = novosArtigos.findIndex(x => x.id === item.id);
    novosArtigos[artigoAlterado].selecionado = !item.selecionado;
    setArtigosFiltrados([...novosArtigos]);
    if (novosArtigos[artigoAlterado].selecionado) {
      AtoProcessoService.adicionarFundamentacao(
        atoProcesso.id,
        novosArtigos[artigoAlterado].id
      );
    } else {
      AtoProcessoService.removerFundamentacao(
        atoProcesso.id,
        novosArtigos[artigoAlterado].id
      );
    }
  };

  return (
    <>
      <Row>
        <div className="col-md-3">
          <div>
            <label className="label">Filtro</label>
            <input
              type="text"
              id="filter"
              onChange={filtrarArtigos}
              value={filtro}
            />
          </div>
        </div>
      </Row>
      <br />
      <Panel isTable>
        <Table<ArtigoVo>
          scrollHorizontal={true}
          fixed
          values={artigosFiltrados}
          keyExtractor={artigo => artigo.id}
        >
          <Table.Column<ArtigoVo>
            header="Selecionado"
            headerClassName="col-md-1"
            className="col-md-1"
            style={{ border: 'none' }}
            value={(item, index) => {
              return (
                <Checkbox
                  id={`${index}`}
                  checked={item.selecionado}
                  onChange={() => {
                    handleChangeSelecionado(item);
                  }}
                />
              );
            }}
          />
          <Table.Column<ArtigoVo>
            header="Fundamentação Legal"
            headerClassName="col-md-2"
            className="col-md-2"
            style={{ border: 'none' }}
            value={artigo => artigo.regulamento}
          />
          <Table.Column<ArtigoVo>
            header="Tipo"
            headerClassName="col-md-2"
            className="col-md-2"
            style={{ border: 'none' }}
            value={artigo => artigo.titulo}
          />
          <Table.Column<ArtigoVo>
            header="Texto da lei"
            headerClassName="col-md-7"
            className="col-md-7"
            style={{ border: 'none' }}
            value={artigo => artigo.descricao}
          />
        </Table>
      </Panel>
    </>
  );
};
