import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDate,
  Row
} from '@elotech/components';
import React from 'react';

import { Processo } from '../../../../types/Processo';

type Props = {
  processo: Processo;
};

export const ProcessoDetail: React.FC<Props> = ({ processo }) => (
  <DisplayDataGrid column={true}>
    <Row>
      <DisplayDataItem md={5} title="Contribuinte">
        {processo.id} {processo.cnpjCpfReu} {processo.reu}
      </DisplayDataItem>
      <DisplayDataItem md={2} title="Data Abertura">
        <FormattedDate value={processo.dataAbertura} />
      </DisplayDataItem>
      <DisplayDataItem md={2} title="Data Encerramento">
        <FormattedDate value={processo.dataFechamento} />
      </DisplayDataItem>
      <DisplayDataItem md={2} title="Ano">
        {processo.exercicio}
      </DisplayDataItem>
    </Row>
  </DisplayDataGrid>
);
