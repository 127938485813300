import {
  Button,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  Loading,
  Row,
  SectionTitle,
  Sort
} from '@elotech/components';
import { Alert } from 'iss-common/utils';
import React from 'react';
import { match } from 'react-router';

import ArtigoGuiaService from '../../service/ArtigoGuiaService';
import ArtigoService from '../../service/ArtigoService';
import { Artigo } from '../../types/Artigo';
import { ArtigoDTO } from '../../types/ArtigoDTO';
import { ArtigoGuiaRecolhimento } from '../../types/ArtigoGuiaRecolhimento';
import { ArtigoGuiaVo } from '../../types/ArtigoGuiaVo';
import GuiaRecolhimentoForm from './GuiaRecolhimentoForm';
import GuiaRecolhimentoList from './GuiaRecolhimentoList';

type Props = {
  match: match<{ idArtigo: string }>;
};

type State = {
  artigo?: ArtigoDTO;
  guiasRecolhimento: {
    content: ArtigoGuiaVo[];
  };
  loading: boolean;
  formVisivel: boolean;
};

class ArtigoGuiaRecolhimentoFormPage extends React.Component<Props, State> {
  state: State = {
    artigo: undefined,
    guiasRecolhimento: {
      content: []
    },
    loading: false,
    formVisivel: false
  };

  componentDidMount() {
    const { match } = this.props;

    this.setState({ loading: true });
    ArtigoService.buscaDtoById(+match.params.idArtigo)
      .then(response => {
        this.setState({
          artigo: response.data
        });
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possivel buscar o artigo' }, error);
      })
      .finally(() => this.setState({ loading: false }));

    this.buscaGuiaVo(+match.params.idArtigo, 'exercicio');
  }

  buscaGuiaVo = (idArtigo: number, sort: string) => {
    this.setState({ loading: true });
    ArtigoGuiaService.buscaGuiaVoByArtigo(idArtigo, sort)
      .then(response => {
        this.setState({
          guiasRecolhimento: response.data
        });
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possivel buscar as guias de recolhimento' },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  onSort = (sort: Sort) => {
    const { match } = this.props;
    this.buscaGuiaVo(+match.params.idArtigo, sort.sort);
  };

  onCancel = () => {
    this.setState({ formVisivel: false });
  };

  onDelete = (artigoGuiaVo: ArtigoGuiaVo) => {
    const { match } = this.props;
    Alert.question({
      title: `Deseja excluir guia de recolhimento?`,
      text: 'Esta ação não poderá ser revertida!'
    }).then((result: any) => {
      if (result.value) {
        this.setState({ loading: true });
        ArtigoGuiaService.deleteById(artigoGuiaVo.idArtigoGuia)
          .then(() => {
            Alert.success({
              title: 'Guia de Recolhimento excluída com sucesso!'
            });
            this.buscaGuiaVo(+match.params.idArtigo, 'exercicio');
          })
          .catch(error => {
            Alert.error(
              {
                title:
                  'Ocorreu uma falha ao tentar excluir a Guia de recolhimento!'
              },
              error
            );
          })
          .finally(() => this.setState({ loading: false }));
      }
    });
  };

  onSave = (artigoGuia: ArtigoGuiaRecolhimento) => {
    const { match } = this.props;
    const { guiasRecolhimento } = this.state;

    const conflito = guiasRecolhimento.content.find((guia: ArtigoGuiaVo) => {
      return (
        guia.exercicio === artigoGuia.exercicio &&
        guia.numeroGuiaRecolhimento ===
          artigoGuia.guiaRecolhimento.guiaRecolhimento &&
        guia.receita === artigoGuia.receita
      );
    });

    if (conflito !== undefined) {
      Alert.warning({ title: 'Essa guia já está associada com o artigo.' });
      return;
    }

    artigoGuia.artigo = { id: +match.params.idArtigo } as Artigo;
    this.setState({ loading: true });

    ArtigoGuiaService.save(artigoGuia)
      .then(() => {
        this.buscaGuiaVo(+match.params.idArtigo, 'exercicio');
        this.setState({
          formVisivel: false
        });
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possivel salvar a guia de recolhimento' },
          error
        );
      })
      .finally(() =>
        this.setState({
          loading: false
        })
      );
  };

  render() {
    const { artigo, guiasRecolhimento, loading, formVisivel } = this.state;

    return (
      <Container breadcrumb={true}>
        <Loading loading={loading} />
        <SectionTitle>Dados Gerais</SectionTitle>
        {artigo !== undefined ? (
          <DisplayDataGrid column={true}>
            <Row>
              <DisplayDataItem md={2} title="Código">
                {artigo.id}
              </DisplayDataItem>
              <DisplayDataItem md={10} title="Natureza">
                {artigo.natureza}
              </DisplayDataItem>
            </Row>
            <Row>
              <DisplayDataItem md={12} title="Descrição">
                {artigo.descricao}
              </DisplayDataItem>
            </Row>
          </DisplayDataGrid>
        ) : null}
        <SectionTitle hasButton={true}>
          Guias de Recolhimento
          {formVisivel ? null : (
            <Button
              onClick={() => this.setState({ formVisivel: true })}
              position={'right'}
            >
              Novo
            </Button>
          )}
        </SectionTitle>

        {formVisivel === true ? (
          <GuiaRecolhimentoForm onSave={this.onSave} onCancel={this.onCancel} />
        ) : (
          <GuiaRecolhimentoList
            guiasRecolhimento={guiasRecolhimento.content}
            onSort={this.onSort}
            onDelete={this.onDelete}
          />
        )}
      </Container>
    );
  }
}

export default ArtigoGuiaRecolhimentoFormPage;
