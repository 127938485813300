import {
  ActionButton,
  ActionsGroup,
  FormattedDateTime,
  Panel,
  Table
} from '@elotech/components';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';

import { StatusRender } from '../../../common/components/StatusRender';
import { AtoProcesso } from '../../../types/AtoProcesso';

type Props = {
  atos: AtoProcesso[];
  onViewClick(id: number, idProcesso: any): void;
  loading?: boolean;
};

export const AtosList: React.FC<Props> = ({ atos, onViewClick, loading }) => {
  const highlightSuccess = (item: AtoProcesso) => {
    let valid = false;
    const ato = atos.find(a => a.id === item.id);
    if (!ato?.autoInfracoes?.length) {
      return false;
    }
    ato.autoInfracoes.map(a => {
      if (!!a.autoInfracaoArtigos.length) {
        const debitosGerados = a.autoInfracaoArtigos.filter(
          dg => !!dg.autoInfracaoDebito && !!dg.autoInfracaoDebito.dataEmissao
        );
        if (debitosGerados.length === a.autoInfracaoArtigos.length) {
          valid = true;
        }
      }
      return a;
    });
    return valid;
  };

  const highlightWarning = (item: AtoProcesso) => {
    let valid = false;
    const ato = atos.find(a => a.id === item.id);
    if (!ato?.autoInfracoes?.length) {
      return false;
    }
    ato.autoInfracoes.map(a => {
      if (!!a.autoInfracaoArtigos.length) {
        const debitosGerados = a.autoInfracaoArtigos.filter(
          dg => !!dg.autoInfracaoDebito && !!dg.autoInfracaoDebito.dataEmissao
        );
        if (debitosGerados.length !== a.autoInfracaoArtigos.length) {
          valid = true;
        }
      }
      return a;
    });
    return valid;
  };

  return (
    <Panel isTable={true} insidePanel={true}>
      <Table
        values={atos}
        keyExtractor={item => `${item.id}`}
        loading={loading}
        highlightWarning={item => highlightWarning(item)}
        highlightSuccess={item => highlightSuccess(item)}
      >
        <Table.Column<AtoProcesso>
          header="Ato"
          value={item => item.tipoAto?.descricao}
        />
        <Table.Column<AtoProcesso>
          header="Número"
          value={item => `${item.numero}/${item.exercicio}`}
        />
        <Table.Column<AtoProcesso>
          header="Criado por"
          value={item => item.fiscalResponsavelNome}
        />
        <Table.Column<AtoProcesso>
          header="Abertura"
          value={item =>
            item.dataAbertura && <FormattedDateTime value={item.dataAbertura} />
          }
        />
        <Table.Column<AtoProcesso>
          header="Encerramento"
          value={item =>
            item.dataFechamento && (
              <FormattedDateTime value={item.dataFechamento} />
            )
          }
        />
        <Table.Column<AtoProcesso>
          header="Origem"
          value={item =>
            item.atoOrigem ? (
              <Link
                to={`/processo/${item.processo}/processo-ato/${item.atoOrigem.id}`}
              >
                {item.atoOrigem.numero}/{item.atoOrigem.exercicio}
              </Link>
            ) : null
          }
        />
        <Table.Column<AtoProcesso>
          header="Status"
          align="center"
          value={item => (
            <StatusRender
              idStatus={item.idStatus || ''}
              status={item.status || ''}
              allSize={true}
            />
          )}
        />
        <Table.Column<AtoProcesso>
          header="Emissão"
          value={item =>
            item.dataEmissao && <FormattedDate value={item.dataEmissao} />
          }
        />
        <Table.Column<AtoProcesso>
          header=""
          value={item => (
            <ActionsGroup>
              <ActionButton
                data-testid="view-btn"
                key={'view-button'}
                icon="eye"
                label="Visualizar ato"
                onClick={() => onViewClick(item.id, item.processo)}
              />
            </ActionsGroup>
          )}
        />
      </Table>
    </Panel>
  );
};
