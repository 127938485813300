import {
  Button,
  Col,
  FormikAutocomplete,
  Loading,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React from 'react';

import TipoAtoService from '../../../service/TipoAtoService';
import { TipoAto } from '../../../types/TipoAto';
import { TipoProcessoAto } from '../../../types/TipoProcessoAto';

type Props = {
  onSubmit: (valor: TipoProcessoAto) => void;
  initialValue?: TipoProcessoAto;
};

const SCHEMA_VALIDATION = Yup.object().shape({
  tipoAto: Yup.string()
    .required()
    .label('É necessário informar o tipo de Ato')
});

const TipoAtoComponent: React.FC<Props> = props => {
  const loading: boolean = false;

  return (
    <>
      <Loading loading={loading} />
      <Formik<any>
        enableReinitialize={true}
        initialValues={props.initialValue || { ativo: true }}
        onSubmit={(values, actions) => {
          props.onSubmit(values);
          actions.resetForm();
        }}
        validationSchema={SCHEMA_VALIDATION}
        render={(formProps: FormikProps<TipoProcessoAto>) => {
          return (
            <>
              <Row style={{ marginBottom: '10px' }}>
                <FormikAutocomplete<TipoAto>
                  name="tipoAto"
                  label="Tipo de Ato"
                  onSearch={TipoAtoService.tipoAtoAutoComplete}
                  getOptionLabel={value => `${value.descricao}`}
                  getOptionValue={value => value.id}
                  size={10}
                />
                <Col className="form-group" md={2}>
                  <label className="label" />
                  <Button onClick={formProps.submitForm}>Adicionar</Button>
                </Col>
              </Row>
            </>
          );
        }}
      ></Formik>
    </>
  );
};

export { TipoAtoComponent };
