import { FormikAutocomplete, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';
import { OptionsPropsEditor } from '../propseditor/OptionsPropsEditor';

const AutocompleteField: React.FC<JsonikFieldProps> = props => (
  <FormikAutocomplete
    {...props}
    onSearch={(search: string) =>
      Promise.resolve({
        data: {
          content: props.props.options.filter((option: any) => {
            const uSearch = search.toUpperCase();
            const uValue = (option.label || '').toUpperCase();

            return uValue.search(uSearch) !== -1;
          })
        }
      } as any)
    }
    getOptionLabel={(option: any) => option && option.label}
    getOptionValue={(option: any) => option && option.value}
  />
);

export const AutocompleteFieldMap: JsonikFieldMap = {
  type: 'autocomplete',
  component: AutocompleteField,
  friendlyName: 'Busca',
  description: 'Permite uma busca em uma lista pré-definida',
  yupType: Yup.mixed,
  getDefaultProps: () => ({
    options: [
      { value: '1', label: 'Opção 1' },
      { value: '2', label: 'Opção 2' }
    ]
  }),
  editor: OptionsPropsEditor
};
