import { Alert, Button, SectionTitle } from '@elotech/components';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  JsonikFieldMap,
  JsonikFieldProps,
  JsonikRecipe
} from '../common/types';
import { JsonikForm } from '../form/JsonikForm';
import { Context } from './Context';
import { FieldWrapper } from './FieldWrapper';
import { changeRecipe } from './utils';

type Props = {
  onDelete(recipe: JsonikRecipe): void;
};

export const FormRenderer: React.FC<Props> = ({ onDelete }) => {
  const [fieldWrapperMap, setFieldWrapperMap] = useState<JsonikFieldMap[]>();
  const {
    recipe,
    setRecipe,
    fieldMap,
    setEditingField,
    setInsertingOrEditing,
    testing
  } = useContext(Context);

  const removeField = useCallback(
    async (field: JsonikFieldProps) => {
      const res = await Alert.question({
        title: `Deseja remover o campo "${field.label}"?`
      });

      if (res.value) {
        const newRecipe = changeRecipe(field.name, undefined, recipe);
        setRecipe(newRecipe);
        onDelete(newRecipe);
      }
    },
    // eslint-disable-next-line
    [recipe, setRecipe]
  );

  const editField = useCallback(
    (field: JsonikFieldProps) => {
      setEditingField(field.name);
      setInsertingOrEditing(true);
    },
    [setEditingField, setInsertingOrEditing]
  );

  const saveOptionField = useCallback(() => {
    setEditingField(undefined);
    setInsertingOrEditing(true);
    // eslint-disable-next-line
  }, [setInsertingOrEditing]);

  /**
   * Gera um fieldMap que usa o FieldWrapper como component.
   */
  useEffect(() => {
    setFieldWrapperMap(
      [...fieldMap!].map(map => ({
        ...map,
        component: field => (
          <FieldWrapper
            field={field}
            map={map}
            onRemove={(field: JsonikFieldProps) => removeField(field)}
            onEdit={(field: JsonikFieldProps) => editField(field)}
          />
        )
      }))
    );
  }, [fieldMap, recipe, removeField, editField]);

  if (!recipe || testing) return null;

  return (
    <>
      <SectionTitle marginTop="20px" hasButton={true}>
        Formulário
        <Button onClick={() => saveOptionField()}>Adicionar Campo</Button>
      </SectionTitle>
      <JsonikForm
        recipe={recipe}
        customFields={fieldWrapperMap}
        render={() => {
          return <></>;
        }}
      />
    </>
  );
};
