import { Alert, Field, ListPage } from '@elotech/components';
import React from 'react';
import { useHistory } from 'react-router';

import TipoLocalInternoService from '../../../service/TipoLocalInternoService';
import { TipoLocalInterno } from '../../../types/TipoLocalInterno';

const fields: Field[] = [
  {
    label: 'Código',
    name: 'id',
    type: 'NUMBER'
  },
  {
    label: 'Descrição',
    name: 'descricao',
    type: 'STRING'
  }
];

type Props = {};

const TipoLocalInternoListPage: React.FC<Props> = () => {
  const history = useHistory();

  const onDelete = (entity: TipoLocalInterno) =>
    TipoLocalInternoService.deleteById(entity.id).catch(erro => {
      Alert.error({ title: 'Erro ao deletar Local Interno' }, erro);
    });

  return (
    <ListPage<TipoLocalInterno>
      title="Local Interno"
      icon="users-cog"
      baseUrl="/tipo-local-interno"
      searchFields={fields}
      onDelete={onDelete}
      onSearch={(search, page) => {
        return TipoLocalInternoService.findAll(search, page).then(response => {
          return response;
        });
      }}
      history={history}
      getId={item => item.id!}
      columns={[
        ['Código', (item: TipoLocalInterno) => item.id],
        ['Descrição', (item: TipoLocalInterno) => item.descricao]
      ]}
      hideButtons={['view']}
    />
  );
};

export default TipoLocalInternoListPage;
