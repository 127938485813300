import ArtigoGuiaRecolhimentoFormPage from '../pages/artigo-guia-recolhimento/ArtigoGuiaRecolhimentoFormPage';
import ArtigoGuiaRecolhimentoListPage from '../pages/artigo-guia-recolhimento/ArtigoGuiaRecolhimentoListPage';
import { Roles } from '../utils/Roles';

const artigoGuiaRecolhimentoRoutes = [
  {
    path: '/artigo-guia-recolhimento',
    component: ArtigoGuiaRecolhimentoListPage,
    title: 'Consulta de Artigo x Guia de Recolhimento',
    role: Roles.artigo_guia_read.name
  },
  {
    path: '/artigo-guia-recolhimento/:idArtigo',
    component: ArtigoGuiaRecolhimentoFormPage,
    title: 'Artigo x Guia de Recolhimento',
    role: Roles.artigo_guia_update.name
  }
];

export default artigoGuiaRecolhimentoRoutes;
