import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { ApuracaoIssOutraReceita } from '../types/ApuracaoIssOutraReceita';

const crudService = CrudService<ApuracaoIssOutraReceita>(
  '/apuracao-iss-outra-receita'
);

const findAllByIdApuracao = (
  id: any,
  page: PageRequest
): AxiosPromise<PagedResponse<ApuracaoIssOutraReceita>> =>
  axios.get(
    `${CONTEXT_PATH}/apuracao-iss-outra-receita/busca-por-apuracao/${id}`,
    {
      params: { ...page }
    }
  );

const removerPorApuracao = (
  idApuracao: string,
  idsOutrasReceitas: number[]
): AxiosPromise<PagedResponse<ApuracaoIssOutraReceita>> =>
  axios.post(
    `${CONTEXT_PATH}/apuracao-iss-outra-receita/remover-por-apuracao/${idApuracao}`,
    idsOutrasReceitas
  );

export default {
  ...crudService,
  findAllByIdApuracao,
  removerPorApuracao
};
