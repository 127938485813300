import { CONTEXT_PATH } from './contextPath';

export type Entity = {
  id: number | string;
};

export type BasePathMap = {
  default: string;
  list?: string;
  findById?: string;
  save?: string;
  deleteById?: string;
};

export type BasePath = string | BasePathMap;

export type GetPathFunc = (operation: keyof BasePathMap) => string;

export const makeGetPath = (basePath: BasePath): GetPathFunc => (
  operation: keyof BasePathMap
) => {
  let path = '';

  if (typeof basePath === 'object') {
    path = basePath[operation] || '';

    if (!path) {
      path = basePath['default'];
    }
  } else if (typeof basePath === 'string') {
    path = basePath;
  }

  return `${CONTEXT_PATH}/${path.startsWith('/') ? path.substring(1) : path}`;
};
