import { Quill } from 'react-quill';

let AlignStyle = Quill.import('attributors/style/align');
let BackgroundStyle = Quill.import('attributors/style/background');
let ColorStyle = Quill.import('attributors/style/color');
let DirectionStyle = Quill.import('attributors/style/direction');
let FontStyle = Quill.import('attributors/style/font');
let SizeStyle = Quill.import('attributors/style/size');

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);

export const reactQuillModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['code-block'],
    [{ align: [] }]
  ]
};

export const reactQuillFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'background',
  'color',
  'bullet',
  'link',
  'image',
  'code-block',
  'align'
];
