import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Entity, GetPathFunc } from './utils';

export const defaultFilterAutocomplete = (
  fieldCodigo: string,
  fieldDescricao?: string
) => (search: string) =>
  search && FilterUtils.buildDefaultFilter(fieldCodigo, fieldDescricao)(search);

export const ReadonlyService = <T extends Entity>(
  getPath: GetPathFunc,
  defaultSort?: object,
  filterAutocomplete?: (search: string) => string
) => {
  const sort = defaultSort || { sort: 'id' };

  const search = (params: string) =>
    !!filterAutocomplete ? filterAutocomplete(params) : params;

  const list = (searchParams: string, page?: any) =>
    axios.get(`${getPath('list')}`, {
      params: { search: search(searchParams), ...sort, ...page }
    });

  const findById = (id: number | string): AxiosPromise<T> =>
    axios.get(`${getPath('findById')}/${id}`);

  return {
    search: list,
    load: list,
    findById
  };
};
