import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { Protocolo } from '../types/Protocolo';

const load = (
  search: string,
  page: any
): AxiosPromise<PagedResponse<Protocolo>> =>
  axios.get(`${CONTEXT_PATH}/protocolo`, {
    params: { search, ...page }
  });

export default { load };
