import {
  ActionButton,
  ActionsGroup,
  Alert,
  DateUtils,
  FormattedDateTime,
  Icon,
  Loading,
  Table
} from '@elotech/components';
import download from 'downloadjs';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import TarefaAssincronaStatusChip from '../../common/components/TarefaAssincronaStatusChip';
import {
  SituacaoTarefaAssincronaEnum,
  TipoTarefaAssincrona,
  TipoTarefaAssincronaValue
} from '../../enum';
import ApuracaoIssService from '../../service/ApuracaoIssService';
import AtoProcessoService from '../../service/AtoProcessoService';
import TarefaAssincronaService from '../../service/TarefaAssincronaService';
import { TarefaAssincrona } from '../../types/TarefaAssincrona';
import TarefaAssincronaExecucaoListPage from './TarefaAssincronaExecucaoListPage';

const shouldRenderRetry = (situacao: string) => situacao === 'ERRO';

type Props = {
  tarefas: TarefaAssincrona[];
  loading: boolean;
};

const TarefaAssincronaListPage: React.FC<Props> = ({ tarefas, loading }) => {
  const history = useHistory();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [loadingRetry, setLoadingRetry] = useState<boolean>(false);
  const [idsHistoricoVisivel, setIdsHistoricoVisivel] = useState<number[]>([]);

  const linkReaderer: {
    [K in TipoTarefaAssincronaValue]: (
      tipo: TarefaAssincrona
    ) => string | undefined;
  } = {
    EXPORTACAO_MAPA_SERVICO: tipo => undefined,
    IMPORTACAO_MAPA_SERVICO: tipo => {
      let parametros: string[] = tipo.parametros
        ? tipo.parametros.split(',')
        : [];
      let idProcessoAto = parametros[0];
      let idProcesso = parametros[1];

      return `/processo/${idProcesso}/processo-ato/${idProcessoAto}`;
    }
  };

  const onRetry = (tarefa: TarefaAssincrona) => {
    setLoadingRetry(true);

    TarefaAssincronaService.retry(tarefa.id)
      .then(() => {
        setLoadingRetry(false);
        history.push(
          `/tarefas?tipoFiltro=TAREFA&filters==situacao_Igual=${SituacaoTarefaAssincronaEnum.processando}`
        );
        Alert.success({
          title: 'Tarefa reiniciada com sucesso.'
        });
      })
      .catch(error => {
        setLoadingRetry(false);
        Alert.error(
          {
            title: 'Erro ao recomeçar o processo.'
          },
          error
        );
      });
  };

  const downloadMapaServico = async (item: TarefaAssincrona) => {
    setLoadingPage(true);

    let parametros: string[] = item.parametros
      ? item.parametros.split(',')
      : [];
    let chave = parametros[0];

    let atoProcesso = await AtoProcessoService.findById(chave);

    ApuracaoIssService.getUrlMapaServico(atoProcesso?.data!.idArquivo ?? '')
      .then(response => {
        download(response.data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Erro ao realizar o download do Mapa de Serviços.' },
          error
        );
      })
      .finally(() => setLoadingPage(false));
  };

  const onViewHistorico = (id: number) =>
    idsHistoricoVisivel.includes(id)
      ? setIdsHistoricoVisivel(
          idsHistoricoVisivel.filter(idTarefa => idTarefa !== id)
        )
      : setIdsHistoricoVisivel([...idsHistoricoVisivel, id]);

  const onViewResultado = (item: TarefaAssincrona) => {
    const url = linkReaderer[item.tipo]
      ? linkReaderer[item.tipo](item)
      : undefined;

    if (url) {
      history.push(url);
    } else if (item.tipo === 'EXPORTACAO_MAPA_SERVICO') {
      downloadMapaServico(item);
    }
  };

  const renderExecucoes = (tarefa: TarefaAssincrona) => {
    if (!idsHistoricoVisivel.includes(tarefa.id)) {
      return;
    }
    return <TarefaAssincronaExecucaoListPage tarefa={tarefa} />;
  };

  const renderLink = (tarefa: TarefaAssincrona) => {
    return linkReaderer[tarefa.tipo] && linkReaderer[tarefa.tipo](tarefa) ? (
      <Link to={linkReaderer[tarefa.tipo](tarefa)!}> {tarefa.parametros} </Link>
    ) : (
      tarefa.parametros
    );
  };

  return (
    <>
      <Loading loading={loadingPage} />
      <Table
        loading={loading || loadingRetry}
        values={tarefas}
        renderInnerComponent={renderExecucoes}
        messageEmpty="Nenhuma tarefa encontrada"
      >
        <Table.Column<TarefaAssincrona>
          header=""
          value={item => {
            return item.situacao ===
              SituacaoTarefaAssincronaEnum.processando ? (
              <Icon icon="spinner" spin primary title="Em execução" />
            ) : (
              undefined
            );
          }}
        />
        <Table.Column<TarefaAssincrona>
          header="Processo"
          value={item => item.id}
        />
        <Table.Column<TarefaAssincrona>
          header="Tarefa"
          value={item => TipoTarefaAssincrona[item.tipo]}
        />
        <Table.Column<TarefaAssincrona>
          header="Parâmetros"
          value={renderLink}
        />
        <Table.Column<TarefaAssincrona>
          header="Início"
          value={item => <FormattedDateTime value={item.dataInicio} />}
        />
        <Table.Column<TarefaAssincrona>
          header="Fim"
          value={item => <FormattedDateTime value={item.dataFim} />}
        />
        <Table.Column<TarefaAssincrona>
          header="Duração"
          value={item => {
            return (
              item.dataInicio &&
              item.dataFim &&
              DateUtils.getDiferencaDatasFormatada(
                item.dataInicio,
                item.dataFim
              )
            );
          }}
        />
        <Table.Column<TarefaAssincrona>
          header="Situação"
          headerClassName="center"
          value={item => (
            <TarefaAssincronaStatusChip situacao={item.situacao} />
          )}
        />
        <Table.Column<TarefaAssincrona>
          header=""
          value={item => (
            <ActionsGroup>
              {item.situacao === SituacaoTarefaAssincronaEnum.finalizado && (
                <ActionButton
                  key="viewResultadoExecutions"
                  icon="check-circle"
                  label="Visualizar Resultado de Execução"
                  onClick={() => {
                    onViewResultado(item);
                  }}
                />
              )}
              <ActionButton
                key="viewHistoricoExecutions"
                icon="eye"
                label="Visualizar Histórico de Execução"
                onClick={() => {
                  onViewHistorico(item.id);
                }}
              />
              {shouldRenderRetry(item.situacao) && (
                <ActionButton
                  key="tentarNovamente"
                  icon="sync-alt"
                  label="Tentar Novamente"
                  onClick={() => onRetry(item)}
                />
              )}
            </ActionsGroup>
          )}
        />
      </Table>
    </>
  );
};

export default TarefaAssincronaListPage;
