import axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { Email } from '../types/Email.d.';

const ENDPOINT: string = '/enviar-email';

const sendEmailTeste = (email: Email) =>
  axios.post(`${CONTEXT_PATH}${ENDPOINT}/teste`, email);

export default { sendEmailTeste };
