import {
  Alert,
  BasicInput,
  Button,
  Col,
  ErrorText,
  FormikCheckBox,
  Modal,
  Panel,
  Row,
  SectionTitle,
  Table,
  Yup
} from '@elotech/components';
import { saveAs } from 'file-saver';
import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { JsonikFormEditor } from './../../../common/components/JsonikForm/editor/JsonikFormEditor';
import TipoAtoService from '../../../service/TipoAtoService';
import { TipoAtoFormulario } from '../../../types/TipoAtoFormulario';
import { FormEditor } from './FormEditor';

const initialEditingFormulario: TipoAtoFormulario = {
  id: undefined,
  descricao: '',
  tipo: '',
  ativo: true
};

const SCHEMA_VALIDATION = Yup.object().shape({
  template: Yup.mixed()
    .label('Arquivo')
    .test('type', 'Tipo de arquivo não suportado', value => {
      return (
        !value ||
        (value &&
          value.name &&
          (value.name.endsWith('.html') || value.name.endsWith('.jasper')))
      );
    }),
  descricao: Yup.string()
    .required()
    .min(3, 'Descrição deve ter no minimo 3 caracteres ')
    .label('Nome do Relatório')
});

const initialValue = {
  ativo: true,
  obrigatorio: true,
  tipo: 'DINAMICO',
  modelo: '',
  descricao: ''
};

type Props = {
  tipoAtoFormulario: TipoAtoFormulario;
  setShowFormRelatorio: (value: boolean) => void;
  onSubmit: (tipoAtoFormulario: TipoAtoFormulario) => void;
};

export const FormularioRelatorio: React.FC<Props> = ({
  onSubmit,
  tipoAtoFormulario,
  setShowFormRelatorio
}) => {
  const [showFormEditor, setShowFormEditor] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [parametros, setParametros] = useState<string[]>([]);

  useEffect(() => {
    TipoAtoService.parameterHandleRelatorio()
      .then(response => {
        setParametros(response.data);
      })
      .catch(onParametrosError);
  }, []);

  const onOpenModal = () => {
    setShowModal(true);
  };
  const onCloseModal = () => {
    setShowModal(false);
  };

  const onDownloadArquivo = (arquivo: any, fileName: string) => {
    TipoAtoService.downloadRelatorio(arquivo)
      .then(response => onDownloadArquivoSuccess(response, fileName))
      .catch(onDownloadArquivoError);
  };

  const onDownloadArquivoSuccess = (response: any, fileName: string) => {
    const file = new Blob([response.data], { type: response.data.type });
    const fileURL = URL.createObjectURL(file);
    saveAs(fileURL, fileName);
  };
  const onDownloadArquivoError = (error: any) => {
    Alert.info(
      {
        title: 'Ocorreu uma falha ao fazer o download do relatório.'
      },
      error
    );
  };

  const onParametrosError = (error: any) => {
    Alert.info(
      {
        title: 'Ocorreu uma falha ao carregar os parâmetros do relatório.'
      },
      error
    );
  };
  return (
    <>
      <Formik<TipoAtoFormulario>
        initialValues={tipoAtoFormulario || initialValue}
        enableReinitialize={true}
        validationSchema={SCHEMA_VALIDATION}
        onSubmit={(value, action) => {
          onSubmit(value);
        }}
        render={({ values, submitForm, resetForm, setFieldValue, errors }) => (
          <Row>
            <Col md={12}>
              <SectionTitle marginTop="0">Relatório</SectionTitle>
            </Col>
            <BasicInput
              label="Nome do Relatório"
              id="nomeRelatorio"
              name="descricao"
              size={4}
            />
            <BasicInput
              size={4}
              id="arquivo-jasper"
              label="Arquivo"
              name="template"
              render={({ field, form }) => (
                <div className="file-uploader">
                  <input
                    type="file"
                    accept=".jasper,.html"
                    className={`file-uploader-input`}
                    id={field.id}
                    title="Clique ou arraste para anexar"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('id', null);
                      form.setFieldValue('template', event.target.files![0]);
                      form.setFieldTouched('template');
                    }}
                  />

                  <label
                    htmlFor={field.id}
                    className="input"
                    data-title={
                      field.value
                        ? field.value.name
                        : 'Clique ou arraste para anexar'
                    }
                  />

                  <label htmlFor={field.id} className="file-uploader-icon" />
                </div>
              )}
            />
            <Col className="form-group" md={1}>
              <label className="label" />
              <Button
                id="download-relatorio"
                onClick={() => onDownloadArquivo(values.id, values.fileName)}
                className={'inline'}
              >
                Download
              </Button>
            </Col>
            <Col className="form-group" md={2}>
              <label className="label" />

              <Button
                id="parametros-relatorio"
                className="inline"
                onClick={() => onOpenModal()}
              >
                Parâmetros
              </Button>
            </Col>

            <FormikCheckBox
              label="Ativo"
              name="ativo"
              size={1}
              noLabel={false}
              id="formularioStatus"
            />
            <Col md={12}>
              <JsonikFormEditor
                recipe={
                  tipoAtoFormulario
                    ? tipoAtoFormulario.modelo!
                    : initialEditingFormulario.modelo!
                }
                onSubmit={(item: any[]) => {
                  if (typeof item == 'string') {
                    item = JSON.parse(item);
                  }
                  const modelo = item.map(({ typeField, ...list }) => list);
                  setFieldValue('modelo', JSON.stringify(modelo));
                }}
              />
              <div className="center">
                <ErrorMessage className="center" name="modelo">
                  {msg => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </div>
            </Col>
            <Col md={12}>
              <Button
                color="neutral"
                className="inline"
                id="cancelar-relatorio"
                onClick={() => {
                  setShowFormRelatorio(false);
                  resetForm();
                }}
              >
                Cancelar
              </Button>
              <Button
                id="salvar-relatorio"
                className="inline"
                onClick={submitForm}
              >
                Salvar Relatório
              </Button>
            </Col>
          </Row>
        )}
      />
      <FormEditor
        formulario={initialEditingFormulario}
        show={showFormEditor}
        onClose={() => setShowFormEditor(false)}
      />

      {showModal && (
        <Modal onClose={onCloseModal}>
          {
            <Panel style={{ height: '80vh', overflowY: 'auto' }} isTable>
              <Table<string> values={parametros} keyExtractor={item => item}>
                <Table.Column<string>
                  header="Parâmetros usados nos relatórios"
                  value={item => item}
                />
              </Table>
            </Panel>
          }
        </Modal>
      )}
    </>
  );
};
