import { TipoProcessoDataPage } from '../pages/configuracao/tipo-processo/TipoProcessoDataPage';
import { TipoProcessoListPage } from '../pages/configuracao/tipo-processo/TipoProcessoListPage';
import { Roles } from '../utils/Roles';

const tipoProcessoRoutes = [
  {
    path: '/configuracao/tipo-processo',
    component: TipoProcessoListPage,
    title: 'Tipo Processo',
    role: Roles.tipo_processo_read.name
  },
  {
    path: '/configuracao/tipo-processo/:id',
    component: TipoProcessoDataPage,
    title: 'Tipo Processo',
    role: Roles.tipo_processo_update.name
  }
];

export default tipoProcessoRoutes;
