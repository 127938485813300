import {
  Alert,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { openPdf } from 'iss-common/utils/FileUtils';
import React, { useState } from 'react';

import CadastroGeralService from '../../service/CadastroGeralService';
import RelatoriosService from '../../service/RelatoriosService';
import { CadastroGeral } from '../../types/CadastroGeral';
import { FiltroRelatorioDocumentosSolicitadosEntreguesDTO } from '../../types/FiltroRelatorioDocumentosSolicitadosEntreguesDTO';

const validationSchema = Yup.object().shape({
  dataInicio: Yup.date()
    .label('Data Inicial')
    .required(),
  dataFim: Yup.date()
    .label('Data Final')
    .required()
    .test(
      'periodoFinalMaiorQueInicial',
      'A data final não pode ser menor que a data inicial',
      function(dataFim: FiltroRelatorioDocumentosSolicitadosEntreguesDTO) {
        const { dataInicio } = this.parent;
        return dataInicio <= dataFim;
      }
    )
});

export const RelatorioDocumentosSolicitadosEntregues: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onFilter = async (
    values: FiltroRelatorioDocumentosSolicitadosEntreguesDTO
  ) => {
    setLoading(true);
    await RelatoriosService.gerarRelatorioDocumentosSolicitadosEntregues(values)
      .then((response: any) => {
        openPdf(response.data);
      })
      .catch((error: any) => {
        Alert.info({
          title: 'Nenhum registro encontrado para gerar o relatório.'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Col md={12}>
        <Row>
          <Formik<FiltroRelatorioDocumentosSolicitadosEntreguesDTO>
            enableReinitialize
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={onFilter}
            render={(
              formProps: FormikProps<
                FiltroRelatorioDocumentosSolicitadosEntreguesDTO
              >
            ) => (
              <>
                <Row>
                  <FormikInputDate
                    label="Data Início"
                    name="dataInicio"
                    size={4}
                  />
                  <FormikInputDate label="Data Final" name="dataFim" size={4} />
                  <FormikAutocomplete<CadastroGeral>
                    data-test-id={`auto-complete-relatorio-doc-solicitado-entregue`}
                    name="reu"
                    label={`Réu`}
                    onSearch={CadastroGeralService.autoComplete}
                    getOptionLabel={value =>
                      value.pessoa
                        ? `${value.pessoa?.cnpjCpf || ''} - 
                ${value.pessoa?.nome || ''} - 
                ${value.tipoCadastro || ''} - 
                ${value.cadastroGeral || ''}`
                        : ''
                    }
                    size={8}
                  />
                </Row>

                <div className="form-group">
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={formProps.submitForm}
                    type="submit"
                  >
                    <i className="fa fa-print" />
                    Imprimir
                  </Button>
                </div>
              </>
            )}
          />
        </Row>
      </Col>
    </Container>
  );
};
