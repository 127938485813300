import { Icon, useInterval } from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import TarefaService from '../service/TarefaService';

type Props = {};

const JobButton: React.FC<Props> = () => {
  const [hasRunningExecution, setRunningExecution] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    TarefaService.hasRunningExecution().then(result =>
      setRunningExecution(result.data)
    );
  }, []);

  useInterval(() => {
    TarefaService.hasRunningExecution().then(result =>
      setRunningExecution(result.data)
    );
  }, 10000);

  return (
    <li title="Tarefas" onClick={() => history.push('/tarefas')}>
      <Icon icon="cogs" className={'icon'} />
      {hasRunningExecution ? (
        <span className="bubble-notification warning loading"></span>
      ) : null}
      <small className="hidden-xs">Tarefas</small>
    </li>
  );
};

export default JobButton;
