import {
  Alert,
  DisplayDataItem,
  EditableInputNumber,
  FormattedCurrency
} from '@elotech/components';
import React, { useState } from 'react';
import styled from 'styled-components';

const ColorOverride = styled.div<{ color: string }>`
  & .dd-content,
  .dd-content *,
  .dd-title,
  .td-content * {
    color: ${({ color }) => color};
  }
`;

type ValueProps = {
  title: string;
  value: number;
  color?: string;
  onChange?(value: number): void;
  decimal?: boolean;
};

export const Value: React.FC<ValueProps> = ({
  title,
  value,
  color,
  onChange,
  decimal = true
}) => {
  const [updating, setUpdating] = useState(false);
  const [internalValue, setInternalValue] = useState<number>(value);

  if (!color) {
    color = 'black';
  }

  const confirmChange = async (value: number) => {
    if (!onChange) return;

    const oldIntervalValue = internalValue;

    try {
      setInternalValue(value);
      setUpdating(true);
      await onChange(value);
    } catch (error) {
      setInternalValue(oldIntervalValue);
      Alert.error({ title: 'Erro ao salvar o valor' }, error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <ColorOverride color={color!}>
      <DisplayDataItem title={title} md={2}>
        {!decimal ? (
          `${internalValue < 10 ? '0' : ''}${internalValue}`
        ) : onChange && !updating ? (
          <EditableInputNumber
            name="edt"
            initialValue={(internalValue || 0) as number}
            onConfirmChange={confirmChange}
          />
        ) : (
          <>
            <FormattedCurrency value={internalValue || 0} hideSymbol={true} />
          </>
        )}
      </DisplayDataItem>
    </ColorOverride>
  );
};
