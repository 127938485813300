import { ActionButton, Field, ListPage } from '@elotech/components';
import { History } from 'history';
import React from 'react';

import ArtigoService from '../../service/ArtigoService';
import { ArtigoDTO } from '../../types/ArtigoDTO';

const fields: Field[] = [
  {
    label: 'Artigo',
    name: 'titulo',
    type: 'STRING'
  },
  {
    label: 'Lei',
    name: 'lei.numero',
    type: 'NUMBER'
  },
  {
    label: 'Ano da Lei',
    name: 'lei.ano',
    type: 'NUMBER'
  },
  {
    label: 'Descrição da Lei',
    name: 'lei.descricao',
    type: 'STRING'
  },
  {
    label: 'Natureza',
    name: 'lei.tipoLei.descricao',
    type: 'STRING'
  }
];

type Props = {
  history: History;
};
const ArtigoGuiaRecolhimentoListPage: React.FC<Props> = props => {
  const { history } = props;

  return (
    <ListPage<ArtigoDTO>
      title="Artigo x Guia de Recolhimento"
      icon="list-alt"
      baseUrl="/artigo-guia-recolhimento"
      searchFields={fields}
      onSearch={(search, page) => {
        return ArtigoService.buscaDto(search, page).then(response => {
          return response;
        });
      }}
      history={history}
      getId={item => item.id}
      columns={[
        ['Código', (item: ArtigoDTO) => item.id],
        ['Artigo', (item: ArtigoDTO) => item.titulo],
        ['Lei', (item: ArtigoDTO) => item.numero + '/' + item.ano],
        ['Natureza', (item: ArtigoDTO) => item.natureza]
      ]}
      hideButtons={['new', 'edit', 'view', 'delete']}
      customRowActions={item => [
        <ActionButton
          icon="list-alt"
          label="Guia de Recolhimento"
          path={'/artigo-guia-recolhimento/' + item.id}
        />
      ]}
    />
  );
};

export default ArtigoGuiaRecolhimentoListPage;
