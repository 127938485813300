import { FormikSelect } from '@elotech/components';
import React from 'react';

type Props = {};

export type TipoDeLaudo = {
  id: any;
  descricao: string;
};

const TIPOS_DE_LAUDO: TipoDeLaudo[] = [
  { id: 'AM', descricao: 'AMBIENTAL' },
  { id: 'BO', descricao: 'BOMBEIRO' },
  { id: 'VS', descricao: 'VIGILÂNCIA SANITÁRIA' },
  { id: 'TR', descricao: 'TRANSPORTE' },
  { id: 'PO', descricao: 'POSTURA' },
  { id: 'IC', descricao: 'INSCRIÇÃO CADASTRAL' }
];

const ConfiguracoesAlvara: React.FC<Props> = () => {
  return (
    <>
      <FormikSelect
        label="Tipo de Laudo"
        name="tipoLaudo"
        size={12}
        options={TIPOS_DE_LAUDO}
        getOptionLabel={option => option.descricao}
        getOptionValue={option => option.id}
        fast={false}
      />
    </>
  );
};

export { ConfiguracoesAlvara };
