import {
  Button,
  Col,
  FormikAutocomplete,
  FormikInputInteger,
  Row,
  Yup
} from '@elotech/components';
import { Formik } from 'formik';
import React from 'react';

import GuiaRecolhimentoReceitaService from '../../service/GuiaRecolhimentoReceitaService';
import GuiaRecolhimentoService from '../../service/GuiaRecolhimentoService';
import { ArtigoGuiaRecolhimento } from '../../types/ArtigoGuiaRecolhimento';
import { ArtigoGuiaRecolhimentoFormRequest } from '../../types/ArtigoGuiaRecolhimentoFormRequest';
import { ReceitaVo } from '../../types/ReceitaVo';

const initialValues = {} as ArtigoGuiaRecolhimentoFormRequest;

const validationSchema = Yup.object().shape({
  exercicio: Yup.number()
    .required()
    .label('Exercício')
    .min(1900),
  guiaRecolhimento: Yup.object()
    .required()
    .label('Guia Recolhimento'),
  receita: Yup.object()
    .required()
    .label('Receita')
});

type Props = {
  onSave(artigoGuia: ArtigoGuiaRecolhimento): void;
  onCancel(): void;
};

const GuiaRecolhimentoForm: React.FC<Props> = (props: Props) => {
  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  };

  const formataTexto = (texto: string) => {
    if (isNumeric(texto)) {
      return `(descricao=='*${texto}*' or guiaRecolhimento ==${parseInt(
        texto
      )})`;
    } else {
      return `descricao=='*${texto}*'`;
    }
  };

  return (
    <Formik<ArtigoGuiaRecolhimentoFormRequest>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={value => {
        const valorCerto: ArtigoGuiaRecolhimento = {
          ...value,
          receita: value.receita.receita
        };
        props.onSave(valorCerto);
      }}
      validationSchema={validationSchema}
      render={formProps => (
        <React.Fragment>
          <Row>
            <FormikInputInteger
              name="exercicio"
              label="Exercício"
              size={2}
              onChange={event => {
                formProps.setFieldValue('exercicio', event.target.value);
                if (formProps.values.guiaRecolhimento) {
                  formProps.setFieldValue('guiaRecolhimento', undefined);
                }
                if (formProps.values.receita) {
                  formProps.setFieldValue('receita', undefined);
                }
              }}
            />

            <FormikAutocomplete
              name="guiaRecolhimento"
              label="Guia de Recolhimento"
              size={6}
              onSearch={texto => {
                return GuiaRecolhimentoService.load(
                  `exercicio==${formProps.values.exercicio} and ${formataTexto(
                    texto
                  )}`
                );
              }}
              fast={false}
              onItemSelected={() => {
                if (formProps.values.receita) {
                  formProps.setFieldValue('receita', undefined);
                }
              }}
              disabled={!formProps.values.exercicio}
            />

            <FormikAutocomplete<ReceitaVo>
              name="receita"
              label="Receita"
              size={4}
              onSearch={texto => {
                return GuiaRecolhimentoReceitaService.buscaGuiaReceitaVo(
                  `guiaRecolhimento.id==${formProps.values.guiaRecolhimento.id} and (receita.descricao=='*${texto}*' or receita.receita =='*${texto}*')`
                );
              }}
              getOptionLabel={value =>
                `${value.receita} - ${value.descricaoReceita}`
              }
              fast={false}
              disabled={!formProps.values.guiaRecolhimento}
            />
          </Row>

          <Row>
            <Col md={12} className="mt-xs">
              <Button
                className="inline"
                position="left"
                onClick={() => formProps.submitForm()}
              >
                Salvar
              </Button>
              <Button
                className="inline"
                color="neutral"
                onClick={() => props.onCancel()}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )}
    />
  );
};

export default GuiaRecolhimentoForm;
