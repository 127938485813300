import {
  ActionButton,
  Alert,
  Field,
  ListPage,
  Modal,
  Panel,
  formatUtils
} from '@elotech/components';
import { FormikProps } from 'formik';
import { History } from 'history';
import React from 'react';

import CadastroGeralService from '../../service/CadastroGeralService';
import { Processo } from '../../types/Processo';

const fields: Field[] = [
  {
    label: 'Cadastro',
    name: 'cadastroGeral',
    type: 'NUMBER'
  },
  {
    label: 'CPF/CNPJ',
    name: 'proprietarios.pessoa.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Proprietário',
    name: 'proprietarios.pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'Endereço',
    name: 'logradouro.descricao',
    type: 'STRING'
  },
  {
    label: 'Cep',
    name: 'cep',
    type: 'STRING'
  },
  {
    label: 'Distrito',
    name: 'distritoLocalizacao.descricao',
    type: 'STRING'
  },
  {
    label: 'Setor',
    name: 'setorLocalizacao.setor',
    type: 'STRING'
  },
  {
    label: 'Quadra',
    name: 'quadraLocalizacao.quadra',
    type: 'STRING'
  },
  {
    label: 'Lote',
    name: 'loteLocalizacao',
    type: 'STRING'
  },
  {
    label: 'Unidade',
    name: 'unidadeLocalizacao',
    type: 'STRING'
  }
];

type CadastroImobiliarioProps = {
  formProps: FormikProps<Processo>;
  historypush: Pick<History, 'push'>;
  onCloseModal(): void;
};

export const CadastroImobiliario: React.FC<CadastroImobiliarioProps> = ({
  formProps,
  onCloseModal,
  historypush
}) => (
  <>
    <div className="form-group">
      <Modal onClose={onCloseModal}>
        <Panel>
          <ListPage<any>
            title="Cadastros imobiliários"
            icon="list-alt"
            baseUrl=""
            searchFields={fields}
            onSearch={(search: string, page: any) => {
              return CadastroGeralService.mobiliariosApi(search, page)
                .then((response: any) => {
                  return response;
                })
                .catch((error: any) => {
                  Alert.error({
                    title: 'Error ao recuperar os cadastros',
                    error
                  });
                  onCloseModal();
                  return error;
                });
            }}
            history={historypush}
            getId={(item: { id: any }) => item.id}
            columns={[
              ['Cadastro', (item: any) => item.cadastroGeral],
              [
                'CPF/CNPJ',
                (item: any) =>
                  formatUtils.formatCpfCnpj(
                    item.proprietarioPrincipal?.pessoa?.cnpjCpf
                  )
              ],
              [
                'Proprietário',
                (item: any) => item.proprietarioPrincipal?.pessoa?.nome
              ],
              ['Endereço', (item: any) => item.enderecoFormatado],
              ['Distrito', (item: any) => item.distritoLocalizacao?.descricao],
              ['Setor', (item: any) => item.setorLocalizacao?.setor],
              ['Quadra', (item: any) => item.quadraLocalizacao?.quadra],
              ['Lote', (item: any) => item.loteLocalizacao],
              ['Unidade', (item: any) => item.unidadeLocalizacao]
            ]}
            hideButtons={['new', 'edit', 'view', 'delete']}
            customRowActions={(item: {
              tipoCadastro: string;
              cadastroGeral: string;
            }) => [
              <ActionButton
                icon="check"
                label="Selecionar"
                onClick={() => {
                  CadastroGeralService.findByTipoAndCadastro(
                    item.tipoCadastro,
                    item.cadastroGeral
                  ).then((result: any) => {
                    onCloseModal();
                    formProps.setFieldValue('reuExterno', result.data);
                  });
                }}
              />
            ]}
          />
        </Panel>
      </Modal>
    </div>
  </>
);
