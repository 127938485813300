import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import {
  TarefaAssincrona,
  TarefaAssincronaExecucao
} from '../types/TarefaAssincrona';

const PATH_TAREFA_ASSINCRONA = `${CONTEXT_PATH}/tarefa-assincrona`;

export const searchTarefas = (
  search: string,
  pagination?: PageRequest
): AxiosPromise<PagedResponse<TarefaAssincrona>> =>
  axios.get(PATH_TAREFA_ASSINCRONA, {
    params: {
      search,
      ...pagination
    }
  });

export const findHistoricoExecucoesByTarefa = (
  tarefa: number
): AxiosPromise<TarefaAssincronaExecucao[]> =>
  axios.get(`${PATH_TAREFA_ASSINCRONA}/${tarefa}/historico`);

export const retry = (id: number): AxiosPromise<void> =>
  axios.post(`${PATH_TAREFA_ASSINCRONA}/${id}/retry`);

export default {
  searchTarefas,
  findHistoricoExecucoesByTarefa,
  retry
};
