import { EnumValue } from '../types/EnumValue';

export const situacaoAtoCodigo = {
  TODOS: 'T',
  ABERTO: 'A',
  FECHADO: 'F'
};

export const situacaoAto: EnumValue[] = [
  {
    codigo: situacaoAtoCodigo.TODOS,
    descricao: 'Todos'
  },
  {
    codigo: situacaoAtoCodigo.ABERTO,
    descricao: 'Aberto'
  },
  {
    codigo: situacaoAtoCodigo.FECHADO,
    descricao: 'Fechado'
  }
];
