import queryString from 'querystring';

import {
  BasicInput,
  Chip,
  DataPage,
  FAB,
  FormikAutocomplete,
  Row,
  Yup
} from '@elotech/components';
import { History } from 'history';
import React, { useState } from 'react';
import { match } from 'react-router';

import * as SituacaoSolicitacaoProcesso from '../../enum/GeracaoSolicitacaoProcesso';
import CadastroGeralService from '../../service/CadastroGeralService';
import SolicitacaoProcessoService from '../../service/GeracaoProcessoService';
import TipoProcessoService from '../../service/TipoProcessoService';
import UsuarioService from '../../service/UsuarioService';
import { CadastroGeral } from '../../types/CadastroGeral';
import { GeracaoProcesso } from '../../types/GeracaoProcesso';
import { TipoProcesso } from '../../types/TipoProcesso';
import { Usuario } from '../../types/Usuario';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'push' | 'replace'>;
  location: Location;
};

const initialValues: GeracaoProcesso = {
  id: 0,
  situacao: 'NOVA'
};

const validationSchema = Yup.object().shape({
  tipoProcesso: Yup.object()
    .label('Tipo do Processo')
    .required(),
  reu: Yup.object()
    .label('Réu')
    .test('reu', 'Réu não encontrado', value => !value || !!value.id)
    .required()
});

export const GeracaoProcessoDataPage: React.FC<Props> = ({
  match,
  history,
  location
}) => {
  const [redirectUrl, setRedirectUrl] = useState('/geracao-processo');
  const [gerarProcesso, setGerarProcesso] = useState(false);

  const load = async (id: number | string) => {
    if (id === 'data') {
      const args = queryString.parse(location.search.substr(1));

      if (args.redirectUrl) {
        setRedirectUrl(args['redirectUrl'] as string);
      }

      return Promise.resolve({
        data: {
          ...initialValues,
          numeroProtocolo: args['numeroProtocolo'],
          tipoProcesso:
            args['tipoProcesso'] &&
            (await TipoProcessoService.findById(args['tipoProcesso'] as string))
              .data,
          reu: { pessoa: { nome: args['reu'] as string } },
          idProtocolo: args['idProtocolo']
        }
      });
    }

    return SolicitacaoProcessoService.findById(id);
  };

  const save = (values: GeracaoProcesso) => {
    if (gerarProcesso) {
      return SolicitacaoProcessoService.save(values).then(saved => {
        // Evita que um "voltar" leve ao form de
        // criação de solicitação novamente
        history.replace(`/geracao-processo/${saved.data.id}`);

        history.push(`/processo/geracao-processo/${saved.data.id}`);
        setGerarProcesso(false);

        return undefined; //Evita que o DataPage redirecioname para a listagem
      });
    }

    return SolicitacaoProcessoService.save(values);
  };

  return (
    <>
      <DataPage<GeracaoProcesso>
        name="nova-geracao-processo"
        title="Geração de Processo"
        icon="file-invoice"
        redirectUrl={redirectUrl}
        history={history}
        match={match}
        validationSchema={() => validationSchema}
        initialValues={initialValues}
        load={id => load(id) as any}
        onSave={save}
        helpBaseUrlSistema="oxy-fiscalizacao"
        render={({ values }) => (
          <>
            <Row>
              <BasicInput
                name="numeroProtocolo"
                label="Número do Protocolo"
                size={3}
              />
              <FormikAutocomplete<TipoProcesso>
                name="tipoProcesso"
                label="Tipo do Processo"
                onSearch={TipoProcessoService.search}
                getOptionLabel={option => option.descricao!}
                getOptionValue={option => option.id}
                size={3}
                fast={false}
              />
            </Row>
            <Row>
              <BasicInput
                name="situacao"
                label="Situação"
                render={props => (
                  <Chip
                    value={SituacaoSolicitacaoProcesso.getDescricaoGeracao(
                      props.field.value
                    )}
                  />
                )}
              />
            </Row>
            <Row>
              <FormikAutocomplete<CadastroGeral>
                data-test-id={`reu`}
                name={'reu'}
                label={'Réu'}
                onSearch={CadastroGeralService.autoComplete}
                getOptionLabel={value =>
                  value.pessoa
                    ? `${value.pessoa?.cnpjCpf || ''} - ${value.pessoa?.nome ||
                        ''}`
                    : ''
                }
                size={6}
              />
            </Row>
            <Row>
              <FormikAutocomplete<Usuario>
                data-test-id={`fiscalResponsavel`}
                name={'fiscalResponsavel'}
                label={'Fiscal Responsável'}
                onSearch={UsuarioService.usuarioAutoComplete}
                getOptionLabel={value => `${value.nome}`}
                getOptionValue={value => value.id}
                size={6}
              />
            </Row>
          </>
        )}
        customActions={formProps =>
          !formProps.values.processo
            ? [
                <FAB
                  icon="file-import"
                  iconColor="white"
                  title="Gerar Processo"
                  onClick={() => {
                    setGerarProcesso(true);
                    formProps.submitForm();
                  }}
                />
              ]
            : []
        }
      />
    </>
  );
};
