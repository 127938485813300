import { FormikAutocomplete, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../JsonikForm';
import { StringListOptionsPropsEditor } from '../propseditor/StringListOptionsPropsEditor';

const AutocompleteField: React.FC<JsonikFieldProps> = props => (
  <>
    <FormikAutocomplete
      {...props}
      onSearch={(search: string) =>
        Promise.resolve({
          data: {
            content: props.props.options.filter((option: any) => {
              const uSearch = search.toUpperCase();
              const uValue = (option || '').toUpperCase();

              return uValue.search(uSearch) !== -1;
            })
          }
        } as any)
      }
      getOptionLabel={(option: any) => option}
      getOptionValue={(option: any) => option}
    />
  </>
);

export const AutocompleteFieldMap: JsonikFieldMap = {
  type: 'autocomplete',
  component: AutocompleteField,
  friendlyName: 'Busca',
  description: 'Permite uma busca em uma lista pré-definida',
  yupType: Yup.mixed,
  getDefaultProps: () => ({
    options: ['Opção 1', 'Opção 2']
  }),
  editor: StringListOptionsPropsEditor
};
