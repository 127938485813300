import { ErrorPage } from '@elotech/components';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import * as EntidadeOperations from './state/entidade/operations';

type Props = {
  children: ReactNode;
  loadEntidadePrincipal: any;
};

type State = {
  loadedEntidade: boolean;
  error?: any;
};

export class EntidadeProvider extends React.Component<Props, State> {
  state: State = {
    loadedEntidade: false,
    error: undefined
  };

  componentDidMount() {
    this.props
      .loadEntidadePrincipal()
      .then(() => this.setState({ loadedEntidade: true }))
      .catch((error: any) => this.setState({ error }));
  }

  render() {
    const { children } = this.props;
    const { loadedEntidade, error } = this.state;

    if (error) {
      const response = error.response || {
        data: { message: '' },
        status: 500
      };

      const { data } = response;
      const message = 'message' in data ? data.message : data;
      return <ErrorPage status={response.status} message={message} />;
    }

    if (!loadedEntidade) {
      return null;
    }

    return <React.Fragment> {children} </React.Fragment>;
  }
}

export default connect(null, {
  loadEntidadePrincipal: EntidadeOperations.getEntidadePrincipal
})(EntidadeProvider);
