import { Sidebar, useAuth } from '@elotech/components';
import imgModule from '@elotech/components/assets/img/modules/arrecadacao-portalcontribuinte.png';
import imgModuleMain from '@elotech/components/assets/img/modules/arrecadacao-small.png';
import { Modulos } from 'iss-common/enums';
import React, { useEffect, useState } from 'react';

import { loadParametros } from '../service/Parametros';
import { Roles } from '../utils/Roles';
import { BadgeValuesConsumer } from './BadgeValuesProvider';

const AppSidebar = ({ showSidebar }) => {
  const [
    habilitaSolicitacaoProcesso,
    setHabilitaSolicitacaoProcesso
  ] = useState('S');

  const { hasRole } = useAuth();

  useEffect(() => {
    loadParametros(Modulos.ISS, 'HABILITASOLICITACAOPROCESSO').then(
      response => {
        setHabilitaSolicitacaoProcesso({
          habilitaSolicitacaoProcesso: response.data
        });
      }
    );
  }, []);

  return (
    <BadgeValuesConsumer>
      {({ _, getBadgeValue }) =>
        showSidebar ? (
          <Sidebar
            title="Fiscalização"
            imgModuleMain={imgModuleMain}
            imgModule={imgModule}
            showBadge={true}
          >
            <Sidebar.Item title="Início" to="/" icon="fa fa-home" />

            <Sidebar.Group title="Configuração" icon="fa fa-cogs">
              <Sidebar.Item
                title="Tipo de Ato"
                to="/configuracao/tipo-ato"
                icon="fa fa-file-signature"
              />
              <Sidebar.Item
                title="Ato - Emails enviados"
                to="/configuracao/ato-emails-enviados"
                icon="fa fa-envelope-open"
              />
              <Sidebar.Item
                title="Tipo de Processo"
                to="/configuracao/tipo-processo"
                icon="fa fa-file-signature"
              />
              <Sidebar.Item
                title="Artigo x Guia de Recolhimento"
                to="/artigo-guia-recolhimento"
                icon="fa fa-list-alt"
              />
              <Sidebar.Item
                title="Cadastro de Artigos"
                to="/configuracao/cadastro-artigo"
                icon="fa fa-home"
              />
              <Sidebar.Item
                title="Tipo de Local Interno"
                to="/configuracao/tipo-local-interno"
                icon="fa fa-clipboard-check"
              />
              <Sidebar.Item
                title="Configuração de Email"
                to="/configuracao/email"
                icon="fa fa-envelope"
              />
            </Sidebar.Group>

            <Sidebar.Item
              title="Divergências"
              icon="fa fa-search"
              to="/divergencia"
            />

            <Sidebar.Item
              title="Gerador de Processos"
              to="/geracao-processo/"
              icon="fa fa-file-invoice"
              visible={habilitaSolicitacaoProcesso === 'S'}
            />

            <Sidebar.Item
              title="Processo"
              to="/processo/"
              icon="fa fa-layer-group"
            />

            <Sidebar.Group
              title="DES - Instituições Financeiras"
              icon="fa fa-university"
            >
              <Sidebar.Item
                title="Cruzamento de informações"
                to="/desif/cruzamento"
                icon="fa fa-file-alt"
                visible={hasRole(Roles.fis_desif_cruzamento_informacoes.name)}
              />
            </Sidebar.Group>
            <Sidebar.Item
              title="Manual do usuário"
              to="/manual-usuario"
              icon="fa fa-question"
            />
            <Sidebar.Group title="Relatórios" icon="fa fa-print">
              <Sidebar.Item
                title="Documentos Intimados"
                to="/relatorios/documentos-intimados"
                icon="fa fa-file-alt"
                visible={hasRole(Roles.fis_relatorio_documentos_intimados.name)}
              />
              <Sidebar.Item
                title="Gerencial da Fiscalização"
                to="/relatorios/gerencial-fiscalizacao"
                icon="fa fa-cogs"
                visible={hasRole(
                  Roles.fis_relatorio_gerencial_fiscalizacao.name
                )}
              />
              <Sidebar.Item
                title="Procedimentos Fiscais por Período"
                to="/relatorios/procedimentos-fiscais-periodo"
                icon="fa fa-file-signature"
                visible={hasRole(
                  Roles.fis_relatorio_procedimentos_fiscais_periodo.name
                )}
              />
              <Sidebar.Item
                title="Documentos Solicitados/Entregues"
                to="/relatorios/documentos-solicitados-entregues"
                icon="fa fa-file-signature"
                visible={hasRole(Roles.fis_relatorio_documentos_intimados.name)}
              />
              <Sidebar.Item
                title="Gerencial de Ordem de Serviço"
                to="/relatorios/gerencial-ordem-servico"
                icon="fa fa-file-contract"
                visible={hasRole(
                  Roles.fis_relatorio_gerencial_ordem_servico.name
                )}
              />
            </Sidebar.Group>

            <Sidebar.Item
              title="Gerenciador de Tarefas"
              to="/tarefas"
              icon="fa fa-cogs"
              visible={hasRole(Roles.fis_gerenciador_tarefas_read.name)}
            />
          </Sidebar>
        ) : null
      }
    </BadgeValuesConsumer>
  );
};

export default AppSidebar;
