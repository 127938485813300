import {
  BasicInput,
  Button,
  Col,
  FormikCheckBox,
  FormikSelect,
  Row,
  Yup
} from '@elotech/components';
import { Formik } from 'formik';
import React from 'react';

import { TipoAtoFormulario } from '../../../types/TipoAtoFormulario';

const MIMES = [
  { label: '.jpeg', value: '.jpeg' },
  { label: '.doc', value: '.doc' },
  { label: '.xls', value: '.xls' },
  { label: '.pdf', value: '.pdf' },
  { label: '.docx', value: '.docx' },
  { label: '.xlsx', value: '.xlsx' }
];

const SCHEMA_VALIDATION = Yup.object().shape({
  descricao: Yup.string()
    .required()
    .min(3, 'Nome do Anexo deve ter no minimo 3 caracteres ')
    .label('Nome do Anexo'),
  mimeType: Yup.string()
    .required()
    .label('Extensão')
});

type Props = {
  tipoAtoFormulario: TipoAtoFormulario;
  onSubmit: (tipoAtoFormulario: TipoAtoFormulario) => void;
};

export const FormularioAnexo: React.FC<Props> = ({
  onSubmit,
  tipoAtoFormulario
}) => {
  return (
    <>
      <Formik<TipoAtoFormulario>
        initialValues={
          tipoAtoFormulario || {
            ativo: true,
            obrigatorio: true,
            tipo: 'UPLOAD',
            mimeType: ''
          }
        }
        validationSchema={SCHEMA_VALIDATION}
        enableReinitialize={true}
        onSubmit={(value, action) => {
          onSubmit(value);
          action.resetForm();
        }}
        render={({ errors, submitForm, resetForm }) => (
          <Row>
            <BasicInput
              label="Nome do Anexo"
              id="descricaoAnexo"
              name="descricao"
              size={5}
            />
            <FormikSelect
              label="Extensão"
              name="mimeType"
              size={2}
              options={MIMES}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
            />
            <FormikCheckBox
              label="Ativo"
              name="ativo"
              size={1}
              noLabel={false}
              id="anexoStatus"
            />
            <FormikCheckBox
              size={2}
              label="Obrigatório"
              name="obrigatorio"
              noLabel={false}
              id="obrigatorioAnexo"
            />
            <Col md={2} className="form-group">
              <label className="label" />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={submitForm} id="salvar-anexo">
                  Salvar
                </Button>
                <Button
                  id="cancelar-anexo"
                  color="neutral"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </Col>
          </Row>
        )}
      />
    </>
  );
};
