import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { ApuracaoAutoInfracao } from '../types/ApuracaoAutoInfracao';
import { ArtigoFormValue } from '../types/ArtigoFormValue';
import { ArtigoVo } from '../types/ArtigoVo';
import { AutoInfracaoArtigo } from '../types/AutoInfracaoArtigo';

const crudService = CrudService<AutoInfracaoArtigo>(
  '/auto-infracacao-artigo',
  undefined
);

const buscarArtigosAutoInfracao = (id: number): AxiosPromise<ArtigoFormValue> =>
  axios.get(
    `${CONTEXT_PATH}/auto-infracacao-artigo/artigos-por-auto-infracao/${id}`
  );

const addAutoInfracaoArtigo = (
  form: ArtigoFormValue
): AxiosPromise<ArtigoFormValue> =>
  axios.post(
    `${CONTEXT_PATH}/auto-infracacao-artigo/adicionar-auto-infracao-artigo/`,
    form
  );

const deletarByIdAndLoad = (
  id?: number,
  idAutoArtigo?: number
): AxiosPromise<ArtigoFormValue> =>
  axios.delete(
    `${CONTEXT_PATH}/auto-infracacao-artigo/remover-artigo-auto-infracao/${id}/${idAutoArtigo}`
  );

const atualizarFatorMultiplicadorArtigoAutoInfracao = (
  id?: number,
  idAutoArtigo?: number,
  fatorMultiplicador?: number
): AxiosPromise<ArtigoFormValue> =>
  axios.put(
    `${CONTEXT_PATH}/auto-infracacao-artigo/atualizar-artigo-auto-infracao/${id}/${idAutoArtigo}/${fatorMultiplicador}`
  );

const findAllArtigos = (searchParams: string): AxiosPromise<ArtigoVo[]> =>
  axios.get(`${CONTEXT_PATH}/artigo/buscarArtigos`, {
    params: {
      search: FilterUtils.buildNameFilter('id', 'titulo')(searchParams),
      fields: 'titulo'
    }
  });

const loadAllApuracoesByIdInfracao = (
  id: string
): AxiosPromise<ApuracaoAutoInfracao[]> =>
  axios.get(
    `${CONTEXT_PATH}/auto-infracacao-artigo/atualizar-artigo-auto-infracao/${id}/apuracoes`
  );

const atualizarApuracoes = (
  atualizarApuracaoVo: any
): AxiosPromise<ArtigoFormValue> =>
  axios.post(
    `${CONTEXT_PATH}/auto-infracacao-artigo/atualizar-artigo-auto-infracao/apuracoes/atualizar`,
    atualizarApuracaoVo
  );

export default {
  loadAllApuracoesByIdInfracao,
  buscarArtigosAutoInfracao,
  ...crudService,
  addAutoInfracaoArtigo,
  deletarByIdAndLoad,
  atualizarFatorMultiplicadorArtigoAutoInfracao,
  findAllArtigos,
  atualizarApuracoes
};
