import { EnumValue } from '../types/EnumValue';

export const tipoReceitas: EnumValue[] = [
  { codigo: 'R', descricao: 'Receitas de prestação de serviços' },
  { codigo: 'D', descricao: 'Receitas de vendas de mercadorias' },
  { codigo: 'O', descricao: 'Outros tipos de receitas' }
];

export const getDescricaoTipoOutrasReceitas = (codigo: string) =>
  (tipoReceitas.find(tipo => tipo.codigo === codigo) || { descricao: '' })
    .descricao;

export const getDescricaoTipoReceita = (tipoReceita: string) => {
  const found = tipoReceitas.find(tipo => tipo.codigo === tipoReceita);
  return found ? found.descricao : '';
};
