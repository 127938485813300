import {
  Alert,
  BasicInput,
  Button,
  Col,
  DataPage,
  FormikCheckBox,
  FormikInputNumber,
  Row,
  Tabs,
  Yup
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { match } from 'react-router-dom';

import TemplateCampoEmailService from '../../../service/TemplateCampoEmailService';
import TipoAtoService from '../../../service/TipoAtoService';
import { TemplateEmailCampo } from '../../../types/TemplateEmailCampo';
import { TipoAto } from '../../../types/TipoAto';
import { TipoAtoConfiguracao } from '../../../types/TipoAtoConfiguracao';
import { TipoAtoFormulario } from '../../../types/TipoAtoFormulario';
import { Configuracao } from './Configuracao';
import { reactQuillFormats, reactQuillModules } from './ConfiguracaoReactQuill';
import { FormularioAnexo } from './FormularioAnexo';
import { FormularioRelatorio } from './FormularioRelatorio';
import { FormularioTable } from './FormularioTable';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const fileToByteArray = (file: any) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray: any[] = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt: any) => {
        if (evt.target.readyState === FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            array = new Uint8Array(arrayBuffer);
          for (let byte of array) {
            fileByteArray.push(byte);
          }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
};

const SCHEMA_VALIDATION = Yup.object().shape({
  prazo: Yup.string()
    .required()
    .label('Prazo'),
  descricao: Yup.string()
    .required()
    .min(3, 'Descrição deve ter no minimo 3 caracteres ')
    .label('Descrição'),
  formularios: Yup.array().test(
    '',
    'formularios',
    (formularios: TipoAtoFormulario[]) => {
      return (
        formularios?.filter(formulario => formulario.tipo === 'DINAMICO')
          .length > 0
      );
    }
  )
});

export const TipoAtoDataPage: React.FC<Props> = ({ match, history }) => {
  const [tipoAtoFormulario, setTipoAtoFormulario] = useState<
    TipoAtoFormulario
  >();

  const [index, setIndex] = useState<any>(undefined);
  const [showFormRelatorio, setShowFormRelatorio] = useState<boolean>(false);
  const [emailCampo, setEmailCampo] = useState('');
  const [templateEmailCampos, setTemplateEmailCampos] = useState<
    TemplateEmailCampo[]
  >([]);
  const reactQuillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    loadTemplateCamposEmail();
  }, [match.params.id]);

  const onLoad = async (id: number) => {
    const result = await TipoAtoService.findById(id);
    processResultLoad(result.data);
    return result;
  };

  const processResultLoad = (tipoAto: TipoAto) => {
    for (let formulario of tipoAto.formularios!) {
      if (formulario.fileName) {
        formulario.template = new File([''], formulario.fileName, {
          type: 'image/png'
        });
      }
    }
    tipoAto.formularios = tipoAto.formularios || [];
    tipoAto.configAux = {};
    tipoAto.configuracoes?.forEach(item => {
      tipoAto.configAux[item.acao] = true;
    });
  };

  const onSave = async (entity: TipoAto) => {
    for (let formulario of entity.formularios!) {
      if (formulario.template) {
        formulario.fileName = formulario.template.name;
        formulario.template = await fileToByteArray(formulario.template);
      }
    }

    entity.configuracoes = Object.entries(entity.configAux)
      .filter(item => item[1])
      .map(item => {
        return { acao: item[0] };
      }) as TipoAtoConfiguracao[];
    return TipoAtoService.save(entity);
  };

  const loadTemplateCamposEmail = () => {
    return TemplateCampoEmailService.loadTemplateCampoEmail()
      .then((response: AxiosResponse<TemplateEmailCampo[]>) => {
        setTemplateEmailCampos(response.data);
      })
      .catch((error: any) => {
        Alert.error(
          { title: 'Não foi possível carregar os campos do template' },
          error
        );
      });
  };

  const onAddEmailCampo = () => {
    if (reactQuillRef && reactQuillRef.current) {
      var range = reactQuillRef.current!.getEditor()!.getSelection();
      let position = range ? range.index : 0;
      reactQuillRef.current!.getEditor()!.insertText(position, emailCampo);
    }
  };

  return (
    <DataPage<TipoAto>
      load={onLoad}
      match={match}
      name="tipoato"
      title="Tipo de Ato"
      history={history}
      redirectUrl="/configuracao/tipo-ato"
      onSave={onSave}
      icon="university"
      validationSchema={SCHEMA_VALIDATION}
      initialValues={{ ativo: true, formularios: [], configAux: {} }}
      render={({ values, setFieldValue, errors }) => (
        <>
          <Row>
            <BasicInput label="Código" disabled={true} name="id" size={1} />
            <FormikInputNumber
              label="Prazo"
              name="prazo"
              decimalScale={0}
              suffixLabel="Dias"
              size={2}
            />
            <FormikCheckBox
              label="Ativo"
              name="ativo"
              size={2}
              noLabel={false}
            />
            <BasicInput label="Descrição" name="descricao" size={12} />
          </Row>
          <div className="mt-xs">
            <Tabs>
              <Tabs.Tab header="Configuração">
                <Configuracao tipoAto={values} />
              </Tabs.Tab>
              <Tabs.Tab
                header="Relatórios"
                badgeCount={errors.formularios ? 1 : 0}
              >
                {showFormRelatorio ? (
                  <FormularioRelatorio
                    setShowFormRelatorio={setShowFormRelatorio}
                    onSubmit={(item: any) => {
                      if (index !== undefined) {
                        let formulariosDinamico = values.formularios?.filter(
                          item => item.tipo === 'DINAMICO'
                        );

                        const formularioIndex = formulariosDinamico!.findIndex(
                          formulario => formulario.descricao === item.descricao
                        );

                        if (formularioIndex !== index) {
                          Alert.warning({
                            title: `Anexo com a descrição ${item.descricao} já incluido na lista`
                          });
                          return;
                        }

                        formulariosDinamico![index] = item;

                        let formulariosUpload =
                          values.formularios?.filter(
                            item => item.tipo === 'UPLOAD'
                          ) || [];

                        const formularios = [
                          ...formulariosUpload,
                          ...formulariosDinamico!
                        ];

                        setFieldValue('formularios', formularios);
                      } else {
                        const formularioVerificacao = values.formularios?.find(
                          formulario =>
                            formulario.descricao === item.descricao &&
                            formulario.tipo === 'DINAMICO'
                        );

                        if (formularioVerificacao !== undefined) {
                          Alert.warning({
                            title: `Anexo com a descrição ${item.descricao} já incluido na lista`
                          });
                          return;
                        }
                        setFieldValue('formularios', [
                          ...values.formularios!,
                          { ...item }
                        ]);
                      }
                      setIndex(undefined);
                      setTipoAtoFormulario(undefined);
                      setShowFormRelatorio(false);
                    }}
                    tipoAtoFormulario={tipoAtoFormulario!}
                  />
                ) : (
                  undefined
                )}

                {values.formularios!.filter(
                  formulario => formulario.tipo === 'DINAMICO'
                )!.length === 0 && !showFormRelatorio ? (
                  <div
                    className="not-found module-color"
                    style={{ maxWidth: '500px' }}
                  >
                    <h1>
                      <em className="fa fa-file-alt module-color" />
                    </h1>
                    <h2>Nenhum relatório foi adicionado</h2>
                    <Button
                      onClick={() => {
                        setShowFormRelatorio(true);
                      }}
                      className="center"
                    >
                      Adicionar relatório
                    </Button>
                  </div>
                ) : (
                  !showFormRelatorio && (
                    <>
                      <Button
                        onClick={() => {
                          setShowFormRelatorio(true);
                          setTipoAtoFormulario(undefined);
                        }}
                        className="end"
                      >
                        Adicionar relatório
                      </Button>
                      <FormularioTable
                        tipo="DINAMICO"
                        onDelete={index => {
                          let formulariosDinamico = values.formularios?.filter(
                            item => item.tipo === 'DINAMICO'
                          );

                          formulariosDinamico = [
                            ...formulariosDinamico!.slice(0, index),
                            ...formulariosDinamico!.slice(index + 1)
                          ];

                          let formulariosUpload =
                            values.formularios?.filter(
                              item => item.tipo === 'UPLOAD'
                            ) || [];

                          const formularios = [
                            ...formulariosUpload,
                            ...formulariosDinamico
                          ];
                          setFieldValue('formularios', formularios);
                        }}
                        onEdit={index => {
                          let formulariosDinamico = values.formularios?.filter(
                            item => item.tipo === 'DINAMICO'
                          );
                          setIndex(index);
                          setTipoAtoFormulario(formulariosDinamico![index]);
                          setShowFormRelatorio(true);
                        }}
                        tipoAto={values}
                      />
                    </>
                  )
                )}
              </Tabs.Tab>
              <Tabs.Tab header="Anexos">
                <FormularioAnexo
                  onSubmit={item => {
                    if (index !== undefined) {
                      let formulariosUpload = values.formularios?.filter(
                        item => item.tipo === 'UPLOAD'
                      );

                      const formularioIndex = formulariosUpload!.findIndex(
                        formulario => formulario.descricao === item.descricao
                      );

                      if (formularioIndex !== index) {
                        Alert.warning({
                          title: `Anexo com a descrição ${item.descricao} já incluido na lista`
                        });
                        return;
                      }

                      formulariosUpload![index] = item;

                      let formulariosDinamico =
                        values.formularios?.filter(
                          item => item.tipo === 'DINAMICO'
                        ) || [];

                      const formularios = [
                        ...formulariosDinamico,
                        ...formulariosUpload!
                      ];

                      setFieldValue('formularios', formularios);
                    } else {
                      const formularioVerificacao = values.formularios?.find(
                        formulario =>
                          formulario.descricao === item.descricao &&
                          formulario.tipo === 'UPLOAD'
                      );

                      if (formularioVerificacao !== undefined) {
                        Alert.warning({
                          title: `Anexo com a descrição ${item.descricao} já incluido na lista`
                        });
                        return;
                      }

                      setFieldValue('formularios', [
                        ...values.formularios!,
                        { ...item }
                      ]);
                    }
                    setIndex(undefined);
                    setTipoAtoFormulario(undefined);
                  }}
                  tipoAtoFormulario={tipoAtoFormulario!}
                />
                <FormularioTable
                  tipo="UPLOAD"
                  onDelete={index => {
                    let formulariosUpload = values.formularios?.filter(
                      item => item.tipo === 'UPLOAD'
                    );

                    formulariosUpload = [
                      ...formulariosUpload!.slice(0, index),
                      ...formulariosUpload!.slice(index + 1)
                    ];

                    let formulariosDinamico =
                      values.formularios?.filter(
                        item => item.tipo === 'DINAMICO'
                      ) || [];

                    const formularios = [
                      ...formulariosUpload,
                      ...formulariosDinamico
                    ];
                    setFieldValue('formularios', formularios);
                  }}
                  onEdit={index => {
                    let formulariosUpload = values.formularios?.filter(
                      item => item.tipo === 'UPLOAD'
                    );
                    setIndex(index);
                    setTipoAtoFormulario(formulariosUpload![index]);
                  }}
                  tipoAto={values}
                />
              </Tabs.Tab>
              <Tabs.Tab header="Email">
                <Row>
                  <FormikCheckBox
                    name="enviaemail"
                    label="Enviar Email"
                    noLabel={true}
                  />
                  <BasicInput
                    name="templateemail"
                    label="Template"
                    render={({ field }) => (
                      <ReactQuill
                        theme={'snow'}
                        ref={reactQuillRef}
                        modules={reactQuillModules}
                        formats={reactQuillFormats}
                        value={field.value || ''}
                        onChange={(_content, _Delta, _Sources, editor) =>
                          setFieldValue('templateemail', editor.getHTML())
                        }
                      />
                    )}
                    size={12}
                  />
                </Row>
                <Row>
                  <Col sm={3}>
                    <div className="form-group">
                      <label htmlFor="emailCampos" className="label">
                        Email campos
                      </label>
                      <select
                        name="emailCampos"
                        onChange={e => setEmailCampo(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        {templateEmailCampos.map(item => (
                          <option key={item.id} value={item.campo}>
                            {item.campo}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="form-group">
                      <Button
                        className="inline mt-sm"
                        iconPosition="left"
                        onClick={onAddEmailCampo}
                        type="submit"
                      >
                        <i className="fa fa-plus" />
                        {'Adicionar'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tabs.Tab>
            </Tabs>
          </div>
        </>
      )}
    />
  );
};
