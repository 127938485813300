import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { Artigo } from '../types/Artigo';

const crudService = CrudService<Artigo>('/artigo', {
  sort: 'id'
});

const buscaDto = (searchParams: string, page?: any) =>
  Axios.get(CONTEXT_PATH + `/artigo/buscarArtigoDto`, {
    params: { search: searchParams, sort: 'id', ...page }
  });

const buscaDtoById = (id: number) => {
  return Axios.get(`${CONTEXT_PATH}/artigo/dto/${id}`);
};

const loadVo = (search: string, page?: any) =>
  Axios.get(`${CONTEXT_PATH}/artigo/buscarArtigos`, {
    params: {
      search: search || null,
      ...page
    }
  });

export default {
  ...crudService,
  buscaDto,
  buscaDtoById,
  loadVo
};
