import {
  ActionButton,
  ActionsGroup,
  Alert,
  DateUtils,
  FormattedDateTime,
  Panel,
  Table,
  TableChild
} from '@elotech/components';
import React, { useEffect, useState } from 'react';

import TarefaAssincronaStatusChip from '../../common/components/TarefaAssincronaStatusChip';
import { SituacaoTarefaAssincronaEnum } from '../../enum';
import TarefaAssincronaService from '../../service/TarefaAssincronaService';
import { TarefaAssincronaExecucao } from '../../types/TarefaAssincrona';

type Props = {
  tarefa: TarefaAssincronaExecucao;
};

const TarefaAssincronaExecucaoListPage: React.FC<Props> = ({ tarefa }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [execucoes, setExecucoes] = useState<TarefaAssincronaExecucao[]>([]);
  const [idsExecucoesComErro, setIdsExecucoesComErro] = useState<number[]>([]);

  const findHistoricoExecucoesByTarefa = (instanceId: number) => {
    setLoading(true);
    TarefaAssincronaService.findHistoricoExecucoesByTarefa(instanceId)
      .then(result => setExecucoes(result.data))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar histórico de execuções.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    findHistoricoExecucoesByTarefa(tarefa.id);
  }, [tarefa.id]);

  const onViewErrorMessage = (id: number) =>
    idsExecucoesComErro.includes(id)
      ? setIdsExecucoesComErro(
          idsExecucoesComErro.filter(idExecucao => idExecucao !== id)
        )
      : setIdsExecucoesComErro([...idsExecucoesComErro, id]);

  const renderErrorMessage = (item: TarefaAssincronaExecucao) => {
    if (!idsExecucoesComErro.includes(item.id)) {
      return;
    }
    return (
      <TableChild>
        <textarea title="Mensagem de Erro" value={item.mensagemErro} readOnly />
      </TableChild>
    );
  };

  return (
    <TableChild>
      <Panel isTable>
        <Table
          values={execucoes}
          loading={loading}
          renderInnerComponent={renderErrorMessage}
        >
          <Table.Column<TarefaAssincronaExecucao>
            header="Identificador"
            value={item => item.id}
          />
          <Table.Column<TarefaAssincronaExecucao>
            header="Início"
            value={item => <FormattedDateTime value={item.dataInicio} />}
          />
          <Table.Column<TarefaAssincronaExecucao>
            header="Fim"
            value={item => <FormattedDateTime value={item.dataFim} />}
          />
          <Table.Column<TarefaAssincronaExecucao>
            header="Duração"
            value={item => {
              return (
                item.dataInicio &&
                item.dataFim &&
                DateUtils.getDiferencaDatasFormatada(
                  item.dataInicio,
                  item.dataFim
                )
              );
            }}
          />
          <Table.Column<TarefaAssincronaExecucao>
            header="Situação"
            headerClassName="center"
            value={item => (
              <TarefaAssincronaStatusChip situacao={item.situacao} />
            )}
          />
          <Table.Column<TarefaAssincronaExecucao>
            header=""
            value={item => (
              <ActionsGroup>
                {item.situacao === SituacaoTarefaAssincronaEnum.erro && (
                  <ActionButton
                    key="executionErrorView"
                    icon="exclamation"
                    label="Visualizar Erros"
                    onClick={() => {
                      onViewErrorMessage(item.id);
                    }}
                  />
                )}
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </TableChild>
  );
};

export default TarefaAssincronaExecucaoListPage;
