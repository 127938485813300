import {
  Button,
  Checkbox,
  DataPage,
  FormattedCurrency,
  Panel,
  Table
} from '@elotech/components';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { match } from 'react-router-dom';

import AutoInfracaoService from '../../../../../service/AutoInfracaoService';
import { ApuracaoAutoInfracao } from '../../../../../types/ApuracaoAutoInfracao';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'goBack'>;
};

export const EnquadramentoLegalApuracoesComponent: React.FC<Props> = props => {
  const [selection, setSelection] = useState<ApuracaoAutoInfracao[]>([]);
  const [apuracoes, setApuracoes] = useState([] as ApuracaoAutoInfracao[]);

  const isAllSelected = () =>
    selection.length + apuracoes.length > 0 &&
    selection.length === apuracoes.length;

  const handleSelection = (item?: ApuracaoAutoInfracao) => {
    if (item) {
      if (isSelected(item)) {
        setSelection(selection.filter(id => id !== item));
      } else {
        setSelection(selection.concat(item));
      }
    } else {
      setSelection(selection.length ? [] : apuracoes.map(item => item));
    }
  };

  const isSelected = (item: ApuracaoAutoInfracao) =>
    selection.indexOf(item) !== -1;

  const atualizarApuracoes = () => {
    AutoInfracaoService.atualizarApuracoes({
      idAutoInfracaoArtigo: apuracoes[0].idAutoInfracaoArtigo,
      idApuracaoIss: selection.map(x => x.idApuracaoIss)
    }).finally(() => {
      props.history.goBack();
    });
  };

  useEffect(() => {
    if (apuracoes.length) {
      setSelection(apuracoes.filter(x => x.selecionado));
    }
  }, [apuracoes]);

  return (
    <>
      <DataPage<ApuracaoAutoInfracao[]>
        icon="university"
        match={props.match}
        load={AutoInfracaoService.loadAllApuracoesByIdInfracao}
        name="Apuracoes"
        title="Apurações"
        render={form => {
          setApuracoes(form.values);
          return apuracoes.length > 0 ? (
            <>
              <Panel isTable>
                <Table<ApuracaoAutoInfracao>
                  scrollHorizontal={true}
                  fixed
                  values={apuracoes}
                  keyExtractor={apuracao => apuracao.idApuracaoIss}
                >
                  <Table.Column<ApuracaoAutoInfracao>
                    header={
                      <Checkbox
                        id={`cb-all`}
                        checked={isAllSelected()}
                        onChange={() => handleSelection()}
                      />
                    }
                    value={item => {
                      return (
                        <Checkbox
                          id={`cb-${item.idApuracaoIss}`}
                          checked={isSelected(item)}
                          onChange={() => handleSelection(item)}
                        />
                      );
                    }}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Mês"
                    value={artigo => artigo.mesCompetencia}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Ano"
                    value={artigo => artigo.anoCompetencia}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Valor"
                    value={artigo => <FormattedCurrency value={artigo.saldo} />}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Correção"
                    value={artigo => (
                      <FormattedCurrency value={artigo.correcao} />
                    )}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Multa"
                    value={artigo => <FormattedCurrency value={artigo.multa} />}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Juros"
                    value={artigo => <FormattedCurrency value={artigo.juros} />}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Abatimento"
                    value={artigo => (
                      <FormattedCurrency value={artigo.abatimento} />
                    )}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Valor Total"
                    value={artigo => (
                      <FormattedCurrency value={artigo.saldoFinal} />
                    )}
                  />
                  <Table.Column<ApuracaoAutoInfracao>
                    header="Penalidade"
                    value={artigo => (
                      <FormattedCurrency value={artigo.valorPenalidade} />
                    )}
                  />
                </Table>
              </Panel>
              <Button onClick={atualizarApuracoes}>Continuar</Button>
            </>
          ) : (
            <div></div>
          );
        }}
      />
    </>
  );
};
