import { Container, FormikSelect, Row } from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useState } from 'react';

import { GeracaoProcessoAlvaraTable } from '../geracao-processo/geracao-processo-alvara/GeracaoProcessoAlvaraTable';
import { GeracaoProcessoProtocoloTable } from '../geracao-processo/GeracaoProcessoProtocoloTable';

type Props = {
  history: Pick<History, 'push'>;
};

export type GeracaoProcesso = {
  descricao: string;
};

const initialValue: GeracaoProcesso = {
  descricao: 'PROCESSO ALVARÁ'
};

const optionsTipoProcesso: GeracaoProcesso[] = [
  {
    descricao: 'PROCESSO ALVARÁ'
  },
  {
    descricao: 'PROCESSO PROTOCOLO'
  }
];

export const GeracaoProcessoListPage: React.FC<Props> = ({ history }) => {
  const [processo, setProcesso] = useState<GeracaoProcesso>({
    descricao: 'PROCESSO ALVARÁ'
  });

  const onSave = () => {};

  const onSelectTipoProcesso = (value: GeracaoProcesso) => {
    if (value === undefined) {
      return;
    }
    setProcesso(value);
  };

  return (
    <>
      <Container breadcrumb={false} title="Gerador de Processos">
        <Row className="mb-xs">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSave}
          >
            {(formProps: FormikProps<GeracaoProcesso>) => (
              <FormikSelect<GeracaoProcesso>
                size={4}
                name="descricao"
                label="Selecione o tipo de Processo"
                options={optionsTipoProcesso}
                getOptionLabel={value => value.descricao}
                getOptionValue={value => value.descricao}
                onSelect={onSelectTipoProcesso}
              ></FormikSelect>
            )}
          </Formik>
        </Row>
        {processo.descricao === 'PROCESSO PROTOCOLO' && (
          <GeracaoProcessoProtocoloTable
            history={history}
          ></GeracaoProcessoProtocoloTable>
        )}
        {processo.descricao === 'PROCESSO ALVARÁ' && (
          <GeracaoProcessoAlvaraTable></GeracaoProcessoAlvaraTable>
        )}
      </Container>
    </>
  );
};
