import { Card, DisplayDataGrid, SectionTitle } from '@elotech/components';
import React from 'react';

import { ApuracaoIss } from '../../../../types/ApuracaoIss';
import { Value } from './Value';

type Props = {
  apuracoesShow: ApuracaoIss[];
};
export const Totalizador: React.FC<Props> = ({ apuracoesShow }) => {
  return (
    <Card key="totalizador">
      <DisplayDataGrid border={false} column={true} className="mb-xs">
        <SectionTitle>Totalizador</SectionTitle>
        <Value
          title="Receita Bruta"
          value={apuracoesShow
            .map(x => x.receitaBruta)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Outras receitas"
          value={apuracoesShow
            .map(x => x.outrasReceitas)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Receita serviços"
          value={apuracoesShow
            .map(x => x.receitaServicos)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Receita tributável"
          value={apuracoesShow
            .map(x => x.receitaTributavel)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value title="RBT12" value={apuracoesShow.map(x => x.rbt12)[11]} />
        <Value title="RBA" value={apuracoesShow.map(x => x.rba)[11]} />
        <Value
          title="Imposto devido"
          value={apuracoesShow
            .map(x => x.impostoDevido)
            .reduce((acummulator, current) => acummulator + current)}
          color="red"
        />
        <Value
          title="Imposto recolhido"
          value={apuracoesShow
            .map(x => x.impostoRecolhido)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Imposto retido"
          value={apuracoesShow
            .map(x => x.impostoRetido)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Imposto declarado aberto"
          value={apuracoesShow
            .map(x => x.impostoDeclaradoAberto)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Saldo"
          value={apuracoesShow
            .map(x => x.saldo)
            .reduce((acummulator, current) => acummulator + current)}
          color="red"
        />
        <Value
          title="Multa"
          value={apuracoesShow
            .map(x => x.multa)
            .reduce((acummulator, current) => acummulator + current)}
          color="red"
        />
        <Value
          title="Juros"
          value={apuracoesShow
            .map(x => x.juros)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Correção"
          value={apuracoesShow
            .map(x => x.correcao)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Saldo total"
          value={apuracoesShow
            .map(x => x.saldoTotal)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Abatimento"
          value={apuracoesShow
            .map(x => x.abatimento)
            .reduce((acummulator, current) => acummulator + current)}
        />
        <Value
          title="Saldo final"
          value={apuracoesShow
            .map(x => x.saldoFinal)
            .reduce((acummulator, current) => acummulator + current)}
          color="red"
        />
      </DisplayDataGrid>
    </Card>
  );
};
