import './SetField.css';

import { BasicInput, Checkbox, Yup } from '@elotech/components';
import { FieldProps, FormikProps } from 'formik';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { JsonikFieldMap, JsonikFieldProps } from '../JsonikForm';
import { StringListOptionsPropsEditor } from '../propseditor/StringListOptionsPropsEditor';

const ScrollPanel = styled.div`
  overflow-y: scroll;
  height: 10em;
  background-color: #f5f5f5;
  padding-top: 5px;
  padding-left: 5px;
  margin-bottom: 5px;
`;

export const SetField: React.FC<JsonikFieldProps> = props => {
  const [filter, setFilter] = useState<string>('');
  const [options, setOptions] = useState<string[]>(props.props.options);

  const search = (txtData: string, txtSearch: string): boolean =>
    (txtData || '').toUpperCase().indexOf((txtSearch || '').toUpperCase()) !==
    -1;

  const update = (form: FormikProps<any>, name: string, values: any) => {
    form.setFieldValue(name, values);
    form.setFieldTouched(name);
  };

  const add = (form: FormikProps<any>, name: string, value: string) => {
    const values = form.values[name] || '';
    update(form, name, `${values}${value}|` || '');
  };

  const del = (form: FormikProps<any>, name: string, value: string) => {
    const values: string = form.values[name] || '';
    update(form, name, values.replace(`${value}|`, ''));
  };

  const isChecked = (form: FormikProps<any>, name: string, value: string) => {
    const values: string = form.values[name] || '';
    return values.indexOf(`${value}|`) !== -1;
  };

  const changeFilter = (filter: string) => {
    setFilter(filter);
    setOptions(
      props.props.options &&
        props.props.options.filter((option: string) => search(option, filter))
    );
  };

  return (
    <BasicInput
      fast={false}
      {...props}
      render={({ field, form }: FieldProps) => (
        <>
          <Row>
            <Col md={12}>
              <ScrollPanel>
                {options &&
                  options.map((option: any, index: number) => (
                    <Checkbox
                      id={`checkbox-${props.name}-${index}`}
                      name={`${props.name}.${option}`}
                      label={option}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        (e.target.checked ? add : del)(form, field.name, option)
                      }
                      checked={isChecked(form, field.name, option)}
                    />
                  ))}
              </ScrollPanel>

              <input
                placeholder="Digite o texto para filtrar a listagem"
                onChange={e => changeFilter(e.target.value)}
                value={filter}
              />

              <i
                className="fa fa-times input-icon"
                onClick={() => changeFilter('')}
                title="Limpar"
              />
            </Col>
          </Row>
        </>
      )}
    />
  );
};

export const SetFieldMap: JsonikFieldMap = {
  type: 'set',
  component: SetField,
  friendlyName: 'Coleção',
  description: 'Aceita a marcação de várias opções pré-definidas',
  yupType: Yup.string,
  getDefaultProps: () => ({
    options: ['Opção 1', 'Opção 2']
  }),
  editor: StringListOptionsPropsEditor
};
