import { FilterUtils } from '@elotech/components';

import { Cnae } from './../types/Cnae.d';
import { CrudService } from '../common/service/CrudService';

const crudService = CrudService<Cnae>(
  '/cnae',
  undefined,
  FilterUtils.buildFilter(['codigo', 'descricao'])
);

export default { ...crudService };
