import styled from 'styled-components';

export const Clear = styled.br`
  clear: both;
  line-height: 0;
`;

export const Holder = styled.div`
  position: relative;
  padding: 8px;
  margin-bottom: 5px;
  font-size: 0.9em;
  & .form-group {
    padding-top: 0px;
  }
  & input {
    pointer-events: none;
  }
`;

export const BlockPanel = styled.div<{ hover: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 900;
  border-radius: 5px;
  transition: 0.2s;
  background-color: #0071b329;
  border: 1px dashed #0071b3;
  opacity: 0;
  ${props =>
    props.hover &&
    `
    opacity: 1
  `}
`;

export const StyledButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 901;
  position: absolute;
  top: 5px;
  right: 7px;
  & .btn.circle: {
    margin-left: 2px !important;
  }
`;

export const HolderInfoPanel = styled.div`
  position: absolute;
  right: 9px;
  bottom: 2px;
  font-size: 0.8em;
  text-align: right;
  & div {
    text-shadow: 1px 1px white;
    color: black;
  }
`;

export const ButtonPanel = styled.div`
  padding: 8px;
`;

export const FieldName = styled.div``;

export const FieldDescription = styled.div`
  font-size: 0.7em;
  color: gray;
`;

export const FieldItem = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

export const FieldButton = styled.div`
  position: absolute;
  right: 0;
  top: 5px;
`;

export const DashedBackground = styled.div`
  background: repeating-linear-gradient(45deg, #f9f9f9, #f1f1f1 20px);
  padding: 5px;
`;
