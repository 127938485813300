import TipoLocalInternoFormPage from '../pages/configuracao/tipolocalinterno/TipoLocalInternoFormPage';
import TipoLocalInternoListPage from '../pages/configuracao/tipolocalinterno/TipoLocalInternoListPage';
import { Roles } from '../utils/Roles';

const tipoLocalInternoRoutes = [
  {
    path: '/configuracao/tipo-local-interno',
    component: TipoLocalInternoListPage,
    title: 'Tipo de local interno',
    role: Roles.tipo_local_interno_read.name
  },
  {
    path: '/tipo-local-interno/:id',
    component: TipoLocalInternoFormPage,
    title: 'Tipo Processo',
    role: Roles.tipo_local_interno_update.name
  }
];

export default tipoLocalInternoRoutes;
