import {
  ActionButton,
  ActionsGroup,
  Panel,
  Sort,
  Table
} from '@elotech/components';
import React from 'react';

import { ArtigoGuiaVo } from '../../types/ArtigoGuiaVo';

type Props = {
  guiasRecolhimento: ArtigoGuiaVo[];
  onSort(sort: Sort): void;
  onDelete(artigoGuiaVo: ArtigoGuiaVo): void;
};

const GuiaRecolhimentoList: React.FC<Props> = (props: Props) => {
  const { guiasRecolhimento, onSort, onDelete } = props;
  return (
    <Panel isTable={true}>
      <Table
        keyExtractor={guia => `${guia.idArtigoGuia}`}
        values={guiasRecolhimento}
        sortOnHeaderColumnClick={sort => {
          onSort(sort);
        }}
      >
        <Table.Column<ArtigoGuiaVo>
          sortable={true}
          header="Exercício"
          name="exercicio"
          value={guia => guia.exercicio}
        />

        <Table.Column<ArtigoGuiaVo>
          sortable={true}
          header="Guia de Recolhimento"
          name="guiaRecolhimento"
          value={guia => guia.numeroGuiaRecolhimento}
        />

        <Table.Column<ArtigoGuiaVo>
          sortable={true}
          header="Descrição"
          name="guiaRecolhimento.descricao"
          value={guia => guia.descricao}
        />

        <Table.Column<ArtigoGuiaVo>
          sortable={true}
          header="Receita"
          name="receita"
          value={guia => guia.receita}
        />

        <Table.Column<ArtigoGuiaVo>
          header=""
          value={guia => (
            <ActionsGroup>
              <ActionButton
                label="Remover"
                icon="trash-alt"
                onClick={() => onDelete(guia)}
              />
            </ActionsGroup>
          )}
        />
      </Table>
    </Panel>
  );
};

export default GuiaRecolhimentoList;
