import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikSelect,
  Loading,
  Panel,
  Row,
  Yup,
  formatUtils
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import CadastroGeralService from '../../service/CadastroGeralService';
import DesifService from '../../service/DesifService';
import { CadastroGeral } from '../../types/CadastroGeral';
import CruzamentoBalanceteApuracaoMensalListPage from './CruzamentoBalanceteApuracaoMensalListPage';
import CruzamentoBalanceteDmsListPage from './CruzamentoBalanceteDmsListPage';
import CruzamentoBalanceteDmsPagoListPage from './CruzamentoBalanceteDmsPagoListPage';

type Option = {
  label: string;
  value: string;
};

const optionTipoCruzamento: Option[] = [
  { label: 'Balancete x DMS', value: 'A' },
  { label: 'Balancete x DMS (Pago)', value: 'B' },
  { label: 'Balancete x Apuração Mensal', value: 'C' }
];

type CruzamentoForm = {
  tipoCruzamento: string;
  cadastro?: CadastroGeral;
  competencia: string;
};

const initialValue: CruzamentoForm = { tipoCruzamento: 'A', competencia: '' };

function isCompetenciaValida(competencia: string) {
  const data = moment(`01/${competencia}`, 'DD/MM/YYYY', true);
  return data.isValid();
}

const validationSchema = Yup.object().shape({
  cadastro: Yup.object()
    .label('Instituição Financeira')
    .test('cadastro', 'Cadastro não encontrado', value => !value || !!value.id)
    .required(),
  competencia: Yup.string()
    .required()
    .test('competenciaInvalida', 'Competência inválida', function(
      competenciaInicial: string
    ) {
      return (
        (!competenciaInicial &&
          (!!this.parent.cadastroGeral || this.parent.pessoa)) ||
        (competenciaInicial?.length > 0 &&
          isCompetenciaValida(competenciaInicial))
      );
    })
});

type Props = {};

const CruzamentoPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<any>();

  const getBalanceteDms = (formValues: CruzamentoForm) => {
    setLoading(true);
    DesifService.findCruzamentoBalanceteDms(
      formValues.cadastro?.id!,
      formValues.competencia
    )
      .then(response => {
        if (formValues.tipoCruzamento === 'A') {
          setValues(response.data);
        } else {
          const vl =
            response.data?.filter(
              item => item.valorDevido && item.valorDevido > 0
            ) || [];
          setValues(vl);
        }
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível obter as informações de cruzamento.' },
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBalanceteApuracaoMensal = (formValues: CruzamentoForm) => {
    setLoading(true);
    DesifService.findCruzamentoBalanceteApuracaoMensal(
      formValues.cadastro?.id!,
      formValues.competencia
    )
      .then(response => {
        setValues(response.data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível obter as informações de cruzamento.' },
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (formValues: CruzamentoForm) => {
    switch (formValues.tipoCruzamento) {
      case 'A':
      case 'B':
        getBalanceteDms(formValues);
        break;
      case 'C':
        getBalanceteApuracaoMensal(formValues);
        break;
    }
  };

  const renderValues = (formValues: CruzamentoForm) => {
    if (!values) return null;

    switch (formValues.tipoCruzamento) {
      case 'A':
        return <CruzamentoBalanceteDmsListPage values={values} />;
      case 'B':
        return <CruzamentoBalanceteDmsPagoListPage values={values} />;
      case 'C':
        return <CruzamentoBalanceteApuracaoMensalListPage values={values} />;
    }
  };

  const onSelect = (value: Option) => setValues([]);

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik<CruzamentoForm>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        render={(formProps: FormikProps<CruzamentoForm>) => (
          <>
            <Panel isForm title={<>Cruzamento de Informações</>}>
              <Row>
                <FormikSelect
                  name="tipoCruzamento"
                  label="Tipo"
                  size={4}
                  options={optionTipoCruzamento}
                  getOptionLabel={(option: Option) => option.label}
                  getOptionValue={(option: Option) => option.value}
                  onSelect={onSelect}
                />
              </Row>
              <Row>
                <FormikAutocomplete<CadastroGeral>
                  data-test-id="idCadastro"
                  name="cadastro"
                  placeholder="Pesquisar por cnpj ou nome da empresa"
                  label="Instituição Financeira"
                  onSearch={CadastroGeralService.autoComplete}
                  size={4}
                  getOptionLabel={value =>
                    `${formatUtils.formatCpfCnpj(value?.pessoa?.cnpjCpf)} - ${
                      value.cadastroGeral
                    } - ${value?.pessoa?.nome}`
                  }
                  getOptionValue={value => value.id}
                />
                <BasicInput
                  size={2}
                  label="Competência"
                  name="competencia"
                  render={({ field }) => (
                    <MaskedInput
                      {...field}
                      type="text"
                      name="competencia"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />
                  )}
                />
                <Col md={3}>
                  <div className="form-group">
                    <label className="label" />
                    <Button iconPosition="left" onClick={formProps.submitForm}>
                      <i className="fa fa-search" />
                      BUSCAR
                    </Button>
                  </div>
                </Col>
              </Row>
            </Panel>
            {renderValues(formProps.values)}
          </>
        )}
      ></Formik>
    </Container>
  );
};

export default CruzamentoPage;
