import { FormikInputInteger, Row } from '@elotech/components';
import React from 'react';

import { JsonikFieldDef } from '../../common/types';

type Props = {
  field: JsonikFieldDef;
};

export const RowsPropsEditor: React.FC<Props> = () => {
  return (
    <Row>
      <FormikInputInteger
        name="props.rows"
        hint="Quantidade de linhas"
        label="Linhas"
        size={12}
      />
    </Row>
  );
};
