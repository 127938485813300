import {
  Alert,
  BasicInput,
  Button,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  FormikInputInteger,
  FormikSelect,
  FormikTextArea,
  Loading,
  PagedResponse,
  Panel,
  Row,
  UrlUtils,
  Wizard,
  Yup,
  buildTouched,
  convertErrorToFormik,
  extractErrorText,
  useShowNotification
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { match } from 'react-router';
import { ObjectSchema } from 'yup';

import { CadastroImobiliario } from '../../common/components/ModalCadastroImobiliario';
import AtoProcessoService from '../../service/AtoProcessoService';
import CadastroGeralService from '../../service/CadastroGeralService';
import SolicitacaoProcessoService from '../../service/GeracaoProcessoService';
import PessoaService from '../../service/PessoaService';
import ProcessoService from '../../service/ProcessoService';
import TipoLocalInternoService from '../../service/TipoLocalInternoService';
import TipoProcessoAtoService from '../../service/TipoProcessoAtoService';
import TipoProcessoService from '../../service/TipoProcessoService';
import { CadastroGeralProcessoDTO } from '../../types/CadastroGeralProcessoDTO';
import { GeracaoProcesso } from '../../types/GeracaoProcesso';
import { Pessoa } from '../../types/Pessoa';
import { Processo, ProcessoLoteDTO } from '../../types/Processo';
import { TipoProcesso } from '../../types/TipoProcesso';
import { TipoProcessoAto } from '../../types/TipoProcessoAto';
import { openGoogleMaps } from '../../utils/utils';
import { LocalField, TipoLocal } from './LocalField';
import ProcessoCadastro from './ProcessoCadastro';
import { dadosGeraisStep, processoValidationSchema } from './validationSchema';

const initialValues: Processo = {
  id: 0
};

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  historypush: Pick<History, 'push'>;
  location: Location;
};

type ProcessoSteps = 'dadosGerais' | 'cadastrosMobiliarios';

export const ProcessoDataPage: React.FC<Props> = ({
  match,
  history,
  historypush,
  location
}) => {
  const [tiposProcessos, setTiposProcessos] = useState<TipoProcesso[]>([]);
  const [tiposAtos, setTiposAtos] = useState<TipoProcessoAto[]>([]);
  const [tipoAutor, setTipoAutor] = useState<TipoLocal>('INTERNO');
  const [tipoReu, setTipoReu] = useState<TipoLocal>('EXTERNO');
  const [tipoProcesso, setTipoProcesso] = useState<TipoProcesso | undefined>();
  const [solicitacaoProcesso, setSolicitacaoProcesso] = useState<
    GeracaoProcesso | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processo, setProcesso] = useState<Processo>(initialValues);
  const showNotification = useShowNotification();

  const [cadastrosProcesso, setCadastrosProcesso] = useState<
    CadastroGeralProcessoDTO[]
  >([]);

  const dataInicial = UrlUtils.getValueFromUrlSearchParams(location, 'dataIni');

  const dataFim = UrlUtils.getValueFromUrlSearchParams(location, 'dataFim');

  const reu = UrlUtils.getValueFromUrlSearchParams(location, 'reu');

  const onChangeSetShowModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (match.params.id !== 'novo') {
      if (location.pathname.includes('geracao-processo')) {
        setLoading(true);
        SolicitacaoProcessoService.findById(match.params.id)
          .then(solicitacao => {
            setTipoReu('EXTERNO');
            setTipoProcesso(solicitacao.data.tipoProcesso.idTipoProcesso);
            setSolicitacaoProcesso(solicitacao.data);

            let novoProcesso: Processo = {
              ...initialValues,
              numeroProtocolo: solicitacao.data.numeroProtocolo,
              tipoProcesso: solicitacao.data.tipoProcesso,
              reu: solicitacao.data.reu?.pessoa?.nome,
              reuExterno: solicitacao.data.reu,
              localizacao: solicitacao.data.localizacao
            };
            setProcesso(novoProcesso);
          })
          .catch(error => {
            Alert.error(
              { message: 'Não foi possível carregar o registro.' },
              error
            );
          })
          .finally(() => setLoading(false));
      } else if (location.pathname.includes('divergencia')) {
        if (reu !== null) {
          setLoading(true);
          CadastroGeralService.findById(reu)
            .then((cadastro: any) => {
              let novoProcesso: any = {
                ...initialValues,
                inicioFiscalizacao: dataInicial!,
                fimFiscalizacao: dataFim!,
                reuExterno: cadastro,
                reu: cadastro.pessoa?.nome
              };
              setProcesso(novoProcesso);
            })
            .catch(error => {
              Alert.error(
                { message: 'Não foi possível carregar o registro.' },
                error
              );
            })
            .finally(() => setLoading(false));
        }
      } else {
        setLoading(true);
        ProcessoService.findById(match.params.id)
          .then(processo => {
            setProcesso(processo.data);
          })
          .catch(error => {
            Alert.error(
              { message: 'Não foi possível carregar o registro.' },
              error
            );
          })
          .finally(() => setLoading(false));
      }
    }
  }, [match.params.id, dataFim, dataInicial, location.pathname, reu]);

  useEffect(() => {
    setLoading(true);
    TipoProcessoService.load('', { size: -1 })
      .then((result: AxiosResponse<PagedResponse<TipoProcesso>>) => {
        setTiposProcessos(result.data.content);
      })
      .catch((error: any) => {
        Alert.error({ title: 'Não foi obter os tipos de processo.' }, error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setCadastrosProcesso(cadastrosProcesso);
  }, [cadastrosProcesso]);

  const onChangeTipoProcesso = (
    tipoProcesso: TipoProcesso,
    formProps: FormikProps<Processo>
  ) => {
    setTipoProcesso(tipoProcesso);

    if (tipoProcesso && tipoProcesso.id) {
      if (tipoProcesso?.vistoriaEmLote === true) {
        formProps.setFieldValue('reuExterno', undefined, false);
        formProps.setFieldValue('reuInterno', undefined, false);
      }

      TipoProcessoAtoService.loadTiposAtos(tipoProcesso.id)
        .then((result: AxiosResponse<TipoProcessoAto[]>) => {
          setTiposAtos(result.data);
        })
        .catch((error: any) => {
          Alert.error({ title: 'Não foi obter os tipos de atos.' }, error);
        });
    } else {
      setTiposAtos([]);
    }
  };

  const normalized = (entity: Processo) => ({
    ...entity,
    autorInterno: tipoAutor === 'INTERNO' ? entity.autorInterno : undefined,
    autorExterno: tipoAutor === 'EXTERNO' ? entity.autorExterno : undefined,
    reuInterno: tipoReu === 'INTERNO' ? entity.reuInterno : undefined,
    reuExterno: tipoReu === 'EXTERNO' ? entity.reuExterno : undefined,
    solicitacaoProcesso
  });

  const confirmSave = async (entity: Processo) => {
    setLoading(true);
    let processo = entity;
    processo.numeroProtocolo = tipoProcesso?.exigeNumeroProtocolo
      ? `${entity.numeroProtocolo}/${entity.anoProtocolo}`
      : undefined;
    const existeProcessoParaReu = await ProcessoService.verificarExisteProcessoParaReu(
      processo
    );
    setLoading(false);
    if (existeProcessoParaReu.data.exitsProcesso) {
      return await Alert.question({
        title: 'Deseja continuar?',
        text: existeProcessoParaReu.data.mensagem
      }).then((result: any) => result.value);
    }
    return true;
  };

  const verificaAto = (entity: any) => {
    setLoading(true);
    if (entity.reuExterno !== undefined) {
      AtoProcessoService.existTipoProcessoAtoCadastro(
        +entity.reuExterno.id,
        entity.idTipoProcessoAto
      )
        .then(response => {
          setLoading(false);
          if (response.data.id !== 0) {
            Alert.question({
              title: `Atenção! Já existe um ato semelhante para este cadastro vinculado ao processo ${response.data.processo}/${response.data.exercicio}, Deseja criar um novo processo mesmo assim?`
            }).then((result: any) => {
              if (result.value) {
                save(normalized(entity));
              }
            });
          } else {
            save(normalized(entity));
          }
        })
        .catch((error: any) =>
          Alert.error(
            {
              title:
                'Ocorreu um erro ao verificar existência de processo do mesmo cadastro'
            },
            error
          )
        );
    } else {
      save(normalized(entity));
    }
  };

  const save = (entity: Processo) => {
    setLoading(true);
    ProcessoService.save(normalized(entity))
      .then((response: { data: { id: any } }) => {
        showNotification({
          level: 'success',
          message: 'Registro salvo com sucesso.'
        });
        history.replace(`/processo/${response.data.id}/visualizar`);
        return undefined; // Evita o redirecionamento pelo DataPage
      })
      .catch((error: any) => {
        Alert.error(
          {
            title: 'Ocorreu um erro ao salvar processo'
          },
          error
        );
        setLoading(false);
      });
  };

  const saveAndRedirect: any = async (entity: Processo) => {
    if (tipoProcesso?.vistoriaEmLote) {
      if (
        !cadastrosProcesso.some(
          cadastroProcesso => !!cadastroProcesso.selecionado
        )
      ) {
        Alert.error({
          title: 'Selecione ao menos um cadastro para gerar o processo!'
        });
        return;
      }

      const cadastrosSelecionados = cadastrosProcesso.filter(
        cadastroProcesso => !!cadastroProcesso.selecionado
      );

      setLoading(true);
      ProcessoService.saveProcessoLote({
        processo: normalized(entity),
        empresas: cadastrosSelecionados
      } as ProcessoLoteDTO)
        .then(() => {
          showNotification({
            level: 'success',
            message: 'Processos em lote realizado com sucesso!'
          });
          history.replace('/processo');
          return undefined;
        })
        .catch(error => {
          Alert.error(
            { title: 'Ocorreu um erro ao criar os processos em lote!' },
            error
          );
        })
        .finally(() => {
          setLoading(false);
        });

      return undefined;
    }

    if (
      !!tipoProcesso?.reuNaoObrigatorio &&
      !entity.reuExterno &&
      !entity.reuInterno
    ) {
      await Alert.question({
        title: 'Deseja continuar?',
        text: 'Réu não preenchido. O processo será salvo sem ato.'
      }).then((result: any) => {
        if (!!result.value) {
          entity.atos = [];
        }
        save(normalized(entity));
      });
    } else {
      const confirm = await confirmSave(entity);
      if (confirm) {
        verificaAto(entity);
      }
    }
  };

  const previewGoogleMaps = (localizacao: string) => {
    if (localizacao === undefined || localizacao.trim() === '') {
      Alert.error({ title: 'É necessário preencher a Localização' });
      return;
    }
    openGoogleMaps(localizacao);
  };

  const fieldsSteps: { [key in ProcessoSteps]: string[] } = {
    dadosGerais: Object.keys(dadosGeraisStep(tipoProcesso, tipoAutor, tipoReu)),
    cadastrosMobiliarios: Object.keys({})
  };

  const validators: { [key in ProcessoSteps]: ObjectSchema<any> } = {
    dadosGerais: Yup.object().shape(
      dadosGeraisStep(tipoProcesso, tipoAutor, tipoReu)
    ),
    cadastrosMobiliarios: Yup.object().shape({})
  };

  const stepHasError = (
    stepId: ProcessoSteps,
    formikProps: FormikProps<Processo>
  ): boolean => extractErrorText(formikProps, fieldsSteps[stepId]) !== '';

  const validateStep = async (
    stepId: ProcessoSteps,
    formProps: FormikProps<Processo>
  ): Promise<boolean> => {
    const { values, errors, touched, setErrors, setTouched } = formProps;
    return validators[stepId]
      .validate(values, { abortEarly: false })
      .then(() => {
        const clearErrorValues = fieldsSteps[stepId].reduce(
          (acc, current) => ({ ...acc, [current]: undefined }),
          {}
        );

        setErrors({ ...errors, ...clearErrorValues });

        return false;
      })
      .catch(error => {
        const errors = convertErrorToFormik(error);
        setErrors({ ...errors, ...errors });
        setTouched({
          ...touched,
          ...buildTouched(fieldsSteps[stepId])
        });
        return true;
      });
  };

  const onBeforeChange = (formikProps: FormikProps<Processo>) => async (
    oldStepData: any,
    newStepData: any
  ) => {
    const stepHasErrors = await validateStep(oldStepData.stepId, formikProps);

    return {
      oldStepData: { ...oldStepData, valid: !stepHasErrors },
      newStepData
    };
  };

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loading} />
        <Panel isTable>
          <Formik
            enableReinitialize
            initialValues={processo}
            onSubmit={saveAndRedirect}
            validationSchema={processoValidationSchema(
              tipoProcesso,
              tipoAutor,
              tipoReu
            )}
            render={(formProps: FormikProps<Processo>) => (
              <Wizard
                onFinish={formProps.submitForm}
                beforeChange={onBeforeChange(formProps)}
                finishButtonOnlyOnLastStep
              >
                <Wizard.Step
                  stepId="dadosGerais"
                  label="Dados Gerais"
                  icon="far fa-list"
                  showPreviousButton={false}
                  valid={!stepHasError('dadosGerais', formProps)}
                  errorMessage={extractErrorText(
                    formProps,
                    fieldsSteps['dadosGerais']
                  )}
                >
                  <>
                    <Row>
                      <FormikInputDate
                        label="Início da Fiscalização"
                        name="inicioFiscalizacao"
                        size={3}
                      />
                      <FormikInputDate
                        label="Fim da Fiscalização"
                        name="fimFiscalizacao"
                        size={3}
                      />
                    </Row>
                    <Row>
                      {tipoProcesso?.exigeNumeroProtocolo && (
                        <>
                          <FormikInputInteger
                            label="Número do Protocolo"
                            name="numeroProtocolo"
                            size={3}
                            maxLength={8}
                            fast={false}
                          />
                          <FormikInputInteger
                            label="Ano do Protocolo"
                            name="anoProtocolo"
                            size={3}
                            maxLength={4}
                            fast={false}
                          />
                        </>
                      )}
                    </Row>
                    <Row>
                      <FormikSelect<TipoProcesso>
                        name="idTipoProcesso"
                        label="Tipo do Processo"
                        options={tiposProcessos}
                        getOptionLabel={(option: { descricao?: string }) =>
                          option.descricao
                        }
                        getOptionValue={(option: { id: number }) => option.id}
                        size={6}
                        fast={false}
                        onSelect={(value: TipoProcesso) =>
                          onChangeTipoProcesso(value, formProps)
                        }
                      />
                      <FormikSelect<TipoProcessoAto>
                        name="idTipoProcessoAto"
                        label="Tipo do Ato"
                        options={tiposAtos}
                        getOptionLabel={(option: {
                          tipoAto?: { descricao?: string };
                        }) => option.tipoAto?.descricao}
                        getOptionValue={(option: { id: number }) => option.id}
                        size={6}
                        fast={false}
                      />
                    </Row>
                    <Row>
                      <LocalField
                        namePrefix="autor"
                        labelPrefix="Autor"
                        openModal={onChangeSetShowModal}
                        onSearchCadastroGeral={
                          CadastroGeralService.autoComplete
                        }
                        onSearchTipoLocal={TipoLocalInternoService.load}
                        size={6}
                        tipo={tipoAutor}
                        onSetTipo={(tipo: any) => setTipoAutor(tipo)}
                      />
                      <FormikTextArea
                        name="observacao"
                        label="Observação"
                        size={6}
                        maxLength={4000}
                      />
                    </Row>
                    <Row>
                      <FormikAutocomplete
                        data-test-id={`auto-complete-denunciante`}
                        name={`denunciante`}
                        label={`Denunciante`}
                        onSearch={PessoaService.search}
                        getOptionLabel={(value: Pessoa) =>
                          `${value?.cnpjCpf} - ${value.nome}`
                        }
                        size={6}
                      />
                    </Row>
                    {!tipoProcesso?.vistoriaEmLote && (
                      <Row>
                        <LocalField
                          namePrefix="reu"
                          labelPrefix="Réu"
                          openModal={onChangeSetShowModal}
                          permiteImobiliario={
                            tipoProcesso?.exigeCadastroImobiliario
                          }
                          onSearchCadastroGeral={
                            CadastroGeralService.autoCompleteReuExterno
                          }
                          onSearchTipoLocal={TipoLocalInternoService.load}
                          size={6}
                          tipo={tipoReu}
                          onSetTipo={(tipo: any) => setTipoReu(tipo)}
                        />
                      </Row>
                    )}
                    {tipoProcesso?.exigeEndereco && (
                      <Row>
                        <BasicInput
                          hint={`Neste campo deve ser informado o endereço completo, conforme exemplo, “Rua Tupã, 1643 - Recanto dos Magnatas, Maringá - PR, 87060-510” caso não tenha todos os dados é possível informar a longitude e latitude, conforme exemplo,"-23.4509116,-51.9481166"`}
                          size={6}
                          label="Localização"
                          name="localizacao"
                        />
                        <div
                          className="form-group col-md-1"
                          style={{ paddingLeft: 0 }}
                        >
                          <label className="label"></label>
                          <Button
                            data-testid="botao-localizacao"
                            iconPosition="left"
                            onClick={() =>
                              previewGoogleMaps(formProps.values.localizacao!)
                            }
                          >
                            <i className="fas fa-map-marker-alt"></i>
                          </Button>
                        </div>
                      </Row>
                    )}
                    {showModal && (
                      <CadastroImobiliario
                        onCloseModal={onChangeSetShowModal}
                        historypush={historypush}
                        formProps={formProps}
                      />
                    )}
                  </>
                </Wizard.Step>
                <Wizard.Step
                  stepId="cadastrosMobiliarios"
                  label="Cadastros"
                  icon="far fa-file-alt"
                  visible={tipoProcesso?.vistoriaEmLote === true}
                >
                  <ProcessoCadastro
                    cadastrosProcesso={cadastrosProcesso}
                    setCadastrosProcesso={setCadastrosProcesso}
                  />
                </Wizard.Step>
              </Wizard>
            )}
          />
        </Panel>
      </Container>
    </>
  );
};
