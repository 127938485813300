import { Button } from '@elotech/components';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './Context';
import { JsonikForm } from '..';

const DashedBackground = styled.div`
  background: repeating-linear-gradient(45deg, #f9f9f9, #f1f1f1 20px);
  padding: 5px;
`;

export const FormTester: React.FC = () => {
  const { recipe, fieldMap, testing, setTesting } = useContext(Context);

  if (!testing) return null;

  return (
    <DashedBackground>
      <JsonikForm
        recipe={recipe}
        customFields={fieldMap}
        render={() => {
          return (
            <>
              <span style={{ fontSize: '.7em', fontStyle: 'italic' }}>
                Área de teste do formulário
              </span>
              <Button onClick={() => setTesting(false)}>Cancelar Teste</Button>
            </>
          );
        }}
      />
    </DashedBackground>
  );
};
