import {
  ActionButton,
  ActionsGroup,
  Alert,
  FAB,
  FabSpeedDial,
  Field,
  Icon,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import React from 'react';

import { situacoesGeracaoProcesso } from '../../enum/GeracaoSolicitacaoProcesso';
import GeracaoProcessoService from '../../service/GeracaoProcessoService';
import TipoProcessoService from '../../service/TipoProcessoService';
import { GeracaoProcesso } from '../../types/GeracaoProcesso';
import { TipoProcesso } from '../../types/TipoProcesso';

type Props = {
  history: Pick<History, 'push'>;
};

const fields: Field[] = [
  {
    label: 'Número do Protocolo',
    name: 'numeroProtocolo',
    type: 'STRING'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: situacoesGeracaoProcesso.map(situacao => ({
      name: `${situacao.codigo}`,
      descricao: situacao.descricao
    }))
  },
  {
    label: 'Tipo de Processo',
    name: 'tipoProcesso',
    type: 'AUTOCOMPLETE',
    loadOptions: TipoProcessoService.load,
    getOptionLabel: (option: TipoProcesso) => option.descricao!,
    getOptionValue: (option: TipoProcesso) => option.id
  }
];

export const GeracaoProcessoProtocoloTable: React.FC<Props> = ({ history }) => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<GeracaoProcesso>({
    search: GeracaoProcessoService.load,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar Processos Protocolo.' }, error);
    }
  });

  return (
    <>
      <Panel isTable>
        <Loading loading={loading} />
        <SearchFilter fields={fields} search={doSearch} />
        <Table loading={loading} values={values}>
          <Table.Column<GeracaoProcesso>
            header="Id"
            value={item => item.id}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Número do Protocolo"
            value={item => item.numeroProtocolo || '---'}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Tipo de Processo"
            value={item => (item.reu ? item.reu?.pessoa?.nome : '---')}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Fiscal Responsável"
            value={item => item.fiscalResponsavel?.nome || '---'}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Situação"
            value={item => item.situacao}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Gerou Processo"
            value={item => item.processo && <Icon icon="check" />}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header=""
            name="actions-buttons"
            value={item => (
              <ActionsGroup>
                <ActionButton
                  key="edit-button"
                  icon="pencil-alt"
                  label="Editar"
                  path={`/geracao-processo/${item.id}`}
                />
                <ActionButton
                  key="edit-button"
                  icon="trash"
                  label="Remover"
                  path={`/geracao-processo`}
                />
                {item.processo && (
                  <ActionButton
                    key="view-button"
                    icon="eye"
                    label="Visualizar"
                    path={`/processo/${item.processo?.id}/visualizar`}
                  />
                )}
              </ActionsGroup>
            )}
          ></Table.Column>
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <div className="btn-save">
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          <FAB
            icon="plus"
            title="Adicionar novo"
            path={'/geracao-processo/novo'}
          />
          <FAB
            icon="file-import"
            iconColor="white"
            title="Importar"
            onClick={() => history.push('/geracao-processo/importar')}
          />
        </FabSpeedDial>
      </div>
    </>
  );
};
