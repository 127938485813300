import { TipoAtoDataPage } from '../pages/configuracao/tipoato/TipoAtoDataPage';
import { TipoAtoListPage } from '../pages/configuracao/tipoato/TipoAtoListPage';
import { Roles } from '../utils/Roles';

const tipoAtoRoutes = [
  {
    path: '/configuracao/tipo-ato',
    component: TipoAtoListPage,
    title: 'Tipo de Ato',
    role: Roles.tipo_ato_read.name
  },
  {
    path: '/configuracao/tipo-ato/:id',
    component: TipoAtoDataPage,
    title: 'Tipo de Ato',
    role: Roles.tipo_ato_update.name
  }
];

export default tipoAtoRoutes;
