import { ActionButton, BasicInput, Col, Flex, Row } from '@elotech/components';
import { FieldArray } from 'formik';
import React from 'react';
import { FormGroup } from 'react-bootstrap';

import { JsonikFieldDef } from '../JsonikForm';

type Props = {
  field: JsonikFieldDef;
};

export const StringListOptionsPropsEditor: React.FC<Props> = ({ field }) => {
  return (
    <FieldArray
      name="props.options"
      render={arrayHelpers => (
        <>
          <Row>
            <Col md={12}>Descrição</Col>
          </Row>
          {field.props &&
            field.props.options &&
            field.props.options.map((value: any, index: number) => (
              <Row key={index}>
                <BasicInput size={12} name={`props.options.${index}`} noLabel />
                <Col md={2} style={{ alignContent: 'right' }}>
                  <FormGroup>
                    <Flex>
                      <ActionButton
                        icon="minus"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                      <ActionButton
                        icon="arrow-up"
                        disabled={index === 0}
                        onClick={() => arrayHelpers.move(index, index - 1)}
                      />
                      <ActionButton
                        icon="arrow-down"
                        disabled={index === field.props.options.length - 1}
                        onClick={() => arrayHelpers.move(index, index + 1)}
                      />
                    </Flex>
                  </FormGroup>
                </Col>
              </Row>
            ))}
          <Row>
            <Col md={12} style={{ marginLeft: '-10px' }}>
              <FormGroup>
                <ActionButton
                  icon="plus"
                  onClick={() =>
                    arrayHelpers.push({
                      value: undefined,
                      label: undefined
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    />
  );
};
