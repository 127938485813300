import {
  ActionButton,
  ActionsGroup,
  Alert,
  Button,
  Checkbox,
  Col,
  FAB,
  FabSpeedDial,
  Field,
  FormattedDate,
  FormikTextArea,
  Loading,
  Modal,
  Panel,
  Row,
  SearchFilter,
  SearchPagination,
  Table,
  Yup,
  showNotification,
  usePagedQuery
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { situacaoMobiliario } from '../../../enum/SituacaoMobiliario';
import {
  getDescricaoSituacaoProcesso,
  situacaoProcessoAlvara
} from '../../../enum/SituacaoProcessoAlvara';
import GeracaoProcessoAlvaraService from '../../../service/GeracaoProcessoAlvaraService';
import TipoProcessoService from '../../../service/TipoProcessoService';
import { GeracaoProcessoAlvara } from '../../../types/GeracaoProcesso';
import { TipoProcesso } from '../../../types/TipoProcesso';

type Props = {};

const validationSchema = Yup.object().shape({
  justificativa: Yup.string().required()
});

const searchFields: Field[] = [
  {
    label: 'Situação Mobiliário',
    name: 'situacaoMobiliario.descricao',
    type: 'ENUM',
    options: situacaoMobiliario.map(situacao => ({
      name: `${situacao.codigo}`,
      descricao: situacao.descricao
    }))
  },
  {
    label: 'Data Cadastro',
    name: 'dataCadastro',
    type: 'DATE'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: situacaoProcessoAlvara.map(situacao => ({
      name: `${situacao.codigo}`,
      descricao: situacao.descricao
    }))
  },
  {
    label: 'Tipo de Processo',
    name: 'tipoProcesso.id',
    type: 'AUTOCOMPLETE',
    loadOptions: TipoProcessoService.load,
    getOptionLabel: (option: TipoProcesso) => option.descricao!,
    getOptionValue: (option: TipoProcesso) => option.id
  }
];

const valorInicialProcesso: GeracaoProcessoAlvara = {
  id: 0
};

export const GeracaoProcessoAlvaraTable: React.FC<Props> = () => {
  const {
    values,
    pagination,
    doSearch,
    doPagedSearch,
    loading
  } = usePagedQuery<GeracaoProcessoAlvara>({
    search: GeracaoProcessoAlvaraService.load,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar Processos Alvará.' }, error);
    }
  });

  const history = useHistory();
  const [processos, setProcessos] = useState<GeracaoProcessoAlvara[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onHandleChangeCheckAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    processos: GeracaoProcessoAlvara[]
  ) => {
    const { checked } = e.target;
    setProcessos(
      processos.map(processo => {
        processo.selecionado = checked;
        return processo;
      })
    );
  };

  const onhandleChangeCheckBoxParcelas = (
    processoSelecionado: GeracaoProcessoAlvara
  ) => {
    setProcessos(
      values?.map(processo => {
        if (
          processo.id === processoSelecionado.id &&
          processo.situacao !== 'PROCESSO_GERADO'
        ) {
          processo.selecionado = !processo.selecionado;
        }
        return processo;
      })
    );
  };

  const ignoraSelecionados = async (value: GeracaoProcessoAlvara) => {
    const result = await Alert.question({
      title: 'Deseja ignorar os processos selecionados?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      target: '#alert'
    });
    if (result.value) {
      setIsLoading(true);
      const alvaras: GeracaoProcessoAlvara[] =
        processos
          ?.filter((alvara: GeracaoProcessoAlvara) => {
            return alvara.selecionado;
          })
          .map((alvara: GeracaoProcessoAlvara) => {
            return {
              id: alvara.id,
              justificativa: value.justificativa
            };
          }) ?? [];
      GeracaoProcessoAlvaraService.ignorar(alvaras)
        .then(() => {
          showNotification({
            message: 'Processos ignorados com sucesso',
            level: 'success'
          });
          window.location.reload();
        })
        .catch(error => {
          Alert.error({ title: 'Ocorreu um ao ignorar processos' }, error);
        })
        .finally(() => {
          setIsLoading(false);
          onCloseModal();
        });
    }
  };

  const onDelete = async (id: number) => {
    const result = await Alert.question({
      title: 'Deseja excluir o alvará selecionado?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      target: '#alert'
    });
    if (result.value) {
      setIsLoading(true);
      GeracaoProcessoAlvaraService.deleteById(id)
        .then(() => window.location.reload())
        .catch(error => {
          Alert.error({ title: 'Ocorreu um erro ao excluir alvará' }, error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const updateNova = (value: GeracaoProcessoAlvara) => {
    setIsLoading(true);
    GeracaoProcessoAlvaraService.novo(value)
      .then(() => {
        showNotification({
          message: 'Situação atualizado com sucesso',
          level: 'success'
        });
        window.location.reload();
      })
      .catch(error => {
        Alert.error(
          { title: 'Ocorreu um erro ao alterar processo para novo' },
          error
        );
      })
      .finally(() => setIsLoading(false));
  };

  const gerarProcesso = async () => {
    const result = await Alert.question({
      title: 'Deseja gerar processo dos cadastros selecionados?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      target: '#alert'
    });
    if (result.value) {
      setIsLoading(true);
      const alvaras: GeracaoProcessoAlvara[] =
        processos?.filter((alvara: GeracaoProcessoAlvara) => {
          return alvara.selecionado;
        }) ?? [];
      history.push('/processo-alvara/novo', alvaras);
    }
  };

  const peloMenosUmSelecionado =
    values?.some(
      alvara => alvara.selecionado && alvara.situacao !== 'PROCESSO_GERADO'
    ) ?? false;

  return (
    <>
      <Panel isTable>
        <Loading loading={isLoading || loading} />
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table
          loading={loading}
          values={values}
          keyExtractor={(item: GeracaoProcessoAlvara) => `${item.id}`}
        >
          <Table.Column<GeracaoProcessoAlvara>
            header={
              <div className="hidden-xs">
                <Checkbox
                  checked={
                    processos.length > 0 &&
                    processos.every(processo => processo.selecionado)
                  }
                  id="checkAll"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onHandleChangeCheckAll(event, values)
                  }
                />
              </div>
            }
            value={(processo: GeracaoProcessoAlvara, index: number) => {
              if (processo.situacao === 'PROCESSO_GERADO') {
                return (
                  <Checkbox
                    id={`${index}`}
                    checked={processo.selecionado}
                    blocked={true}
                    onChange={() => onhandleChangeCheckBoxParcelas(processo)}
                  />
                );
              }
              return (
                <Checkbox
                  id={`${index}`}
                  checked={processo.selecionado}
                  onChange={() => onhandleChangeCheckBoxParcelas(processo)}
                />
              );
            }}
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Código"
            value={item => item.id}
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Nº Cadastro"
            value={item =>
              item.cadastroGeral ? item.cadastroGeral?.cadastroGeral : ''
            }
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Data Cadastro"
            value={item => <FormattedDate value={item.dataCadastro} />}
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Razão Social"
            value={item =>
              item.cadastroGeral ? item.cadastroGeral.pessoa?.nome : ''
            }
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Situação Mobiliário"
            value={item =>
              item.situacaoMobiliario
                ? item.situacaoMobiliario.descricao
                : '---'
            }
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Grau de Risco"
            value={item => item.grauRisco}
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Tipo Processo"
            value={item =>
              item.tipoProcesso ? item.tipoProcesso.descricao : ''
            }
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header="Situação"
            value={item => getDescricaoSituacaoProcesso(item.situacao)}
          ></Table.Column>
          <Table.Column<GeracaoProcessoAlvara>
            header=""
            name="actions-buttons"
            value={item => (
              <ActionsGroup>
                {item.situacao === 'PROCESSO_GERADO' && (
                  <ActionButton
                    key="view-button"
                    icon="eye"
                    label="Visualizar"
                    path={`/processo/${item.processo?.id}/visualizar`}
                  />
                )}
                {item.situacao === 'IGNORADA' && (
                  <>
                    <ActionButton
                      key="view-ignorada-button"
                      icon="eye"
                      label="Visualizar"
                      path={`/geracao-processo-alvara/${item.id}/visualizar`}
                    />
                    <ActionButton
                      key="alter-button"
                      icon="plus"
                      label="Nova"
                      onClick={() => updateNova(item)}
                    />
                  </>
                )}
                {item.situacao !== 'PROCESSO_GERADO' && (
                  <>
                    <ActionButton
                      key="delete-button"
                      icon="trash"
                      label="Deletar"
                      onClick={() => onDelete(item.id)}
                    />
                  </>
                )}
              </ActionsGroup>
            )}
          ></Table.Column>
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      {showModal && (
        <Modal onClose={onCloseModal}>
          {
            <Panel isForm title="Ignorar Alvarás">
              <Formik
                initialValues={valorInicialProcesso}
                enableReinitialize
                onSubmit={ignoraSelecionados}
                validationSchema={validationSchema}
                render={(formProps: FormikProps<GeracaoProcessoAlvara>) => (
                  <>
                    <Row id={'alert'}>
                      <FormikTextArea
                        name="justificativa"
                        label="Justificativa"
                        size={12}
                        rows={12}
                      />
                    </Row>
                    <Row>
                      <Col md={12} className="form-group">
                        <Button
                          onClick={formProps.submitForm}
                          color="positive"
                          className="inline mt-xs mb-xs"
                        >
                          Confirmar
                        </Button>
                        <Button
                          onClick={onCloseModal}
                          color="neutral"
                          className="inline mt-xs mb-xs"
                        >
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              ></Formik>
            </Panel>
          }
        </Modal>
      )}
      <div className="btn-save">
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          {peloMenosUmSelecionado === false && (
            <FAB
              icon="file-import"
              iconColor="white"
              title="Importar"
              onClick={() =>
                history.push('/geracao-processo-alvara/importar', processos)
              }
            />
          )}
          {peloMenosUmSelecionado && (
            <FAB
              icon="file-invoice"
              title="Gerar Processo"
              onClick={() => gerarProcesso()}
            />
          )}
          {peloMenosUmSelecionado && (
            <FAB
              icon="eye-slash"
              iconColor="white"
              title="Ignorar"
              onClick={() => onShowModal()}
            />
          )}
        </FabSpeedDial>
      </div>
    </>
  );
};
