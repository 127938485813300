const asRoleType = <
  T extends { [K in keyof T]: { name: K; description: string } }
>(
  t: T
) => t;

export const Roles = asRoleType({
  processo_read: {
    name: 'processo_read',
    description: 'Permite consultar processos'
  },
  processo_update: {
    name: 'processo_update',
    description: 'Permite alterar processos'
  },
  divergencia_read: {
    name: 'divergencia_read',
    description: 'Permite consultar uma divergência'
  },
  divergencia_update: {
    name: 'divergencia_update',
    description: 'Permite criar uma divergência'
  },
  geracao_processo_read: {
    name: 'geracao_processo_read',
    description: 'Permite gerar um processo'
  },
  geracao_processo_update: {
    name: 'geracao_processo_update',
    description: 'Permite alterar gerador de processo'
  },
  ato_emails_enviados_read: {
    name: 'ato_emails_enviados_read',
    description: 'Permite consultar emails enviados'
  },
  tipo_ato_read: {
    name: 'tipo_ato_read',
    description: 'Permite consultar cadastro de tipo de ato'
  },
  tipo_ato_update: {
    name: 'tipo_ato_update',
    description: 'Permite alterar cadastro de tipo de ato'
  },
  tipo_processo_read: {
    name: 'tipo_processo_read',
    description: 'Permite consultar cadastro de tipo de processo'
  },
  tipo_processo_update: {
    name: 'tipo_processo_update',
    description: 'Permite alterar cadastro de tipo de processo'
  },
  artigo_read: {
    name: 'artigo_read',
    description: 'Permite consultar cadastro de artigo'
  },
  artigo_update: {
    name: 'artigo_update',
    description: 'Permite alterar cadastro de artigo'
  },
  artigo_guia_read: {
    name: 'artigo_guia_read',
    description: 'Permite consultar cadastro de artigo x guia de recolhimento'
  },
  artigo_guia_update: {
    name: 'artigo_guia_update',
    description: 'Permite alterar cadastro de artigo x guia de recolhimento'
  },
  tipo_local_interno_read: {
    name: 'tipo_local_interno_read',
    description: 'Permite consultar cadastro de tipo de local interno'
  },
  tipo_local_interno_update: {
    name: 'tipo_local_interno_update',
    description: 'Permite alterar cadastro de tipo de local interno'
  },
  fiscalizacao_alterar_numero_protocolo: {
    name: 'fiscalizacao_alterar_numero_protocolo',
    description: 'Permite alterar número do protocolo'
  },
  fiscalizacao_reabertura_ato_processo: {
    name: 'fiscalizacao_reabertura_ato_processo',
    description: 'Permite reabrir processo'
  },
  fiscalizacao_cancelar_encaminhamento: {
    name: 'fiscalizacao_cancelar_encaminhamento',
    description: 'Permite cancelar encaminhamento'
  },
  fiscalizacao_alterar_dt_abertura_fechamento_proc: {
    name: 'fiscalizacao_alterar_dt_abertura_fechamento_proc',
    description: 'Permite alterar data abertura e fechamento do processo'
  },
  fis_supervisor: {
    name: 'fis_supervisor',
    description: 'Permite visualizar e reabrir todos os processos'
  },
  fis_relatorio_documentos_intimados: {
    name: 'fis_relatorio_documentos_intimados',
    description: 'Permite realizar consulta de documentos intimados'
  },
  fis_relatorio_gerencial_fiscalizacao: {
    name: 'fis_relatorio_gerencial_fiscalizacao',
    description: 'Permite realizar consulta de processos do fiscalização'
  },
  fis_relatorio_procedimentos_fiscais_periodo: {
    name: 'fis_relatorio_procedimentos_fiscais_periodo',
    description:
      'Permite realizar consulta de procedimentos fiscais por período'
  },
  fis_relatorio_documentos_solicitados_entregues: {
    name: 'fis_relatorio_documentos_solicitados_entregues',
    description: 'Permite realizar consulta de documentos solicitados/entregues'
  },
  fis_relatorio_gerencial_ordem_servico: {
    name: 'fis_relatorio_gerencial_ordem_servico',
    description: 'Permite realizar a consulta de ordens de serviço'
  },
  fis_desif_cruzamento_informacoes: {
    name: 'fis_desif_cruzamento_informacoes',
    description: 'Permite visualizar tela de cruzamento de informações da Desif'
  },
  fis_gerenciador_tarefas_read: {
    name: 'fis_gerenciador_tarefas_read',
    description: 'Permite consultar o gerenciador de tarefas'
  }
});
