import { BasicInput, FormikCheckBox, Yup } from '@elotech/components';
import { FieldProps } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';
import { OptionsPropsEditor } from '../propseditor/OptionsPropsEditor';

const SetField: React.FC<JsonikFieldProps> = props => (
  <BasicInput
    {...props}
    render={({ field, form }: FieldProps) => (
      <Row>
        {props.props.options &&
          props.props.options.map((option: any) => (
            <Col md={6} key={`setoption-${props.name}-${option.value}`}>
              <FormikCheckBox
                id={`setoption-${props.name}-${option.value}`}
                name={`${props.name}.${option.value}`}
                label={option.label}
                size={12}
              />
            </Col>
          ))}
      </Row>
    )}
  />
);

const SetFieldValidation = () =>
  Yup.object().test(
    '',
    'Pelo menos uma opção deve ser marcada',
    (values: any) =>
      values &&
      Object.keys(values).reduce(
        (acc: boolean, key: string) => acc || values[key],
        false
      )
  );

export const SetFieldMap: JsonikFieldMap = {
  type: 'set',
  component: SetField,
  friendlyName: 'Coleção',
  description: 'Aceita a marcação de várias opções pré-definidas',
  yupType: SetFieldValidation,
  getDefaultProps: () => ({
    options: [
      { value: 1, label: 'Opção 1' },
      { value: 2, label: 'Opção 2' }
    ]
  }),
  editor: OptionsPropsEditor
};
