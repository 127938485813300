import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  SectionTitle
} from '@elotech/components';
import React from 'react';

import { ResumoApuracaoSemCorrecao } from '../../../../../types/ResumoApuracaoSemCorrecao';

type Props = {
  value: ResumoApuracaoSemCorrecao;
};

export const ValoresSemCorrecaoComponent: React.FC<Props> = ({ value }) => {
  return (
    <>
      <SectionTitle>Valores sem correções</SectionTitle>
      <DisplayDataGrid>
        <DisplayDataItem title="Receita bruta" md={3}>
          <FormattedCurrency value={value.receitaBruta} />
        </DisplayDataItem>
        <DisplayDataItem title="Outras receitas" md={3}>
          <FormattedCurrency value={value.outrasReceitas} />
        </DisplayDataItem>
        <DisplayDataItem title="Receita serviços" md={3}>
          <FormattedCurrency value={value.receitaServicos} />
        </DisplayDataItem>
        <DisplayDataItem title="Receita tributável" md={3}>
          <FormattedCurrency value={value.receitaTributaveis} />
        </DisplayDataItem>

        <DisplayDataItem title="Imposto devido" md={3}>
          <FormattedCurrency value={value.impostosDevido} />
        </DisplayDataItem>
        <DisplayDataItem title="Imposto recolhido" md={3}>
          <FormattedCurrency value={value.impostoRecolhido} />
        </DisplayDataItem>
        <DisplayDataItem title="Imposto retido" md={3}>
          <FormattedCurrency value={value.impostoRetido} />
        </DisplayDataItem>
        <DisplayDataItem title="Imposto declarado aberto" md={3}>
          <FormattedCurrency value={value.impostoDeclaradoAberto} />
        </DisplayDataItem>
      </DisplayDataGrid>
    </>
  );
};
