import { IconsLibrary } from '@elotech/components';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as customIcons from '@fortawesome/free-solid-svg-icons';
import { IconLibrary } from 'iss-common/components';

library.add(...IconLibrary);
library.add(IconsLibrary);

library.add(
  customIcons.faFileInvoice,
  customIcons.faTrash,
  customIcons.faTrashAlt,
  customIcons.faUsersCog,
  customIcons.faPlus,
  customIcons.faStopCircle,
  customIcons.faFileExcel,
  customIcons.faLayerGroup,
  customIcons.faFilePdf,
  customIcons.faPencilRuler,
  customIcons.faMapMarkedAlt,
  customIcons.faCashRegister
);
