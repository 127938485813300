import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { CadastroGeral } from '../types/CadastroGeral';
import { CadastroGeralProcessoDTO } from '../types/CadastroGeralProcessoDTO';
import { FiltroProcessoCadastroDTO } from '../types/FiltroProcessoCadastroDTO';

const crudService = CrudService<CadastroGeral>(
  '/cadastro-geral',
  undefined,
  FilterUtils.buildFilter(['pessoa.cnpjCpf', 'pessoa.nome'])
);

const autoComplete = (search: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/mobiliarios`, {
    params: {
      search: FilterUtils.buildNumberAndStringFilter(
        ['cadastroGeral'],
        ['pessoa.cnpjCpf', 'pessoa.nome']
      )(search),
      ...page
    }
  });

const mobiliariosApi = (search: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/imobiliarios-api`, {
    params: {
      search,
      ...page
    }
  });

const autoCompleteReuExterno = (
  search: string,
  extraFilter: string = '',
  page?: any
) =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/mobiliarios-reu-externo`, {
    params: {
      search: `${FilterUtils.buildNumberAndStringFilter(
        ['cadastroGeral'],
        ['pessoa.cnpjCpf', 'pessoa.nome']
      )(search)} ${extraFilter ? ` and ${extraFilter}` : ''}`,
      ...page
    }
  });

const findByTipoAndCadastro = (tipo: string, cadastro: string) =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/${tipo}/${cadastro}`);

const searchEmpresasProcesso = (
  filtro: FiltroProcessoCadastroDTO,
  pagination?: any
): AxiosPromise<PagedResponse<CadastroGeralProcessoDTO>> =>
  axios.post(
    `${CONTEXT_PATH}/cadastro-geral/search-empresas-processo`,
    filtro,
    {
      params: {
        ...pagination
      }
    }
  );

export default {
  ...crudService,
  autoComplete,
  mobiliariosApi,
  findByTipoAndCadastro,
  autoCompleteReuExterno,
  searchEmpresasProcesso
};
