import {
  Chip,
  FormattedCurrency,
  Panel,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import { CruzamentoBalanceteApuracaoMensalDTO } from '../../types/Desif';

type Props = {
  values?: CruzamentoBalanceteApuracaoMensalDTO[];
};

const CruzamentoBalanceteApuracaoMensalListPage: React.FC<Props> = ({
  values
}) => {
  const highlightError = (item: CruzamentoBalanceteApuracaoMensalDTO) =>
    item.diferencaCredito !== 0 ||
    item.diferencaDebito !== 0 ||
    ((item.valorCredito > 0 || item.valorDebito > 0) &&
      item.apuracaoRecebida === 'N');

  return (
    <>
      <SectionTitle>Informações</SectionTitle>
      <Panel isTable className="mt-xs">
        <Table<CruzamentoBalanceteApuracaoMensalDTO>
          values={values || []}
          keyExtractor={item => `${item.ano}-${item.mes}-${item.conta}`}
          highlightError={item => highlightError(item)}
          reduced={false}
        >
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Cosif"
            value={item => item.contaCosif}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Conta"
            value={item => item.conta}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Descrição"
            value={item => item.descricao}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Valor Crédito"
            align="right"
            value={item => <FormattedCurrency value={item.valorCredito} />}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Valor Débito"
            align="right"
            value={item => <FormattedCurrency value={item.valorDebito} />}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Diferença Crédito"
            align="right"
            value={item => <FormattedCurrency value={item.diferencaCredito} />}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Diferença Débito"
            align="right"
            value={item => <FormattedCurrency value={item.diferencaDebito} />}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Valor Credito Apuração"
            align="right"
            value={item => (
              <FormattedCurrency value={item.valorCreditoMensal} />
            )}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Valor Débito Apuração"
            align="right"
            value={item => <FormattedCurrency value={item.valorDebitoMensal} />}
          />
          <Table.Column<CruzamentoBalanceteApuracaoMensalDTO>
            header="Apuração"
            value={item =>
              item.apuracaoRecebida === 'S' ? (
                <Chip value={'Sim'} color="neutral" outlined={false} />
              ) : (
                <Chip
                  value={'Não'}
                  color="negative"
                  outlined={highlightError(item) ? false : true}
                />
              )
            }
          />
        </Table>
      </Panel>
    </>
  );
};

export default CruzamentoBalanceteApuracaoMensalListPage;
