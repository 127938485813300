import qs from 'querystring';

import {
  Container,
  FAB,
  FabSpeedDial,
  Loading,
  SectionTitle,
  Tabs
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';

import { customFields } from '../../../common/components/customfields';
import AtoProcessoService from '../../../service/AtoProcessoService';
import ProcessoService from '../../../service/ProcessoService';
import UsuarioService from '../../../service/UsuarioService';
import { AtoProcesso } from '../../../types/AtoProcesso';
import { Processo } from '../../../types/Processo';
import { TipoAtoConfiguracao } from '../../../types/TipoAtoConfiguracao';
import Constantes from '../../../utils/Constantes';
import { Roles } from '../../../utils/Roles';
import { AtoDetail } from './AtoDetail';
import { FormularioRenderer } from './FormularioRenderer';
import { ProcessoDetail } from './ProcessoDetail';
import { ProcessoDetailReu } from './ProcessoDetailReu';

type Props = {
  match: match<{ id: string; idAto: string }>;
  history: Pick<History, 'push' | 'replace'>;
  location: Pick<Location, 'search' | 'pathname'>;
  usuario: any;
};

const initialValues: AtoProcesso = {
  id: 0,
  idProcesso: 0,
  processo: {
    id: 0,
    numero: undefined,
    exercicio: undefined,
    prazoDoProcesso: undefined,
    dataAbertura: undefined,
    dataFechamento: undefined,
    inicioFiscalizacao: undefined,
    fimFiscalizacao: undefined,
    numeroProtocolo: undefined,
    anoProtocolo: undefined,
    fiscalResponsavel: undefined,
    tipoProcesso: undefined,
    status: undefined,
    tempoFiscalizacao: undefined,
    descricaoStatus: undefined,
    reu: undefined,
    cnpjCpfReu: undefined,
    idStatus: undefined,
    autorInterno: undefined,
    autorExterno: undefined,
    reuInterno: undefined,
    reuExterno: undefined,
    idTipoProcesso: undefined,
    atos: undefined,
    movimentacaoAtual: undefined,
    inscricaoMunicipalReu: undefined,
    fiscalResponsavelNome: undefined,
    localizacao: undefined
  },
  numero: 0,
  exercicio: 0
};

const AtoDataPage: React.FC<Props> = ({
  match,
  history,
  location,
  usuario
}) => {
  const { id } = match.params;
  const [processo, setProcesso] = useState<Processo | undefined>();
  const [processoAto, setProcessoAto] = useState<AtoProcesso>(initialValues);
  const search = qs.parse(location.search.slice(1));
  const [podeReabrirAto, setPodeReabrirAto] = useState(false);

  const load = async (id: number | string) => {
    const permissoes = await (await UsuarioService.permissoesByUsuario()).data;
    setPodeReabrirAto(
      permissoes.clientRoles.find(
        x => x.name === Roles.fiscalizacao_reabertura_ato_processo.name
      )
        ? false
        : true
    );
    const ato = await AtoProcessoService.findById(id);
    await ProcessoService.findById(ato.data.idProcesso).then(response =>
      setProcesso(response.data)
    );

    if (ato.data.formularios) {
      for (let formulario of ato.data.formularios) {
        formulario.mimeType = ato.data.tipoAto?.formularios?.filter(
          filter => filter.descricao === formulario.descricao
        )[0]?.mimeType;
      }
    }
    return ato;
  };

  useEffect(() => {
    setLoading(true);
    load(match.params.id)
      .then(result => setProcessoAto(result.data))
      .catch(error => {
        Alert.error(
          { message: 'Não foi possível carregar o registro.' },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [match.params.id]);

  const changeTab = (ato: AtoProcesso, newTab: number): boolean => {
    const tab = ato.formularios![newTab].id.toString();

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({ tab })
    });

    return true;
  };

  const getInitialTab = (ato: AtoProcesso) => {
    const formId = ato.formularios!.findIndex(
      form => form.id.toString() === search['tab']
    );
    return formId;
  };

  const visualizarDocumento = () => {
    setLoading(true);
    AtoProcessoService.visualizarDocumentos(id)
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error: string) => {
        Alert.error(
          {
            title: 'Não foi possível gerar o documento.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const detalhesProcesso = () => {
    history.push(`/processo/${processo!.id}/visualizar`);
  };

  const excluirAto = () => {
    Alert.question({
      title: 'Informe o motivo da exclusão',
      input: 'textarea',
      inputPlaceholder: '',
      inputValidator: (value: string) =>
        value.length === 0
          ? 'Campo obrigatório!'
          : !(value.length < 4000)
          ? 'Excedeu o limite de 4000 caracteres'
          : null,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancelar'
    })
      .then((result: any) => {
        if (result.value) {
          setLoading(true);
          AtoProcessoService.deletarAto(
            id,
            result.value,
            usuario.profile.username
          )
            .then(() => {
              detalhesProcesso();
              Alert.success({
                title: 'Exclusão realizada com sucesso',
                confirmButtonText: 'OK'
              });
            })
            .catch(error => {
              Alert.error(
                {
                  title: 'Não foi possível excluir o ato.'
                },
                error
              );
            });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function isTermoCircunstanciado(conf: TipoAtoConfiguracao) {
    return conf.acao === Constantes.acaoTermoCircunstanciado;
  }

  function isConfiguradoComoResposta(conf: TipoAtoConfiguracao) {
    return conf.acao === Constantes.acaoResposta;
  }

  const conclusaoAto = (
    idAto: string,
    prazoAtendido: string | undefined,
    incluiNfOriginal: string | undefined,
    enviaEmaiil: boolean
  ) => {
    setLoading(true);

    AtoProcessoService.concluirAto(
      idAto,
      prazoAtendido,
      incluiNfOriginal,
      enviaEmaiil
    )
      .then(async () => {
        setLoading(false);
        await Alert.success({
          title: 'Ato concluído com sucesso',
          confirmButtonText: 'OK'
        });

        detalhesProcesso();
      })
      .catch((error: string) => {
        setLoading(false);
        Alert.error(
          {
            title: 'Não foi possível concluir o ato.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const concluirAto = async () => {
    const ato = await AtoProcessoService.findById(id);
    let enviaEmail: boolean = false;

    if (ato.data.tipoAto?.enviaemail) {
      await Alert.question({
        title: 'Deseja enviar email ao contribuinte?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result: any) =>
        result.value ? (enviaEmail = true) : (enviaEmail = false)
      );
    }

    if (ato.data.tipoAto?.configuracoes?.some(isTermoCircunstanciado)) {
      const retorno = await Alert.question({
        title: 'Incluir notas fiscais originais?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      });
      if (retorno.value) {
        conclusaoAto(id, undefined, 'S', enviaEmail);
      } else {
        conclusaoAto(id, undefined, 'N', enviaEmail);
      }
    } else if (
      ato.data.tipoAto?.configuracoes?.some(isConfiguradoComoResposta)
    ) {
      const retorno = await Alert.question({
        title: 'Atendeu a solicitação?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      });
      if (retorno.value) {
        conclusaoAto(id, 'S', undefined, enviaEmail);
      } else {
        conclusaoAto(id, 'N', undefined, enviaEmail);
      }
    } else {
      conclusaoAto(id, undefined, undefined, enviaEmail);
    }
  };

  const reabrirAto = () => {
    Alert.question({
      title: 'Confirmar reabertura do ato',
      input: 'textarea',
      inputPlaceholder: '',
      inputValidator: (value: string) =>
        value.length === 0
          ? 'Campo obrigatório!'
          : !(value.length < 4000)
          ? 'Excedeu o limite de 4000 caracteres'
          : null,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar'
    })
      .then((result: any) => {
        if (result.value) {
          setLoading(true);
          AtoProcessoService.reabrirAto(
            id,
            result.value,
            usuario.profile.username
          )
            .then(async () => {
              setLoading(false);
              await Alert.success({
                title: 'Ato reaberto com sucesso',
                confirmButtonText: 'OK'
              });
              detalhesProcesso();
            })
            .catch(error => {
              setLoading(false);
              Alert.error(
                {
                  title: 'Não foi possível reabrir o ato.'
                },
                error
              );
            });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Container breadcrumb>
      <Formik
        enableReinitialize
        initialValues={processoAto}
        onSubmit={() => ''}
        render={({ values, setValues }: FormikProps<AtoProcesso>) => (
          <>
            <SectionTitle marginTop={'0'}>Dados do Processo</SectionTitle>
            <ProcessoDetail processo={processo!} />
            <SectionTitle>Dados do Contribuinte</SectionTitle>
            <ProcessoDetailReu processo={processo!}></ProcessoDetailReu>
            <Loading loading={loading} />

            <SectionTitle>Dados do Ato</SectionTitle>

            <AtoDetail ato={values} />

            {values.formularios && (
              <Tabs
                onChangeTab={newTab => changeTab(values, newTab)}
                initialTab={getInitialTab(values)}
                renderAllContents={true}
              >
                {values.formularios.map((formulario, index) => (
                  <Tabs.Tab header={formulario.descricao} key={index}>
                    <FormularioRenderer
                      formulario={formulario}
                      index={index}
                      processo={processo!}
                      ato={values}
                      customFields={customFields}
                      history={history}
                      readonly={values?.status !== 'ABERTO'}
                    />
                  </Tabs.Tab>
                ))}
              </Tabs>
            )}

            <FabSpeedDial icon="ellipsis-v" title="Ações">
              <FAB
                data-testid="btn-visualizar-documento"
                icon="file-pdf"
                onClick={visualizarDocumento}
                title="Visualizar documento"
              />
              <FAB
                data-testid="btn-detalhes-processo"
                icon="binoculars"
                onClick={detalhesProcesso}
                title="Detalhes do processo"
              />
              <FAB
                data-testid="btn-excluir-ato"
                icon="trash-alt"
                onClick={excluirAto}
                title="Excluir ato"
              />
              {values?.status === 'ABERTO' ? (
                <FAB
                  data-testid="btn-concluir-ato"
                  icon="check"
                  onClick={concluirAto}
                  title="Concluir ato"
                />
              ) : (
                <FAB
                  data-testid="btn-reabrir-ato"
                  icon="pencil-alt"
                  onClick={reabrirAto}
                  disabled={podeReabrirAto}
                  title="Reabrir ato"
                />
              )}
            </FabSpeedDial>
          </>
        )}
      />
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user
});
const connectedComponent = connect(mapStateToProps)(AtoDataPage);
export { connectedComponent as default, AtoDataPage };
