import {
  ActionButton,
  ActionsGroup,
  Alert,
  Loading,
  PaginationData,
  Panel,
  SearchPagination,
  SectionTitle,
  Table,
  usePagedQuery
} from '@elotech/components';
import download from 'downloadjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import DivergenciaService from '../../../service/DivergenciaService';
import { DivergenciaItem } from '../../../types/DivergenciaItem';
import DivergenciaItemEmpresaTable from './DivergenciaItemEmpresaTable';

type Props = {
  id: string;
  dataInicial?: string;
  dataFim?: string;
};

type LocationState = {
  page: PaginationData;
  selectedIndexes: number[];
};

const DivergenciaItemTable: React.FC<Props> = ({
  id,
  dataInicial,
  dataFim
}) => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation<LocationState>();

  const find = useCallback(
    (search, page, sort) =>
      DivergenciaService.findItensDivergenciaEmpresa(
        parseInt(id),
        search,
        page
      ),
    [id]
  );

  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<DivergenciaItem>({
    search: find,
    onError: useCallback(error => {
      Alert.error(
        { title: 'Erro ao buscar os itens das divergências.' },
        error
      );
    }, [])
  });

  useEffect(() => {
    if (location.state !== undefined) {
      doSearch(undefined, {
        page: location.state.page.number,
        size: location.state.page.size
      });

      setExpandedIndexes(location.state.selectedIndexes);
      return;
    }

    doSearch();
  }, [doSearch, location]);

  const onExpandIndex = (_: DivergenciaItem, index: number) => {
    if (expandedIndexes.includes(index)) {
      const newIndexes = expandedIndexes.filter(value => value !== index);
      return setExpandedIndexes(newIndexes);
    }

    setExpandedIndexes((prevIndexTable: number[]) => {
      return [...prevIndexTable, index];
    });
  };

  const onViewNota = (item: DivergenciaItem) => {
    history.replace(location.pathname, {
      page: pagination,
      selectedIndexes: expandedIndexes
    });
    history.push(`/divergencia/${id}/visualizar/${item.id}/notas`);
  };

  const renderInnerComponent = (
    item: DivergenciaItem,
    indexCadastro: number
  ) => {
    if (expandedIndexes.length > 0 && expandedIndexes.includes(indexCadastro)) {
      return (
        <DivergenciaItemEmpresaTable
          id={+id}
          cadastrogeralid={+item.cadastroGeral.id}
          onViewNota={onViewNota}
        />
      );
    }
    return null;
  };

  const onSendExcel = async (item: DivergenciaItem) => {
    setIsLoading(true);
    try {
      const response = await DivergenciaService.exportToExcelDivergencia(
        parseInt(id),
        item.cadastroGeral.id
      );

      const filename = `Divergencia${item.id}-${item.cadastroGeral.cadastroGeral}.xls`;
      const data = response.data;

      download(dataURLtoFile(data, filename), filename);
    } catch (error) {
      Alert.error({ title: 'Erro ao gerar a planilha' }, error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSendPDF = async (item: DivergenciaItem) => {
    setIsLoading(true);
    await DivergenciaService.exportToPDFDivergencia(
      parseInt(id),
      item.cadastroGeral.id
    )
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <SectionTitle>Cadastros</SectionTitle>
      <Loading loading={isLoading || loading} />
      <Panel isTable>
        <Table
          loading={loading}
          values={values ?? []}
          keyExtractor={(item: DivergenciaItem) => `${item.id}`}
          renderInnerComponent={renderInnerComponent}
        >
          <Table.Column<DivergenciaItem>
            header="Código"
            value={item => item.cadastroGeral.cadastroGeral}
          ></Table.Column>
          <Table.Column<DivergenciaItem>
            header="Empresa"
            value={item => item.cadastroGeral.pessoa?.nome}
          ></Table.Column>
          <Table.Column<DivergenciaItem>
            header=""
            name="actions-buttons"
            value={(item, index) => (
              <ActionsGroup>
                <ActionButton
                  key="gera-processo"
                  icon="layer-group"
                  label="Gerar Processo"
                  path={`/processo/divergencia?dataIni=${dataInicial}&dataFim=${dataFim}&reu=${item.cadastroGeral.id}`}
                />
                <ActionButton
                  key="send-pdf"
                  icon="file-pdf"
                  label="Gerar PDF"
                  onClick={() => onSendPDF(item)}
                />
                <ActionButton
                  key="send-excel"
                  icon="file-excel"
                  label="Excel"
                  onClick={() => onSendExcel(item)}
                />
                <ActionButton
                  key={`expand-${item.id}`}
                  icon="arrow-down"
                  label="Itens"
                  onClick={() => onExpandIndex(item, index)}
                />
              </ActionsGroup>
            )}
          ></Table.Column>
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </>
  );
};

function dataURLtoFile(dataurl: any, filename: any) {
  var bstr = atob(dataurl),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename);
}

export default DivergenciaItemTable;
