import {
  ActionButton,
  ActionsGroup,
  ErrorText,
  Panel,
  Table
} from '@elotech/components';
import { ErrorMessage } from 'formik';
import React from 'react';

import { UsuarioLocalInterno } from '../../../types/UsuarioLocalInterno';

type Props = {
  fiscais: UsuarioLocalInterno[];
  onDelete: (index: number) => void;
};

const TableFiscalResposavel: React.FC<Props> = props => {
  return (
    <>
      <Panel isTable={true} insidePanel={true}>
        <Table
          values={props?.fiscais ?? []}
          keyExtractor={fiscal => `${fiscal.id}`}
        >
          <Table.Column<UsuarioLocalInterno>
            header="Código"
            value={fiscal => fiscal.usuario.id}
          />
          <Table.Column<UsuarioLocalInterno>
            header="Nome"
            value={fiscal =>
              fiscal.usuario?.pessoa?.nome ?? fiscal.usuario?.nome
            }
          />
          <Table.Column<UsuarioLocalInterno>
            header=""
            value={(value, index: any) => (
              <ActionsGroup>
                <ActionButton
                  data-testid="btn-excluir-usuario"
                  key="delete-button"
                  icon="trash"
                  label="Excluir"
                  onClick={() => props.onDelete(index)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
      <ErrorMessage name="fiscal">
        {msg => <ErrorText>{msg}</ErrorText>}
      </ErrorMessage>
    </>
  );
};

export default TableFiscalResposavel;
