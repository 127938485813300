import {
  DisplayDataGrid,
  DisplayDataItem,
  Row,
  formatUtils
} from '@elotech/components';
import React from 'react';

import { Processo } from '../../../types/Processo';

type Props = {
  processo: Processo;
};

export const ProcessoDetailReu: React.FC<Props> = ({ processo }) => {
  return (
    <DisplayDataGrid column={true}>
      <Row>
        <DisplayDataItem md={5} title="Empresa/Razão Social">
          {processo?.reu}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="CNPJ/CPF">
          {formatUtils.formatCpfCnpj(processo?.cnpjCpfReu)}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Insc. Municipal">
          {processo?.inscricaoMunicipalReu}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Situação do Cadastro">
          {processo?.reuSituacaoCadastro}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={12} title="Endereço">
          {processo?.reuEnderecoCompleto}
        </DisplayDataItem>
      </Row>
    </DisplayDataGrid>
  );
};
