import { FormikInputInteger, Yup } from '@elotech/components';
import React from 'react';

import { JsonikFieldMap, JsonikFieldProps } from '../../common/types';

const NumberField: React.FC<JsonikFieldProps> = props => (
  <FormikInputInteger {...props} />
);

export const NumberFieldMap: JsonikFieldMap = {
  type: 'number',
  component: NumberField,
  friendlyName: 'Número',
  description: 'Aceita números inteiros',
  yupType: Yup.number,
  getDefaultProps: () => undefined
};
