import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { FiltroProcessoAlvaraImport } from '../types/Alvara';
import { GeracaoProcessoAlvara } from '../types/GeracaoProcesso';
import { GeracaoProcessoAlvaraDTO } from '../types/GeracaoProcessoAlvaraDTO';

const geracaoProcessoAlvara = CrudService<GeracaoProcessoAlvara>(
  '/geracao-processo-alvara',
  {
    sort: 'id,desc'
  }
);

export const saveAll = (processos: GeracaoProcessoAlvaraDTO[]) =>
  axios.post(`${CONTEXT_PATH}/geracao-processo-alvara/importar`, processos);

export const filtrar = (
  filtro: FiltroProcessoAlvaraImport,
  pagination?: any
): AxiosPromise<PagedResponse<GeracaoProcessoAlvaraDTO>> =>
  axios.post(`${CONTEXT_PATH}/geracao-processo-alvara/filtrar`, filtro, {
    params: {
      ...pagination
    }
  });

export const ignorar = (alvara: GeracaoProcessoAlvara[]) =>
  axios.post(`${CONTEXT_PATH}/geracao-processo-alvara/ignorar`, alvara);

export const novo = (alvara: GeracaoProcessoAlvara) =>
  axios.post(`${CONTEXT_PATH}/geracao-processo-alvara/nova`, alvara);

const deleteAlvara = (id: number): AxiosPromise<any> =>
  axios.delete(`${CONTEXT_PATH}/geracao-processo-alvara/delete/${id}`);

export default {
  saveAll,
  ...geracaoProcessoAlvara,
  filtrar,
  ignorar,
  novo,
  deleteAlvara
};
