import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { Lei } from '../types/Lei';

const crudService = CrudService<Lei>(
  '/lei',
  undefined,
  FilterUtils.buildDefaultFilter()
);

const leiAutoComplete = (
  searchParams: string
): AxiosPromise<PagedResponse<Lei>> =>
  axios.get(`${CONTEXT_PATH}/lei/auto-complete`, {
    params: {
      search: FilterUtils.buildNameFilter('numero', 'descricao')(searchParams),
      fields: 'descricao,numero,id'
    }
  });

export default { ...crudService, leiAutoComplete };
