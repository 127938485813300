import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  Loading,
  RadioButton,
  Row,
  SectionTitle,
  Yup,
  formatUtils
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { openPdf } from 'iss-common/utils/FileUtils';
import React, { useState } from 'react';

import {
  tipoAgrupamentoRelatorioGerencialCodigo,
  tipoAgrupamentoRelatorioGerencialSelect,
  tipoCadastroCodigo,
  tipoCadastroSelect,
  tipoRelatorioGerencialCodigo,
  tipoRelatorioGerencialSelect
} from '../../../enum';
import CadastroGeralService from '../../../service/CadastroGeralService';
import PessoaService from '../../../service/PessoaService';
import RelatoriosService from '../../../service/RelatoriosService';
import { CadastroGeral } from '../../../types/CadastroGeral';
import { FiltroRelatorioGerencialFiscalizacaoDTO } from '../../../types/FiltroRelatorioGerencialFiscalizacaoDTO';
import { Pessoa } from '../../../types/Pessoa';
import { Usuario } from '../../../types/Usuario';

const filtroRelatorioGerencialInitial: FiltroRelatorioGerencialFiscalizacaoDTO = {
  tipoCadastro: tipoCadastroCodigo.TODOS,
  tipoRelatorio: tipoRelatorioGerencialCodigo.CONTRIBUINTES_FISCALIZADOS,
  tipoAgrupamento: tipoAgrupamentoRelatorioGerencialCodigo.POR_CADASTRO
};

const validationSchema = Yup.object().shape({
  dataInicio: Yup.date()
    .label('Data Início')
    .when('tipoRelatorio', (tipoRelatorio: string, schema: Yup.DateSchema) => {
      if (
        tipoRelatorio !==
        tipoRelatorioGerencialCodigo.CONTRIBUINTES_NAO_FISCALIZADOS
      ) {
        return schema.required();
      }
      return schema;
    }),
  dataFim: Yup.date()
    .label('Data Fim')
    .when('tipoRelatorio', (tipoRelatorio: string, schema: Yup.DateSchema) => {
      if (
        tipoRelatorio !==
        tipoRelatorioGerencialCodigo.CONTRIBUINTES_NAO_FISCALIZADOS
      ) {
        return schema.test(
          'dataFimMaiorQueInicial',
          'A data de fim não pode ser menor do que a data de início',
          function(value: Date) {
            const { dataInicio } = this.parent;
            return dataInicio <= value;
          }
        );
      }
      return schema;
    }),
  tipoCadastro: Yup.number()
    .label('Tipo Cadastro')
    .required(),
  tipoRelatorio: Yup.string()
    .label('Tipo Relatório')
    .required(),
  tipoAgrupamento: Yup.string()
    .label('Tipo Agrupamento')
    .required()
});

export const RelatorioGerencialFiscalizacaoPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [
    desabilitarFiltrosTipoRelatorioNaoFiscalizados,
    setDesabilitarFiltrosTipoRelatorioNaoFiscalizados
  ] = useState(false);

  const [
    desabilitarFiltrosTipoRelatorioQuantitativoPorFiscal,
    setDesabilitarFiltrosTipoRelatorioQuantitativoPorFiscal
  ] = useState(false);

  const onFilter = async (values: FiltroRelatorioGerencialFiscalizacaoDTO) => {
    setLoading(true);
    await RelatoriosService.gerarRelatorioGerencialFiscalizacao(values)
      .then((response: any) => {
        openPdf(response.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const onChangeTipoRelatorio = (
    event: React.ChangeEvent<HTMLInputElement>,
    formProps: FormikProps<FiltroRelatorioGerencialFiscalizacaoDTO>
  ) => {
    const tipoRelatorio = event.target.value;
    setDesabilitarFiltrosTipoRelatorioNaoFiscalizados(false);
    setDesabilitarFiltrosTipoRelatorioQuantitativoPorFiscal(false);

    if (
      tipoRelatorio ===
      tipoRelatorioGerencialCodigo.CONTRIBUINTES_NAO_FISCALIZADOS
    ) {
      formProps.setFieldValue('dataInicio', '', true);
      formProps.setFieldValue('dataFim', '', true);
      formProps.setFieldValue('fiscal', undefined, true);
      formProps.setFieldValue('reuExterno', undefined, true);
      formProps.setFieldValue(
        'tipoAgrupamento',
        tipoAgrupamentoRelatorioGerencialCodigo.POR_CADASTRO,
        true
      );
      setDesabilitarFiltrosTipoRelatorioNaoFiscalizados(true);
    }

    if (
      tipoRelatorio === tipoRelatorioGerencialCodigo.QUANTITATIVO_POR_FISCAL
    ) {
      formProps.setFieldValue(
        'tipoAgrupamento',
        tipoAgrupamentoRelatorioGerencialCodigo.POR_FISCAL,
        true
      );
      setDesabilitarFiltrosTipoRelatorioQuantitativoPorFiscal(true);
    }

    formProps.setFieldValue('tipoRelatorio', tipoRelatorio, true);
  };

  const buildExtraFilterReuExterno = (tipoCadastro: string) => {
    if (tipoCadastro === tipoCadastroCodigo.TODOS) {
      return '';
    }

    return `tipoCadastro==${tipoCadastro}`;
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Col md={12}>
        <Row>
          <Formik<FiltroRelatorioGerencialFiscalizacaoDTO>
            enableReinitialize
            initialValues={filtroRelatorioGerencialInitial}
            validationSchema={validationSchema}
            onSubmit={onFilter}
            render={(
              formProps: FormikProps<FiltroRelatorioGerencialFiscalizacaoDTO>
            ) => (
              <>
                <Row>
                  <FormikInputDate
                    label="Data Início"
                    name="dataInicio"
                    size={3}
                    fast={false}
                    disabled={desabilitarFiltrosTipoRelatorioNaoFiscalizados}
                  />
                  <FormikInputDate
                    name="dataFim"
                    label="Data Fim"
                    size={3}
                    fast={false}
                    disabled={desabilitarFiltrosTipoRelatorioNaoFiscalizados}
                  />
                </Row>
                <Row>
                  <BasicInput
                    data-test-id="tipoRelatorio"
                    size={9}
                    name="tipoRelatorio"
                    label="Tipo Relatório"
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        options={tipoRelatorioGerencialSelect}
                        selectedValue={field.value}
                        onChange={(
                          value: React.ChangeEvent<HTMLInputElement>
                        ) => onChangeTipoRelatorio(value, formProps)}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <FormikAutocomplete<Usuario>
                    name="fiscal"
                    label="Fiscal"
                    onSearch={PessoaService.search}
                    getOptionLabel={(value: Pessoa) =>
                      `${formatUtils.formatCpfCnpj(value.cnpjCpf)} - ${
                        value.nome
                      }`
                    }
                    getOptionValue={(value: Pessoa) => value.id}
                    size={8}
                    fast={false}
                    disabled={desabilitarFiltrosTipoRelatorioNaoFiscalizados}
                  />
                </Row>
                <Row>
                  <BasicInput
                    data-test-id="tipoCadastro"
                    size={9}
                    name="tipoCadastro"
                    label="Tipo Cadastro"
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        options={tipoCadastroSelect}
                        selectedValue={field.value}
                      />
                    )}
                  />
                  <FormikAutocomplete<CadastroGeral>
                    name="reuExterno"
                    label="Réu Externo"
                    onSearch={search =>
                      CadastroGeralService.autoCompleteReuExterno(
                        search,
                        buildExtraFilterReuExterno(
                          formProps.values.tipoCadastro!
                        )
                      )
                    }
                    getOptionLabel={value =>
                      value.pessoa
                        ? `${formatUtils.formatCpfCnpj(value.pessoa?.cnpjCpf) ||
                            ''} - 
                    ${value.pessoa?.nome || ''} - 
                    ${value.tipoCadastro || ''} - 
                    ${value.cadastroGeral || ''}`
                        : ''
                    }
                    getOptionValue={value => value.id}
                    size={8}
                    fast={false}
                    disabled={desabilitarFiltrosTipoRelatorioNaoFiscalizados}
                  />
                </Row>
                <Row>
                  <BasicInput
                    data-test-id="tipoAgrupamento"
                    size={3}
                    name="tipoAgrupamento"
                    label="Tipo Agrupamento"
                    disabled={
                      desabilitarFiltrosTipoRelatorioNaoFiscalizados ||
                      desabilitarFiltrosTipoRelatorioQuantitativoPorFiscal
                    }
                    fast={false}
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        options={tipoAgrupamentoRelatorioGerencialSelect}
                        selectedValue={field.value}
                      />
                    )}
                  />
                </Row>

                <div className="form-group">
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={formProps.submitForm}
                    type="submit"
                  >
                    <i className="fa fa-print" />
                    Imprimir
                  </Button>
                </div>
              </>
            )}
          />
        </Row>
      </Col>
    </Container>
  );
};
