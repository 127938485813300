import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { DeclaracaoMensalServico } from './../types/DeclaracaoMensalServico.d';
import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { ServiceProps } from '../menus/BadgeValuesProvider';
import { Divergencia } from '../types/Divergencia.d';
import { DivergenciaEmpresa } from '../types/DivergenciaEmpresa';
import { DivergenciaItem } from '../types/DivergenciaItem';
import { NotaFiscal } from '../types/NotaFiscal';

const qtdProcessadosNaoVisualizados = (
  serviceProps: ServiceProps
): AxiosPromise<Divergencia[]> =>
  axios.get(`${CONTEXT_PATH}/divergencias/qtd-processados-nao-visualizado`);

const recuperarDMSDivergencia = (
  id: number,
  page?: any
): AxiosPromise<PagedResponse<DeclaracaoMensalServico>> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/dms`, {
    params: { ...page }
  });

const findDivergenciaEmpresas = (
  id: number,
  page?: any
): AxiosPromise<PagedResponse<DivergenciaEmpresa>> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/divergencias-empresas`, {
    params: { ...page }
  });

const findItensByDivergenciaAndCadastro = (
  idDivergencia: number,
  idCadastro?: number
): AxiosPromise<DivergenciaItem[]> =>
  axios.get(
    `${CONTEXT_PATH}/divergencias/itens/${idDivergencia}/${idCadastro}`
  );

const recuperarNotasDivergencia = (
  id: number,
  page?: any
): AxiosPromise<PagedResponse<NotaFiscal>> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/notas`, {
    params: { ...page }
  });

const crudService = CrudService<Divergencia>('/divergencias', {
  sort: 'dataCriacao,desc'
});

const searchDivergencia = (filtro: any) =>
  axios.post(`${CONTEXT_PATH}/divergencias/divergencias-empresas`, filtro);

const findItensDivergencia = (
  id: number,
  cadastrogeralid: number,
  search?: any,
  page?: any
): AxiosPromise<PagedResponse<DivergenciaItem>> =>
  axios.get(
    `${CONTEXT_PATH}/divergencias/${id}/${cadastrogeralid}/divergencia`,
    {
      params: { size: 10, ...page, search: search }
    }
  );

const findItemById = (id: number): AxiosPromise<DivergenciaItem> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/divergencia-item`);

const findItensDivergenciaEmpresa = (
  id: number,
  search?: any,
  page?: any
): AxiosPromise<PagedResponse<DivergenciaItem>> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/divergencia-empresa`, {
    params: { ...page, search: search }
  });

const findNotaFiscalDivergencia = (
  id: number,
  page?: any
): AxiosPromise<PagedResponse<NotaFiscal>> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/itens-divergencia`, {
    params: { ...page }
  });

const stopConsultaDivergencia = (id: number): AxiosPromise<void> =>
  axios.post(`${CONTEXT_PATH}/divergencias/stop/${id}`);

const exportToExcelDivergencia = (
  id: number,
  idCadastroGeral: number
): AxiosPromise<void> =>
  axios.get(
    `${CONTEXT_PATH}/divergencias/${id}/${idCadastroGeral}/exportExcel`
  );

const exportToPDFDivergencia = (
  id: number,
  idCadastroGeral: number
): AxiosPromise<void> =>
  axios.get(`${CONTEXT_PATH}/divergencias/${id}/${idCadastroGeral}/exportPDF`, {
    responseType: 'blob'
  });

export default {
  ...crudService,
  recuperarNotasDivergencia,
  qtdProcessadosNaoVisualizados,
  recuperarDMSDivergencia,
  findDivergenciaEmpresas,
  findItensByDivergenciaAndCadastro,
  searchDivergencia,
  stopConsultaDivergencia,
  findItensDivergencia,
  findNotaFiscalDivergencia,
  findItemById,
  findItensDivergenciaEmpresa,
  exportToExcelDivergencia,
  exportToPDFDivergencia
};
