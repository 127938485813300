import {
  Alert,
  Button,
  Checkbox,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  Field,
  FormattedDate,
  FormikTextArea,
  Loading,
  Modal,
  Panel,
  Row,
  SearchFilter,
  SearchPagination,
  SectionTitle,
  Table,
  Yup,
  formatUtils,
  showNotification,
  usePagedQuery
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { BooleanChip } from 'iss-common/components';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import SolicitacaoBaixaRedeSimService from '../../../service/SolicitacaoBaixaRedeSimService';
import { SolicitacaoBaixaRedeSim } from '../../../types/SolicitacaoBaixaRedeSim';

type Props = {};

const validationSchema = Yup.object().shape({
  justificativa: Yup.string().required()
});

const searchFields: Field[] = [
  {
    label: 'CNPJ/CPF',
    name: 'cadastroGeral.pessoa.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Protocolo',
    name: 'protocolo',
    type: 'STRING'
  },
  {
    label: 'Data de encerramento',
    name: 'dataBaixa',
    type: 'DATE'
  }
];

const valorInicialProcesso: SolicitacaoBaixaRedeSim = {
  id: 0
};

export const SolicitacaoBaixaRedeSimListPage: React.FC<Props> = () => {
  const find = useCallback(
    (search, page, sort) =>
      SolicitacaoBaixaRedeSimService.findSolicitacoes(page),
    []
  );

  const {
    values,
    pagination,
    doSearch,
    doPagedSearch,
    loading
  } = usePagedQuery<SolicitacaoBaixaRedeSim>({
    search: find,
    onError: useCallback(error => {
      Alert.error({ title: 'Erro ao buscar as solicitações de baixa.' }, error);
    }, [])
  });

  const history = useHistory();
  const [solicitacoesBaixa, setSolicitacoesBaixa] = useState<
    SolicitacaoBaixaRedeSim[]
  >([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ignorarSelecionados = async (value: SolicitacaoBaixaRedeSim) => {
    const result = await Alert.question({
      title: 'Deseja ignorar as solicitações selecionadas?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      target: '#alert'
    });
    if (result.value) {
      setIsLoading(true);
      const solicitacoes: SolicitacaoBaixaRedeSim[] =
        solicitacoesBaixa
          ?.filter((solicitacao: SolicitacaoBaixaRedeSim) => {
            return solicitacao.selecionado;
          })
          .map((solicitacao: SolicitacaoBaixaRedeSim) => {
            return {
              id: solicitacao.id,
              justificativa: value.justificativa
            };
          }) ?? [];
      SolicitacaoBaixaRedeSimService.ignorar(solicitacoes)
        .then(() => {
          showNotification({
            message: 'Solicitações ignoradas com sucesso',
            level: 'success'
          });
          window.location.reload();
        })
        .catch(error => {
          Alert.error(
            { title: 'Ocorreu um erro ao ignorar solicitações' },
            error
          );
        })
        .finally(() => {
          setIsLoading(false);
          onCloseModal();
        });
    }
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const gerarProcesso = async () => {
    const result = await Alert.question({
      title: 'Deseja gerar processo dos cadastros selecionados?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      target: '#alert'
    });
    if (result.value) {
      setIsLoading(true);
      const solicitacoes: SolicitacaoBaixaRedeSim[] =
        solicitacoesBaixa?.filter((solicitacao: SolicitacaoBaixaRedeSim) => {
          return solicitacao.selecionado;
        }) ?? [];
      history.push('/processo-solicitacao-baixa/novo', solicitacoes);
    }
  };

  const peloMenosUmSelecionado =
    values?.some(solicitacao => solicitacao.selecionado) ?? false;

  const onHandleChangeCheckAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    solicitacoes: SolicitacaoBaixaRedeSim[]
  ) => {
    const { checked } = e.target;
    setSolicitacoesBaixa(
      solicitacoes.map(solicitacao => {
        solicitacao.selecionado = checked;
        return solicitacao;
      })
    );
  };

  const onhandleChangeCheckBoxSolicitacoes = (
    solicitacaoSelecionada: SolicitacaoBaixaRedeSim
  ) => {
    setSolicitacoesBaixa(
      values?.map(solicitacao => {
        if (solicitacao.id === solicitacaoSelecionada.id) {
          solicitacao.selecionado = !solicitacao.selecionado;
        }
        return solicitacao;
      })
    );
  };

  return (
    <Container breadcrumb={false} title="Solicitações de Baixa - RedeSim">
      <SectionTitle marginTop="0">
        Solicitações de Baixa da RedeSim
      </SectionTitle>
      <Panel isTable>
        <Loading loading={isLoading || loading} />
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table
          loading={loading}
          values={values}
          keyExtractor={(item: SolicitacaoBaixaRedeSim) => `${item.id}`}
        >
          <Table.Column<SolicitacaoBaixaRedeSim>
            header={
              <div className="hidden-xs">
                <Checkbox
                  checked={
                    solicitacoesBaixa.length > 0 &&
                    solicitacoesBaixa.every(
                      solicitacao => solicitacao.selecionado
                    )
                  }
                  id="checkAll"
                  data-test-id="check-selecionar-tudo"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onHandleChangeCheckAll(event, values)
                  }
                />
              </div>
            }
            value={(processo: SolicitacaoBaixaRedeSim, index: number) => {
              return (
                <Checkbox
                  id={`${index}`}
                  data-test-id="check-selecionar-individual"
                  checked={processo.selecionado}
                  onChange={() => onhandleChangeCheckBoxSolicitacoes(processo)}
                />
              );
            }}
          ></Table.Column>
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="Código"
            value={item => item.id}
          ></Table.Column>
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="Nº Cadastro"
            value={item =>
              item.cadastroGeral ? item.cadastroGeral?.cadastroGeral : ''
            }
          ></Table.Column>
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="Razão Social"
            value={item =>
              item.cadastroGeral ? item.cadastroGeral.pessoa?.nome : ''
            }
          ></Table.Column>
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="CNPJ"
            value={item =>
              formatUtils.formatCpfCnpj(item.cadastroGeral?.pessoa?.cnpjCpf)
            }
          />
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="Protocolo"
            value={item => (item.protocolo ? item.protocolo : '')}
          ></Table.Column>
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="Data da Baixa"
            value={item => <FormattedDate value={item.dataBaixa} />}
          ></Table.Column>
          <Table.Column<SolicitacaoBaixaRedeSim>
            header="Possui débitos em aberto"
            value={item => (
              <BooleanChip value={item.possuiDebitosEmAberto ?? false} />
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      {showModal && (
        <Modal onClose={onCloseModal}>
          {
            <Panel isForm title="Ignorar Solicitações">
              <Formik
                initialValues={valorInicialProcesso}
                enableReinitialize
                onSubmit={ignorarSelecionados}
                validationSchema={validationSchema}
                render={(formProps: FormikProps<SolicitacaoBaixaRedeSim>) => (
                  <>
                    <Row id={'alert'}>
                      <FormikTextArea
                        data-test-id="justificativa"
                        name="justificativa"
                        label="Justificativa"
                        size={12}
                        rows={12}
                      />
                    </Row>
                    <Row>
                      <Col md={12} className="form-group">
                        <Button
                          onClick={formProps.submitForm}
                          color="positive"
                          className="inline mt-xs mb-xs"
                        >
                          Confirmar
                        </Button>
                        <Button
                          onClick={onCloseModal}
                          color="neutral"
                          className="inline mt-xs mb-xs"
                        >
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              ></Formik>
            </Panel>
          }
        </Modal>
      )}
      <div className="btn-save">
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          {peloMenosUmSelecionado && (
            <FAB
              icon="file-invoice"
              title="Gerar Processos"
              onClick={() => gerarProcesso()}
            />
          )}
          {peloMenosUmSelecionado && (
            <FAB
              icon="eye-slash"
              iconColor="white"
              title="Ignorar"
              onClick={() => onShowModal()}
            />
          )}
        </FabSpeedDial>
      </div>
    </Container>
  );
};
