import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import { ServiceUtils } from 'iss-common/utils';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { ApuracaoIssNota } from '../types/ApuracaoIssNota';

const crudService = CrudService<ApuracaoIssNota>('/apuracao-iss-nota');

const findAllNotasByIdAtoAnoMes = (
  id: any,
  ano: any,
  mes: any
): AxiosPromise<ApuracaoIssNota[]> =>
  axios.get(
    `${CONTEXT_PATH}/apuracao-iss-nota/notas-por-id-apuracao/${id}/${ano}/${mes}`
  );

const filterAutocomplete = (search: any) => {
  if (search) {
    if (isNaN(search)) {
      return FilterUtils.buildDefaultFilter('descricao')(search);
    }

    return FilterUtils.buildDefaultFilter('id')(search);
  }

  return '';
};

export const loadAutoComplete = (searchParams: any, page = 50) =>
  axios.get(
    `${CONTEXT_PATH}/apuracao-iss-nota/tipos-documento?${ServiceUtils.buildSearchParams(
      filterAutocomplete(searchParams)
    )}`
  );

export default {
  ...crudService,
  loadAutoComplete,
  findAllNotasByIdAtoAnoMes
};
